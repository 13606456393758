import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Text, Title, Row, Col, Image, Form, CheckboxInput, ModalFormActions } from 'ui';

import { type InfoData } from '../../types';

export type InfoFormData = InfoData;

type P = {
  initialData?: InfoFormData;
  onSubmit: (values: InfoFormData) => void;
};

const SubscribeInfo: FC<P> = ({ initialData, onSubmit }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<InfoFormData>();

  const initialValues: Partial<InfoFormData> = initialData ?? {};

  const handleFinish = (values: InfoFormData) => {
    onSubmit(values);
  };

  return (
    <Form<InfoFormData> form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Section className="subscribe-info">
        <Row>
          <Col item={{ className: 'subscribe-info__image' }}>
            <Image
              preview={false}
              src="/assets/course/withings.jpg"
              srcSet="/assets/course/withings.jpg 1000w, /assets/course/withings.png 4000w"
            />
          </Col>
          <Col>
            <Title level={3} className="subscribe-info__title">
              By completing the following order, you will receive Health devices free of charge
            </Title>
          </Col>
          <Col item={{ className: 'subscribe-info__item' }}>
            <Text>
              The devices will be delivered to your delivery address within two weeks. In the next steps it is necessary
              to enter your personal data, confirm the delivery address. The devices will be customized for you.
            </Text>
          </Col>
        </Row>

        <CheckboxInput
          item={{
            label: (
              <Text>
                {t('I agree to the')}{' '}
                <a href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">
                  {t('Terms & Conditions')}
                </a>
              </Text>
            ),
            name: 'terms',
            valuePropName: 'checked',
            rules: [
              {
                validator: async (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('This field is required')),
              },
            ],
          }}
        />

        <CheckboxInput
          item={{
            label: t('I agree to receive commercial and marketing emails from Withings'),
            name: 'marketing',
            valuePropName: 'checked',
          }}
        />
      </Section>
      <ModalFormActions submit={{ children: 'Continue' }} />
    </Form>
  );
};

export default SubscribeInfo;
