import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { type RadioChangeEvent } from 'antd/lib/radio';
import { type CategoryDeprecated } from 'models';
import { type Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adminDashboardRedirect, routes } from 'common/admin-dashboard';
import type Program from 'models/program';
import { type ImageDataObject, type OrganizationDataObject } from 'models/service';
import { type AutocompleteUser, type ReduxUser } from 'models/user';
import {
  Alert,
  Button,
  Col,
  DateTimeInput,
  EmptyInput,
  Form,
  HelpIcon,
  ImageInputDeprecated,
  InformationFillIcon,
  NumberInput,
  RadioGroupInput,
  RichTextInput,
  Row,
  Section,
  SelectInput,
  SwitchInput,
  Text,
  TextInput,
  Title,
  Tooltip,
} from 'ui';
import {
  ProgramFeaturedParticipants,
  ProgramModules,
  ProgramOutcomes,
  ProgramSponsors,
  ProgramUsersSelect,
} from './features';
import ProgramChannelsOptions from './ProgramChannelsOptions';

export type ProgramFormValues = {
  cover_photo: ImageDataObject;
  name: string;
  organization: string;
  tag_ids?: string[];
  categories: string[];
  author_name: string;
  author_logo?: ImageDataObject;
  author_overview_text?: string;
  html_author_overview?: string;
  text_content?: string;
  html_content?: string;
  start_time: Moment;
  end_time: Moment;
  price_option: 'contact_us' | 'free' | 'paid';
  price?: number;
  sponsors?: Array<{
    name: string;
    price: number;
    logo?: ImageDataObject;
    description?: string;
    html_description?: string;
  }>;
  who_should_enroll?: string;
  expected_outcomes?: string[];
  topics: Array<{ name: string; description?: string; has_content?: boolean }>;
  administrators?: Array<{ id: string }>; // TODO check if correct
  instructors?: Array<{ id: string }>; // TODO check if correct
  featured_participants?: Array<{
    name: string;
    logo?: ImageDataObject;
    description?: string;
    html_description?: string;
  }>;
  allow_comments?: boolean;
  mandatory: boolean;
  supportsWithingsDevices: boolean;
  withingsProgram?: string;
  privacy: 'private' | 'public' | 'secret';
  is_medical?: boolean;
  show_in_channels?: boolean;
  featured_in_channels?: boolean;
};

type P = {
  // TODO fix
  onSubmit: (program: any) => void;
  getUsers: (scope: string, value: string, limit?: number) => Promise<AutocompleteUser[]>;
  loading: boolean;
  categories: CategoryDeprecated[];
  organizations: OrganizationDataObject[];
  error?: Error;
  program: Program;
  currentUser: ReduxUser;
  formTitle: string;
  organizationId?: string;
};

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
const PROGRAM_MAX_LENGTH = 300;

const getInitialValues = (program: Program, organizationId?: string): Partial<ProgramFormValues> => {
  return {
    cover_photo: program.cover_photo,
    name: program.name,
    organization: program.organization?.id ?? organizationId,
    tag_ids: program.tags?.map((tagItem) => tagItem.id),
    categories: program.categories?.map((category: CategoryDeprecated) => category.id),
    author_name: program.author_name,
    author_logo: program.author_logo,
    author_overview_text: program.author_overview_text,
    html_author_overview: program.html_author_overview,
    text_content: program.text_content,
    html_content: program.html_content,
    start_time: program.start_time,
    end_time: program.end_time,
    price_option: program.price_option,
    price: program.price,
    sponsors: program.sponsors,
    who_should_enroll: program.who_should_enroll,
    expected_outcomes: program.expected_outcomes,
    topics: program.topics,
    administrators: program.administrators,
    instructors: program.instructors,
    featured_participants: program.featured_participants,
    allow_comments: program.settings.allow_comments,
    mandatory: program.mandatory,
    supportsWithingsDevices: program.withings_devices.length > 0,
    withingsProgram: program.withings_devices?.[0] ?? String(process.env.REACT_APP_WITHINGS_EAN_RPM_PROGRAM),
    privacy: program.privacy,
    is_medical: !program.settings.view_members,
    show_in_channels: program.show_in_channels,
    featured_in_channels: program.featured_in_channels,
  };
};

const ProgramForm = ({
  onSubmit,
  getUsers,
  categories,
  organizations,
  program,
  currentUser,
  loading,
  formTitle,
  organizationId,
}: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const initialValues = getInitialValues(program, organizationId);

  const allOrganizations = useMemo(() => {
    const allOrganizations: Array<{ id: string; name: string }> = [...organizations];

    if (
      program.organization &&
      !allOrganizations.some((organization) => organization.id === program.organization?.id)
    ) {
      allOrganizations.push(program.organization);
    }

    return allOrganizations;
  }, [organizations, program.organization]);

  const resetChannelsOptions = () => {
    form.setFieldsValue({ show_in_channels: false, featured_in_channels: false, tag_ids: [] });
  };

  const isMedicalOrganization = (organizationId?: string) => {
    // TODO this is now hack
    // Viewer can be possibly not admin of Program organization in edit, so `program.organization.id` is not part of array `organizations`
    if (editProgram && organizationId === program.organization?.id) {
      return !program.settings.view_members;
    }

    const organization = organizations.find((org) => org.id === organizationId);
    return organization?.settings.allow_medical_community ?? false;
  };

  const [isPublicProgram, setIsPublicProgram] = useState<boolean>(program.privacy === 'public');
  const editProgram = Boolean(program.id);
  const editProgramMedical = Boolean(editProgram && isMedicalOrganization(program.organization?.id));
  const editWlaProgram = Boolean(editProgram && program.featured_in_wla);

  const setUsersField = (users: AutocompleteUser[], name: string) => {
    form.setFieldsValue({
      [name]: users,
    });
  };

  const onFinish = ({
    organization,
    administrators,
    categories,
    allow_comments,
    is_medical,
    supportsWithingsDevices,
    withingsProgram,
    price,
    ...rest
  }: ProgramFormValues) => {
    const isMedical = Boolean(isMedicalOrganization(organization) && is_medical);

    const programData = {
      ...rest,
      organization_id: organization, // TODO maybe rename?
      admin_ids: administrators?.map((admin) => admin.id),
      category_ids: categories, // TODO maybe rename?
      price: rest.price_option === 'paid' ? price : null,

      // TODO maybe fix logically correctly
      settings: {
        allow_comments: isMedical ? false : allow_comments,
        view_members: !isMedical,
        allow_unsubscribe: !isMedical,
      },

      withings_devices: supportsWithingsDevices ? [withingsProgram] : [],
      show_in_channels: rest.show_in_channels ?? false,
      featured_in_channels: rest.featured_in_channels ?? false,
      tag_ids: rest.tag_ids ?? [],
    };

    onSubmit(programData);
  };

  useEffect(() => {
    form.resetFields();
  }, [program, form]);

  return loading ? (
    <Col item={{ className: 'program-form__loading' }}>
      <LoadingOutlined />
    </Col>
  ) : (
    <Form<ProgramFormValues> initialValues={initialValues} onFinish={onFinish} form={form}>
      <Title level={2} className="program-form__title">
        {formTitle}
      </Title>
      <Section lineBottom={false}>
        <ImageInputDeprecated item={{ name: 'cover_photo' }} aspectRatio={{ width: 960, height: 300 }} />
      </Section>
      <Section paddingTop={false} paddingBottom={false}>
        <Title level={3}>Basic information</Title>
        <TextInput
          item={{
            name: 'name',
            label: 'Program name',
            rules: [
              {
                required: true,
                message: t('Please provide program name'),
                whitespace: true,
              },
              {
                max: PROGRAM_MAX_LENGTH,
                message: t('Program name cannot be longer than {{maxLength}} characters', {
                  maxLength: PROGRAM_MAX_LENGTH,
                }),
              },
            ],
          }}
        />
        <SelectInput
          item={{
            name: 'organization',
            label: 'Organization',
            rules: [
              {
                required: true,
                message: t('Please, select an organization'),
              },
            ],
            'data-test-id': 'program_select-organization',
          }}
          input={{
            showSearch: true,
            placeholder: t('Select organization'),
            options: allOrganizations,
            getOptionLabel: (organization) => organization.name,
            getOptionValue: (organization) => organization.id,
            disabled: editProgram,
            onChange(value: any) {
              form.setFieldsValue({
                is_medical: isMedicalOrganization(value),
                privacy: isMedicalOrganization(value) ? 'secret' : 'private',
              });
              resetChannelsOptions();
            },
          }}
        />

        <SelectInput
          item={{
            name: 'categories',
            label: 'Categories',
            rules: [
              {
                required: true,
                message: t('Please select at least one category'),
              },
            ],
            'data-test-id': 'program_select-category',
          }}
          input={{
            placeholder: t('Select category'),
            mode: 'multiple',
            options: categories,
            getOptionLabel: (category) => category.name,
            getOptionValue: (category) => category.id,
          }}
        />
        <TextInput
          item={{
            name: 'author_name',
            label: 'Author / Provider',
            rules: [
              {
                required: true,
                message: t('Please provide Author / Provider name'),
                whitespace: true,
              },
            ],
          }}
        />
        <Row>
          <Col item={{ span: 8 }}>
            <ImageInputDeprecated
              item={{ label: 'Upload author logo', name: 'author_logo' }}
              aspectRatio={{ width: 500, height: 500 }}
            />
          </Col>
        </Row>
        <RichTextInput
          text={{ name: 'author_overview_text' }}
          html={{
            name: 'html_author_overview',
            label: 'Author / Provider content',
            maxWidth: '643px',
            'data-test-id': 'program_author-content',
          }}
        />
        <RichTextInput
          text={{ name: 'text_content' }}
          html={{ name: 'html_content', label: 'Overview', maxWidth: '643px', 'data-test-id': 'program_overview' }}
        />
      </Section>
      <Section paddingTop paddingBottom={false}>
        <Title level={3}>Dates and price</Title>

        <DateTimeInput
          item={{
            name: 'start_time',
            label: 'Start date and time',
            rules: [
              {
                required: true,
                message: t('Please provide start date'),
              },
            ],
            getValueFromEvent(event: Moment) {
              const endTime = form.getFieldValue('end_time') as Moment;
              if (event.isAfter(endTime.subtract(60, 'minutes'))) {
                form.setFieldsValue({
                  end_time: event.clone().add(60, 'minutes'),
                });
              }

              return event;
            },
          }}
          input={{
            format: 'MMM D, YYYY [at] h:mm A',
            showTime: { format: 'h:mm A' },
            'data-test-id': 'program_start-time',
          }}
        />
        <DateTimeInput
          item={{
            name: 'end_time',
            label: 'End date and time',
            rules: [
              {
                required: true,
                message: t('Please provide end date'),
              },
            ],
            getValueFromEvent(event: Moment) {
              const startTime = form.getFieldValue('start_time') as Moment;
              if (event.isBefore(startTime) || event.diff(startTime, 'minutes') < 60) {
                return startTime.clone().add(60, 'minutes');
              }

              return event;
            },
          }}
          input={{
            format: 'MMM D, YYYY [at] h:mm A',
            showTime: { format: 'h:mm A' },
            'data-test-id': 'program_end-time',
          }}
        />
        <Row item={{ gutter: 30 }}>
          <Col item={{ span: 24 }}>
            <RadioGroupInput
              item={{
                name: 'price_option',
                label: t('Price options'),
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              input={{
                options: [
                  {
                    label: t('Paid'),
                    value: 'paid',
                    id: 'option_paid',
                  },
                  {
                    label: t('Free'),
                    value: 'free',
                    id: 'option_free',
                  },
                  {
                    label: t('Contact us'),
                    value: 'contact_us',
                    id: 'option_contact_us',
                  },
                ],
              }}
            />
          </Col>
        </Row>
        <Form.Item noStyle dependencies={['price_option']}>
          {({ getFieldValue }) =>
            getFieldValue('price_option') === 'paid' ? (
              <Row item={{ gutter: 30 }}>
                <Col item={{ span: 24 }}>
                  <NumberInput
                    item={{
                      name: 'price',
                      label: t('Price'),
                      rules: [
                        {
                          message: t('Price is required'),
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          // eslint-disable-next-line @typescript-eslint/require-await
                          async validator(_rule, value) {
                            const sponsorsSum = getFieldValue('sponsors')?.reduce(
                              (previous: number, current?: { price: number }) =>
                                current ? previous + current.price : previous,
                              0,
                            );
                            if (sponsorsSum && sponsorsSum >= value) {
                              throw t('Price must be higher than sponsors prices');
                            }
                          },
                        }),
                      ],
                    }}
                    input={{
                      placeholder: '0.00',
                      min: 0,
                      'data-test-id': 'program_price',
                      addonBefore: '$',
                    }}
                  />
                </Col>
              </Row>
            ) : null
          }
        </Form.Item>
        <ProgramSponsors name="sponsors" />
      </Section>
      <Section paddingTop paddingBottom={false}>
        <Title level={3}>Who should enroll in and outcomes</Title>
        <TextInput
          item={{
            name: 'who_should_enroll',
            label: 'Who should enroll',
          }}
        />
        <ProgramOutcomes name="expected_outcomes" />
      </Section>
      <Section paddingTop paddingBottom={false}>
        <Title level={3}>Modules</Title>
        <Text className="modules__subtitle">Modules with attached content can&apos;t be removed</Text>
        <ProgramModules name="topics" modules={program.topics} />
      </Section>
      <Section paddingTop paddingBottom={false}>
        <Title level={3}>Instructors, administrators and featured participants</Title>
        {program.author?.id === currentUser.id || currentUser.superadmin || !editProgram ? (
          <>
            <EmptyInput<AutocompleteUser[]> name="administrators" />
            <Form.Item noStyle dependencies={['administrators']}>
              {({ getFieldValue }) => {
                return (
                  <ProgramUsersSelect
                    name="administrators"
                    label="Administrators"
                    okText="Invite"
                    modalTitle="Invite administrators"
                    scope="all"
                    buttonLabel="Add administrator"
                    onSubmit={setUsersField}
                    getUsers={getUsers}
                    chosen={getFieldValue('administrators')}
                  />
                );
              }}
            </Form.Item>
          </>
        ) : null}
        <EmptyInput<AutocompleteUser[]> name="instructors" />
        <Form.Item noStyle dependencies={['instructors']}>
          {({ getFieldValue }) => {
            return (
              <ProgramUsersSelect
                name="instructors"
                label="Instructors"
                okText="Invite"
                modalTitle="Invite instructors"
                scope="all"
                buttonLabel="Add instructor"
                onSubmit={setUsersField}
                getUsers={getUsers}
                chosen={getFieldValue('instructors')}
                hasUserDescription
              />
            );
          }}
        </Form.Item>
        <ProgramFeaturedParticipants name="featured_participants" />
      </Section>
      <Section paddingTop paddingBottom={false}>
        <Title level={3}>Settings</Title>
        <Form.Item noStyle dependencies={['is_medical', 'organization']}>
          {({ getFieldValue }) => {
            const organizationId = getFieldValue('organization');
            const isMedical = getFieldValue('is_medical');

            if (isMedicalOrganization(organizationId) && isMedical) {
              return null;
            }

            return (
              <SwitchInput
                item={{
                  name: 'allow_comments',
                  label: 'Allow comments',
                  id: 'program_comments',
                }}
                input={{
                  disabled: editProgramMedical,
                  'data-test-id': 'btn_comments',
                }}
              />
            );
          }}
        </Form.Item>

        {editWlaProgram ? null : (
          <SwitchInput
            item={{
              name: 'mandatory',
              label: 'Member is automatically enrolled',
              tooltip: {
                title: t(
                  'It prevents program members from leaving the program after they are invited to the program. Automatically joins new community members of the invited communities to the program.',
                ),
                icon: <InfoCircleOutlined />,
              },
            }}
            input={{
              'data-test-id': 'btn_mandatory',
            }}
          />
        )}

        <SwitchInput
          item={{
            name: 'supportsWithingsDevices',
            label: 'Support Withings/Dexcom integration',
            tooltip: { title: t('withingsInfoMessage'), icon: <InfoCircleOutlined /> },
          }}
          input={{
            disabled: editProgram,
            'data-test-id': 'btn_withings_support',
          }}
        />

        {editWlaProgram ? (
          <Form.Item dependencies={['organization']}>
            {({ getFieldValue }) => {
              const organizationId = getFieldValue('organization');

              return (
                <Alert
                  className="info-message"
                  icon={<InformationFillIcon />}
                  showIcon
                  type="info"
                  description={t(
                    'Mandatory enrollment is automatically turned on because program is set as Featured for this WLA. To change this go to Admin Console.',
                  )}
                  action={
                    <Button
                      type="link"
                      onClick={async () => adminDashboardRedirect(routes.dataManagement.organization(organizationId))}
                    >
                      Go to Admin Console
                    </Button>
                  }
                />
              );
            }}
          </Form.Item>
        ) : null}

        <Form.Item
          noStyle
          shouldUpdate={(previousValues, currentValues) =>
            previousValues.supportsWithingsDevices !== currentValues.supportsWithingsDevices
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue('supportsWithingsDevices') === true ? (
              <RadioGroupInput
                item={{
                  name: 'withingsProgram',
                  label: 'Choose program',
                  id: 'withings_program',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  options: [
                    {
                      label: (
                        <>
                          <Title level={5} className="withings-radio-label">
                            RPM program
                          </Title>
                          <Text type="secondary" size="small" className="program-form__withings-radio__text">
                            BPM Connect Blood Pressure Cuff + Body Pro Scale + Dexcom Glucometer
                          </Text>
                        </>
                      ),
                      value: String(process.env.REACT_APP_WITHINGS_EAN_RPM_PROGRAM),
                      'data-test-id': 'rpm_program',
                    },
                    {
                      label: (
                        <>
                          <Title level={5} className="withings-radio-label">
                            DPP program
                          </Title>
                          <Text type="secondary" size="small" className="program-form__withings-radio__text">
                            Move Activity Tracker Watch + Body Pro Scale
                          </Text>
                        </>
                      ),
                      value: '3700546707766',
                      'data-test-id': 'dpp_program',
                    },
                  ],
                  className: 'program-form__withings-radio',
                  disabled: editProgram,
                }}
              />
            ) : null;
          }}
        </Form.Item>
        <div className="program-form__status">
          <Form.Item noStyle dependencies={['organization']}>
            {({ getFieldValue }) => {
              const organizationId = getFieldValue('organization');

              const options = [
                {
                  label: (
                    <>
                      {t('Public')}
                      {currentUser.superadmin ? null : (
                        <>
                          {' '}
                          <Tooltip
                            item={{
                              title:
                                'This option is available only for super admins. If you need to set this program to Public, please contact a super admin for assistance.',
                            }}
                          >
                            <HelpIcon />
                          </Tooltip>
                        </>
                      )}
                    </>
                  ),
                  value: 'public',
                  disabled: !currentUser.superadmin,
                  'data-test-id': 'public',
                },
                {
                  label: t('Private'),
                  value: 'private',
                  'data-test-id': 'private',
                },
                {
                  label: t('Secret'),
                  value: 'secret',
                  'data-test-id': 'secret',
                },
              ];

              if (isMedicalOrganization(organizationId)) {
                options.splice(0, 2);
              }

              return (
                <>
                  <RadioGroupInput
                    item={{
                      name: 'privacy',
                      label: 'Privacy',
                      id: 'program_privacy',
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    }}
                    input={{
                      disabled: editProgramMedical || editWlaProgram,
                      options,
                      onChange(event: RadioChangeEvent) {
                        const selectedPrivacy = event.target.value;

                        setIsPublicProgram(selectedPrivacy === 'public');

                        if (selectedPrivacy === 'secret') {
                          resetChannelsOptions();
                        }
                      },
                    }}
                  />
                  {!currentUser.superadmin && isPublicProgram ? (
                    <Text>A request for approval will be created. The change will not be immediate.</Text>
                  ) : null}
                </>
              );
            }}
          </Form.Item>
        </div>
        <Form.Item noStyle dependencies={['organization']}>
          {({ getFieldValue }) => {
            const organizationId = getFieldValue('organization');
            if (!organizationId || !isMedicalOrganization(organizationId)) {
              return null;
            }

            return (
              <RadioGroupInput
                item={{
                  name: 'is_medical',
                  label: 'Program type',
                  id: 'program_is_medical',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  disabled: editProgram,
                  options: [
                    {
                      label: t('Medical'),
                      value: true,
                      'data-test-id': 'enable-is_medical',
                    },
                    {
                      label: t('Non-Medical'),
                      value: false,
                      'data-test-id': 'disable-is_medical',
                    },
                  ],
                }}
              />
            );
          }}
        </Form.Item>
      </Section>

      <ProgramChannelsOptions<ProgramFormValues> organizations={organizations} viewer={currentUser} />

      <Section paddingTop lineTop={false} lineBottom={false}>
        <Button id="btn_program-submit" htmlType="submit" type="primary" size="large">
          Submit
        </Button>
      </Section>
    </Form>
  );
};

export default ProgramForm;
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
