import { type ContentObjectRedux } from 'models';
import { images } from 'common/utils';
import { type ReduxUser, type AutocompleteUser } from 'models/user';

import { type MenuProps, ReadMore } from 'ui';
import { ContentObjectFooter } from '../ContentObjectFooter';
import { ContentObjectHeader, type Pilltype } from '../ContentObjectHeader';

type P = {
  author: AutocompleteUser;
  renderMessageLine: void;
  renderAttachments: () => JSX.Element | null;
  isOnFeed?: boolean;
  actions?: MenuProps['items'];
  timestamp: string;
  pill?: Pilltype;
  item: ContentObjectRedux;
  viewer: ReduxUser;
};

const CommentBookmark = ({
  author,
  renderMessageLine,
  renderAttachments,
  isOnFeed,
  actions,
  timestamp,
  pill,
  item,
  viewer,
}: P) => {
  const commentBody = (
    <div className="comment-bookmark__body">
      {renderMessageLine}
      {renderAttachments()}
    </div>
  );

  return (
    <div className="comment-bookmark">
      <ContentObjectHeader
        title={`${author.first_name} ${author.last_name}`}
        actions={actions}
        subtitle={timestamp}
        avatar={{ url: author.profile_photo?.url ?? images.default.userProfilePhoto }}
        pill={pill}
        authorId={author.id}
      />
      {isOnFeed ? <ReadMore>{commentBody}</ReadMore> : commentBody}
      <ContentObjectFooter item={item} viewer={viewer} view="bookmark" />
    </div>
  );
};

export default CommentBookmark;
