import { useEffect } from 'react';
import { CARDS_IN_ROW } from 'features/channels';
import { Slider } from 'ui';
import { EventCard } from '../../../events/components/EventCard';
import { useIsChannelEmpty, usePageCursor } from '../../hooks';
import { useFeaturedEvents } from './hooks';

type P = {
  getPathToEventDetail: (id: string) => string;
  organizationId?: string;
  title?: string;
  onSeeAll?: () => void;
  view: 'detail' | 'preview';
  onEmptyState?: (isEmpty: boolean) => void;
};

const EventsSlider = ({ getPathToEventDetail, organizationId, title, onSeeAll, view, onEmptyState }: P) => {
  const [pageCursor, setPageCursor] = usePageCursor(organizationId);
  const {
    featured,
    query: { data: featuredEvents, isFetching: isFetchingFeatured },
  } = useFeaturedEvents({
    onSeeAll,
    organizationId,
    pagination: { pageSize: CARDS_IN_ROW, position: pageCursor },
  });

  const showSeeAllButton = onSeeAll && (featuredEvents?.length ?? 0) > 0;

  const isEmpty = useIsChannelEmpty(isFetchingFeatured, pageCursor, featuredEvents);

  // TODO fix not working without organizationId, this hack is needed together with useEffect in default channels to set empty state correctly
  useEffect(() => {
    onEmptyState?.(isEmpty);
  }, [isEmpty, onEmptyState, organizationId]);

  if (isEmpty) {
    return null;
  }

  return (
    <Slider
      isLoading={isFetchingFeatured}
      items={featuredEvents?.map((event) => (
        <EventCard event={event} getPathToEventDetail={getPathToEventDetail} key={`event-${event.id}`} />
      ))}
      title={title}
      queryData={{
        limit: CARDS_IN_ROW,
        hasNextPage: CARDS_IN_ROW === featuredEvents?.length,
        hasPreviousPage: pageCursor > 0,
      }}
      clickActions={{
        onPrevious() {
          setPageCursor((pageCursor) => Math.max(pageCursor - 1, 0));
        },
        onNext() {
          setPageCursor((pageCursor) => pageCursor + 1);
        },
        onSeeAll: showSeeAllButton ? onSeeAll : undefined,
      }}
      hideArrows={!featured}
      view={view}
    />
  );
};

export default EventsSlider;
