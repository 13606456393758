import { type Pagination, type PaginationData } from 'models';

import { useQuery } from 'query';
import { getPrograms } from '../services';
import {
  type ProgramListDataObject,
  getProgramListData,
  type ProgramListItem,
  type ProgramStatus,
  type ProgramsScope,
} from '../types';
import { getProgramsQueryKey } from '.';

type P = {
  scope?: ProgramsScope;
  search?: string;
  organizationId?: string;
  featured?: boolean;
  status?: ProgramStatus;
  pagination?: Pagination;
};

export const useProgramsQuery = ({ scope, pagination, organizationId, featured, search, status }: P) => {
  return useQuery<PaginationData<ProgramListItem>>(
    getProgramsQueryKey({ scope, pagination, organizationId, featured, search }),
    async () => {
      const { pageSize, position } = pagination ?? {};

      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const response = await getPrograms({
        scope,
        limit: pageSize,
        offset: position !== undefined && pageSize !== undefined ? position * pageSize : undefined,
        organization_id: organizationId,
        featured,
        status,
        view: 'table',
        search,
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return {
        data: response.data.data.map((programDataObject: ProgramListDataObject) =>
          getProgramListData(programDataObject),
        ),
        total: response.data.total,
      };
    },
  );
};
