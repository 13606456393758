import { type ReduxUser } from 'models';
import { useState } from 'react';
import variables from 'common/styles/variables.json';
import { canCreateVideo } from 'permissions';
import { AddLine, Button, Col, Form, Row, TextInput, Title } from 'ui';
import { CreateVideoModal } from './CreateVideoModal';
import { VideoList } from './VideoList';

type FormValues = {
  search?: string;
};

type P = {
  viewer: ReduxUser;
  getPathToVideoDetail: (id: string) => string;
};

const Videos = ({ viewer, getPathToVideoDetail }: P) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Form<FormValues> noOptional>
      <Title level={1}>Videos</Title>
      <Row item={{ gutter: variables.spaceMd.value, align: 'middle', justify: 'space-between' }}>
        <Col>
          <TextInput
            item={{
              name: 'search',
              label: 'Search',
            }}
          />
        </Col>

        {canCreateVideo({ viewer }) ? (
          <Col>
            <Button
              onClick={() => {
                setIsOpened(true);
              }}
              type="link"
              icon={<AddLine />}
            >
              Add video
            </Button>
          </Col>
        ) : null}
      </Row>

      <Form.Item<FormValues> noStyle dependencies={['search']}>
        {({ getFieldsValue }) => {
          const { search = '' } = getFieldsValue();

          return <VideoList getPathToVideoDetail={getPathToVideoDetail} search={search} />;
        }}
      </Form.Item>

      <CreateVideoModal
        isOpened={isOpened}
        onCancel={() => {
          setIsOpened(false);
        }}
      />
    </Form>
  );
};

export default Videos;
