import { useTranslation } from 'react-i18next';

import { Avatar } from 'ui';
import { CircleIcon } from '../icon';
import { ControlIcon } from '../text-editor/ControlIcon';
import { Text } from 'ui';
import { ThemeConsumer } from '../theme';
import { Loader } from '../loader';
import { images } from 'common/utils';
import cs from 'classnames';

export function HeaderWithAccessoryView(props) {
  return (
    <div className="header-with-accessory">
      <div className="header-with-accessory__avatar">
        <Avatar photo={props.viewer.profile_photo ?? images.default.userProfilePhoto} />
      </div>
      <div className="header-with-accessory__inner">{props.children}</div>
    </div>
  );
}

export function AttachmentTrigger(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div className="attachment-trigger" data-test-id="icon_control-attachment">
          <div className="attachment-trigger__wrapper">
            {props.uploading ? <Loader size={14} /> : <ControlIcon name="pba-attachment" size={24} />}
            {props.active ? (
              <div className="attachment-trigger__active" style={{ background: theme.color.brand }} />
            ) : null}
          </div>
        </div>
      )}
    </ThemeConsumer>
  );
}

export function PinnedStateToggle(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          className="pinned-state-toggle"
          onClick={() => props.onToggle(!props.active)}
          data-test-id="toggle_pin-post"
        >
          <Text
            className={cs('pinned-state-toggle__text', {
              'pinned-state-toggle__text-active': props.active,
              'pinned-state-toggle__text-default': !props.active,
            })}
          >
            {props.title}
            <CircleIcon
              size={28}
              name="pin-1"
              className={cs('pinned-state-toggle__icon', {
                'pinned-state-toggle__icon-active': props.active,
                'pinned-state-toggle__icon-default': !props.active,
              })}
              background={props.active ? theme.color.brand : theme.color.text}
            />
          </Text>
        </div>
      )}
    </ThemeConsumer>
  );
}

export function ErrorBag(props) {
  const { t } = useTranslation();
  const arr = Object.values(props.errors);
  if (arr.length === 0) {
    return null;
  }
  // $FlowExpectedError
  const error = arr.pop();
  return <div className="error-bag">{t(error)}</div>;
}
