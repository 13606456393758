import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Form, TextInput, Section, Col, Row, CheckboxInput, Button } from 'ui';

type P = {
  onSubmit: (data: { email: string }) => void;
  onNavigateToPrimaryLogin: () => void;
  loading: boolean;
};

const PasswordLessLoginForm = ({ onSubmit, loading, onNavigateToPrimaryLogin }: P) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = (data: { email: string }) => {
    onSubmit(data);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      initialValues={{ remember: true }}
      className="onboarding-password-login-form__form"
    >
      <Row item={{ align: 'middle' }}>
        <Col item={{ span: 24 }}>
          <TextInput
            item={{
              name: 'email',
              label: 'Email',
              rules: [
                {
                  type: 'email',
                },
                {
                  required: true,
                  whitespace: true,
                },
              ],
            }}
            input={{
              autocomplete: 'email',
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <CheckboxInput
            item={{
              label: t('Remember me'),
              name: 'remember',
              valuePropName: 'checked',
            }}
          />
        </Col>
      </Row>
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <Row item={{ gutter: [0, variables.spaceMd.value] }}>
          <Col item={{ span: 24 }}>
            <Button type="primary" htmlType="submit" loading={loading} size="large" block>
              E-mail me a log in link
            </Button>
          </Col>
          <Col item={{ span: 24 }}>
            <Button
              onClick={() => {
                onNavigateToPrimaryLogin();
              }}
              icon={<ArrowLeftOutlined />}
              size="large"
              block
            >
              All log in options
            </Button>
          </Col>
        </Row>
      </Section>
    </Form>
  );
};

export default PasswordLessLoginForm;
