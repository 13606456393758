import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { AppLayout } from '../../layouts/AppLayout';
import { ContentObjectRenderer } from '../../atoms/feed-object';
import { PostEditor } from '../../atoms/post-editor';
import { Loader } from '../../atoms/loader';
import { getFeedKey } from '../../models/feed';
import { Modal, Card } from 'ui';
import { onErrorReport, onSuccessReport } from '../../utils/report';

export class Renderer extends React.Component {
  state = {
    postDraft: undefined,
    postDetail: null,
  };

  componentDidMount() {
    this.props.loadContentObject();
  }

  componentDidUpdate(preProps) {
    if (preProps.match.params.kudosId !== this.props.match.params.kudosId) {
      this.props.loadContentObject();
    }
  }

  initiatePostEditing = async (object) => {
    switch (object.type) {
      case 'kudo':
        this.setState({ postDraft: object });
        break;
      default:
        console.warn(`Cannot initiated editing object of type '${object.type}`);
    }
  };

  actions = {
    createComment: this.props.createComment,
    createObject: this.props.createObject,
    deleteObject: this.props.deleteObject,
    likeObject: this.props.likeObject,
    loadCollection: this.props.loadCollection,
    reportObject: this.props.reportObject,
    sortChange: this.props.sortChange,
    updateObject: this.props.updateObject,
    reloadObject: this.props.reloadContentObject,
    bookmarkObject: this.props.bookmarkObject,
  };

  render() {
    return (
      <AppLayout
        center={
          <React.Fragment>
            {this.props.loadingContentObject ? (
              <Loader />
            ) : this.props.contentObject ? (
              <Card shrinked>
                <ContentObjectRenderer
                  item={this.props.contentObject}
                  scrollToCommentId={this.props.match?.params.commentId}
                  scrollToReplyId={this.props.match?.params.replyId}
                  onCreateComment={this.actions.createComment}
                  onCreateObject={this.actions.createObject}
                  onDeleteObject={this.actions.deleteObject}
                  onEditObject={this.actions.updateObject}
                  onInitiatePostEditing={this.initiatePostEditing}
                  onLikeObject={this.actions.likeObject}
                  onReportObject={this.actions.reportObject}
                  onSortChange={this.actions.sortChange}
                  viewer={this.props.viewer}
                  onReloadObject={this.actions.reloadObject}
                  onBookmarkObject={this.actions.bookmarkObject}
                  commentsOpened
                />
                {this.state.postDraft !== undefined && (
                  <Modal
                    title={
                      <React.Fragment>
                        <Trans>What is on your mind</Trans>, {this.props.viewer.first_name}?
                      </React.Fragment>
                    }
                    isOpened
                    onCancel={() => this.setState({ postDraft: undefined })}
                  >
                    <PostEditor
                      draft={this.state.postDraft}
                      viewer={this.props.viewer}
                      onSubmit={async (object) => {
                        const response = await this.actions.updateObject(object);
                        this.setState({ postDraft: undefined });
                        return response;
                      }}
                    />
                  </Modal>
                )}
              </Card>
            ) : null}
          </React.Fragment>
        }
        right={<div />}
      />
    );
  }
}

const mapState = (state) => {
  return {
    contentObject: select.feed.findById(state, state.feed.currentObjectId),
    loadingContentObject: state.loading.effects.feed.findAsync,
    viewer: select.session.user(state),
  };
};

const mapDispatch = (dispatch, props) => {
  const feedName = getFeedKey(props);
  return {
    loadContentObject: () => {
      return dispatch.feed.findKudosAsync({
        feed: feedName,
        postId: props.match.params.kudosId,
      });
    },
    createComment: (draft, parent_id) => {
      return dispatch.feed.createAsync({ draft: draft, parent_id: parent_id, comment: true });
    },
    createObject: (draft) => {
      return dispatch.feed.createKudos({
        kudo: {
          ...draft.kudos,
          attachments: draft.attachments,
          post_in_communities: draft.post_in_communities,
          pinned: draft.pinned,
          publish_at: draft.publish_at,
          text_content: draft.text_content,
          html_content: draft.html_content,
        },
      });
    },
    updateObject: async (object) => {
      return dispatch.feed.updateKudos({
        kudo: {
          id: object.kudo.id,
          attachments: object.attachments,
          post_in_communities: object.post_in_communities,
          pinned: object.pinned,
          publish_at: object.publish_at,
          text_content: object.text_content,
          html_content: object.html_content,
        },
      });
    },
    likeObject: (object) => {
      return dispatch.feed.likeAsync({ object: object });
    },
    deleteObject: async ({ object, originalItem }) => {
      if (await window.confirm('Delete this Kudo/Comment?')) {
        return dispatch.feed.deleteAsync({ object, originalItem });
      }
    },
    reportObject: async (object) => {
      if (await window.confirm('Report this Kudo/Comment?')) {
        return dispatch.feed.reportAsync({ object: object, onSuccess: onSuccessReport, onError: onErrorReport });
      }
    },
    reloadContentObject: (object) => {
      dispatch.feed.reloadAsync({ feed: feedName, postId: object.id });
    },
    bookmarkObject: (object) => {
      return dispatch.feed.bookmarkAsync({ object: object });
    },
  };
};

export const PageKudos = connect(mapState, mapDispatch)(Renderer);
