import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';
import { getOnboardingTourStepsQueryKey } from '..';
import { createOnboardingTourStep, type OnboardingTourStep } from '../../models';
import { updateOnboardingStep } from '../../services';

export const useUpdateOnboardingStep = () => {
  const queryClient = useQueryClient();

  return useMutation<OnboardingTourStep, { id: string; status: 'completed'; calendars?: string[] }>(
    async ({ id, status, calendars }) => {
      // TODO is not nice that request types are not separated on application logic
      const { data: onboardingStep } = await updateOnboardingStep(id, {
        status,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        meta: calendars ? { selected_calendar_options: calendars } : undefined,
      });

      return createOnboardingTourStep(onboardingStep);
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(getOnboardingTourStepsQueryKey());
      },
    },
  );
};
