import variables from 'common/styles/variables.json';
import { Form, TextInput, Section, Button, Row, Col } from 'ui';

type P = {
  onSubmit: (data: { email: string }) => void | void;
  loading: boolean;
  onNavigateToPrimaryLogin: () => void;
  email?: string;
};

const ForgotPasswordForm = ({ onSubmit, loading, onNavigateToPrimaryLogin, email }: P) => {
  const [form] = Form.useForm();

  const onFinish = (data: { email: string }) => {
    onSubmit(data);
  };

  return (
    <Form onFinish={onFinish} initialValues={{ email }} form={form} className="forgot-password-form">
      <TextInput
        item={{
          name: 'email',
          label: 'Email',
          rules: [
            {
              type: 'email',
            },
            {
              required: true,
              whitespace: true,
            },
          ],
        }}
        input={{
          autocomplete: 'email',
        }}
      />
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <Row item={{ gutter: [0, variables.spaceMd.value] }}>
          <Col item={{ span: 24 }}>
            <Button type="primary" htmlType="submit" loading={loading} size="large" block>
              Send reset link
            </Button>
          </Col>
          <Col item={{ span: 24 }}>
            <Button
              onClick={() => {
                onNavigateToPrimaryLogin();
              }}
              size="large"
              block
            >
              Back to Log in
            </Button>
          </Col>
        </Row>
      </Section>
    </Form>
  );
};

export default ForgotPasswordForm;
