import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message, Title, Text } from 'ui';
import { ForgotPasswordForm } from '../components';
import { useRequestResetQuery } from '../queries';

type P = {
  onNavigateToPrimaryLogin: () => void;
  onResetSuccess: () => void;
  email?: string;
};

const ForgotPassword = ({ onNavigateToPrimaryLogin, onResetSuccess, email }: P) => {
  const { t } = useTranslation();
  const {
    mutate: resetPassword,
    isLoading: isLoadingResetPasswordRequest,
    error: resetPasswordError,
    data: resetPasswordData,
  } = useRequestResetQuery();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const onSubmit = (submitData: { email: string }) => {
    resetPassword({ ...submitData, domain: window.location.hostname });
  };

  useEffect(() => {
    if (resetPasswordData) {
      setShowSuccessMessage(true);
    }
  }, [resetPasswordData]);

  useEffect(() => {
    if (resetPasswordError) {
      if (resetPasswordError.response && resetPasswordError.response.status >= 500) {
        message.error(t('Something went wrong. Please, try again later.'));
      } else {
        setShowSuccessMessage(true);
      }
    }
  }, [resetPasswordError, t]);

  return (
    <>
      <Title level={1}>{t('Forgot password')}</Title>
      {showSuccessMessage ? (
        <>
          <Text className="onboarding-forgot-password__reset-request-message">{t('resetRequestSuccess')}</Text>
          <Button
            onClick={() => {
              onResetSuccess();
            }}
            block
            size="large"
          >
            Back to Log in
          </Button>
        </>
      ) : (
        <div className="onboarding-forgot-password__form-wraper">
          <ForgotPasswordForm
            onSubmit={onSubmit}
            loading={isLoadingResetPasswordRequest}
            onNavigateToPrimaryLogin={() => {
              onNavigateToPrimaryLogin();
            }}
            email={email}
          />
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
