import { useGetFiles } from 'features/feed/queries';
import { type UploadedFileDataObject } from 'models/service';
import { Button } from 'ui';

type P = {
  attachments: UploadedFileDataObject[];
  mode?: 'all' | 'single';
};

const SaveAttachmentButton = ({ attachments, mode = 'single' }: P) => {
  const { mutate: handleSave, isLoading } = useGetFiles();

  return (
    <Button
      type="link"
      onClick={() => {
        handleSave(attachments);
      }}
      loading={isLoading}
    >
      {mode === 'single' ? 'Save' : 'Save all'}
    </Button>
  );
};

export default SaveAttachmentButton;
