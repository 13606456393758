import { useQueryClient } from '@tanstack/react-query';
import { DefaultImage, type Image } from 'models';
import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { updateUserProfile } from 'features/user-profile/services';
import { type BirthDateType, formatBirthdayToString, type ImageUploadedData } from 'ui';
import { getProfileQueryKey } from '..';

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    {
      userId: string;
      userData: {
        profilePhoto?: ImageUploadedData<DefaultImage | Image>;
        firstName?: string;
        middleName?: string;
        lastName?: string;
        email?: string;
        phone?: string;
        birthDate?: BirthDateType;
        timeZone?: string;
        gender?: string;
        country?: string;
        state?: string;
        city?: string;
        zip?: string;
        street?: string;
        apt?: string;
        educationLevel?: string;
        company?: string;
        profession?: string;
        biography?: string;
        interests?: string[];
      };
    }
  >(
    async ({ userId, userData }) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const requestData = {
        last_name: userData.lastName,
        middle_name: userData.middleName,
        first_name: userData.firstName,
        phone: userData.phone,
        birthdate: userData.birthDate ? formatBirthdayToString(userData.birthDate) : undefined,
        time_zone: userData.timeZone,
        profile_photo: userData.profilePhoto?.image instanceof DefaultImage ? undefined : userData.profilePhoto?.image,
        education_level: userData.educationLevel ? { id: userData.educationLevel } : undefined,
        gender: userData.gender ? { id: userData.gender } : undefined,
        interests: userData.interests?.map((interest) => ({ id: interest })),
        profession: userData.profession ? { id: userData.profession } : undefined,
        country: userData.country,
        state: userData.state,
        city: userData.city,
        zip: userData.zip,
        street: userData.street,
        apt: userData.apt,
        biography: userData.biography,
        company: userData.company,
        email: userData.email,
      };
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      const { data } = await updateUserProfile(userId, requestData);

      // TODO return model in future
      return data;
    },
    {
      async onSuccess(userData) {
        await queryClient.invalidateQueries(getProfileQueryKey());
        // TODO remove when we get rid of redux
        await dispatch.session.setUserData(userData);
      },
    },
  );
};
