import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import variables from 'common/styles/variables.json';

import { Button, Col, Form, Row, TextInput } from 'ui';

type P = {
  name: string;
};

const ProgramOutcomes = ({ name }: P) => {
  const { t } = useTranslation();

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field) => (
              <Form.Item required={false} key={field.key}>
                <Row item={{ gutter: variables.spaceMd.value }}>
                  <Col item={{ span: 20 }}>
                    <TextInput
                      item={{
                        ...field,
                        label: 'Expected outcomes',
                      }}
                      input={{
                        placeholder: t('Expected outcome'),
                        id: `program_expected-outcome[${field.key}]`,
                      }}
                    />
                  </Col>
                  <Col item={{ span: 2, className: 'dynamic-delete-button-wrapper' }}>
                    <Button
                      type="text"
                      size="large"
                      onClick={() => {
                        remove(field.name);
                      }}
                      data-test-id="btn_delete-outcome"
                      icon={<MinusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                data-test-id="btn_add-outcome"
                size="large"
              >
                <PlusOutlined /> {t('Add Outcome')}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default ProgramOutcomes;
