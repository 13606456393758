import { Entity } from './entity';
import { type CategoryDataObject } from './service';
import { type UploadedFile } from './uploaded-file';

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export class CategoryDeprecated {
  id = '';
  name = '';
  organizations_count?: number;
  communities_count?: number;
  symbol_photo?: UploadedFile;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

abstract class CategoryBase extends Entity {}

export class CategoryListItem extends CategoryBase {}

export class Category extends CategoryBase {
  // TODO what is optional
  organizationsCount?: number;
  communitiesCount?: number;
  // TODO this should be image probably
  symbolPhoto?: UploadedFile;

  constructor(data: CategoryDataObject) {
    super(data);
    this.organizationsCount = data.organizations_count;
    this.communitiesCount = data.communities_count;
    this.symbolPhoto = data.symbol_photo;
  }
}
