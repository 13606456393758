import cn from 'classnames';
import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { routes } from '../../../common/services';
import { ID } from '../../../common/types';
import { Message as MessageModel, getParticipantInitials } from '../../../models';
import { usersInChatroomState } from '../../../recoil/selectors';
import { ChatroomMessage } from '../../../types';
import { Button, Avatar, Tooltip } from '../../../ui';
import { urlRegex } from '../../../utils';
import MessageAttachments from './MessageAttachments';
import MessageLinkPreview from './MessageLinkPreview';

type P = {
  message: MessageModel;
  isAuthor: boolean;
  isLastMessageInMessageGroup: boolean;
  onResendMessage: (data: ChatroomMessage, resentMessageId: ID) => void;
};

const Message: FC<P> = ({ message, isAuthor, isLastMessageInMessageGroup, onResendMessage }) => {
  const { t } = useTranslation(['translationChat']);
  const chatroomUsers = useRecoilValue(usersInChatroomState);

  const convertToTextWithLinks = (text: string) => {
    return text.split(urlRegex).map((part) => {
      if (urlRegex.test(part)) {
        return (
          <a className="chat-message__link" target="_blank" href={part} rel="noreferrer">
            {part}
          </a>
        );
      }

      return part;
    });
  };

  const isFailedMessage = message.status === 'failed';
  const handleTryAgainClick = () => {
    onResendMessage({ text: message.text, payload: message.payload }, message._id);
  };

  const MessagePreviewMemo = useMemo(() => {
    return message.text && <MessageLinkPreview text={message.text} isAuthor={isAuthor} />;
  }, [isAuthor, message.text]);

  return (
    <>
      <div
        className={cn(
          'chat-message',
          { 'chat-message--sender': !isAuthor },
          { 'chat-message--me': isAuthor },
          { 'chat-message--with-avatar': isLastMessageInMessageGroup },
          { 'chat-message--failed': isFailedMessage },
        )}
      >
        <div className="chat-message__wrapper">
          <div className="chat-message__content">
            <Tooltip
              title={t('chat.chatroomDetail.messageTooltipLabel', { date: message.updatedAt })}
              placement={isAuthor ? 'right' : 'left'}
            >
              {message.text && <p className="p--large chat-message__message">{convertToTextWithLinks(message.text)}</p>}
            </Tooltip>
            {message.payload.length > 0 && (
              <MessageAttachments isAuthor={isAuthor} payload={message.payload} messageDate={message.updatedAt} />
            )}
          </div>

          {!isAuthor && (
            <div className="chat-message__avatar">
              <Avatar
                size="small"
                alt="user profile photo"
                src={
                  new URL(
                    routes.chatrooms.media.getAvatarFile(message.author as string, 'user_avatar', 'medium'),
                    process.env.REACT_APP_MEDIA_MODULE_URL,
                  ).href
                }
              >
                <span className="chat-message__avatar__initials">{message.author ? getParticipantInitials(chatroomUsers[message.author]) : ''}</span>
              </Avatar>
            </div>
          )}
        </div>

        {isFailedMessage && (
          <div className="chat-message__resend">
            {t('chat.chatroomDetail.messages.messageSendError')}
            <Button className="chat-message__resend__button" type="link" onClick={() => handleTryAgainClick()}>
              <span className="chat-message__resend__button-text">
                {t('chat.chatroomDetail.messages.messageTryAgain')}
              </span>
            </Button>
          </div>
        )}
      </div>
      {MessagePreviewMemo}
    </>
  );
};

export default Message;
