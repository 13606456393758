import { useQuery } from 'query';
import { type MediaDataObject } from 'models/service';
import { getVideoMedia } from '../services';
import { getMediaQueryKey } from '.';

export const useGetVideoMedia = ({ mediaId }: { mediaId?: string }) =>
  // TODO: fix type dont return data object
  useQuery<MediaDataObject>(
    getMediaQueryKey({ mediaId }),
    async () => {
      const { data } = await getVideoMedia({ mediaId });

      return data;
    },
    {
      enabled: Boolean(mediaId),
    },
  );

export default useGetVideoMedia;
