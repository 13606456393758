import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type VideoContentObjectDataObject } from 'models/service';

type UpdateMediaPayload = {
  video: {
    full?: Record<string, never> | { external_id: string };
    trailer?: Record<string, never> | { external_id: string };
  };
};

export const updateMedia = async ({ id, data }: { id: string; data: UpdateMediaPayload }) =>
  apiClient.patch<VideoContentObjectDataObject, ApiClientResponse<VideoContentObjectDataObject>>(
    routes.videos.update(id),
    data,
  );
