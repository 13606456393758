import { useTranslation } from 'react-i18next';
import { type UploadedFileDataObject } from 'models/service';
import { Button, Text, Collapse } from 'ui';
import { FileAttachment } from './FileAttachment';

type P = {
  // TODO: should be application models
  attachments: UploadedFileDataObject[];
  // TODO: should be application models
  onRemove: (attachment: UploadedFileDataObject) => void;
  onClear: () => void;
};

const Files = ({ attachments, onRemove, onClear }: P) => {
  const { t } = useTranslation();

  if (attachments.length > 1) {
    return (
      <Collapse ghost defaultActiveKey="attachments-key">
        <Collapse.Panel
          key="attachments-key"
          header={<Text>{t('Attachments ({{count}})', { count: attachments.length })}</Text>}
          extra={
            <Button type="link" onClick={onClear}>
              Clear all
            </Button>
          }
        >
          {attachments.map((attachment) => (
            <FileAttachment onRemove={onRemove} attachment={attachment} key={attachment.id} />
          ))}
        </Collapse.Panel>
      </Collapse>
    );
  }

  return (
    <>
      {attachments.map((attachment) => (
        <FileAttachment onRemove={onRemove} attachment={attachment} key={attachment.id} />
      ))}
    </>
  );
};

export default Files;
