import { useQueryClient } from '@tanstack/react-query';
import { Consent } from 'models';
import { useMutation } from 'query';
import { getProfileQueryKey } from 'features/user-profile/queries';
import { acceptConsents } from '../../services';

export const useAcceptConsents = () => {
  const queryClient = useQueryClient();

  return useMutation<Consent[], { consentIds: string[] }>(
    async ({ consentIds }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- We missing correct application model in camelCase, we need to fix it
      const response = await acceptConsents({ consent: { consent_ids: consentIds } });

      return response.data.map((consent) => new Consent(consent));
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(getProfileQueryKey());
      },
    },
  );
};
