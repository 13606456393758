import semver from 'semver';

export const useVersionCompare = () => {
  const compareVersions = ({
    backendVersion,
    packageVersion,
  }: {
    backendVersion: string;
    packageVersion: string;
  }): boolean => {
    const semverBackendVersion = semver.coerce(backendVersion);
    const semverPackageVersion = semver.coerce(packageVersion);

    if (!semverBackendVersion || !semverPackageVersion) {
      console.error('Invalid version detected:', { backendVersion, packageVersion });
      return false;
    }

    const difference = semver.diff(semverBackendVersion, semverPackageVersion);

    return difference === null || difference === 'patch';
  };

  return { compareVersions };
};
