import { addMinutes } from 'date-fns';

export const MAX_WEBINAR_RANGE = 240; // minutes
export const MIN_RANGE = 60; // minutes
export const EXTRA_MINUTES = 5; // minutes

export const EVENT_DRAFT = {
  allow_guest: true,
  cover_photo: null,
  rich_content: '',
  text_content: '',
  enable_survey: false,
  end_time: addMinutes(new Date(), MIN_RANGE),
  event_type: 'conference',
  name: '',
  place: '',
  description: '',
  post_in_communities: [],
  invitees: [],
  moderators: [],
  presenters: [],
  emailInvitees: [],
  emailModerators: [],
  emailPresenters: [],
  record_event: true,
  see_poll_results: true,
  share_presenter_webcams: false,
  show_chat_in_recording: true,
  start_time: new Date(),
  view_permission: 'public',
  events_files: [],
};
