import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';
import { type VideoContentObjectDataObject } from 'models/service';
import { getVideoDetailQueryKey } from '..';
import { updateMedia } from '../../services';

const transformVideoField = (field?: Record<string, never> | { externalId: string }) => {
  if (!field) {
    return undefined;
  }

  if ('externalId' in field) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return { external_id: field.externalId };
  }

  return field;
};

export const useUpdateMedia = () => {
  const queryClient = useQueryClient();

  // TODO: we dont want to return data object
  return useMutation<
    VideoContentObjectDataObject,
    {
      id: string;
      data: {
        video: {
          full?: Record<string, never> | { externalId: string };
          trailer?: Record<string, never> | { externalId: string };
        };
      };
    }
  >(
    async ({ id, data }) => {
      const modifiedData = {
        video: {
          full: transformVideoField(data.video.full),
          trailer: transformVideoField(data.video.trailer),
        },
      };

      const response = await updateMedia({ id, data: modifiedData });
      return response.data;
    },
    {
      // TODO: need to change this invalidation, we need to invalidate but in current implementation we have short flash on the ui
      onSuccess(_, { id }) {
        queryClient.invalidateQueries(getVideoDetailQueryKey({ videoId: id }));
      },
    },
  );
};
