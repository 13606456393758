import { AutocompleteUser } from 'models';
import { useMutation } from 'query';

import { getProgramsUsers } from '../../services';

// TODO add `queryKey` maybe? this is more like `useQuery`
export const useProgramsUsersMutation = () =>
  useMutation<
    AutocompleteUser[],
    {
      programsIds?: string[];
      limit?: number;
      search?: string;
    }
  >(async ({ programsIds, limit, search }) => {
    const usersResponse = await getProgramsUsers({
      /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      program_ids: programsIds,
      limit,
      search,
      scope: 'programs',
      /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
    });

    return usersResponse.data.map((user) => new AutocompleteUser(user));
  }, {});
