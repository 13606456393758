import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type ResponseErrorDataObject } from 'common/services/api';
import { getCurrentUserTimezone } from 'common/utils';
import { type ProfileDataObject } from 'models/service';
import { signUp } from '../../services';

type MutationVariables = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organizationId?: string;
  invitationToken?: string;
  publicCommunityId?: string;
};

export const useSignUpQuery = () =>
  useMutation<ProfileDataObject, ApiClientResponseError<ResponseErrorDataObject>, MutationVariables>({
    async mutationFn({ firstName, lastName, email, password, organizationId, publicCommunityId, invitationToken }) {
      const response = await signUp({
        /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
        body: {
          user: {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            password_confirmation: password,
            join_community_id: publicCommunityId,
            time_zone: getCurrentUserTimezone(),
          },
        },
        params: {
          invitation_token: invitationToken,
          organization_id: organizationId,
        },
        /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      });

      // TODO: Do not return DataObject
      return response.data;
    },
  });
