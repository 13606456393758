import { type CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { getAppConfig } from 'old/atoms/app-config-gate';
import { dispatch } from 'old/store';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Row, Col, message, modalConfirm } from 'ui';

type P = {
  onSuccessLogin: () => void;
  onNavigateToSignUp?: () => void;
  getOrganizationId?: () => string | undefined;
};

export const SocialButtons = ({ onSuccessLogin, onNavigateToSignUp, getOrganizationId }: P) => {
  const { t } = useTranslation();
  const { constants } = getAppConfig();

  const onSuccess = async (credentials: CredentialResponse) => {
    try {
      const response: any = await dispatch.session.attemptWithSocial({
        code: credentials.credential,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- key is defined by Backend API endpoint
        organization_id: getOrganizationId?.(),
        provider: 'google_v2',
      });

      if (response.ok) {
        onSuccessLogin();
      } else if (onNavigateToSignUp && response.data?.message === 'User cannot register without organization_id.') {
        modalConfirm({
          title: t('Account doesn’t exists'),
          content: t(
            'Account you trying to log in to, doesn’t exist. Please proceed with Sign up and create your account first.',
          ),
          okText: t('Sign up'),
          cancelText: t('Cancel'),
          onOk() {
            onNavigateToSignUp();
          },
        });
      } else {
        onError();
      }
    } catch (error) {
      onError();
      console.error('Google Sign-in failed:', error);
    }
  };

  const onError = () => {
    message.error('Failed to connect to Google account.');
  };

  return (
    <Row item={{ gutter: variables.spaceMd.value, justify: 'center' }}>
      {constants.GOOGLE_ID ? (
        <Col>
          <GoogleLogin onSuccess={onSuccess} onError={onError} logo_alignment="center" />
        </Col>
      ) : null}
    </Row>
  );
};
