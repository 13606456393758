import moment, { type Moment } from 'moment';
import { type CategoryDeprecated } from './category';
import { type Social } from './content-object';
import { type Entity } from './entity';
import { type Privacy } from './privacy';
import { type Tag } from './tag';
import { type AutocompleteUser } from './user';

export type Sponsor = {
  id: string;
  name: string;
  price: number;
  description: string;
  html_description: string;
  logo?: { id: string; url: string };
};

export type FeaturedParticipant = {
  id: string;
  name: string;
  description: string;
  html_description: string;
  logo?: { id: string; url: string };
};

export type Topic = {
  id?: string;
  name: string;
  description: string;
  has_content: boolean;
};

export type PriceContact = {
  name: string;
  email: string;
  phone: string;
};
/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export default class Program implements Social {
  id = '';
  name = '';
  text_content?: string;
  rich_content?: string;
  html_content?: string;
  start_time?: Moment;
  end_time?: Moment;
  privacy: Privacy = 'private';
  author_name = '';
  author_overview_text?: string;
  html_author_overview?: string;
  author_logo: { id: string; url: string } = { id: '', url: '' };
  price?: number;
  price_option: 'contact_us' | 'free' | 'paid' = 'paid';
  price_contact?: PriceContact;
  who_should_enroll?: string;
  expected_outcomes?: string[];
  settings: {
    allow_comments: boolean;
    view_members: boolean;
    allow_unsubscribe: boolean;
  } = { allow_comments: true, view_members: true, allow_unsubscribe: true };

  cover_photo?: { id: string; url: string };
  author?: AutocompleteUser;
  organization?: Entity;
  administrators: Array<{ id: string }> = [];
  instructors: Array<{ id: string }> = [];
  sponsors?: Sponsor[];
  featured_participants?: FeaturedParticipant[];
  invited_communities?: Entity[];
  invited_organizations?: Entity[];
  categories?: CategoryDeprecated[];
  topics: Topic[] = [{ name: '', description: '', has_content: false }];
  invited_users?: AutocompleteUser[];
  mandatory = false;
  status?: string;
  users?: AutocompleteUser[];
  withings_devices: string[] = [];
  requested_to_contact = false;
  tags?: Tag[];
  show_in_channels?: boolean;
  featured_in_channels?: boolean;
  featured_in_wla?: boolean;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);

      if (data.start_time) {
        this.start_time = moment(data.start_time);
      }

      if (data.end_time) {
        this.end_time = moment(data.end_time);
      }
    }

    if (!this.start_time) {
      this.start_time = moment().add(10, 'minutes');
    }

    if (!this.end_time) {
      this.end_time = this.start_time.clone().add(60, 'minutes');
    }
  }

  get isContactUs() {
    return this.price_option === 'contact_us';
  }

  isUserAuthor(userId: string) {
    return this.author?.id === userId;
  }
}
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
