import { type ImageDataObject } from './service';

export class Image {
  id: string;
  url: string;
  downloadUrl?: string;
  filename?: string;

  constructor(data: ImageDataObject) {
    this.id = data.id;
    this.url = data.url;
    this.downloadUrl = data.download_url;
    this.filename = data.filename;
  }
}

export class DefaultImage {
  url: string;

  constructor(data: { url: string }) {
    this.url = data.url;
  }
}
