import { parseISO } from 'date-fns';
import {
  type UploadedFileDataObject,
  type ImageDataObject,
  type ReplyDataObject,
  type BaseReplyDataObject,
  type ExtendedReplyDataObject,
  type CommentDataObject,
  type BaseCommentDataObject,
  type ProgramCommentDataObject,
  type CommunityCommentDataObject,
} from './service';

type BaseReply = {
  id: string;
  type: string;
  htmlContent: string;
  textContent: string;
  parentId: string;
  liked: boolean;
  likesCount: number;
  bookmarked: boolean;
  // TODO: corrrect type
  author: {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto?: ImageDataObject;
  };
  attachments: UploadedFileDataObject[];
  createdAt: Date;
};

type ExtendedReply = BaseReply & {
  topParentId: string;
  topParentType: string; // This is enum
  topParentSharableId: string;
};

export type Reply = BaseReply | ExtendedReply;

type BaseComment = ExtendedReply & {
  contentableId: string;
};

export type ProgramComment = BaseComment & {
  contentableType: 'Program';
  // TODO: correct type
  program: {
    id: string;
    organization: {
      id: string;
    };
  };
};

export type CommunityComment = BaseComment & {
  contentableType: 'Community';
  // TODO: correct type
  community: {
    id: string;
    organizationId: string;
  };
};

export type Comment = CommunityComment | ProgramComment;

export const getReplyData = (data: ReplyDataObject): Reply => {
  if ('top_parent_id' in data) {
    return getExtendedReplyData(data);
  }

  return getBaseReplyData(data);
};

const getBaseReplyData = (data: BaseReplyDataObject): BaseReply => ({
  id: data.id,
  htmlContent: data.html_content ?? '',
  textContent: data.text_content ?? '',
  parentId: data.parent_id,
  createdAt: parseISO(data.created_at),
  type: data.type,
  likesCount: data.likes_count,
  liked: data.liked,
  bookmarked: data.bookmarked,
  author: {
    id: data.author.id,
    firstName: data.author.first_name,
    lastName: data.author.last_name,
    profilePhoto: data.author.profile_photo,
  },
  attachments: data.attachments,
});

const getExtendedReplyData = (data: ExtendedReplyDataObject): ExtendedReply => ({
  ...getBaseReplyData(data),
  topParentId: data.top_parent_id,
  topParentType: data.top_parent_type,
  topParentSharableId: data.top_parent_sharable_id,
});

export const getCommentData = (data: CommentDataObject): Comment => {
  switch (data.contentable_type) {
    case 'Program':
      return getProgramCommentData(data);
    case 'Community':
      return getCommunityCommentData(data);
  }
};

const getBaseCommentData = (data: BaseCommentDataObject): BaseComment => ({
  ...getExtendedReplyData(data),
  contentableId: data.contentable_id,
});

const getProgramCommentData = (data: ProgramCommentDataObject): ProgramComment => ({
  ...getBaseCommentData(data),
  contentableType: data.contentable_type,
  program: {
    id: data.program.id,
    organization: {
      id: data.program.organization.id,
    },
  },
});

const getCommunityCommentData = (data: CommunityCommentDataObject): CommunityComment => ({
  ...getBaseCommentData(data),
  contentableType: data.contentable_type,
  community: {
    id: data.post_in_communities[0].id,
    organizationId: data.post_in_communities[0].organization_id,
  },
});
