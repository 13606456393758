import { type ContentObjectRedux, type ReduxUser } from 'models';
import { Form, Modal, ModalFormActions, RichTextInput, message } from 'ui';
import { ContentObjectPreview } from '../../../../components';
import { useRepostContentObjectMutation } from '../../../../queries';
import { CommunitySelect } from './CommunitySelect';

type FormValues = {
  community: { label: string; value: string };
  htmlContent?: string;
  textContent?: string;
};

type P = {
  isOpened: boolean;
  onCancel: () => void;
  item: ContentObjectRedux;
  viewer: ReduxUser;
};

const RepostModal = ({ onCancel, isOpened, item, viewer }: P) => {
  const { isLoading: isLoadingRepostObject, mutate: repostObject } = useRepostContentObjectMutation();

  const handleFormFinish = (values: FormValues) => {
    repostObject(
      {
        communityId: values.community.value,
        objectId: item.id,
        htmlContent: values.htmlContent,
        textContent: values.textContent,
      },
      {
        onSuccess() {
          message.success('Repost successfull');
          onCancel();
        },
      },
    );
  };

  return (
    <Modal title="Repost" isOpened={isOpened} onCancel={onCancel} disableBack destroyOnClose>
      <Form<FormValues> name="repostForm" onFinish={handleFormFinish}>
        <CommunitySelect item={{ rules: [{ required: true }] }} viewer={viewer} />
        <RichTextInput
          text={{ name: 'textContent' }}
          html={{
            name: 'htmlContent',
            label: 'Create post',
            placeholder: 'Create post...',
          }}
        />
        <ContentObjectPreview item={item} viewer={viewer} view="form" />
        <ModalFormActions
          left={{ onClick: onCancel, children: 'Cancel' }}
          submit={{ children: 'Repost', loading: isLoadingRepostObject }}
        />
      </Form>
    </Modal>
  );
};

export default RepostModal;
