import { type Comment, getCommentData } from 'models';
import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { type UploadedFileDataObject, type CommentDataObject } from 'models/service';
import { createComment } from '../../services';

export const useCreateCommentMutation = () =>
  // TODO: stop returning responseData once removed from redux
  useMutation<
    {
      data: Comment;
      responseData: CommentDataObject;
    },
    {
      parentId: string;
      textContent?: string;
      htmlContent?: string;
      attachments?: UploadedFileDataObject[];
    }
  >(
    async ({ attachments, textContent, htmlContent, parentId }) => {
      const response = await createComment({
        /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
        content_object: {
          parent_id: parentId,
          html_content: htmlContent,
          text_content: textContent,
          attachments,
        },
        /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      });

      return {
        data: getCommentData(response.data),
        responseData: response.data,
      };
    },
    {
      onSuccess: async (data) => dispatch.feed.storeComment({ responseData: data.responseData }),
    },
  );
