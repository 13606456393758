import { FormOutlined, MoreOutlined } from '@ant-design/icons';
import { type Asset, type FileAsset, FolderAsset, FileType, type Community, type ReduxUser } from 'models';
import { type Key, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { openInNewWindow, renderFileSize } from 'common/utils';
import { useAssetsQuery } from 'features/communities/queries/useAssetsQuery';
import { canManageAssets } from 'permissions';
import {
  Button,
  Card,
  Col,
  type ColumnsType,
  Dropdown,
  FileIcon,
  FileManager,
  message,
  modalConfirm,
  Row,
  Text,
  type SorterResult,
  DeleteIcon,
} from 'ui';
import { useDeleteFile } from '../../queries';
import { type FileSorter } from '../../types';
import { RenameAsset } from '../AssetsActionModals';
import { AssetsHeaderButtons } from '../AssetsHeaderButtons';
import { useAssetsFoldersParents } from './hooks';

const pageSize = 10;
const defaultSorter: FileSorter = { sort: undefined, order: undefined };
type P = {
  community: Community;
  viewer: ReduxUser;
};

const Assets = ({ community, viewer }: P) => {
  const { t } = useTranslation();
  const communityId = community.id;
  const [sort, setSort] = useState<FileSorter>(defaultSorter);
  const [editingFile, setEditingFile] = useState<Asset | undefined>(undefined);
  const hasPermissionsToManage = canManageAssets({
    viewer,
    community,
  });

  const { currentFolderName, lastParentFolder, addParentFolder, removeLastParentFolder } = useAssetsFoldersParents();

  const {
    isLoading: isLoadingFiles,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAssetsQuery({
    communityId,
    parentId: lastParentFolder?.id,
    pageSize,
    ...sort,
  });

  const { isLoading: isLoadingDeleteFile, mutate: deleteFile } = useDeleteFile();

  const files = useMemo(() => data?.pages.flat().flat(), [data]);

  const onChange = (sorter: Array<SorterResult<FileAsset | FolderAsset>> | SorterResult<FileAsset | FolderAsset>) => {
    const mapField = (field: Key | readonly Key[]) => {
      switch (field) {
        case 'updatedAt':
        case 'size':
          return field;
        default:
          return 'name';
      }
    };

    const mapOrder = (order: string) => {
      return order === 'descend' ? 'desc' : 'asc';
    };

    const sort: FileSorter = (() => {
      if (!Array.isArray(sorter) && sorter.field && sorter.order) {
        return {
          sort: mapField(sorter.field),
          order: mapOrder(sorter.order),
        };
      }

      return defaultSorter;
    })();

    setSort(sort);
  };

  const onDeleteFile = (fileId: string) => {
    modalConfirm({
      content: <Text>{t('Are you sure?')}</Text>,
      cancelText: t('Cancel'),
      okText: t('Delete'),
      okButtonProps: {
        loading: isLoadingDeleteFile,
      },
      onOk() {
        deleteFile(
          {
            id: fileId,
            params: {
              communityId,
              parentId: lastParentFolder?.id,
            },
          },
          {
            onSuccess() {
              message.success(t('Asset successfully deleted'));
            },
          },
        );
      },
    });
  };

  const actionsMenu = (record: FileAsset | FolderAsset) => [
    {
      key: 'rename',
      label: t('Rename'),
      icon: <FormOutlined />,
      onClick() {
        setEditingFile(record);
      },
    },
    {
      key: 'delete',
      label: t('Delete'),
      icon: <DeleteIcon />,
      onClick() {
        onDeleteFile(record.id);
      },
    },
  ];

  const onCellHandler = (record: FileAsset | FolderAsset) => ({
    onClick() {
      if (record.type === FileType) {
        openInNewWindow(record.downloadUrl);
      } else if (record.id === 'goBack') {
        removeLastParentFolder();
      } else {
        addParentFolder(record);
      }
    },
  });

  const columns: ColumnsType<FileAsset | FolderAsset> = [
    {
      title: t('File name'),
      dataIndex: 'name',
      sorter: true,
      onCell: onCellHandler,
      render: (item, record) => (
        <Row item={{ align: 'middle', justify: 'start', gutter: variables.spaceXs.value }}>
          <Col item={{ className: 'assets__name-row__icon' }}>
            <FileIcon asset={record} />
          </Col>
          <Col>
            {record.name}
            {record.type === FileType && record.extension}
          </Col>
        </Row>
      ),
      className: 'assets__row',
    },
    {
      title: t('Modified'),
      dataIndex: 'updatedAt',
      sorter: true,
      onCell: onCellHandler,
      render: (item, record) => (record.id === 'goBack' ? '' : t('dateFormatShortMonthDayYearTime', { date: item })),
      className: 'assets__row',
    },
    {
      title: t('Size'),
      dataIndex: 'size',
      sorter: true,
      onCell: onCellHandler,
      render: (item, record) => (record.type === FileType ? renderFileSize(record.size) : ''),
      className: 'assets__row',
    },
    {
      dataIndex: 'actions',
      render: (item, record) =>
        record.id === 'goBack' || !hasPermissionsToManage ? (
          ''
        ) : (
          <Row item={{ align: 'middle', justify: 'center' }}>
            <Dropdown placement="bottomRight" menu={{ items: actionsMenu(record) }} trigger={['click']}>
              <Button type="text" size="large">
                <MoreOutlined />
              </Button>
            </Dropdown>
          </Row>
        ),
    },
  ];

  const preprocessData = (files: Array<FileAsset | FolderAsset>) => {
    const previousParent = lastParentFolder?.parentId;

    if (lastParentFolder?.id) {
      return [
        /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
        new FolderAsset({
          id: 'goBack',
          type: 'folder',
          name: '...',
          updated_at: '',
          parent_id: previousParent ?? null,
        }),
        ...files,
      ];
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
    }

    return files;
  };

  return (
    <Card
      title={currentFolderName}
      extra={
        hasPermissionsToManage ? (
          <AssetsHeaderButtons
            queryParams={{
              communityId,
              parentId: lastParentFolder?.id,
            }}
          />
        ) : null
      }
      className="assets"
    >
      <FileManager
        dataSource={preprocessData(files ?? [])}
        columns={columns}
        loading={isLoadingFiles || isFetchingNextPage}
        onChange={(_, filter, sorter) => {
          onChange(sorter);
        }}
        pagination={false}
        rowKey={'id'}
        className="assets__table"
      />
      {hasNextPage && !isFetchingNextPage ? (
        <Row item={{ align: 'middle', justify: 'center', className: 'assets__show-more' }}>
          <Button type="ghost" onClick={async () => fetchNextPage()} size="large">
            Show More
          </Button>
        </Row>
      ) : null}
      {editingFile ? (
        <RenameAsset
          isOpened
          editingFile={editingFile}
          onCancel={() => {
            setEditingFile(undefined);
          }}
          communityId={communityId}
          parentId={lastParentFolder?.id}
        />
      ) : null}
    </Card>
  );
};

export default Assets;
