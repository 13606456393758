import { BaseUser } from 'models';
import { useQuery } from 'query';
import { useTranslation } from 'react-i18next';
import { message } from 'ui';
import { getUsers } from '../services';
import { getUsersQueryKey } from './';

type P = {
  search?: string;
  excludeCommunityId?: string;
};

// TODO should be somewhere in different feature
export const useUsersQuery = ({ search, excludeCommunityId }: P) => {
  const { t } = useTranslation();

  return useQuery<BaseUser[]>(
    getUsersQueryKey({ search, excludeCommunityId }),
    async () => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- key is defined by Backend API endpoint
      const { data } = await getUsers({ search: search ?? '', exclude_community_id: excludeCommunityId });

      return data.map((user) => new BaseUser(user));
    },
    {
      onError() {
        message.error(t('Failed to load data.'));
      },
    },
  );
};
