import { apiClient, type ApiClientResponse, routes } from 'common/services';

type EventFeedbackRequestData = {
  helpful_rating?: number;
  audio_rating?: number;
  video_rating?: number;
  recommend_rating?: number;
  provider_rating?: number;
  helpful_text?: string;
  recommend_text?: string;
  provider_text?: string;
  audio_text?: string;
  video_text?: string;
};

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export const createEventFeedback = async (id: string, data: EventFeedbackRequestData) =>
  apiClient.post<undefined, ApiClientResponse<undefined>>(routes.events.createFeedback(id), {
    events_feedback: data,
  });
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
