import { type ContentObjectRedux } from 'models';
import { useHistory } from 'react-router-dom';
import { generateContentObjectPath } from 'common/utils';
import { Button, EyeLineIcon } from 'ui';

type P = {
  item: ContentObjectRedux;
};

const ViewButton = ({ item }: P) => {
  const { push } = useHistory();

  return (
    <Button
      type="text"
      icon={<EyeLineIcon />}
      onClick={() => {
        push(generateContentObjectPath(item));
      }}
      size="large"
    >
      View
    </Button>
  );
};

export default ViewButton;
