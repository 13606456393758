import variables from 'common/styles/variables.json';
import { calculateFileSize } from 'common/utils';
import { type UploadedFileDataObject } from 'models/service';
import { Text, Row, Col } from 'ui';
import { FileIcon } from '../../FileIcon';
import { SaveAttachmentButton } from '../../SaveAttachmentButton';

type P = {
  attachment: UploadedFileDataObject; // TODO data object?
};
const Attachment = ({ attachment }: P) => {
  const { filename, url, content_type: contentType, byte_size: byteSize, download_url: downloadUrl } = attachment;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Row item={{ gutter: variables.spaceXs.value, wrap: false, align: 'middle' }}>
        <Col>
          <FileIcon url={url} contentType={contentType} size="large" />
        </Col>

        <Col item={{ flex: 1 }}>
          <Text size="small" ellipsis>
            {filename}
          </Text>

          {/** TODO can be empty row - resolve after type is right */}
          <Row item={{ gutter: variables.spaceXs.value }}>
            {byteSize ? (
              <Col>
                <Text size="small" type="secondary">
                  {calculateFileSize(byteSize)}
                </Text>
              </Col>
            ) : null}
            {downloadUrl ? (
              <Col
                item={{
                  onClick(event) {
                    event.preventDefault();
                  },
                }}
              >
                <SaveAttachmentButton attachments={[attachment]} />
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </a>
  );
};

export default Attachment;
