import { type UploadFile, type VideoType, type UploadSubscriber } from 'lib/media-upload-file';
import { useEffect } from 'react';
import { type FormInstance } from 'ui';

type P = {
  file?: UploadFile;
  form: FormInstance;
  formName: string;
  type: VideoType;
};

export const useFormFileSubscribe = ({ file, form, type, formName }: P) => {
  useEffect(() => {
    if (!file) {
      return;
    }

    const updateFormField = ({ status, percent }: { status: 'done' | 'error' | 'uploading'; percent?: number }) => {
      form.setFieldsValue({
        [formName]: [
          {
            uid: type,
            name: file.file.name,
            status,
            percent,
          },
        ],
      });
    };

    const subscriber: UploadSubscriber = {
      onProgress({ percent }) {
        updateFormField({ status: 'uploading', percent });
      },
      onSuccess() {
        updateFormField({ status: 'done' });
      },
      onError() {
        updateFormField({ status: 'error' });
      },
      onAbort() {
        form.setFieldsValue({ [formName]: undefined });
      },
    };

    file.subscribe(subscriber);

    return () => {
      file.unsubscribe(subscriber);
    };
  }, [file, form, formName, type]);
};
