import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { type ReplyDataObject } from 'models/service';
import { deleteComment } from '../../services';

export const useDeleteCommentMutation = () => {
  return useMutation<unknown, { item: { id: string }; reduxItem: ReplyDataObject }>(
    async ({ item }) => deleteComment(item.id),
    {
      async onSuccess(_, params) {
        // TODO remove this from redux
        await dispatch.feed.deleteComment({ comment: params.reduxItem });
      },
    },
  );
};
