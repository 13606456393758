import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type JoinEventDataObject } from 'models/service';

type JoinEventRequest = {
  eventId: string;
  userIds: string[];
  email?: string;
  exitUrl?: string;
};

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export const joinEvent = async ({ eventId, userIds, email, exitUrl }: JoinEventRequest) =>
  apiClient.post<JoinEventDataObject, ApiClientResponse<JoinEventDataObject>>(routes.events.enter(eventId), {
    user_ids: userIds,
    email,
    exit_uri: exitUrl,
  });
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
