import cn from 'classnames';
import { endOfDay, isAfter } from 'date-fns';
import variables from 'common/styles/variables.json';
import { type TimeMode, type TagMode } from 'features/todos/types';
import { Tag, TimeLineIcon, RadarLineIcon, Text, Row, Col } from 'ui';
import { TodoWidgetItemTimeText } from './TodoWidgetItemTimeText';

type P = {
  mode: TimeMode;
  time: Date;
  tags?: TagMode[];
};

const TodoWidgetItemTimeTag = ({ time, mode, tags }: P) => {
  if (mode === 'hidden' || isAfter(time, endOfDay(new Date()))) {
    return null;
  }

  const isLive = tags?.includes('live');

  return (
    <Row item={{ gutter: variables.spaceXs.value }}>
      {isLive ? (
        <Col>
          <Tag className={cn('todo-widget-time-tag', 'todo-widget-time-tag--live')}>
            <RadarLineIcon className="todo-widget-time-tag__icon--live" />
            <Text className={cn('todo-widget-time-tag__text', 'todo-widget-time-tag__text--live')}>time_tag_live</Text>
          </Tag>
        </Col>
      ) : null}
      <Col>
        <Tag className="todo-widget-time-tag">
          <TimeLineIcon />
          <TodoWidgetItemTimeText className="todo-widget-time-tag__text" time={time} mode={mode} />
        </Tag>
      </Col>
    </Row>
  );
};

export default TodoWidgetItemTimeTag;
