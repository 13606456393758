import { Skeleton, Title } from 'ui';
import { useGetVideoDetailQuery } from '../queries';
import { useGetVideoMedia } from '../queries/useGetMedia';
import { VideoUploadForm } from './VideoUploadForm';

type P = {
  videoId: string;
};

const VideoDetail = ({ videoId }: P) => {
  const { data, isLoading } = useGetVideoDetailQuery(videoId);

  // TODO: consider load only when someone is subscribed
  const {
    data: trailerData,
    isLoading: isTrailerLoading,
    isFetching: isTrailerFetching,
  } = useGetVideoMedia({ mediaId: data?.trailer?.externalId });

  // TODO: consider load only when someone is subscribed
  const {
    data: fullData,
    isLoading: isFullLoading,
    isFetching: isFullFetching,
  } = useGetVideoMedia({ mediaId: data?.full?.externalId });

  return (
    <>
      {!data || isLoading || (isTrailerLoading && isTrailerFetching) || (isFullLoading && isFullFetching) ? (
        <Skeleton active paragraph={{ rows: 8 }} />
      ) : (
        <>
          <Title level={2}>{data.title}</Title>
          <VideoUploadForm video={data} trailerData={trailerData} fullData={fullData} />
        </>
      )}
    </>
  );
};

export default VideoDetail;
