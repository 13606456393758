// TODO: we want reuse this api client in chat-web
import axios, { type AxiosResponse, type AxiosError } from 'axios';

import qs from 'qs';
import { getCurrentNodeToken } from 'features/auth/services/nodeApi';
import { type ApiClientResponse } from '.';

export const mediaModuleClient = axios.create({
  responseType: 'json',
  withCredentials: false,
  baseURL: String(process.env.REACT_APP_MEDIA_MODULE_URL),
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

mediaModuleClient.interceptors.response.use(
  (response) => {
    return normalizeSuccessResponse(response);
  },
  (error: AxiosError) => {
    normalizeErrorResponse(error);
  },
);

// TODO: Refactor after Axios update (see PBA files for reference)
mediaModuleClient.interceptors.request.use((config) => {
  const headers = config.headers as Record<string, unknown>;
  // TODO: this function must be placed in different file and also cover the case when token is not set by itself
  const accessToken = getCurrentNodeToken();
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

function normalizeSuccessResponse<T>(response: AxiosResponse<T>): ApiClientResponse<T> {
  return response;
}

function normalizeErrorResponse(error: any) {
  if (error.response?.status === 401) {
    // TODO change one day to new logout
    console.log('TODO change one day to new logout');
  }

  throw error;
}
