import { type AxiosError, isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Result, Button } from 'ui';

type P<T = unknown> = {
  children: JSX.Element;
  error?: AxiosError<T> | Error | null;
  showError?: boolean;
  actions?: boolean;
};

const AxiosErrorHandler = ({ children, error, showError = true, actions = true }: P) => {
  const { t } = useTranslation();
  const { push, go } = useHistory();

  if (error) {
    if (!showError) {
      return null;
    }

    if (isAxiosError(error)) {
      if (!error.response) {
        return <Result subTitle={t('Please check your internet connection.')} />;
      }

      if (error.response.status === 403) {
        return (
          <Result
            status="403"
            subTitle={t('Sorry, you are not authorized to access this resource.')}
            extra={
              actions ? (
                <Button
                  type="primary"
                  onClick={() => {
                    push('/');
                  }}
                  size="large"
                >
                  Home
                </Button>
              ) : null
            }
          />
        );
      }

      if (error.response.status === 404) {
        return (
          <Result
            status="404"
            subTitle={t('Sorry, resource does not exist.')}
            extra={
              actions ? (
                <Button
                  type="primary"
                  onClick={() => {
                    push('/');
                  }}
                  size="large"
                >
                  Home
                </Button>
              ) : null
            }
          />
        );
      }
    }

    return (
      <Result
        status="500"
        subTitle={t('Sorry, something went wrong.')}
        extra={
          actions ? (
            <Button
              type="primary"
              onClick={() => {
                go(0);
              }}
              size="large"
            >
              Retry
            </Button>
          ) : null
        }
      />
    );
  }

  return children;
};

export default AxiosErrorHandler;
