import { type OrganizationBase } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { findStateByCode } from 'common/utils';
import { OrganizationOption } from '../OrganizationOption';
import { type PrimaryOrganizationOption } from '../PrimaryOrganizationSelect';

export const useCreatePrimaryOrganizationOption = () => {
  const { t } = useTranslation();

  return useCallback(
    (organization: OrganizationBase): PrimaryOrganizationOption => ({
      optionLabel: (
        <OrganizationOption
          title={organization.name}
          description={[organization.city, findStateByCode(organization.state)?.name].filter(Boolean).join(', ')}
          image={{
            alt: t('{{organizationName}} photo', { organizationName: organization.name }),
            src: organization.profilePhoto?.url,
          }}
        />
      ),
      label: organization.name,
      value: organization.id,
    }),
    [t],
  );
};
