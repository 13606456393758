import cs from 'classnames';
import { Form, Section, Button, type ButtonProps, type SectionProperties } from 'ui';

type P = {
  section?: SectionProperties;
  left?: ButtonProps;
  right?: ButtonProps;
  submit?: ButtonProps;
  justifyContent?: 'end' | 'space-between' | 'start';
};

// TODO use this on mostly every Modal footer ideally
const ModalFormActions = ({ section, left, right, submit, justifyContent = 'end' }: P) => (
  <Section paddingBottom={false} lineBottom={false} {...section}>
    <Form.Item className="modal-form-actions">
      <div
        className={cs('modal-form-actions__buttons-wrapper', {
          'modal-form-actions__buttons-wrapper--justify-content-start': justifyContent === 'start',
          'modal-form-actions__buttons-wrapper--justify-content-end': justifyContent === 'end',
          'modal-form-actions__buttons-wrapper--justify-content-space-between': justifyContent === 'space-between',
        })}
      >
        {left ? (
          <Button className={cs('modal-form-actions__button', left.className)} size="middle" {...left}>
            {left.children ?? 'Back'}
          </Button>
        ) : null}
        {submit ? (
          <Button
            type="primary"
            htmlType={submit.onClick ? 'button' : 'submit'}
            className={cs('modal-form-actions__button', submit.className)}
            size="middle"
            {...submit}
          >
            {submit.children ?? 'Submit'}
          </Button>
        ) : null}
        {right ? (
          <Button className={cs('modal-form-actions__button', right.className)} size="middle" {...right}>
            {right.children ?? 'Back'}
          </Button>
        ) : null}
      </div>
    </Form.Item>
  </Section>
);

export default ModalFormActions;
