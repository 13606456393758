import * as React from 'react';

import { Translation } from 'old/atoms/translation';
import { RichTextRenderer, Title } from 'ui';

const PADDING = 20;

export class Renderer extends React.Component {
  render() {
    const { item } = this.props;
    const { html_content, text_content } = item[item.type] ?? {};

    if (html_content || text_content) {
      return (
        <div style={{ paddingLeft: PADDING, paddingRight: PADDING }}>
          {item.type === 'post' && item.post.title ? <Title level={5}>{item.post.title}</Title> : null}
          {html_content ? (
            <RichTextRenderer data={html_content} />
          ) : (
            <div className="dont-break-out">{text_content}</div>
          )}
          {text_content ? <Translation id={item[item.type]?.id} type={item.type} /> : null}
        </div>
      );
    }

    return null;
  }
}

export const Content = Renderer;
