const routes = {
  index: '/',
  onboardingTour: {
    index: '/profile/onboarding',
    // TODO this is hack for Google + Azure
    calendarSyncUrl: '/profile/onboarding/calendar-sync',
  },
  consents: '/consents',
  user: {
    profile: '/profile',
    settings: '/settings',
    help: '/help',
  },
  admin: {
    index: '/admin',
    whiteLabel: '/admin/app-configs',
  },
  notifications: '/notifications',
  events: {
    detail: (eventId: string) => `/events/${eventId}/about`,
    share: (eventId: string, invitationToken: string) => `/events/${eventId}/enter/${invitationToken}`,
  },
  // TODO rearange routes to better fit the structure
  contentObjects: {
    posts(id: string) {
      return `/posts/${id}`;
    },
    activities(id: string) {
      return `/activities/${id}`;
    },
    polls(id: string) {
      return `/polls/${id}`;
    },
    kudos(id: string) {
      return `/kudos/${id}`;
    },
    events(id: string) {
      return `/events/${id}/about`;
    },
    campaigns(id: string, organizationId: string) {
      return `/campaigns/listing/${organizationId}/${id}`;
    },
    withingsOrderPosts(id: string) {
      return `/withings_order_posts/${id}`;
    },
  },
  community: {
    joined: '/joined-communities',
    feed(id: string) {
      return `/communities/${id}/feed`;
    },
  },
  program: {
    joined: '/joined-programs',
    create: '/joined-programs/create',
    update(id: string) {
      return `/joined-programs/${id}/update`;
    },
    timeline(id: string) {
      return `/courses/${id}/timeline`;
    },
    about(id: string) {
      return `/courses/${id}/about`;
    },
  },
  channels: {
    index: '/channels',
    organizationChannel: (organizationId: string, scope: 'campaigns' | 'communities' | 'events' | 'programs') =>
      `/channels/${organizationId}/${scope}`,
    /**
     * @deprecated
     * Remove in version 2.8.0+ with backward redirect
     */
    createProgram: (id: string) => `/channels/${id}/programs/create`,
    /**
     * @deprecated
     * Remove in version 2.8.0+ with backward redirect
     */
    updateProgram: (organizationId: string, programId: string) =>
      `/channels/${organizationId}/programs/${programId}/update`,
    customChannelDetail: (id: string) => `/channels/${id}`,
  },
  members: {
    detail: (id: string) => `/members/${id}`,
    message: (id: string) => `/members/${id}/message`,
  },
  chat: {
    create: (path: ':chatState(new)' | 'new' = 'new') => `/message/${path}`,
  },
  privacyPolicy: '/privacy-policy',
  video: {
    index: '/videos',
    detail: (id: string) => `/videos/${id}`,
  },
};

export default routes;
