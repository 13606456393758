import moment from 'moment';

import { Attachment } from 'old/atoms/feed-object/shared/Attachment';
import { Translation } from 'old/atoms/translation';
import { dispatch } from 'old/store';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import Poll from 'models/poll';
import { Checkbox, Col, message, Radio, RichTextRenderer, Row, Text, Title } from 'ui';
import api from './services/api';

type P = {
  poll: Poll;
  isDisabled: boolean;
  isBookmark?: boolean;
  showChoicePercentage: boolean;
};

const PollRendererCore = ({ poll, isDisabled, isBookmark = false, showChoicePercentage }: P) => {
  const pollData = useMemo(() => new Poll(poll), [poll]);
  const isFinished = moment().isAfter(pollData.end_time);
  const notStarted = moment().isBefore(pollData.start_time);

  const { t } = useTranslation();

  const onVote = async (event: any) => {
    const voteResponse = event.target.checked
      ? await api.addVote(event.target.value)
      : await api.removeVote(event.target.value);

    if (voteResponse.ok) {
      dispatch.poll.votePoll({ poll: voteResponse.data });
    } else {
      message.error('Failed to add vote.');
    }
  };

  const getPercentage = (finalNumber: number, percentageNumber: number) => {
    return Math.round(((percentageNumber * 100) / finalNumber) * 100) / 100;
  };

  return (
    <Row item={{ gutter: 16, className: 'poll-core' }}>
      {pollData.title ? (
        <Col item={{ span: 24 }}>
          <Title level={5}>{pollData.title}</Title>
        </Col>
      ) : null}
      <Col item={{ span: 24 }}>
        <RichTextRenderer data={pollData.html_description} />
        {pollData.description ? <Translation id={pollData.id} type="poll" /> : null}
      </Col>
      {isFinished ? (
        <Col item={{ span: 24, style: { marginTop: 30 } }}>
          <Title level={4}>Poll has ended</Title>
        </Col>
      ) : null}
      {notStarted ? (
        <Col item={{ span: 24, style: { marginTop: 30 } }}>
          <Title level={4}>Poll not started yet</Title>
        </Col>
      ) : null}
      <Col item={{ span: 24, className: `poll-core__poll ${isBookmark ? 'poll-core__disabled' : ''}` }}>
        {pollData.questions.map((item) => {
          const votes = item.question_votes;
          return (
            <div key={item.id} className="poll-core__poll__item">
              <Text strong size="large">
                {item.question}
              </Text>
              <Text strong size="small" style={{ marginTop: 25 }}>
                {item.multiple ? t('Please select all that apply') : t('Please select one')}
              </Text>

              {item.multiple ? (
                <div className="poll-core__choices">
                  <Checkbox.Group
                    defaultValue={item.choices.filter((choice) => choice.voted).map((choice) => choice.id)}
                    disabled={isFinished || notStarted || isDisabled || isBookmark}
                  >
                    {item.choices.map((choice) => (
                      <Row item={{ gutter: 13 }} key={choice.id}>
                        <Col item={{ span: 21 }}>
                          <Checkbox value={choice.id} className="poll-core__choice" onChange={onVote}>
                            {choice.name}
                          </Checkbox>
                        </Col>
                        {isFinished || showChoicePercentage ? (
                          <Col item={{ span: 3, className: 'poll-core__choices__percentage' }}>
                            <Text weight="bold">
                              {votes === 0 ? choice.votes_count : getPercentage(votes, choice.votes_count)}%
                            </Text>
                          </Col>
                        ) : null}
                      </Row>
                    ))}
                  </Checkbox.Group>
                </div>
              ) : (
                <div className="poll-core__choices">
                  <Radio.Group
                    onChange={onVote}
                    defaultValue={item.choices.find((choice) => choice.voted)?.id}
                    disabled={isFinished || notStarted || isDisabled || isBookmark}
                  >
                    {item.choices.map((choice) => (
                      <Row item={{ gutter: 13 }} key={choice.id}>
                        <Col item={{ span: 21 }}>
                          <Radio value={choice.id} className="poll-core__choice">
                            {choice.name}
                          </Radio>
                        </Col>
                        {isFinished || showChoicePercentage ? (
                          <Col item={{ span: 3, className: 'poll-core__choices__percentage' }}>
                            {votes === 0 ? choice.votes_count : getPercentage(votes, choice.votes_count)}%
                          </Col>
                        ) : null}
                      </Row>
                    ))}
                  </Radio.Group>
                </div>
              )}
              <Text strong size="small" style={{ marginTop: 15 }}>
                {votes} {t('pollVotes', { count: votes })}
              </Text>
            </div>
          );
        })}
      </Col>
      {/* TODO Create Attachments component */}
      {pollData.attachments?.length ? (
        <Col item={{ span: 24, className: 'poll-core__attachments' }}>
          <div className="renderer-core__attachments">
            <Title level={5}>{t('Attachments')}</Title>
            <div className="renderer-core__attachments-wrapper">
              {pollData.attachments.map((attachment) => (
                <Attachment key={attachment.id} attachment={attachment} />
              ))}
            </div>
          </div>
        </Col>
      ) : null}
    </Row>
  );
};

export default PollRendererCore;
