import { type AxiosError, isAxiosError } from 'axios';
import { type ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Result, Button } from 'ui';

type AxiosErrorBoundaryProperties = {
  children: ReactNode;
  actions?: boolean;
  handleUnauthorized?: boolean;
};

type AxiosErrorFallbackProperties = Omit<AxiosErrorBoundaryProperties, 'children'> & {
  error: AxiosError | Error;
  onRetry: () => void;
};

const AxiosErrorFallback = ({
  error,
  actions = true,
  handleUnauthorized = false,
  onRetry,
}: AxiosErrorFallbackProperties) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  if (isAxiosError(error)) {
    if (!error.response) {
      return (
        <Result
          subTitle={t('Please check your internet connection.')}
          extra={
            actions ? (
              <Button
                type="primary"
                onClick={() => {
                  onRetry();
                }}
                size="large"
              >
                Retry
              </Button>
            ) : null
          }
        />
      );
    }

    if (error.response.status === 401 && !handleUnauthorized) {
      throw error;
    }

    const resultDataMap: Record<number, { status: 403 | 404; subTitle: string; action: JSX.Element } | undefined> = {
      401: {
        status: 403,
        subTitle: t('Sorry, you are not authorized to access this resource.'),
        action: (
          <Button
            type="primary"
            onClick={() => {
              push('/login');
            }}
            size="large"
          >
            Home
          </Button>
        ),
      },
      403: {
        status: 403,
        subTitle: t('You are not permitted to access this data.'),
        action: (
          <Button
            type="primary"
            onClick={() => {
              push('/');
            }}
            size="large"
          >
            Home
          </Button>
        ),
      },
      404: {
        status: 404,
        subTitle: t('Sorry, resource does not exist.'),
        action: (
          <Button
            type="primary"
            onClick={() => {
              push('/');
            }}
            size="large"
          >
            Home
          </Button>
        ),
      },
    };

    const resultData = resultDataMap[error.response.status];
    if (resultData) {
      return (
        <Result status={resultData.status} subTitle={resultData.subTitle} extra={actions ? resultData.action : null} />
      );
    }
  }

  return (
    <Result
      status="500"
      subTitle={t('Sorry, something went wrong.')}
      extra={
        actions ? (
          <Button
            type="primary"
            onClick={() => {
              onRetry();
            }}
            size="large"
          >
            Retry
          </Button>
        ) : null
      }
    />
  );
};

const AxiosErrorBoundary = ({ children, ...rest }: AxiosErrorBoundaryProperties) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <AxiosErrorFallback onRetry={resetErrorBoundary} error={error} {...rest} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default AxiosErrorBoundary;
