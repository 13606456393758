import { WithingsOrderPost, type Program } from 'models';
import { useState, useEffect } from 'react';

import { type Social } from 'models/content-object';
import { Modal, message } from 'ui';
import { WithingsOrderPostForm } from './components';
import { useCreateWithingsOrderPostQuery } from './queries';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
  program: Program;
  onRequestReturn?: () => void;
};

const CreateWithingsOrderPost = ({ onSuccess, onCancel, isOpened, program, onRequestReturn }: P) => {
  const [postData, setPostData] = useState<WithingsOrderPost<Social>>(new WithingsOrderPost({}, program));

  const { mutate: createWithingsOrderPost, isLoading: isCreatingWithingsOrderPost } = useCreateWithingsOrderPostQuery();

  const onSubmit = (post: WithingsOrderPost<Social>) => {
    if (post.program_topic_id && post.publish_at && post.text_content && post.html_content) {
      createWithingsOrderPost(
        {
          programId: program.id,
          programTopicId: post.program_topic_id,
          publishAt: post.publish_at,
          textContent: post.text_content,
          htmlContent: post.html_content,
          title: post.title,
          todoDueDate: post.todo_due_date,
        },
        {
          onSuccess,
          onError() {
            message.error('Something went wrong, please try again later.');
          },
        },
      );
    }
  };

  useEffect(() => {
    if (isOpened) {
      setPostData(new WithingsOrderPost({}, program));
    }
  }, [isOpened, program]);

  return (
    <Modal
      title="Create Order"
      isOpened={isOpened}
      onCancel={onCancel}
      onBackClick={onRequestReturn}
      disableBack={!onRequestReturn}
    >
      <WithingsOrderPostForm
        onSubmit={onSubmit}
        loading={isCreatingWithingsOrderPost}
        post={postData}
        program={program}
        submitText="Create Post"
      />
    </Modal>
  );
};

export default CreateWithingsOrderPost;
