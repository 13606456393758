import { useCallback, useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/naming-convention -- Keys are constant values
export const CHANGELOG_STORAGE_KEY = 'changelog';

export const useChangelogVersion = () => {
  const storedVersion = localStorage.getItem(CHANGELOG_STORAGE_KEY);
  const latestChangelogVersion = String(process.env.REACT_APP_VERSION);

  const updateLatestChangelogVersion = useCallback(() => {
    if (storedVersion !== latestChangelogVersion) {
      localStorage.setItem(CHANGELOG_STORAGE_KEY, latestChangelogVersion);
    }
  }, [latestChangelogVersion, storedVersion]);

  return useMemo(
    () => ({
      storedVersion,
      latestChangelogVersion,
      updateLatestChangelogVersion,
    }),
    [latestChangelogVersion, storedVersion, updateLatestChangelogVersion],
  );
};
