import { Button, Check } from 'ui';

const CompleteNowItem = () => {
  return (
    <Button type="primary" size="small" icon={<Check />}>
      Complete now
    </Button>
  );
};

export default CompleteNowItem;
