import { Consent } from 'models';
import { useQuery } from 'query';
import { getConsents } from '../services';
import { getConsentsKey } from '.';

export const useConsentsQuery = (params: { consentIds?: string[] }) =>
  useQuery<Consent[]>(
    getConsentsKey({ consentIds: params.consentIds }),
    async () => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- We missing correct application model in camelCase, we need to fix it
      const response = await getConsents({ consent_ids: params.consentIds });

      return response.data.map((consent) => new Consent(consent));
    },
    {
      enabled: Boolean(params.consentIds),
    },
  );
