import { useMutation } from '@tanstack/react-query';

import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { createOauthToken, type OauthToken } from '../../models';
import { loginWithToken } from '../../services';

export const useLoginWithTokenQuery = () =>
  useMutation<
    OauthToken,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    { token: string; type?: 'magic_token' }
  >({
    async mutationFn({ token, type }) {
      const oauthResponse = await loginWithToken({
        /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
        login_token: token,
        login_token_type: type,
        grant_type: 'password',
        /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      });

      return createOauthToken(oauthResponse.data);
    },
  });
