import { LoadingOutlined } from '@ant-design/icons';
import { type Form, Upload, message } from 'antd';
import { type UploadChangeParam } from 'antd/lib/upload/interface';

import { ImageCropperModal } from 'old/atoms/image-cropper';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../Item';

// eslint-disable-next-line @typescript-eslint/naming-convention -- Keys are constant values
const CANCEL_ERROR = 'Action cancelled';

type ItemP = Omit<React.ComponentProps<typeof Form.Item>, 'children'> & {
  label?: string;
};
type InputP = React.ComponentProps<typeof Upload.Dragger> & Record<string, unknown>;

type ImageInputContentProperties = {
  input?: InputP;
  value?: any;
  onChange?: (value: any) => void;
  aspectRatio: any;
};

type P = {
  item?: ItemP;
  input?: InputP;
  aspectRatio: any;
};

const ImageInputContent = ({ value, onChange, input, aspectRatio }: ImageInputContentProperties) => {
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [onFileProcess, setOnFileProcess] = useState<Function | null>(null);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [onFileError, setOnFileError] = useState<Function | null>(null);
  const [uploadFile, setUploadFile] = useState<string | null>(null);
  // eslint-disable-next-line react/hook-use-state
  const [$imageCropper, set$ImageCropper] = useState<any>(null);

  const { t } = useTranslation();

  const handleChange = (info: UploadChangeParam) => {
    switch (info.file.status) {
      case 'uploading':
        setLoading(true);
        break;
      case 'done':
        setLoading(false);
        break;
      case 'error':
        if (info.file.error.message !== t(CANCEL_ERROR)) {
          message.error(t('Failed to upload'), 1.5);
        }

        setLoading(false);
        break;
    }

    if (onChange) {
      onChange(info);
    }
  };

  const onRequestClose = (success: boolean) => {
    if (!success && onFileError) {
      onFileError(new Error(CANCEL_ERROR));
    }
  };

  const onCropperSubmit = (response: any) => {
    if (onFileProcess) {
      const onSuccess = onFileProcess[0];
      const file = onFileProcess[1];
      onSuccess(response, file);
    }
  };

  const uploadButton = (
    <div style={{ height: '180px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
      {loading ? <LoadingOutlined /> : <img src="/assets/upload_image.svg" alt="upload" />}
    </div>
  );

  return (
    <>
      <Upload.Dragger
        accept="image/*"
        listType="picture-card"
        multiple={false}
        showUploadList={false}
        customRequest={({ onError, onSuccess, file }) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            setOnFileProcess(() => [onSuccess, file]);
            setOnFileError(() => onError);
            setUploadFile(reader.result as string);
            $imageCropper.open();
          });

          if (file instanceof Blob) {
            reader.readAsDataURL(file);
          }
        }}
        onChange={handleChange}
        style={{ backgroundColor: 'inherit' }}
        {...input}
        id={input?.id}
      >
        {value?.url && !loading ? (
          <img src={value.url} alt="avatar" style={{ maxWidth: '100%', minHeight: '200px' }} />
        ) : (
          uploadButton
        )}
      </Upload.Dragger>
      <ImageCropperModal
        initialAspectRatio={aspectRatio.width / aspectRatio.height}
        ref={set$ImageCropper}
        src={uploadFile}
        onRequestClose={onRequestClose}
        onSubmit={onCropperSubmit}
      />
    </>
  );
};

const ImageInputDeprecated = ({ item, input, aspectRatio }: P) => {
  const normFile = (event: any) => {
    return event.file.response || event.file;
  };

  return (
    <Item getValueFromEvent={normFile} {...item}>
      <ImageInputContent input={input} aspectRatio={aspectRatio} />
    </Item>
  );
};

export default ImageInputDeprecated;
