import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type OrganizationBaseDataObject } from 'models/service';

type PublicOrganizationParams = {
  limit?: number;
  offset?: number;
  search?: string;
  community_id?: string;
};

export const getPublicOrganizations = async (params: PublicOrganizationParams) => {
  return apiClient.get<OrganizationBaseDataObject[], ApiClientResponse<OrganizationBaseDataObject[]>>(
    routes.organizations.public.find,
    {
      params,
    },
  );
};
