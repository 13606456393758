export const routes = {
  oauth: {
    token: '/oauth/token',
    magicLink: '/oauth/magic_token',
  },
  user: {
    current: '/users/current',
    password: '/users/password',
    login: '/users/login',
    logout: '/users/logout',
    delete: '/users/request_delete',
    autocomplete: '/auto_completes/users',
    exists: '/users/exists',
    get: (userId: string) => `/users/${userId}`,
    patch: (userId: string) => `/users/${userId}`,
    genders: '/genders',
    professions: '/professions',
    educationsLevel: '/education_levels',
    interets: '/interests',
    calendar: {
      get: '/calendar_sync',
      create: '/calendar_sync',
      delete: '/calendar_sync',
    },
  },
  chatrooms: {
    stats: '/chatrooms/stats',
  },
  signup: '/users',
  dexcom: {
    sync: '/dexcom/access_token',
    unSync: '/dexcom/access_token',
  },
  withings: {
    createOrderPost: '/withings_order_posts.json',
    editOrderPost: (id: string) => `/withings_order_posts/${id}.json`,
    subscribe: '/withings/orders',
  },
  program: {
    users: (id: string) => `programs/${id}/users`,
    requestToJoin: (id: string) => `/programs/${id}/request`,
    find: '/programs',
    autoComplete: 'auto_completes/program_users',
  },
  community: {
    get: (id: string) => `/communities/${id}`,
    find: '/communities',
    update: (id: string) => `/communities/${id}.json`,
    leave: (id: string) => `/communities/${id}/join`,
    join: (id: string) => `/communities/${id}/join`,
    notifications: {
      get: (id: string) => `/communities/${id}/user_notifications_settings`,
      update: (id: string) => `/communities/${id}/user_notifications_settings`,
    },
    inviteUsers: '/change_requests.json',
    invToken: (communityId: string) => `/communities/${communityId}/public_invite`,
    members: (id: string) => `/communities/${id}/members`,
    autoComplete: 'auto_completes/community_users',
    changeRole: '/admin/users/change_role',
  },
  kudos: {
    get: (id: string) => `/kudos/${id}`,
  },
  files: {
    get: '/assets',
    create: '/assets',
    update: (id: string) => `/assets/${id}`,
    upload: '/file_uploads',
  },
  events: {
    find: '/events',
    create: '/events',
    createFeedback: (id: string) => `/events/${id}/feedback`,
    update: (id: string) => `/events/${id}`,
    patch: (id: string) => `/events/${id}`,
    get: (id: string) => `/events/${id}`,
    users: (id: string) => `/events/${id}/users`,
    updateUserStatus: (id: string) => `/events/${id}/connect`,
    enter: (id: string) => `/calls/${id}/enter`,
  },
  tokens: {
    generateInvitation: '/invitation_tokens',
    getInvitationToken: (invitationToken: string) => `/invitation_tokens/${invitationToken}`,
  },
  changeRequests: {
    get: (id: string) => `/change_requests/${id}`,
    post: '/change_requests.json',
  },
  consents: {
    find: '/consents',
    accept: '/consents/accept',
  },
  todos: {
    get: '/todos',
  },
  posts: {
    create: '/posts',
    markAsRead: (id: string) => `/posts/${id}/read`,
  },
  contentObjects: {
    get: (id: string) => `/content_objects/${id}`,
    like: {
      create: (id: string) => `/content_objects/${id}/like`,
      delete: (id: string) => `/content_objects/${id}/like`,
    },
    bookmark: {
      create: (id: string) => `/content_objects/${id}/bookmark`,
      delete: (id: string) => `/content_objects/${id}/bookmark`,
    },
    comment: {
      delete: (id: string) => `/content_objects/${id}`,
      create: '/content_objects',
      edit: (id: string) => `/content_objects/${id}`,
    },
  },
  report: '/abuse_reports',
  campaigns: {
    stripeDonation: {
      create: '/donations.json',
      payment: {
        create: (id: string) => `/donations/${id}/payment`,
      },
    },
    find: '/campaigns',
  },
  organizations: {
    find: '/organizations.json',
    public: {
      find: '/organizations/public_search',
    },
  },
  channels: {
    find: '/channels',
    entities: {
      get: (id: string) => `/channels/${id}/content`,
    },
    detail: {
      get: (id: string) => `/channels/${id}/detail`,
    },
  },
  onboardingSteps: {
    find: '/onboarding_steps',
    update: (id: string) => `/onboarding_steps/${id}`,
    complateAll: '/onboarding_steps/complete_all',
  },
  videos: {
    get: (id: string) => `/videos/${id}`,
    create: '/videos',
    find: '/videos',
    update: (id: string) => `/videos/${id}`,
  },
};
