import { type ReduxUser } from 'models';
import { AppLayout } from 'old/layouts/AppLayout';
import { Videos } from 'features/videos/Videos';
import { usePathToVideoDetail } from './hooks';

type P = {
  viewer: ReduxUser;
};

const PageVideos = ({ viewer }: P) => {
  const getPathToVideoDetail = usePathToVideoDetail();

  return <AppLayout center={<Videos viewer={viewer} getPathToVideoDetail={getPathToVideoDetail} />} />;
};

export default PageVideos;
