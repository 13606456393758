import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { type UploadedFileDataObject } from 'models/service';
import { Row, Text, Col, Space } from 'ui';
import { SaveAttachmentButton } from '../SaveAttachmentButton';
import { Attachment } from './Attachment';
import { ImageAttachments } from './ImageAttachments';

type P = {
  attachments: UploadedFileDataObject[];
};

const Attachments = ({ attachments }: P) => {
  const { t } = useTranslation();

  return (
    <Space size="small" direction="vertical" block>
      <ImageAttachments attachments={attachments} />
      <Row>
        <Col item={{ flex: 1 }}>
          <Text className="medium-weight">{t('Attachments ({{count}})', { count: attachments.length })}</Text>
        </Col>
        <Col>
          <SaveAttachmentButton attachments={attachments} mode="all" />
        </Col>
      </Row>
      <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value] }}>
        {attachments.map((attachment) => (
          <Col item={{ span: 12 }} key={attachment.id}>
            <Attachment attachment={attachment} />
          </Col>
        ))}
      </Row>
    </Space>
  );
};

export default Attachments;
