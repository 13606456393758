import { type UploadFile, type VideoType } from 'lib/media-upload-file';
import { useCallback } from 'react';
import { message, type FormInstance } from 'ui';
import { useUpdateMedia } from '../../../../queries';

type P = {
  file?: UploadFile;
  form: FormInstance;
  formName: string;
  ref: string;
  type: VideoType;
  removeUploadFromContext: () => void;
};

export const useRemoveUpload = ({ file, form, formName, ref, type, removeUploadFromContext }: P) => {
  const { mutate: updateMedia } = useUpdateMedia();

  return useCallback(() => {
    // TODO: state done with upload doesnt mean CO is updated
    if (!file || file.state.status === 'done') {
      updateMedia(
        { id: ref, data: { video: { [type]: {} } } },
        {
          onSuccess() {
            message.success('Video removed successfully');
            form.setFieldValue(formName, undefined);
            removeUploadFromContext();
          },
          onError() {
            message.error('Failed to remove video');
          },
        },
      );
    } else {
      file.abort();
    }
  }, [file, updateMedia, form, formName, ref, type, removeUploadFromContext]);
};
