import cs from 'classnames';
import { routes } from 'routes';
import { images } from 'common/utils';

import { Avatar, Link } from 'ui';

type P = {
  authorId: string;
  avatar: {
    url: string;
    alias?: string;
  };
  className?: string;
};

const AvatarLink = ({ authorId, avatar, className }: P) => {
  return (
    <Link
      to={routes.members.detail(authorId)}
      className={cs('avatar-link', className)}
      effects={{ focus: true, hover: true }}
    >
      <Avatar size="default" photo={avatar.url ?? images.default.userProfilePhoto}>
        {avatar.alias}
      </Avatar>
    </Link>
  );
};

export default AvatarLink;
