import { addMinutes, isAfter, isBefore, parseISO, subMinutes } from 'date-fns';
import { type EventInviteeRole, type UserRSVP } from 'models';
import {
  type EventTodoDataObject,
  type ActivityTodoDataObject,
  type CommonTodoDataObject,
  type TodoDataObject,
  type PollTodoDataObject,
  type WithingsOrderPostTodoDataObject,
  type ActivityDataObject,
  type EventDataObject,
  type PollDataObject,
  type WithingsOrderPostDataObject,
  type PostTodoDataObject,
  type PostDataObject,
  type UserTodoDataObject,
} from './service';

// TODO fix something is not required and is optional

type CommonTodo = {
  id: string;
  startTime: Date;
  endTime: Date;
  path: string;
};

export type ActivityTodo = CommonTodo & {
  type: 'Activity';
  activity: Activity;
};

type Activity = {
  id: string;
  name: string;
  description: string;
  periodicityType: 'annually' | 'daily' | 'monthly' | 'one_time' | 'quarterly' | 'weekly';
  times: Array<{ id: string; time: string }>;
  currentActivityTime?: Date;
};

export type EventTodo = CommonTodo & {
  type: 'Event';
  event: Event;
};

export type Event = {
  id: string;
  name: string;
  textContent: string;
  startTime: Date;
  endTime: Date;
  bmgId: string | null;
  currentUserRole: EventInviteeRole;
  currentUserRsvp: UserRSVP;
};

export type PollTodo = CommonTodo & {
  type: 'Poll';
  poll: Poll;
};

type Poll = {
  id: string;
  title: string;
  description: string;
};

export type WithingsOrderPostTodo = CommonTodo & {
  type: 'WithingsOrderPost';
  withingsOrderPost: WithingsOrderPost;
};

type WithingsOrderPost = {
  id: string;
  title: string;
  textContent: string;
};

export type PostTodo = CommonTodo & {
  type: 'Post';
  post: Post;
};

type Post = {
  id: string;
  title: string;
  textContent: string;
  htmlContent: string;
};

export type UserTodo = CommonTodo & {
  type: 'User';
};

export type Todo = ActivityTodo | EventTodo | PollTodo | PostTodo | UserTodo | WithingsOrderPostTodo;

export const createTodo = (todo: TodoDataObject): Todo => {
  switch (todo.type) {
    case 'Activity':
      return createActivityTodo(todo);
    case 'Event':
      return createEventTodo(todo);
    case 'Poll':
      return createPollTodo(todo);
    case 'WithingsOrderPost':
      return createWithingsOrderPostTodo(todo);
    case 'Post':
      return createPostTodo(todo);
    case 'User':
      return createUserTodo(todo);
  }
};

const createCommonTodo = (todo: CommonTodoDataObject): CommonTodo => ({
  id: todo.id,
  startTime: parseISO(todo.start_time),
  endTime: parseISO(todo.end_time),
  path: todo.path,
});

export const createActivityTodo = (todo: ActivityTodoDataObject): ActivityTodo => ({
  ...createCommonTodo(todo),
  type: todo.type,
  activity: createActivity(todo.activity),
});

const createActivity = (activity: ActivityDataObject): Activity => ({
  id: activity.id,
  name: activity.name,
  description: activity.description,
  periodicityType: activity.periodicity_type,
  times: activity.times,
  currentActivityTime: activity.current_activity_time ? parseISO(activity.current_activity_time) : undefined,
});

export const createEventTodo = (todo: EventTodoDataObject): EventTodo => ({
  ...createCommonTodo(todo),
  type: todo.type,
  event: createEvent(todo.event),
});

const createEvent = (event: EventDataObject): Event => ({
  id: event.id,
  name: event.name,
  textContent: event.text_content,
  startTime: parseISO(event.start_time),
  endTime: parseISO(event.end_time),
  bmgId: event.bmg_id,
  currentUserRole: event.current_user_role,
  currentUserRsvp: event.current_user_rsvp,
});

export const createPollTodo = (todo: PollTodoDataObject): PollTodo => ({
  ...createCommonTodo(todo),
  type: todo.type,
  poll: createPoll(todo.poll),
});

const createPoll = (poll: PollDataObject): Poll => ({
  id: poll.id,
  title: poll.title,
  description: poll.description,
});

export const createWithingsOrderPostTodo = (todo: WithingsOrderPostTodoDataObject): WithingsOrderPostTodo => ({
  ...createCommonTodo(todo),
  type: todo.type,
  withingsOrderPost: createWithingsOrderPost(todo.withings_order_post),
});

const createWithingsOrderPost = (withingsOrderPost: WithingsOrderPostDataObject): WithingsOrderPost => ({
  id: withingsOrderPost.id,
  title: withingsOrderPost.title,
  textContent: withingsOrderPost.text_content,
});

export const createPostTodo = (todo: PostTodoDataObject): PostTodo => ({
  ...createCommonTodo(todo),
  type: todo.type,
  post: createPost(todo.post),
});

const createPost = (post: PostDataObject): Post => ({
  id: post.id,
  title: post.title,
  textContent: post.text_content,
  htmlContent: post.html_content,
});

export const createUserTodo = (todo: UserTodoDataObject): UserTodo => ({
  ...createCommonTodo(todo),
  type: todo.type,
});

export type TimeMode = 'at' | 'by' | 'hidden';
export type TagMode = 'live';

const activityOffset = 15;

const isAfterActivityAvailable = (time: Date) => {
  const endTime = addMinutes(time, activityOffset);
  return isAfter(new Date(), endTime);
};

const isBeforeActivityAvailable = (time: Date) => {
  const startTime = subMinutes(time, activityOffset);
  return isBefore(new Date(), startTime);
};

export const isActivityAvailable = (currentActivityTime: Date) => {
  return !(isBeforeActivityAvailable(currentActivityTime) || isAfterActivityAvailable(currentActivityTime));
};
