import { Card } from 'ui';
import { TelehealthRequest } from 'features/events/components/Telehealth';
import { CommunityListItem } from 'models';
import { getCreateEventPermissions } from 'features/events/utils';
import { TodosWidget } from 'features/todos';

const SidebarRenderer = ({ viewer, numberOfNotifications, unreadMessages, hideTodosFeedCta }) => {
  const { isTelehealthButtonAvailable } = getCreateEventPermissions({ viewer });

  return (
    <div className="main-sidebar">
      {isTelehealthButtonAvailable ? (
        <Card className="main-sidebar__telehealth">
          <TelehealthRequest
            buttonType="secondary"
            viewer={viewer}
            communities={viewer.joined_communities.map((community) => new CommunityListItem(community))}
          />
        </Card>
      ) : null}

      <TodosWidget
        numberOfNotifications={numberOfNotifications}
        unreadMessages={unreadMessages}
        className="main-sidebar__todos"
        hideTodosFeedCta={hideTodosFeedCta}
        viewer={viewer}
      />
    </div>
  );
};

export const Sidebar = SidebarRenderer;
