import { useTranslation } from 'react-i18next';
import { type TimeMode } from 'features/todos/types';
import { Text } from 'ui';

type P = {
  mode: TimeMode;
  time: Date;
  className: string;
};

const TodoWidgetItemTimeText = ({ time, mode, className }: P) => {
  const { t } = useTranslation();

  switch (mode) {
    case 'at':
      return <Text className={className}>{t('time_tag_at', { date: time })}</Text>;

    case 'by':
      return <Text className={className}>{t('time_tag_by', { date: time })}</Text>;

    case 'hidden':
      return null;
  }
};

export default TodoWidgetItemTimeText;
