import { Account } from 'models';
import { useQuery } from 'query';
import { type NodeApiClientResponseError, type NodeApiError } from 'common/services';
import { getPositions } from '../services';
import { getOverallPointsQueryKey } from './';

export const useOverallPointsQuery = (userId: string) =>
  useQuery<Account | null, NodeApiClientResponseError<NodeApiError>>(
    getOverallPointsQueryKey(userId),
    async () => {
      const response = await getPositions({
        type: 'points',
        status: 'active',
        $limit: 1,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- key is defined by Backend API endpoint
        'meta.ownerExternalIds': userId,
      });

      return response.data.data.length > 0 ? new Account(response.data.data[0]) : null;
    },
    // TODO need to make sure that this request will be executed after login so there will be no 401 error when session expires
    // possible fix might be to make sure that React Router will not do anything until user is logged in

    // {
    //   onError: (error) => {
    //     handleAxiosError(error, 'Failed to load Overall Points.');
    //   }
    // }
  );
