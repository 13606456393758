import { ModalFormActions, RoundElement, Text, Title, CheckFillIcon, Section } from 'ui';

type P = {
  onSubmit: () => void;
};

const SubscribeResult = ({ onSubmit }: P) => {
  return (
    <>
      <Section className="subscribe-result">
        <RoundElement size="large" color="success" className="subscribe-result__icon">
          <CheckFillIcon type="success" />
        </RoundElement>
        <Title level={3}>Order completeness was successful</Title>
        <Text>
          You successfully finished the order form. You will receive an e-mail when we will ship the Withings devices to
          your delivery address.
        </Text>
      </Section>
      <ModalFormActions submit={{ onClick: onSubmit, children: 'Continue to program' }} />
    </>
  );
};

export default SubscribeResult;
