import cs from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { isPast, parseISO } from 'date-fns';

import { AvatarGroup } from '../../atoms/avatar-group';
import { Box } from '../../atoms/box';
import { Button } from '../../atoms/button';
import { Icon } from '../../atoms/icon';
import { isLive, isOpenToResponse, isOpenToJoin } from '../../utils/events';
import { Pill } from '../../atoms/pill';
import { Text } from '../../atoms/text';
import { RsvpButton } from './RsvpButton';
import { ThemeConsumer } from '../../atoms/theme';
import { getLastChangeRequestStatusRedux } from 'features/events/utils';
import { EventStatus } from 'features/events/components/EventStatus';
import { Tooltip, Link } from 'ui';

export function Live({ marginBottom = true }) {
  return (
    <div className={cs('widget-event-row__live', { 'widget-event-row__live--margin-bottom': marginBottom })}>
      <Trans>Live</Trans>
    </div>
  );
}

function JoinButton(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Button
          type="button"
          color={theme.color.green}
          size="sm"
          textColor="white"
          title={<Trans>Join</Trans>}
          block
          onClick={() => props.onClick('join')}
        />
      )}
    </ThemeConsumer>
  );
}

function ButtonGroup(props) {
  const { current_user_rsvp } = props.event;

  return (
    <div style={{ width: props.event.status === 'published' ? 67 : 150 }} className="widget-event-row__join-button">
      {props.event.status === 'draft' ? (
        <EventStatus status={getLastChangeRequestStatusRedux(props.event)} />
      ) : isOpenToJoin(props.event) ? (
        <JoinButton onClick={() => props.onClick('join')} />
      ) : (
        <>
          <RsvpButton
            variant={current_user_rsvp === 'not_going' ? 'not_going' : 'pending'}
            style={{ marginRight: 7 }}
            disabled={!isOpenToResponse(props.event)}
            onClick={() => props.onClick('not_going')}
            data-test-id={current_user_rsvp === 'not_going' ? 'btn_not-going' : 'btn_pending-not-going'}
          >
            <Tooltip item={{ title: "No, I'm not going", trigger: 'hover' }}>
              <Icon name="close" color="inherit" size={14} />
            </Tooltip>
          </RsvpButton>

          <RsvpButton
            variant={current_user_rsvp === 'going' ? 'going' : 'pending'}
            disabled={!isOpenToResponse(props.event)}
            onClick={() => props.onClick('going')}
            data-test-id={current_user_rsvp === 'going' ? 'btn_going' : 'btn_pending-going'}
          >
            <Tooltip item={{ title: "Yes, I'm going", trigger: 'hover' }}>
              <Icon name="check" color="inherit" size={14} />
            </Tooltip>
          </RsvpButton>
        </>
      )}
    </div>
  );
}

export function EventRow(props) {
  const pastEvent = isPast(parseISO(props.data.end_time));
  const { t } = useTranslation();

  return (
    <ThemeConsumer>
      {(theme) => (
        <Box className={`widget-event-row ${props.className}`} flexDirection="row" alignItems="center">
          <div className="widget-event-row__wrapper" id={`event-row-${props.data.id}`}>
            <div className="widget-event-row__past-event">
              <div className={`${pastEvent ? 'past-event' : ''}`}>
                <Link to={`/events/${props.data.id}/about`}>
                  <Text
                    component="p"
                    size="14px"
                    lineHeight="16px"
                    weight="500"
                    className="dont-break-out"
                    style={{ marginBottom: 5, cursor: 'pointer' }}
                  >
                    {props.data.name}
                  </Text>
                </Link>
                {isLive(props.data) && props.data.status === 'published' ? (
                  <Live />
                ) : (
                  <Text
                    color={theme.color.lightGray}
                    size="12px"
                    lineHeight="15px"
                    component="p"
                    style={{ marginBottom: 8 }}
                  >
                    {t('dateFormatShortMonthDayYearTime', {
                      date: new Date(props.data.start_time),
                    })}
                  </Text>
                )}
              </div>
              {props.data.recording_status && pastEvent ? (
                <div style={{ margin: 'auto', marginLeft: 0, paddingBottom: 10 }}>
                  {props.data.recording_status === 'recorded' ? (
                    <img src="/assets/video/video-available.svg" alt="video-available" />
                  ) : (
                    <img src="/assets/video/video-unavailable.svg" alt="video-unavailable" />
                  )}
                </div>
              ) : null}
            </div>
            <Box flexDirection="row" alignItems="center">
              <div style={{ marginRight: 6 }}>
                <Box flexDirection="row" alignItems="center" flexWrap="wrap">
                  {props.data.post_in_communities.map((community) => (
                    <Link
                      to={`/communities/${community.id}`}
                      key={community.id}
                      id={`community-${community.id}`}
                      style={{ padding: '0 5px 5px', cursor: 'pointer' }}
                    >
                      <Pill color={theme.color.brand} title={community.name} truncate />
                    </Link>
                  ))}
                  {props.data.invitees ? (
                    <Link to={`/events/${props.data.id}/attendees`}>
                      <AvatarGroup size="small" users={[...props.data.invitees].splice(0, 5)} total={props.data.invitees_count} />
                    </Link>
                  ) : null}
                </Box>
              </div>
            </Box>
          </div>
          <div>
            <ButtonGroup event={props.data} onClick={(action) => props.onActionClick(action, props.data)} />
          </div>
        </Box>
      )}
    </ThemeConsumer>
  );
}
