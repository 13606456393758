import { type VideoType, type UploadFile } from 'lib/media-upload-file';
import { useMemo } from 'react';
import { type MediaDataObject } from 'models/service';
import { type UploadFile as UploadFileUi } from 'ui';

type P = {
  type: VideoType;
  file?: UploadFile;
  mediaData?: MediaDataObject;
};

export const useFormFileInitialValue = ({ type, file, mediaData }: P): [UploadFileUi] | undefined =>
  useMemo(() => {
    if (mediaData) {
      return [
        {
          uid: type,
          name: mediaData.name,
          status: 'done',
        },
      ];
    }

    // TODO: refactor duplicity?
    if (file?.state.status === 'done') {
      return [
        {
          uid: type,
          name: file.file.name,
          status: 'done',
        },
      ];
    }

    if (file?.state.status === 'error') {
      return [
        {
          uid: type,
          name: file.file.name,
          status: 'error',
        },
      ];
    }

    if (file?.state.status === 'uploading') {
      return [
        {
          uid: type,
          name: file.file.name,
          status: 'uploading',
          // TODO: percent are missing to read from file
        },
      ];
    }

    return undefined;
  }, [mediaData, file, type]);
