import cn from 'classnames';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { User } from '../../../common/models';
import { routes } from '../../../common/services';
import { Chatroom as ChatroomModel, getParticipantInitials } from '../../../models';
import { Avatar, Row, Badge } from '../../../ui';
import { AttachmentIcon } from '../../../ui/Icons';

type P = {
  isOnline?: boolean;
  isActive: boolean;
  chatroom: ChatroomModel;
  currentUser: User;
  onChatroomClick: (id: string) => void;
};

const Chatroom = ({ isOnline, isActive, chatroom, currentUser, onChatroomClick }: P) => {
  const { t } = useTranslation(['translationChat']);
  const users = chatroom.participants;

  const { customClassNameUser, customClassNameText, customClassNameChatroom } = useMemo(() => {
    const highlighted = !chatroom.isReadByUser(currentUser._id);

    const customClassNameUser = cn('chatroom__name', {
      highlighted,
    });

    const customClassNameText = cn('p--small chatroom__description', {
      highlighted,
    });

    const customClassNameChatroom = cn('chatroom', {
      'is-active': isActive,
    });

    return {
      customClassNameUser,
      customClassNameText,
      customClassNameChatroom,
    };
  }, [currentUser._id, chatroom, isActive]);
  const name = useMemo(() => chatroom.getName(currentUser._id, users), [currentUser._id, chatroom, users]);
  const usersForAvatars = useMemo(() => chatroom.getUsersForAvatars(currentUser._id), [currentUser._id, chatroom]);
  const messagePreview = useMemo(() => {
    const messagePreviewType = chatroom.getMessageTypeForPreview();
    switch (messagePreviewType) {
      case 'attachment':
        return (
          <div className="chatroom-message__preview">
            <div className="chatroom-message__preview--attachment-text">
              {chatroom.message?.author === currentUser._id && t('chat.chatroomSidebar.lastMessageAttachmentFromUser')}
              {t('chat.chatroomSidebar.lastMessageAttachment')}
            </div>
            <div className="chatroom-message__preview--attachment-icon">
              <AttachmentIcon />
            </div>
          </div>
        );
      case 'text':
        return chatroom.message?.author === currentUser._id
          ? t('chat.chatroomSidebar.messagePreview', { message: chatroom.message.text })
          : chatroom?.message?.text;
      case 'empty':
      default:
        return t('chat.chatroomSidebar.emptyStates.emptyChatroom');
    }
  }, [chatroom, currentUser._id, t]);

  const handleChatroomClick = () => onChatroomClick(chatroom._id);

  const renderAvatars = (userId: string) => {
    const user = users[userId];

    return (
      <Avatar
        key={userId}
        size="default"
        alt={`profile photo`}
        src={
          new URL(
            routes.chatrooms.media.getAvatarFile(userId, 'user_avatar', 'medium'),
            process.env.REACT_APP_MEDIA_MODULE_URL,
          ).href
        }
      >
        {getParticipantInitials(user)}
      </Avatar>
    );
  };

  const hasUnreadMessages = !chatroom.isReadByUser(currentUser._id);

  return (
    <Row className={customClassNameChatroom} onClick={handleChatroomClick}>
      <div className="chatroom__wrapper">
        <div className="chatroom__avatar">
          <Avatar.Group maxCount={1} maxPopoverPlacement="bottom" className="chatroom__avatar-group">
            {usersForAvatars.map((user) => renderAvatars(user))}
          </Avatar.Group>
          <div className="chatroom__status">
            <Badge status={isOnline ? 'success' : undefined} data-testid="chat-user-dot-online"/>
          </div>
        </div>
        <div className="chatroom__content">
          <div className="chatroom__details">
            <h3 className={customClassNameUser} data-testid="chat-user-user-name">
              {hasUnreadMessages ? <strong>{name}</strong> : name}
            </h3>
            <span className={customClassNameText} data-testid="chat-user-last-message">
              {hasUnreadMessages ? <strong>{messagePreview}</strong> : messagePreview}
            </span>
          </div>
          <div className="chatroom__meta">
            <span className="label--small meta-item meta-item--date">
              {t('chat.chatroomSidebar.chatroomDateFormat', {
                date: chatroom.message ? chatroom.message?.updatedAt : chatroom.createdAt,
              })}
            </span>

            {hasUnreadMessages ? (
              <div className="meta-item meta-item--unread">
                <Badge dot={true} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Row>
  );
};

export default Chatroom;
