import withAntIconWrapper from '../icon-wrapper/withAntIconWrapper';
import { ReactComponent as AudioFileIconImport } from './audio.svg';
import { ReactComponent as CodeFileIconImport } from './code.svg';
import { ReactComponent as FileDefaultIconImport } from './default_file.svg';
import { ReactComponent as DocumentFileIconImport } from './doc.svg';
import { ReactComponent as FolderIconImport } from './folder.svg';
import { ReactComponent as ImgFileIconImport } from './img.svg';
import { ReactComponent as PdfFileIconImport } from './pdf.svg';
import { ReactComponent as PptFileIconImport } from './ppt.svg';
import { ReactComponent as SheetFileIconImport } from './sheet.svg';
import { ReactComponent as VideoFileIconImport } from './video.svg';
import { ReactComponent as ZipFileIconImport } from './zip.svg';

export const FolderIcon = withAntIconWrapper(FolderIconImport);
export const VideoFileIcon = withAntIconWrapper(VideoFileIconImport);
export const AudioFileIcon = withAntIconWrapper(AudioFileIconImport);
export const ZipFileIcon = withAntIconWrapper(ZipFileIconImport);
export const SheetFileIcon = withAntIconWrapper(SheetFileIconImport);
export const PptFileIcon = withAntIconWrapper(PptFileIconImport);
export const PdfFileIcon = withAntIconWrapper(PdfFileIconImport);
export const DocumentFileIcon = withAntIconWrapper(DocumentFileIconImport);
export const CodeFileIcon = withAntIconWrapper(CodeFileIconImport);
export const ImgFileIcon = withAntIconWrapper(ImgFileIconImport);
export const DefaultFileIcon = withAntIconWrapper(FileDefaultIconImport);
