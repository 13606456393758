import { type ChannelBase, getChannelBaseData } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { getChannels } from '../services';
import { getChannelsQueryKey } from '.';

type P = {
  pageSize: number;
  organizationId?: string;
};

export const useChannelsInfiniteQuery = ({ pageSize, organizationId }: P) => {
  return useInfiniteQuery<ChannelBase[]>(
    getChannelsQueryKey({ pageSize, organizationId }),
    async ({ pageParam: pageParameter = undefined }: QueryFunctionContext<QueryKey, number>) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const response = await getChannels({
        limit: pageSize,
        offset: pageParameter,
        organization_id: organizationId,
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return response.data.map((entity) => getChannelBaseData(entity));
    },
    {
      getNextPageParam(lastPage, allPages) {
        return lastPage.length < pageSize ? undefined : allPages.length * pageSize;
      },
    },
  );
};
