import { type PrefferedUnit } from '../types';
import {
  convertInchesToCentimeters,
  convertPoundsToKilograms,
  convertCentimetersToInches,
  convertKilogramsToPounds,
} from '.';

export type Unit = {
  label: string;
  format: (value: number) => string;
  convertToDefault: (value: number) => number;
  convertFromDefault: (value: number) => number;
};

type ImperialHeightUnit = Omit<Unit, 'format'> & {
  format: (feet: number, inches: number) => string;
  inches: {
    label: string;
    format: (inches: number) => string;
  };
  feet: {
    label: string;
    format: (feet: number) => string;
  };
};

export type ImperialUnits = Omit<Units, 'units'> & {
  units: { height: ImperialHeightUnit; weight: Unit; label: string };
};

export type Units = {
  value: PrefferedUnit;
  label: string;
  units: {
    label: string;
    weight: Unit;
    height: Unit;
  };
};

export const imperialUnits: ImperialUnits = {
  value: 'imperial',
  label: 'Imperial',
  units: {
    label: 'lb, in, yd, °F',
    weight: {
      label: 'lbs',
      format: (lbs: number) => `${lbs} lbs`,
      convertToDefault: convertPoundsToKilograms,
      convertFromDefault: convertKilogramsToPounds,
    },
    height: {
      label: 'in',
      format: (feet: number, inches: number) => `${feet}’ ${inches}”`,
      inches: {
        label: 'in',
        format: (inches: number) => `${inches}”`,
      },
      feet: {
        label: 'ft',
        format: (feet: number) => `${feet}’`,
      },

      convertToDefault: convertInchesToCentimeters,
      convertFromDefault: convertCentimetersToInches,
    },
  },
};

export const metricUnits: Units = {
  value: 'metric',
  label: 'Metric',
  units: {
    label: 'kg, m, cm, °C',
    weight: {
      label: 'kg',
      format: (kg: number) => `${kg} kg`,
      convertToDefault: (kg: number) => kg,
      convertFromDefault: (kg: number) => kg,
    },
    height: {
      label: 'cm',
      format: (cm: number) => `${cm} cm`,
      convertToDefault: (cm: number) => cm,
      convertFromDefault: (cm: number) => cm,
    },
  },
};
