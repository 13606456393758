import { type CommunityListItem } from 'models';
import { useCallback, useMemo } from 'react';

import { type TelehealthEventFormValues, type DefaultEventData, type TelehealthEventData } from '../components';
import { type EventRequestData, type MetaRequestData } from '../services';

export const useMapEventRequestData = () => {
  const prepareEventsFiles = (eventsFiles: Exclude<DefaultEventData['formValues']['eventsFiles'], undefined>) =>
    eventsFiles.fileList.map((file) => file.response);

  // TODO refactor
  const mapEventRequestData = useCallback(
    (data: DefaultEventData | TelehealthEventData, communitiesData: CommunityListItem[]): EventRequestData => {
      // TODO separate this all logic too much here
      const selectedCommunity = communitiesData.find((community) => community.id === data.formValues.communityId);
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const commonData = {
        community_id: selectedCommunity?.id,
        text_content: data.formValues.textContent ?? '',
        html_content: data.formValues.htmlContent ?? '',
        start_time: data.formValues.startTime.toDate(),
        end_time: data.formValues.endTime.toDate(),
        duration: data.formValues.duration,
        cover_photo: data.formValues.coverPhoto,
      };

      if (data.eventType === 'telehealth_visit') {
        const { formValues, eventType } = data;
        const selectedCommunity = communitiesData.find((community) => community.id === formValues.communityId);

        // TODO this is copy pasta from `TelehealthRequestForm` need to be fixed
        const serviceTypeForRequest = (data: TelehealthEventFormValues, selectedCommunity?: CommunityListItem) => {
          const { relatedAdmissionId } = data;

          const meta: MetaRequestData = {
            service_type: [],
            admission: relatedAdmissionId ? { _id: relatedAdmissionId } : undefined,
          };

          const patientType = selectedCommunity?.meta?.patientTypes?.find(
            (patientType) => patientType.name === data.patientType,
          );
          if (patientType) {
            meta.patient_type = { name: patientType.name, translations: patientType.translations };
          }

          const visitType = selectedCommunity?.meta?.serviceTypes?.find(
            (serviceType) => serviceType.name === data.visitType,
          );
          if (visitType) {
            meta.service_type?.push({ name: visitType.name, translations: visitType.translations });
          }

          // eslint-disable-next-line unicorn/consistent-destructuring
          const subOption = visitType?.children?.find((child) => child.name === data.visitTypeSubOption);
          if (subOption) {
            meta.service_type?.push({ name: subOption.name, translations: subOption.translations });
          }

          const secondSubOption = subOption?.children?.find(
            // eslint-disable-next-line unicorn/consistent-destructuring
            (childItem) => childItem.name === data.visitTypeSubSubOption,
          );
          if (secondSubOption) {
            meta.service_type?.push({ name: secondSubOption.name, translations: secondSubOption.translations });
          }

          return { meta, name: secondSubOption?.getTranslation() ?? subOption?.getTranslation() ?? data.visitType };
        };

        const { meta, name } = serviceTypeForRequest(formValues, selectedCommunity);

        return {
          ...commonData,
          event_type: eventType,
          allow_guest: false,
          name: `${name} - ${formValues.duration} min`,
          view_permission: 'private',
          invitees: [...(formValues.invitees ?? []), ...(formValues.moderators ?? [])].map((user) => ({
            id: user.id,
            event_role: user.eventRole,
          })),
          email_invitees: [],
          meta,
          change_request_id: formValues.changeRequestId,
        };
      }

      const { formValues, eventType } = data;

      return {
        ...commonData,
        event_type: eventType,
        program_id: formValues.programId,
        program_topic_id: formValues.programTopicId,
        name: formValues.name,
        view_permission: formValues.programId ? 'public' : formValues.viewPermission,
        publish_at: formValues.publishAt?.toDate(),
        invitees: [
          ...(formValues.invitees ?? []),
          ...(formValues.moderators ?? []),
          ...(formValues.presenters ?? []),
        ].map((user) => ({
          id: user.id,
          event_role: user.eventRole,
        })),
        email_invitees: [
          ...(formValues.emailInvitees ?? []),
          ...(formValues.emailModerators ?? []),
          ...(formValues.emailPresenters ?? []),
        ].map((emailInvitee) => ({
          email: emailInvitee.email,
          event_role: emailInvitee.eventRole,
        })),
        events_files: formValues.eventsFiles ? prepareEventsFiles(formValues.eventsFiles) : undefined,
        tag_ids: formValues.tags,
        show_in_channels: formValues.showInChannels,
        featured_in_channels: formValues.featuredInChannels,
      };
    },
    [],
  );

  /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
  return useMemo(() => ({ mapEventRequestData }), [mapEventRequestData]);
};
