import { Image as ImageA } from 'antd';
import cn from 'classnames';
import { type ComponentProps } from 'react';

export type ImageSize = 'large' | 'middle' | 'small';

type P = ComponentProps<typeof ImageA> & { borderRadius?: 'large' | 'small'; objectFit?: 'cover'; size?: ImageSize };

const { PreviewGroup } = ImageA;

const Image = ({ borderRadius, objectFit, className, size, ...rest }: P) => {
  return (
    <ImageA
      className={cn(className, 'ui-image', {
        'ui-image--border-radius--large': borderRadius === 'large',
        'ui-image--border-radius--small': borderRadius === 'small',
        'ui-image--object-fit--cover': objectFit === 'cover',
        'ui-image--small': size === 'small',
        'ui-image--middle': size === 'middle',
        'ui-image--large': size === 'large',
      })}
      {...rest}
    />
  );
};

Image.PreviewGroup = PreviewGroup;

export default Image;
