import { CheckCircleOutlined } from '@ant-design/icons';
import moment, { type Moment } from 'moment';
import { Translation } from 'old/atoms/translation';
import { dispatch } from 'old/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Activity, type Log, type PeriodType } from 'models/activity';
import { Button, Col, Image, message, Modal, RichTextRenderer, Text, Title } from 'ui';
import ActivityTasksForm from '../form/ActivityTasksForm';
import { useMarkAsCompleted } from '../queries/mutations/useMarkAsCompleted';
import api from './../services/api';

type P = {
  onSuccess: () => Promise<void>;
  activity: unknown;
  canDownloadReport: boolean;
  canSubmitData: boolean;
  reloadingPost: boolean;
  isProgramPublished: boolean;
  userSubscribed: boolean;
  isBookmark?: boolean;
};

type ActivityTime = {
  startTime: Moment;
  endTime: Moment;
};

const ActivityTask = ({
  activity,
  onSuccess,
  canDownloadReport,
  canSubmitData,
  reloadingPost,
  isProgramPublished,
  userSubscribed,
  isBookmark = false,
}: P) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [activityData, setActivityData] = useState<Activity>(new Activity(activity));
  const isFinished = moment().isAfter(activityData.end_time);
  const hasStarted = !moment().isBefore(activityData.start_time);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [taskLogsData, setTaskLogsData] = useState<Log[]>([]);
  const [thankYouModalOpened, setThankYouModalOpened] = useState<boolean>(false);
  const { isLoading: isLoadingMarkAsCompleted, mutate: markAsCompleted } = useMarkAsCompleted();

  const onSubmit = async () => {
    setLoading(true);

    const activityResponse = await api.createTaskLog(activityData.id, taskLogsData);

    if (activityResponse.ok) {
      setThankYouModalOpened(true);
    } else {
      message.error(activityResponse.errorMessage ?? 'Failed to record your progress.');
    }

    setLoading(false);
  };

  const downloadReport = async (id: string) => {
    setLoading(true);

    const downloadResponse = await api.downloadReport(id);

    if (downloadResponse.ok) {
      const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'report.csv');
      document.body.append(link);
      link.click();
      link.remove();

      message.success('Report was successfully downloaded.');
    } else {
      message.error('Failed to download report.');
    }

    setLoading(false);
  };

  const renderThankYouMessage = () => {
    return (
      <div className="activity-core__recorded">
        <CheckCircleOutlined className="activity-core__recorded__icon" />
        <div className="activity-core__recorded__info">
          <Title level={4}>Thank you for new recording!</Title>
        </div>
      </div>
    );
  };

  const renderEndedMessage = () => {
    return (
      <Title level={4} className="activity-core__info">
        Activity has ended
      </Title>
    );
  };

  const renderActivityCompleteButton = () => {
    const disabled = Boolean(activityData.last_activity_submission);

    return (
      <div className="activity-core__action">
        <Button
          type="primary"
          size="large"
          disabled={disabled}
          loading={isLoadingMarkAsCompleted}
          onClick={() => {
            markAsCompleted(
              { activityId: activityData.id },
              {
                onSuccess(data) {
                  dispatch.feed.storeContentObjectData(data);
                },
                onError(error) {
                  message.error(error.response?.data?.message ?? 'Failed to update activity.');
                },
              },
            );
          }}
        >
          {disabled ? 'Activity marked as completed' : 'Mark activity as completed'}
        </Button>
      </div>
    );
  };

  const renderWithingsNextPeriodMessage = () => {
    const currentDate = moment();

    const result = renderPeriod();

    if (result) {
      return result;
    }

    if (activityData.next_period_date?.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')) {
      const withingsDailyTaskMessage = () => {
        if (activityData.isBloodPressureType) {
          return t('Please take your blood pressure.');
        }

        if (activityData.isWithingsWeightType) {
          return t('Please take your weight.');
        }

        return t('Please synchronize the withings device with your mobile app today.');
      };

      return (
        <>
          <Title level={4} className="activity-core__info">
            {withingsDailyTaskMessage()}
          </Title>
          {renderActivityCompleteButton()}
        </>
      );
    }

    return (
      <Title level={4} className="activity-core__info">
        {t('activityPeriod', { day: activityData.next_period_date?.format('MMM D, YYYY') })}
      </Title>
    );
  };

  const renderState = () => {
    if (!hasStarted) {
      return (
        <Title level={4} className="activity-core__info">
          Activity did not start
        </Title>
      );
    }

    if (isFinished || !activityData.next_period_date) {
      return renderEndedMessage();
    }

    if (!canSubmitData && !canDownloadReport && activityData.is_withings) {
      return renderWithingsNextPeriodMessage();
    }

    if (!canSubmitData && !canDownloadReport && activityData.isDexcom) {
      return (
        <>
          <Image preview={false} src="/assets/dexcom.png" className="activity-core__image" />
          <Title level={4}>Please measure your Blood Glucose</Title>
        </>
      );
    }

    return null;
  };

  const getNextPeriodDate = (periodType: keyof PeriodType, nextPeriod: Moment) => {
    const period: PeriodType = {
      weekly: 'w',
      monthly: 'M',
      quarterly: 'Q',
      annually: 'y',
      daily: 'd',
    };

    const nextPeriodDate = nextPeriod.add(1, period[periodType]);

    return nextPeriodDate.isAfter(activityData.end_time) ? (
      renderEndedMessage()
    ) : (
      <Title level={4} className="activity-core__info">
        {t('activityPeriod', {
          day: nextPeriodDate.format('MMM D, YYYY'),
        })}
      </Title>
    );
  };

  const renderNextDayWithTime = (time: Moment, day: Moment) => {
    const nextDay = day.add(1, 'd').set({ hour: time.hour(), minute: time.minute() });
    return nextDay.isAfter(activityData.end_time) ? (
      renderEndedMessage()
    ) : (
      <Title level={4} className="activity-core__info">
        {t('activityNotAvailableToday', {
          time: time.format('h:mm A'),
          day: nextDay.format('MMM D, YYYY'),
        })}
      </Title>
    );
  };

  // eslint-disable-next-line complexity
  const renderPeriod = () => {
    const currentDate = moment();

    if (!activityData.next_period_date) {
      return;
    }

    if (
      activityData.next_period_date &&
      activityData.next_period_date.format('YYYY-MM-DD') !== currentDate.format('YYYY-MM-DD')
    ) {
      return activityData.next_period_date.isAfter(activityData.end_time) ? (
        renderEndedMessage()
      ) : (
        <Title level={4} className="activity-core__info">
          {t('activityPeriod', { day: activityData.next_period_date.format('MMM D, YYYY') })}
        </Title>
      );
    }

    const getLastLogTime = () => {
      // TODO hovno kod
      if (activityData.tasks[0]?.logs?.length) {
        return moment(activityData.tasks[0]?.logs?.slice(-1)[0].updated_at);
      }

      let lastLogTime: Moment | undefined;

      for (const option of activityData.tasks[0]?.options ?? []) {
        for (const log of option.logs ?? []) {
          const newLogTime = moment(log.updated_at);

          if (!lastLogTime || newLogTime.isAfter(lastLogTime)) {
            lastLogTime = newLogTime;
          }
        }
      }

      return lastLogTime;
    };

    const lastLogTime = getLastLogTime();

    if (activityData.times.length > 0) {
      let momentTimes = activityData.times.map((item: any) => {
        return {
          startTime: moment(item.time, 'HH:mm:ssZ').subtract(15, 'minutes'),
          endTime: moment(item.time, 'HH:mm:ssZ').add(15, 'minutes'),
        };
      });
      momentTimes = momentTimes.map((time: ActivityTime, index: number) => {
        if (index !== 0 && time.startTime.isBefore(momentTimes[index - 1].endTime)) {
          return { ...time, startTime: momentTimes[index - 1].endTime };
        }

        return time;
      });
      const availableTime = momentTimes[0].startTime;

      if (
        momentTimes.every((time: ActivityTime) => moment().isAfter(time.endTime)) ||
        momentTimes.every((time: ActivityTime) => moment().format('DD') !== time.startTime.format('DD'))
      ) {
        return renderNextDayWithTime(availableTime, currentDate);
      }

      if (momentTimes.every((time: ActivityTime) => !currentDate.isBetween(time.startTime, time.endTime))) {
        for (const time of momentTimes) {
          if (time.startTime.isAfter(currentDate)) {
            return time.startTime.isAfter(activityData.end_time) ? (
              renderEndedMessage()
            ) : (
              <Title level={4} className="activity-core__info">
                {t('activityAvailableAtAnotherTime', { time: time.startTime.format('h:mm A') })}
              </Title>
            );
          }
        }
      }

      if (lastLogTime && !activityData.multiple_submissions) {
        for (const [index, time] of momentTimes.entries()) {
          if (lastLogTime.isBetween(time.startTime, time.endTime) && currentDate.isBefore(time.endTime)) {
            return index + 1 === momentTimes.length ? (
              renderNextDayWithTime(availableTime, currentDate)
            ) : momentTimes[index + 1].startTime.isAfter(activityData.end_time) ? (
              renderEndedMessage()
            ) : (
              <Title level={4} className="activity-core__info">
                {t('activityAvailableAtAnotherTime', { time: momentTimes[index + 1].startTime.format('h:mm A') })}
              </Title>
            );
          }
        }
      }
    }

    if (lastLogTime && activityData.periodicity_type && activityData.times.length === 0) {
      if (activityData.periodicity_type === 'one_time') {
        return renderEndedMessage();
      }

      if (!activityData.multiple_submissions) {
        return getNextPeriodDate(activityData.periodicity_type, activityData.next_period_date);
      }
    }

    return undefined;
  };

  const renderTask = () => {
    const result = renderPeriod();

    if (result) {
      return result;
    }

    return (
      <ActivityTasksForm
        onSubmit={renderConfirmationModal}
        activity={activityData}
        loading={loading || reloadingPost}
        isProgramPublished={isProgramPublished}
        isBookmark={isBookmark}
      />
    );
  };

  const renderConfirmationData = () => {
    return taskLogsData.map((log: any, index: number) => (
      <Col item={{ className: 'activity-core__confirmation-item-wrapper' }} key={`${index}`}>
        {activityData.tasks.map((task, index) => (
          <div className="activity-core__confirmation-item" key={`${task.input}-${index}`}>
            {task.input === 'select'
              ? task.options?.find((option) => option.id === log[index]?.activity_task_log.activity_task_option_id)
                  ?.name
              : log[index]?.activity_task_log.value}
          </div>
        ))}
      </Col>
    ));
  };

  const renderConfirmationModal = (taskLogs: Log[]) => {
    setTaskLogsData(taskLogs);
    setModalOpened(true);
  };

  useEffect(() => {
    setActivityData(new Activity(activity));
  }, [activity]);

  return (
    <div className="activity-core">
      {activityData.html_description ? <RichTextRenderer data={activityData.html_description} /> : null}
      {/* TODO Create new Translate component */}
      {activityData.description ? (
        <div style={{ padding: '0  24px' }}>
          <Translation id={activityData.id} type="activity" />
        </div>
      ) : null}
      <div className={`activity-core__task-wrapper ${isBookmark ? 'activity-core__disabled' : ''}`}>
        {renderState()}
        {!canDownloadReport && canSubmitData && userSubscribed && !isFinished && !loading ? renderTask() : null}
        {canDownloadReport && hasStarted ? (
          <div className="activity-core__action">
            <Button
              type="primary"
              size="large"
              disabled={isBookmark}
              onClick={async () => downloadReport(activityData.id)}
            >
              Download report
            </Button>
          </div>
        ) : null}
      </div>
      {modalOpened ? (
        <Modal
          title="Submit your values"
          isOpened={modalOpened}
          onCancel={() => {
            setModalOpened(false);
          }}
          width="small"
          disableBack
          footer={
            <Button
              type="primary"
              onClick={() => {
                setModalOpened(false);
                onSubmit();
              }}
              size="large"
              key={`confirmation-submit-button`}
            >
              Submit
            </Button>
          }
        >
          <Text className="activity-core__modal-desc">Are you sure that you want to submit these values?</Text>
          {renderConfirmationData()}
        </Modal>
      ) : null}
      {thankYouModalOpened ? (
        <Modal
          title="Your progress was recorded."
          isOpened={thankYouModalOpened}
          onCancel={async () => {
            setThankYouModalOpened(false);
            await onSuccess();
          }}
          width="small"
          disableBack
          footer={
            <Button
              type="primary"
              onClick={async () => {
                setThankYouModalOpened(false);
                await onSuccess();
              }}
              size="large"
              key={`confirmation-submit-button`}
            >
              OK
            </Button>
          }
        >
          {renderThankYouMessage()}
        </Modal>
      ) : null}
    </div>
  );
};

export default ActivityTask;
