import { type IconSize } from 'ui/Icons/icon-wrapper';
import { ApplicationFileIcon } from './ApplicationFileIcon';
import { ContentFileIcon } from './ContentFileIcon';
import { TextFileIcon } from './TextFileIcon';

type P = {
  url?: string;
  contentType: string;
  size: IconSize;
};

const FileIcon = ({ url, contentType, size }: P) => {
  const [type, subtype] = contentType.split('/');

  switch (type) {
    case 'application':
      return <ApplicationFileIcon subtype={subtype} size={size} />;
    case 'text':
      return <TextFileIcon subtype={subtype} size={size} />;
    default:
      return <ContentFileIcon type={type} url={url} size={size} />;
  }
};

export default FileIcon;
