import { useState, useEffect } from 'react';

import { Activity } from 'models/activity';
import Program from 'models/program';
import { message, Modal } from 'ui';
import { ActivityForm } from '../form';
import api from './../services/api';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void>;
  program: Program;
  activity: any;
};

const EditActivity = ({ onSuccess, onCancel, isOpened, program, activity }: P) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [programData, setProgramData] = useState<Program>(new Program());
  const [activityData, setActivityData] = useState<Activity>(new Activity(activity));

  const fetchData = () => {
    (() => {
      if (isOpened) {
        setLoading(true);
        setProgramData(program);
        setActivityData(new Activity(activity));
        setLoading(false);
      }
    })();
  };

  const onSubmit = async (activity: Activity) => {
    setLoading(true);

    const activityResponse = await api.updateActivity(activity);

    if (activityResponse.ok) {
      await onSuccess();
      message.success('Activity was updated.');
    } else {
      message.error('Failed to update activity.');
    }

    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [isOpened]);

  return (
    <Modal title={'Edit Activity'} isOpened={isOpened} onCancel={onCancel}>
      <ActivityForm program={programData} activity={activityData} onSubmit={onSubmit} loading={loading} edit />
    </Modal>
  );
};

export default EditActivity;
