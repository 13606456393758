import { Col as ColAD } from 'antd';
import cn from 'classnames';
import type React from 'react';
import { useTranslation } from 'react-i18next';

type ItemP = React.ComponentProps<typeof ColAD> & {
  textAlign?: 'center';
};

type P = {
  item?: ItemP;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_ITEM: ItemP = {};

const Col: React.FC<P> = ({ item: { textAlign, className, ...item } = DEFAULT_ITEM, children }) => {
  const { t } = useTranslation();

  return (
    <ColAD
      {...item}
      className={cn(className, 'ui-col', {
        'ui-col--align-text-center': textAlign === 'center',
      })}
    >
      {typeof children === 'string' ? t(children) : children}
    </ColAD>
  );
};

export default Col;
