import { BellOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { type ReduxUser, type Community, type Image, DefaultImage } from 'models';
import { hasChangeRequestStatus, hasMembershipInSubject } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { handleAxiosError } from 'common/error-handlers';
import { images } from 'common/utils';
import { ConsentsModal } from 'features/consents';
import { canLeaveCommunity, canManageCommunity, canViewCommunityMembers } from 'permissions';
import {
  type ButtonStatus,
  type MenuProps,
  Avatar,
  HeaderImage,
  Row,
  Col,
  JoinStatusButton,
  Title,
  DotSeparator,
  Text,
  modalConfirm,
} from 'ui';

import { RequestJoinModal, NotificationSettingsModal, EditImageModal } from '../';
import { useCommunityQuery, useLeaveCommunity, useJoinCommunity, useUpdateCommunity } from '../../queries';
import { CommunityTabs, type CommunityTabItem, mapCommunityTabToPathName } from './CommunityTabs';

type ModalOption = 'consents' | 'cover' | 'notifications' | 'request';

type P = {
  viewer: ReduxUser;
  community: Community;
  tab: CommunityTabItem;
  onStatusChange: () => unknown;
};

const CommunityHeader = ({ viewer, community, tab, onStatusChange }: P) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [isOpened, setIsOpened] = useState<ModalOption>();

  const isMemberInCommunity = hasMembershipInSubject({ viewer, subjectType: 'Community', subjectId: community.id });
  const isRequestPending = hasChangeRequestStatus({ community, status: 'pending' });

  const { isRefetching: isReloadingCommunity } = useCommunityQuery(community.id);
  const {
    isLoading: isJoiningCommunity,
    mutate: joinCommunity,
    error: joinError,
    isSuccess: isJoinedSuccess,
  } = useJoinCommunity(onStatusChange);
  const {
    isLoading: isLeavingCommunity,
    mutate: leaveCommunity,
    error: leaveError,
  } = useLeaveCommunity(onStatusChange);
  const {
    isLoading: isUpdatingCommunity,
    mutate: updateCommunity,
    isSuccess: isCommunityUpdated,
    error: updateError,
  } = useUpdateCommunity();

  useEffect(() => {
    handleAxiosError(joinError);
  }, [joinError]);

  useEffect(() => {
    handleAxiosError(leaveError);
  }, [leaveError]);

  useEffect(() => {
    handleAxiosError(updateError);
  }, [updateError]);

  useEffect(() => {
    if (isCommunityUpdated) {
      setIsOpened(undefined);
    }
  }, [isCommunityUpdated]);

  const onRequestJoin = useCallback(
    (reason?: string) => {
      setIsOpened(undefined);
      joinCommunity({ id: community.id, privacy: community.privacy, reason });
    },
    [community.id, community.privacy, joinCommunity],
  );

  const onEditCoverImage = useCallback(
    (image: Image) => {
      updateCommunity({ id: community.id, data: { image } });
    },
    [community.id, updateCommunity],
  );

  const communityInfoItems = useMemo(
    () =>
      [community.organization?.categories[0]?.name, t('membersCount', { count: community.membersCount })]
        .filter(Boolean)
        .map((text, index) => <Text key={`comunity-info-item-${index}`}>{text}</Text>),
    [community, t],
  );

  const {
    status,
    statusText,
    dropdown,
    onClick: onJoinButtonClick,
  }: {
    status: ButtonStatus;
    statusText?: string;
    dropdown?: MenuProps;
    onClick?: () => void;
  } = useMemo(() => {
    // TODO maybe move this logic somewhere else
    if (
      community.organization.requiredConsents.some(
        (requiredConsent) => !viewer.users_consents?.some((consent) => requiredConsent.id === consent.id),
      )
    ) {
      return {
        status: 'join',
        statusText: 'Confirm consents to join',
        onClick() {
          setIsOpened('consents');
        },
      };
    }

    if (isRequestPending) {
      return {
        status: 'pending',
      };
    }

    if (isMemberInCommunity) {
      return {
        status: 'joined',
        dropdown: canLeaveCommunity({ viewer, community })
          ? {
              items: [
                {
                  label: t('Leave'),
                  icon: <CloseCircleOutlined />,
                  key: 'leave',
                },
              ],
              onClick({ key }: { key: string }) {
                if (key === 'leave') {
                  modalConfirm({
                    title: t('Leave community?'),
                    content: <Text>{t('Do you want to leave selected community?')}</Text>,
                    cancelText: t('Cancel'),
                    okText: t('Leave'),
                    onOk() {
                      leaveCommunity({ id: community.id });
                    },
                  });
                }
              },
            }
          : undefined,
      };
    }

    if (community.privacy === 'private') {
      return {
        status: 'request',
        onClick() {
          setIsOpened('request');
        },
      };
    }

    return {
      status: 'join',
      onClick() {
        joinCommunity({ id: community.id, privacy: community.privacy });
      },
    };
  }, [community, isRequestPending, isMemberInCommunity, viewer, t, leaveCommunity, joinCommunity]);

  if (isJoinedSuccess && isMemberInCommunity) {
    push(mapCommunityTabToPathName(community.id, '/feed'));
    return null;
  }

  return (
    <div className="community-header">
      <HeaderImage
        onRequestEdit={
          canManageCommunity({ viewer, community })
            ? () => {
                setIsOpened('cover');
              }
            : undefined
        }
        src={community.coverPhoto?.url ?? images.default.communityCoverPhoto}
        alt={t('Community cover image')}
      />
      <div className="community-header__content">
        <Row item={{ wrap: false, align: 'middle', className: 'community-header__content__info' }}>
          <Col>
            <Avatar photo={community.profilePhoto?.url ?? images.default.communityProfilePhoto} />
          </Col>
          <Col item={{ flex: 1, className: 'community-header__content__info__data' }}>
            <Row>
              <Col item={{ span: 24 }}>
                <Title level={3} className="community-header__content__info__title">
                  {community.name}
                </Title>
              </Col>
              <Col item={{ span: 24 }}>
                <DotSeparator items={communityInfoItems} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col item={{ span: 24, className: 'community-header__content__actions' }}>
                <JoinStatusButton
                  loading={isReloadingCommunity || isJoiningCommunity || isLeavingCommunity}
                  status={status}
                  dropdown={dropdown}
                  onClick={onJoinButtonClick}
                >
                  {statusText}
                </JoinStatusButton>
                {status === 'joined' && (
                  <BellOutlined
                    className="community-header__content__actions__notification"
                    onClick={() => {
                      setIsOpened('notifications');
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <CommunityTabs
          isPublicView={!isMemberInCommunity}
          active={tab}
          communityId={community.id}
          showMembers={canViewCommunityMembers({ viewer, community })}
        />
      </div>
      {isOpened === 'request' ? (
        <RequestJoinModal
          isOpened
          onFinish={onRequestJoin}
          onCancel={() => {
            setIsOpened(undefined);
          }}
        />
      ) : null}
      {isOpened === 'notifications' ? (
        <NotificationSettingsModal
          isOpened
          communityId={community.id}
          onClose={() => {
            setIsOpened(undefined);
          }}
        />
      ) : null}
      {isOpened === 'cover' ? (
        <EditImageModal
          isOpened
          title="Edit Cover Image"
          image={community.coverPhoto ?? new DefaultImage({ url: images.default.communityCoverPhoto })}
          isLoading={isUpdatingCommunity}
          onSubmit={onEditCoverImage}
          onCancel={() => {
            setIsOpened(undefined);
          }}
        />
      ) : null}
      {isOpened === 'consents' ? (
        <ConsentsModal
          isOpened
          entity={community}
          requiredConsentIds={community.organization.requiredConsents.map((consent) => consent.id)}
          onAccept={async () => {
            await onStatusChange();
            setIsOpened(undefined);
          }}
          onReject={async () => {
            await onStatusChange();
            setIsOpened(undefined);
          }}
          onClose={() => {
            onStatusChange();
            setIsOpened(undefined);
          }}
        />
      ) : null}
    </div>
  );
};

export default CommunityHeader;
