import { type ReactNode, type ComponentProps, useState, useMemo } from 'react';
import { useDebounce } from 'common/hooks';
import { InfinityScrollSelect } from 'ui';
import { useOrganizationsInfiniteQuery } from '../../queries';

export type OrganizationOption = { label: ReactNode; value: string };

type P = Omit<ComponentProps<typeof InfinityScrollSelect<OrganizationOption>>, 'handleRequestMoreData' | 'hasMoreData'>;

const OrganizationSelect = (props: P) => {
  const [search, setSearch] = useState<string>();
  const { value, isDebouncing } = useDebounce(search);

  const {
    data: organizations,
    fetchNextPage: fetchNextPageOfOrganizations,
    hasNextPage: hasMoreOrganizations,
    isFetching: isFetchingOrganizations,
  } = useOrganizationsInfiniteQuery({ filter: { search: value }, pagination: { pageSize: 10 } });

  const options = useMemo(() => {
    return organizations?.pages.flatMap((page) => page.map((org) => ({ label: org.name, value: org.id })));
  }, [organizations]);

  return (
    <InfinityScrollSelect<OrganizationOption>
      loading={isFetchingOrganizations || isDebouncing}
      options={options}
      handleRequestMoreData={fetchNextPageOfOrganizations}
      hasMoreData={hasMoreOrganizations}
      showSearch
      onSearch={setSearch}
      searchValue={search}
      {...props}
    />
  );
};

export default OrganizationSelect;
