import { ListEvent } from 'models';
import { useInfiniteQuery, type QueryFunctionContext, type QueryKey } from 'query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';

import { getEvents } from '../services';
import { getEventsQueryKey } from './';

export type EventsQueryType = 'past' | 'upcoming';

type EventsQueryParams = {
  communityId?: string;
  limit: number;
  type?: EventsQueryType;
};

export const useEventsQuery = (params: EventsQueryParams) =>
  useInfiniteQuery<ListEvent[], ApiClientResponseError<DefaultResponseErrorDataObject>>(
    getEventsQueryKey({ type: params.type, communityId: params.communityId, limit: params.limit }),
    async ({ pageParam: cursor = undefined }: QueryFunctionContext<QueryKey, string>) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const response = await getEvents({
        community_id: params.communityId,
        cursor,
        limit: params.limit,
        type: params.type,
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return response.data.map((event) => new ListEvent(event));
    },
    {
      // TODO this is set to `always` because events are not deleted from React Query, so there could be some bad data shown
      refetchOnMount: 'always',
      useErrorBoundary: true,
      getNextPageParam: (lastPage) => (lastPage.length < params.limit ? undefined : lastPage[lastPage.length - 1]?.id),
    },
  );
