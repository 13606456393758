import { AxiosError } from 'axios';
import { dispatch } from 'old/store';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Title } from 'ui';
import LoginForm from '../components/LoginForm';
import { useLoginQuery, useUserQuery } from '../queries';
import { useShowLockedAccountModal } from './hooks';

type P<T> = {
  onSuccessLogin: () => void;
  onExpiredPassword: (email: string) => void;
  onNavigateToPrimaryLogin: () => void;
  onNavigateToForgotPassword: (email?: string) => void;
  publicCommunityId?: string;
  state?: T;
};

const PasswordLogin = <T,>({
  publicCommunityId,
  onExpiredPassword,
  onSuccessLogin,
  state,
  onNavigateToPrimaryLogin,
  onNavigateToForgotPassword,
}: P<T>) => {
  const { t } = useTranslation();
  const { mutateAsync: loginAsync } = useLoginQuery();
  const { mutateAsync: getProfileAsync } = useUserQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const email = useRef<string>(''); // TODO this is just a hack, needs refactor
  const { showUnblockAccountModal } = useShowLockedAccountModal();

  /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
  const messages = useMemo(
    () => ({
      user_locked: t('Your account is locked for 30 minutes after 5 failed attempts to login.'),
      invalid_grant: t('Invalid user name or password or user is blocked'),
      user_banned: t('userBanned'),
      password_expired: t('Your password expired. Please set a new password.'),
      default: t('Something went wrong, please try again later.'),
    }),
    [t],
  );
  /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

  const onSubmit = async (submitData: { email: string; password: string }) => {
    email.current = submitData.email;
    setIsLoading(true);

    try {
      const loginData = await loginAsync({ ...submitData, publicCommunityId });
      await dispatch.session.storeSuccessfulEmailResponse(loginData);

      const profileData = await getProfileAsync();
      await dispatch.session.setUserData(profileData);

      onSuccessLogin();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error.response?.data.error === 'password_expired') {
          onExpiredPassword(email.current);
        }

        if (error.response?.data.error === 'user_locked') {
          showUnblockAccountModal({
            onRequestUnblockAccount() {
              onNavigateToForgotPassword(submitData.email);
            },
          });
        } else {
          message.error(messages[error.response?.data.error ?? 'default']);
        }
      } else {
        message.error(t('Something went wrong, please try again later.'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title level={1}>{t('Use password to log in')}</Title>
      <div className="onboarding-password-login__form-wraper">
        <LoginForm<T>
          onSubmit={onSubmit}
          loading={isLoading}
          state={state}
          onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
        />
      </div>
    </>
  );
};

export default PasswordLogin;
