import {
  type ReduxUser,
  type Reply,
  type ContentObjectRedux,
  isCommunityAdminInProgramContentObject,
  isOrganizationAdminInProgramContentObject,
  isCommunityAdminInCommunityContentObject,
  isOrganizationAdminInCommunityContentObject,
} from 'models';

export const canDeleteComment = ({
  viewer,
  comment,
  contentObject,
}: {
  viewer: ReduxUser;
  comment: Reply;
  // TODO: should be application type
  contentObject: ContentObjectRedux;
}) => {
  if (viewer.superadmin || viewer.id === comment.author.id) {
    return true;
  }

  if (contentObject.contentable_type === 'Program') {
    return (
      isCommunityAdminInProgramContentObject({ viewer, contentObject }) ||
      isOrganizationAdminInProgramContentObject({ viewer, contentObject })
    );
  }

  if (contentObject.contentable_type === 'Community') {
    return (
      isCommunityAdminInCommunityContentObject({ viewer, contentObject }) ||
      isOrganizationAdminInCommunityContentObject({ viewer, contentObject })
    );
  }

  console.error('canDeleteComment', viewer, comment);

  return false;
};
