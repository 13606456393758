import { useCallback, useState } from 'react';
import { isSignUpOrganizationEnabled } from 'common/validators';
import { Spin } from 'ui';
import { usePreselectPrimaryOrganization } from './hooks';
import { type PrimaryOrganizationOption } from './PrimaryOrganizationSelect';
import { SignUpOrganization } from './SignUpOrganization';
import { type SignUpFormValues, SignUpUser } from './SignUpUser';

type P = {
  onSuccessLogin: () => void;
  onSubmit: (values: SignUpFormValues) => void;
  loading: boolean;
  changeRequestId?: string;
  invitationToken?: string;
  communityId?: string;
};

const SignUpContent = ({ onSuccessLogin, onSubmit, loading, changeRequestId, invitationToken, communityId }: P) => {
  const [step, setStep] = useState<
    { option: 'organization' } | { option: 'user'; organization?: PrimaryOrganizationOption }
  >({ option: isSignUpOrganizationEnabled() ? 'organization' : 'user' });

  const { isLoading } = usePreselectPrimaryOrganization({
    communityId,
    onPrimaryOrganization: useCallback((organization: PrimaryOrganizationOption) => {
      setStep({ option: 'user', organization });
    }, []),
  });

  if (isLoading) {
    return <Spin />;
  }

  if (step.option === 'organization') {
    return (
      <SignUpOrganization
        onNext={(organization) => {
          setStep({ option: 'user', organization });
        }}
      />
    );
  }

  return (
    <SignUpUser
      onSuccessLogin={onSuccessLogin}
      onSubmit={onSubmit}
      loading={loading}
      initialOrganization={step.organization}
      changeRequestId={changeRequestId}
      invitationToken={invitationToken}
    />
  );
};

export default SignUpContent;
