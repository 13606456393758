import { LinkOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  type RegisteredEventInvitee,
  type EmailEventInvitee,
  type EventEmailInvitee,
  type InvitationTokenKind,
} from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import variables from 'common/styles/variables.json';
import { copyToClipboard, images } from 'common/utils';
import AvatarList from 'ui/AvatarList/AvatarList';
import { Title as Heading, Button, Row, Col, EmptyInput, Space, message } from 'ui';
import { useGenerateInviteToken } from '../queries';

type InviteOptions = {
  eventId: string;
  kind: InvitationTokenKind;
  successMessage: string;
};

type P = {
  name: string;
  nameEmail?: string;
  label: string;
  buttonText: string;
  onPress: () => void;
  id?: string;
  disabled?: boolean;
  inviteOptions?: InviteOptions;
};

const EventInviteesButton = ({
  name,
  nameEmail,
  label,
  buttonText,
  onPress,
  disabled,
  inviteOptions,
  ...others
}: P) => {
  const { t } = useTranslation();
  const { isLoading: isGeneratingInviteToken, mutateAsync: generateInviteToken } = useGenerateInviteToken();

  const [normalUsers, setNormalUsers] = useState<RegisteredEventInvitee[]>([]);
  const [emailUsers, setEmailUsers] = useState<EmailEventInvitee[]>([]);

  const allUsers = [
    ...emailUsers.map((user: EmailEventInvitee) => {
      return {
        id: user.email,
        title: user.email,
        url: images.default.userProfilePhoto,
      };
    }),
    ...normalUsers.map((user: RegisteredEventInvitee) => {
      return {
        id: user.id,
        url: user.profilePhoto?.url ?? images.default.userProfilePhoto,
      };
    }),
  ];

  const onGenerateInviteLink = ({ kind, eventId, successMessage }: InviteOptions) => {
    copyToClipboard(async () => {
      const tokenData = await generateInviteToken({ kind, subjectId: eventId });

      const link = `${window.location.origin}${routes.events.share(eventId, tokenData.token)}`;
      message.success(successMessage);

      return link;
    });
  };

  return (
    <>
      <EmptyInput<RegisteredEventInvitee[]>
        name={name}
        onChange={(value: RegisteredEventInvitee[]) => {
          setNormalUsers(value);
        }}
      />
      {nameEmail ? (
        <EmptyInput<EventEmailInvitee[]>
          name={nameEmail}
          onChange={(value: EventEmailInvitee[]) => {
            setEmailUsers(value);
          }}
        />
      ) : null}
      <Row item={{ className: 'event_invitees_select' }}>
        <Col item={{ span: 24 }}>
          <Heading level={5}>{t(label)}</Heading>
        </Col>
        <Col item={{ span: 24 }}>
          <Space size={variables.spaceXs.value}>
            {inviteOptions ? (
              <Button
                icon={<LinkOutlined />}
                onClick={() => {
                  onGenerateInviteLink(inviteOptions);
                }}
                loading={isGeneratingInviteToken}
                id={others.id}
                size="large"
              >
                {t('Copy link')}
              </Button>
            ) : null}

            <Space size={40}>
              <Button
                icon={<PlusCircleOutlined />}
                onClick={onPress}
                disabled={disabled}
                id={others.id}
                size="large"
                type="dashed"
              >
                {t(buttonText)}
              </Button>

              <AvatarList titles={allUsers} />
            </Space>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default EventInviteesButton;
