import { type IconSize } from 'ui/Icons/icon-wrapper';
import { CodeFileIcon, DefaultFileIcon, DocumentFileIcon, SheetFileIcon } from 'ui';

type P = {
  subtype: string;
  size: IconSize;
};

const TextFileIcon = ({ subtype, size }: P) => {
  switch (subtype) {
    case 'csv':
    case 'tab-separated-values':
      return <SheetFileIcon size={size} />;
    case 'plain':
      return <DocumentFileIcon size={size} />;
    case 'html':
    case 'css':
    case 'x-python':
    case 'x-java-source':
      return <CodeFileIcon size={size} />;
    default:
      return <DefaultFileIcon size={size} />;
  }
};

export default TextFileIcon;
