import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { type Login, type OauthToken, createOauthToken } from '../../models';
import { loginWithPassword } from '../../services';

export const useLoginQuery = () =>
  useMutation<OauthToken, ApiClientResponseError<DefaultResponseErrorDataObject>, Login>({
    async mutationFn({ email, password, publicCommunityId }) {
      const oauthResponse = await loginWithPassword({
        /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
        username: email,
        password,
        provider: 'local',
        grant_type: 'password',
        join_community_id: publicCommunityId,
        /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      });

      return createOauthToken(oauthResponse.data);
    },
  });
