import { type ReactNode, useMemo, useState, type ComponentProps } from 'react';
import { useDebounce } from 'common/hooks';
import { usePublicOrganizationsInfiniteQuery } from 'features/organizations/queries';
import { InfinityScrollSelect } from 'ui';
import { useCreatePrimaryOrganizationOption } from './hooks';

export type PrimaryOrganizationOption = { label: ReactNode; value: string; optionLabel: ReactNode };

type P = Omit<
  ComponentProps<typeof InfinityScrollSelect<PrimaryOrganizationOption>>,
  'handleRequestMoreData' | 'hasMoreData'
>;

const PrimaryOrganizationSelect = (props: P) => {
  const [search, setSearch] = useState<string>();
  const { value, isDebouncing } = useDebounce(search);
  const createPrimaryOrganizationOption = useCreatePrimaryOrganizationOption();

  const { isFetching, data, hasNextPage, fetchNextPage } = usePublicOrganizationsInfiniteQuery({
    filter: { search: value },
  });

  const options = useMemo(() => {
    return data?.pages.flatMap((page) => page.map((organization) => createPrimaryOrganizationOption(organization)));
  }, [createPrimaryOrganizationOption, data]);

  return (
    <InfinityScrollSelect<PrimaryOrganizationOption>
      loading={isDebouncing || isFetching}
      options={options}
      handleRequestMoreData={fetchNextPage}
      hasMoreData={hasNextPage}
      showSearch
      onSearch={setSearch}
      searchValue={search}
      optionLabelProp="optionLabel"
      fieldNames={{ label: 'optionLabel' }}
      {...props}
    />
  );
};

export default PrimaryOrganizationSelect;
