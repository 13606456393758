import { dispatch } from 'old/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidationResponseErrorDataObject } from 'common/services';
import { message } from 'ui';
import { useLoginQuery, useSignUpQuery, useUserQuery } from '../queries';
import { SignUpContent, type SignUpFormValues } from './SignUpContent';

type P = {
  onSuccessLogin: () => void;
  onLoginError: () => void;
  publicCommunityId?: string;
  changeRequestId?: string;
  invitationToken?: string;
  communityId?: string;
};

const SignUp = ({
  publicCommunityId,
  onSuccessLogin,
  onLoginError,
  changeRequestId,
  invitationToken,
  communityId,
}: P) => {
  const { t } = useTranslation();
  // TODO: This is hack because of `dispatch` methods
  const [isPerformingLoginPart, setIsPerformingLoginPart] = useState<boolean>(false);

  const { mutateAsync: signUp, isLoading: isLoadingSignUp, error: signUpError } = useSignUpQuery();
  const { mutateAsync: getProfile, isLoading: isLoadingProfile } = useUserQuery();
  const { mutateAsync: login, isLoading: isLoadingLogin } = useLoginQuery();

  const handleSubmit = async (values: SignUpFormValues) => {
    await signUp({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      organizationId: values.organization?.value,
      publicCommunityId,
      invitationToken,
    });

    try {
      setIsPerformingLoginPart(true);
      const oauthToken = await login(values);
      await dispatch.session.storeSuccessfulEmailResponse(oauthToken);
      const profileData = await getProfile();
      await dispatch.session.setUserData(profileData);
      setIsPerformingLoginPart(false);
      onSuccessLogin();
    } catch {
      setIsPerformingLoginPart(false);
      onLoginError();
    }
  };

  // Sign Up Error handler
  useEffect(() => {
    if (signUpError) {
      const errorData = signUpError.response?.data;
      message.error(
        isValidationResponseErrorDataObject(errorData)
          ? Object.entries(errorData.errors)
              .map(([key, value]) => value.map((error: string) => `${key} ${error}`).join(', '))
              .join(', ')
          : t('Something went wrong, please try again later.'),
      );
    }
  }, [signUpError, t]);

  return (
    <SignUpContent
      onSuccessLogin={onSuccessLogin}
      onSubmit={handleSubmit}
      loading={isLoadingSignUp || isLoadingProfile || isLoadingLogin || isPerformingLoginPart}
      changeRequestId={changeRequestId}
      invitationToken={invitationToken}
      communityId={communityId}
    />
  );
};

export default SignUp;
