import { type Community, type Privacy, type ReduxUser } from 'models';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { canSeeTags } from 'permissions';
import { Avatar, Col, Row, Title, Text, Card, TagList, Space } from 'ui';

type P = {
  community: Community;
  viewer: ReduxUser;
};

const About = ({ community, viewer }: P) => {
  const { t } = useTranslation();

  const mapToTranslateKey = (key: Privacy) => {
    switch (key) {
      case 'public':
        return 'Public';
      case 'private':
        return 'Private';
      case 'secret':
        return 'Secret';
    }
  };

  return (
    <Card title="About" className="community-content-about">
      <Row>
        <Col item={{ span: 24 }}>
          <Title level={5}>{t('Organization')}</Title>
        </Col>
        <Col item={{ span: 24 }}>
          <Row item={{ className: 'community-content-about__organization', align: 'middle' }}>
            <Col item={{ className: 'community-content-about__organization__avatar' }}>
              <Avatar
                photo={community.organization.profilePhoto?.url ?? images.default.organizationProfilePhoto}
                alt={t('Organization logo')}
              />
            </Col>
            <Col>
              <Text strong type="secondary">
                {community.organization.name}
              </Text>
            </Col>
          </Row>
        </Col>
        <Space direction="vertical" size="large">
          <Col item={{ span: 24 }}>
            <Title level={5}>{t('Privacy')}</Title>
            <TagList tags={[{ name: t(mapToTranslateKey(community.privacy)) }]} />
          </Col>
          {community.tags &&
          community.tags.length > 0 &&
          canSeeTags({ viewer, organizationId: community.organizationId }) ? (
            <Col item={{ span: 24 }}>
              <Title level={5}>{t('Tags')}</Title>
              <TagList tags={community.tags} />
            </Col>
          ) : null}
        </Space>
      </Row>
    </Card>
  );
};

export default About;
