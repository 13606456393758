import { type ReduxUser } from 'models';
import { useState } from 'react';
import { Button } from 'ui';
import { ContactUsModal } from './index';

type P = {
  programData: {
    id: string;
    requested_to_contact?: boolean;
  };
  viewer: ReduxUser;
  onSuccess: (programId: string) => void;
};

const ContactUs = ({ programData, viewer, onSuccess }: P) => {
  const [isContactUsModalOpened, setIsContactUsModalOpened] = useState<boolean>(false);
  const onContactUsRequestSuccess = () => {
    onSuccess(programData.id);
    setIsContactUsModalOpened(false);
  };

  return (
    <>
      {/* TODO replace with `change_request` array */}
      {programData.requested_to_contact ? (
        <Button type="primary" disabled size="large">
          Request sent
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() => {
            setIsContactUsModalOpened(true);
          }}
          size="large"
        >
          Contact us
        </Button>
      )}
      <ContactUsModal
        isOpened={isContactUsModalOpened}
        onClose={() => {
          setIsContactUsModalOpened(false);
        }}
        programId={programData.id}
        userPhone={viewer.phone}
        onSuccess={onContactUsRequestSuccess}
      />
    </>
  );
};

export default ContactUs;
