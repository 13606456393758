import { useCallback, useState } from 'react';
import { polling, openPopup } from '../../../../old/utils/socialAuthHelpers';
import { useSyncAccount } from '../../queries';
import { type StatusType } from '../DexcomSync';

const useDexcomSync = (clientId: string, onFinished: (result: { status: StatusType; messageText: string }) => void) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const redirectUrl = window.location.href;
  const { mutate: syncAccount } = useSyncAccount();

  const onDone = useCallback(
    ({ status, messageText }: { status: StatusType; messageText: string }) => {
      setIsSyncing(false);
      onFinished({ status, messageText });
    },
    [setIsSyncing, onFinished],
  );

  const syncDexcom = useCallback(() => {
    setIsSyncing(true);

    polling(
      openPopup(
        `https://sandbox-api.dexcom.com/v2/oauth2/login?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code&scope=offline_access`,
        'dexcom-login-popup',
      ),
      (query?: URLSearchParams) => {
        const code = query?.get('code');
        if (code) {
          syncAccount(
            // eslint-disable-next-line @typescript-eslint/naming-convention -- We missing correct application model in camelCase, we need to fix it
            { code, redirect_uri: redirectUrl },
            {
              onSuccess() {
                onDone({ status: 'success', messageText: 'Dexcom account linked' });
              },
              onError() {
                onDone({ status: 'error', messageText: 'Dexcom account link failed' });
              },
            },
          );
          return;
        }

        onDone({ status: 'abort', messageText: 'Dexcom account link aborted' });
      },
      'dexcom.com',
    );
  }, [clientId, redirectUrl, onDone, syncAccount]);

  return { syncDexcom, isSyncing };
};

export default useDexcomSync;
