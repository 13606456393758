import { type ContentType, useFileUpload, type VideoType } from 'lib/media-upload-file';
import { useMemo } from 'react';
import { type MediaDataObject } from 'models/service';
import { type FormInstance } from 'ui';
import { useFormFileInitialValue } from '../useFormFileInitialValue';
import { useFormFileSubscribe } from '../useFormFileSubscribe';
import { useRemoveUpload } from './useRemoveUpload';
import { useRetryUpload } from './useRetryUpload';
import { useStartUpload } from './useStartUpload';

type P = {
  form: FormInstance;
  formName: string;
  contentType: ContentType;
  ref: string;
  type: VideoType;
  mediaData?: MediaDataObject;
};

export const useFormFileUpload = ({ form, type, formName, contentType, ref, mediaData }: P) => {
  const {
    file,
    startUpload: initiateFileUpload,
    removeUpload: removeUploadFromContext,
  } = useFileUpload({ contentType, ref, videoType: type });

  const initialValue = useFormFileInitialValue({ type, file, mediaData });

  const startUpload = useStartUpload({
    initiateFileUpload,
    form,
    formName,
    type,
  });
  const retryUpload = useRetryUpload({ file });
  const removeUpload = useRemoveUpload({
    file,
    form,
    formName,
    ref,
    type,
    removeUploadFromContext,
  });

  useFormFileSubscribe({ file, form, type, formName });

  return useMemo(
    () => ({
      file,
      initialValue,
      startUpload,
      retryUpload,
      removeUpload,
    }),
    [file, initialValue, startUpload, retryUpload, removeUpload],
  );
};
