import { type Pagination } from 'models';
import { generateQueryKey } from 'query';
import { type ProgramStatus, type ProgramsScope } from '../types';

export * from './mutations';
export * from './hooks';

export * from './useProgramsInfiniteQuery';
export * from './useProgramsQuery';

export const getProgramsInfiniteQueryKey = (params: {
  scope?: ProgramsScope;
  pageSize?: number;
  organizationId?: string;
  featured?: boolean;
  status?: ProgramStatus;
  search?: string;
}) => generateQueryKey(['programs', 'list', 'infinite', params]);

export const getProgramsQueryKey = (params: {
  scope?: ProgramsScope;
  organizationId?: string;
  featured?: boolean;
  status?: ProgramStatus;
  search?: string;
  pagination?: Pagination;
}) => generateQueryKey(['programs', 'list', params]);

export const getProgramUsersKey = (params: { programsIds?: string[]; limit?: number; search?: string }) =>
  generateQueryKey(['programs', 'users', params]);
