import { type Pagination } from 'models';
import { generateQueryKey } from 'query';
import { type CommunitiesScope } from '../types';
import { type EventsQueryType } from './useEventsQuery';

export * from './mutations';
export * from './useCommunityQuery';
export * from './useNotificationSettingsQuery';
export * from './useEventQuery';
export * from './useEventsQuery';
export * from './useUsersQuery';
export * from './useAssetsQuery';
export * from './useMembersQuery';
export * from './useCommunitiesInfiniteQuery';
export * from './useCommunitiesQuery';
export * from './useCommunitiesUsers';

export const getCommunityQueryKey = ({ id, invitationToken }: { id?: string; invitationToken?: string }) =>
  generateQueryKey([
    'communities',
    {
      id,
      invitationToken: invitationToken ?? null,
    },
  ]);

export const getUsersQueryKey = (params: { search?: string; excludeCommunityId?: string }) =>
  generateQueryKey(['communities', 'users-all', params]);

export const getCommunityUsersKey = (params: {
  communitiesIds?: string[];
  role?: string;
  limit?: number;
  search?: string;
}) => generateQueryKey(['communities', 'users', params]);

export const getCommunityNotificationsKey = ({ id }: { id: string }) =>
  generateQueryKey([...getCommunityQueryKey({ id }), 'notifications']);

export const getAssetsQueryKey = ({
  id,
  params,
}: {
  id: string;
  params: { parentId?: string; pageSize?: number; order?: string; sort?: string };
}) => generateQueryKey([...getCommunityQueryKey({ id }), 'files', params]);

// TODO relocate to events
export const getEventsQueryKey = (params: { type?: EventsQueryType; communityId?: string; limit?: number }) =>
  generateQueryKey(['communities', 'events', params]);

export const getEventQueryKey = ({ eventId }: { eventId: string }) =>
  generateQueryKey(['communities', 'events', eventId]);

export const getCommunityMembersKey = ({ communityId, pageSize }: { communityId?: string; pageSize?: number }) =>
  generateQueryKey(['communities', 'members', { communityId, pageSize }]);

export const getCommunitiesInfiniteQueryKey = (params: {
  scope?: CommunitiesScope;
  pageSize?: number;
  organizationId?: string;
  featured?: boolean;
  search?: string;
}) => generateQueryKey(['communities', 'list', 'infinite', params]);

export const getCommunitiesQueryKey = (params: {
  scope?: CommunitiesScope;
  organizationId?: string;
  featured?: boolean;
  search?: string;
  pagination?: Pagination;
}) => generateQueryKey(['communities', 'list', params]);
