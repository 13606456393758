import { type ApiClientResponse, apiClient, routes } from 'common/services';
import { type CommentDataObject, type UploadedFileDataObject } from 'models/service';

type CreateCommentRequest = {
  content_object: {
    parent_id: string | null;
    text_content?: string;
    html_content?: string;
    attachments?: UploadedFileDataObject[];
  };
};

export const createComment = async (data: CreateCommentRequest) =>
  apiClient.post<CommentDataObject, ApiClientResponse<CommentDataObject>>(routes.contentObjects.comment.create, data);
