import { CommunityListItem, type ReduxUser, type EventType, RegisteredEventInvitee } from 'models';
import { useEffect, useMemo } from 'react';
import { type EventContentObjectDataObject } from 'models/service';

import { Loading, message } from 'ui';
import { CreateEventFormModal } from './components';
import { useEventUserQuery } from './queries';

type P = {
  viewer: ReduxUser;
  onCancel: (force?: boolean) => void;
  onSuccess: (eventData: EventContentObjectDataObject) => Promise<void> | void;
  onRequestReturn?: () => void;
  eventTypes?: EventType[];
  initialData: {
    communityId: string;
    invitees: string[];
    eventType: 'telehealth_visit';
    relatedAdmissionId?: string;
  };
};

// TODO solve `initialData` more generic way for reuse not for only case
// probalby if solved correctly `CreateTelehealthEvent` can be deleted after and `CreateEvent` should be enough
export const CreateTelehealthEvent = ({ viewer, initialData, onCancel, ...rest }: P) => {
  const communitiesData = useMemo<CommunityListItem[]>(
    () => viewer.joined_communities.map((community) => new CommunityListItem(community)),
    [viewer],
  );

  const {
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    data: userData,
    error: userError,
  } = useEventUserQuery({ userId: initialData.invitees[0] }, { useErrorBoundary: false });

  useEffect(() => {
    if (userError) {
      message.error('Failed to load data.');
      onCancel(true);
    }
  }, [userError, onCancel]);

  if ((isLoadingUser && isFetchingUser) || !userData) {
    return <Loading />;
  }

  return (
    <CreateEventFormModal
      isOpened
      viewer={viewer}
      communities={communitiesData}
      initialData={{
        communityId: initialData.communityId,
        invitees: [
          /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
          new RegisteredEventInvitee({
            id: userData.id,
            first_name: userData.firstName,
            last_name: userData.lastName,
            profile_photo: userData.profilePhoto,
            event_role: 'attendee',
          }),
          /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
        ],
        relatedAdmissionId: initialData.relatedAdmissionId,
      }}
      onCancel={onCancel}
      {...rest}
    />
  );
};
