import { getAppConfig } from 'old/atoms/app-config-gate';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Title, Button, Section, Row, Col } from 'ui';
import { SocialButtons, Separator } from '../components';

type P = {
  onSuccessLogin: () => void;
  onNavigateToSignUp: () => void;
  onNavigateToPasswordlessLogin: () => void;
  onNavigateToPasswordLogin: () => void;
};

const LoginSignpost = ({
  onSuccessLogin,
  onNavigateToSignUp,
  onNavigateToPasswordlessLogin,
  onNavigateToPasswordLogin,
}: P) => {
  const { t } = useTranslation();

  const { constants } = getAppConfig();
  const hasAnySocialLogin = Boolean(constants.GOOGLE_ID);

  return (
    <>
      <Title level={1}>{t('Log in')}</Title>
      <Section paddingTop paddingBottom lineTop={false} lineBottom={false}>
        <Row item={{ gutter: [0, variables.spaceMd.value] }}>
          <Col item={{ span: 24 }}>
            <Button
              block
              type="primary"
              onClick={() => {
                onNavigateToPasswordLogin();
              }}
              size="large"
            >
              {t('Use password')}
            </Button>
          </Col>
          <Col item={{ span: 24 }}>
            <Button
              block
              onClick={() => {
                onNavigateToPasswordlessLogin();
              }}
              size="large"
            >
              {t('Send email link')}
            </Button>
          </Col>
        </Row>
      </Section>
      {hasAnySocialLogin ? (
        <>
          <Separator text="or" />
          <SocialButtons onSuccessLogin={onSuccessLogin} onNavigateToSignUp={onNavigateToSignUp} />
        </>
      ) : null}
    </>
  );
};

export default LoginSignpost;
