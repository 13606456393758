import { type AutocompleteUser, type InviteType } from 'models';
import { useCommunitiesUsers } from 'features/communities/queries';
import { type FormInstance } from 'ui';

import { type InviteesType } from '../../../EventInvitees';

import { type CommonFormValues } from './hooks';
import InviteesPicker from './InviteesPicker';

type P = {
  // TODO this should be type
  role?: 'admin' | 'member';
  allowedInvites?: InviteesType[];
  form: FormInstance<CommonFormValues>;
  inviteType: InviteType;
  onInvite: (inviteType: InviteType, invitees: AutocompleteUser[], emailInvitees?: string[] | undefined) => void;
  communityId: string;
};

const CommunitiesEventInviteesPicker = ({ role, communityId, ...rest }: P) => {
  const {
    isLoading: isLoadingCommunitiesUsers,
    isFetching: isFetchingCommunitiesUsers,
    isRefetching: isRefetchingCommunitiesUsers,
    data: communitiesUsers,
    error: communitiesUsersError,
  } = useCommunitiesUsers({ communitiesIds: [communityId], role }, { useErrorBoundary: false });

  return (
    <InviteesPicker
      {...rest}
      isLoading={isLoadingCommunitiesUsers}
      isFetching={isFetchingCommunitiesUsers}
      isRefetching={isRefetchingCommunitiesUsers}
      data={communitiesUsers}
      error={communitiesUsersError}
    />
  );
};

export default CommunitiesEventInviteesPicker;
