import translationChat from 'chat-web/translation.json';
import translationChatES from 'chat-web/translationES.json';
import { isDate, isValid, formatDistanceToNow } from 'date-fns';
import formatDate from 'date-fns/format';
import { enUS, es } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import numbro from 'numbro';
import { initReactI18next } from 'react-i18next';

import translationEN from './en/resource.json';
import translationES from './es/resource.json';

// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
const numbroLanguages = require('numbro/dist/languages.min.js');

// eslint-disable-next-line @typescript-eslint/naming-convention -- key is from i18next
const locales = { enUS, es };

numbro.registerLanguage({
  ...numbroLanguages['en-AU'],
  currency: {
    symbol: '$',
    position: 'prefix',
    code: 'USD',
  },
  currencyFormat: {
    thousandSeparated: true,
    mantissa: 2,
  },
});

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: translationEN,
        translationChat,
      },
      es: {
        translations: translationES,
        translationChat: translationChatES,
      },
    },
    fallbackLng: 'en',
    debug: false,

    ns: ['translations', 'translationChat'],
    // eslint-disable-next-line @typescript-eslint/naming-convention -- key is from i18next
    defaultNS: 'translations',
    nsSeparator: false,

    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        const locale = locales[lng];

        if (format === 'age') {
          return formatDistanceToNow(value, { locale });
        }

        if (isDate(value) && isValid(value)) {
          const formats = {
            month: 'MMMM',
            shortMonth: 'MMM',
            shortMonthDay: 'MMM d',
            shortMonthDayYear: 'MMM d, yyyy',
            time: 'h:mm a',
          };

          return formatDate(value, formats[format] || format, { locale });
        }

        return value;
      },
    },

    whitelist: ['en', 'es'],
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'lang',
      caches: ['localStorage'],
    },
  });

export { default } from 'i18next';
