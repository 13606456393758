import { type EventSlim, getEventSlimData, type Pagination } from 'models';
import { useQuery } from 'query';
import { getEvents } from '../services';
import { getEventsQueryKey } from '.';

type P = {
  scope?: 'channels';
  organizationId?: string;
  featured?: boolean;
  pagination?: Pagination;
};

export const useEventsQuery = ({ scope, pagination, organizationId, featured }: P) => {
  return useQuery<EventSlim[]>(getEventsQueryKey({ scope, pagination, organizationId, featured }), async () => {
    const { pageSize, position } = pagination ?? {};

    /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
    const response = await getEvents({
      limit: pageSize,
      offset: position !== undefined && pageSize !== undefined ? position * pageSize : undefined,
      organization_id: organizationId,
      featured,
      scope,
    });
    /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

    return response.data.map((event) => getEventSlimData(event));
  });
};
