import { type BasicContentObjectRedux } from 'models';
import { canSeeContentObjectLikes } from 'permissions';
import { LikeCount } from '../../../components';
import { CommentCount } from '../CommentCount';
import { StatusBarLayout } from '../StatusBarLayout';

type P = {
  item: BasicContentObjectRedux;
  onShowComments?: () => void;
};

const BasicStatusBar = ({ item, onShowComments }: P) => {
  const { comments_count: commentsCount = 0, liked, likes_count: likesCount } = item;
  const showLikes = likesCount > 0 && canSeeContentObjectLikes({ contentObject: item });
  const showComments = commentsCount > 0;

  return showLikes || showComments ? (
    <StatusBarLayout
      left={
        <div className="basic-status-bar">
          {showLikes ? <LikeCount count={likesCount} liked={liked} /> : null}
          {/* TODO this will probably not be clickable in bookmarks */}
          {showComments ? <CommentCount count={commentsCount} onShowComments={onShowComments} /> : null}
        </div>
      }
      right={null}
    />
  ) : null;
};

export default BasicStatusBar;
