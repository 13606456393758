import { type ProfileConsent, type Consent as ConsentModel, type Entity } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ButtonProps, Col, Form, ModalFormActions, Row, Text, Title, Space } from 'ui';

import { type Consent, type ConsentOption } from '../../types';
import ConsentDetail from './ConsentDetail/ConsentDetail';
import ConsentsFormItem from './ConsentFormItem/ConsentsFormItem';

type FormValues = {
  consents: Consent[];
};

type P = {
  onAccept: () => Promise<void> | void;
  onReject?: () => Promise<void> | void;
  onConsent?: (id?: string) => void;
  consents: ConsentModel[];
  currentAcceptedConsents: ProfileConsent[];
  entities: Entity[];
  showTitle?: boolean;
  acceptTitle?: string;
  rejectTitle?: string;
  showInfoMessage?: boolean;
  infoMessage?: string;
  left?: ButtonProps;
  submit?: ButtonProps;
};

const generateInitialValues = ({
  consents,
  currentAcceptedConsents,
}: {
  consents: ConsentModel[];
  currentAcceptedConsents: ProfileConsent[];
}): Partial<FormValues> => ({
  consents: consents.map((consent) => ({
    id: consent.id,
    name: consent.name,
    htmlContent: consent.htmlContent,
    consented:
      currentAcceptedConsents.find((currentAcceptedConsent) => currentAcceptedConsent.id === consent.id)?.consented ??
      false,
  })),
});

const ConsentsForm = ({
  onAccept,
  onReject,
  onConsent,
  consents,
  currentAcceptedConsents,
  entities,
  showTitle = true,
  acceptTitle,
  rejectTitle,
  showInfoMessage = true,
  infoMessage,
  left,
  submit,
}: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const [selectedConsent, setSelectedConsent] = useState<ConsentOption>();
  const [isAccepting, setIsAccepting] = useState<boolean>();
  const [isRejecting, setIsRejecting] = useState<boolean>();

  const initialValues = generateInitialValues({ consents, currentAcceptedConsents });

  const showEntitiesInfo = entities.length > 0;

  const onSelectConsent = (consent?: ConsentOption) => {
    onConsent?.(consent?.value.id);
    setSelectedConsent(consent);
  };

  const onFinish = async () => {
    setIsAccepting(true);
    await onAccept();
    setIsAccepting(false);
  };

  const onClose = async () => {
    setIsRejecting(true);
    await onReject?.();
    setIsRejecting(false);
  };

  return (
    <Form<FormValues> form={form} initialValues={initialValues} onFinish={onFinish} className="consents-form">
      {selectedConsent ? (
        <ConsentDetail
          consent={selectedConsent.value}
          onAccept={() => {
            selectedConsent.onChange({ ...selectedConsent.value, consented: true });
            onSelectConsent(undefined);
          }}
          onCancel={() => {
            onSelectConsent(undefined);
          }}
        />
      ) : (
        <>
          {showTitle ? <Title level={2}>Confirm consents</Title> : null}
          {showEntitiesInfo || showInfoMessage ? (
            <Row item={{ className: 'consents-form__description' }}>
              <Col>
                {showEntitiesInfo ? (
                  <>
                    <Text>You’re missing consents from following communities:</Text>
                    <ul>
                      {entities.slice(0, entities.length === 3 ? 3 : 2).map((entity) => (
                        <li key={entity.id}>
                          <Text>{entity.name}</Text>
                        </li>
                      ))}
                      {entities.length > 3 ? (
                        <li>
                          <Text>{t('more_communities_count', { count: entities.length - 2 })}</Text>
                        </li>
                      ) : null}
                    </ul>
                  </>
                ) : null}
                {showInfoMessage ? (
                  <Text>
                    {infoMessage ??
                      'To use the application, you must agree with policies. Without consent, it will not be possible to use the application.'}
                  </Text>
                ) : null}
              </Col>
            </Row>
          ) : null}

          <Form.List name="consents">
            {(fields) => (
              <Space direction="vertical" size="extra-small" block>
                {fields.map((field) => (
                  <Form.Item
                    {...field}
                    rules={[
                      {
                        // eslint-disable-next-line @typescript-eslint/require-await
                        async validator(_, value: Consent) {
                          if (!value.consented) {
                            throw new Error(t('Error: Consent for {{label}} is required', { label: value.name }));
                          }
                        },
                      },
                    ]}
                    key={`consents-${field.key}`}
                  >
                    <ConsentsFormItem
                      onSelect={(data) => {
                        onSelectConsent(data);
                      }}
                    />
                  </Form.Item>
                ))}
              </Space>
            )}
          </Form.List>
          <ModalFormActions
            left={
              onReject
                ? { onClick: onClose, children: rejectTitle ?? 'Log out', loading: isRejecting, ...left }
                : undefined
            }
            submit={{ children: acceptTitle ?? 'Continue', loading: isAccepting, ...submit }}
          />
        </>
      )}
    </Form>
  );
};

export default ConsentsForm;
