import { useMutation } from 'query';
import { reportObject } from '../../services';

export const useReportObjectMutation = () => {
  return useMutation<unknown, { item: { id: string } }>(async ({ item }) =>
    reportObject({
      /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      abuse_report: {
        content_object_id: item.id,
      },
      /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
    }),
  );
};
