import { routes } from 'routes';

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
const contentObjectURLMappings = {
  post: (item: any) =>
    routes.contentObjects.posts(item.type === 'comment' ? item.top_parent_shareable_id : item.post.id),
  activity: (item: any) =>
    routes.contentObjects.activities(item.type === 'comment' ? item.top_parent_shareable_id : item.activity.id),
  poll: (item: any) =>
    routes.contentObjects.polls(item.type === 'comment' ? item.top_parent_shareable_id : item.poll.id),
  kudo: (item: any) =>
    routes.contentObjects.kudos(item.type === 'comment' ? item.top_parent_shareable_id : item.kudo.id),
  event: (item: any) =>
    routes.contentObjects.events(item.type === 'comment' ? item.top_parent_shareable_id : item.event.id),
  withings_order_post: (item: any) =>
    routes.contentObjects.withingsOrderPosts(
      item.type === 'comment' ? item.top_parent_shareable_id : item.withings_order_post.id,
    ),
  campaign: (item: any) => routes.contentObjects.campaigns(item.campaign.id, item.campaign.organization.id),
  comment: (item: any) =>
    `${contentObjectURLMappings[item.top_parent_type](item)}/comments/${
      item.top_parent_id === item.parent_id ? item.id : `${item.parent_id}/${item.id}`
    }`,
};
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

// TODO Very similar logic is used in campaigns
export const generateContentObjectPath = (item: any) => contentObjectURLMappings[item.type](item);
