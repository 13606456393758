import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { withTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';

import { getFeedKey } from '../../models/feed';
import { InViewport } from '../in-viewport';
import { getAppConfig } from '../../atoms/app-config-gate';
import { Loading, Title, Result, Button } from 'ui';
import { onErrorReport, onSuccessReport } from '../../utils/report';

class Renderer extends React.Component {
  static defaultProps = {
    render: (node, actions) => node,
  };

  state = {
    hasMoreObjects: true,
    failedToLoad: false,
    feed: [],
    showMessage: true,
  };

  actions = {
    createComment: this.props.createComment,
    createObject: this.props.createObject,
    deleteObject: this.props.deleteObject,
    likeObject: this.props.likeObject,
    bookmarkObject: this.props.bookmarkObject,
    loadCollection: this.props.loadCollection,
    reportObject: this.props.reportObject,
    sortChange: this.props.sortChange,
    updateObject: this.props.updateObject,
    reloadObject: this.props.reloadContentObject,
  };

  getNextObjects = async () => {
    if (!this.props.loading) {
      const lastObject = this.props.collection[this.props.collection.length - 1];
      const resp = await this.props.loadCollection({
        cursor: lastObject ? lastObject.id : undefined,
      });

      if (resp.ok) {
        this.setState({ hasMoreObjects: resp.data.length > 0 });
      } else {
        this.setLoadErrorStatus();
      }
    }
  };

  setLoadErrorStatus = (isError = true) => {
    this.setState({ hasMoreObjects: !isError, failedToLoad: isError });
  };

  componentWillUnmount = () => {
    this.props.removeAll();
  };

  handleCollectionChange = () => {
    this.setState({ showMessage: this.props.collection.length === 0 });
  };

  render() {
    const node = (
      <TransitionGroup component={null}>
        {this.props.collection.map((object) => {
          return !getAppConfig().feature_flags.campaigns && object.type === 'campaign' ? null : (
            <CSSTransition
              key={`${object.type}-${object.id}`}
              timeout={400}
              classNames="feed-object"
              onEnter={this.handleCollectionChange}
              onExited={this.handleCollectionChange}
            >
              {this.props.renderObject(object, this.actions)}
            </CSSTransition>
          );
        })}
        <CSSTransition key="loader" timeout={400} classNames="feed-object">
          <div
            className={`feed-object__wrapper ${
              this.props.direction === 'horizontal' ? 'feed-object__wrapper--horizontal' : ''
            } ${this.props.direction === 'vertical' ? 'feed-object__wrapper--vertical' : ''}`}
          >
            {this.props.loading ? (
              <Loading />
            ) : this.state.hasMoreObjects ? (
              <InViewport useNewLoader size={12} key="feed-bookmarks-viewport" onEnter={this.getNextObjects} />
            ) : this.state.failedToLoad ? (
              <Result
                status="error"
                subTitle={<Trans>Sorry, something went wrong.</Trans>}
                extra={
                  <Button type="primary" onClick={() => this.setLoadErrorStatus(false)}>
                    Retry
                  </Button>
                }
              />
            ) : (
              this.state.showMessage && <Title level={4}>You currently don't have any bookmarked content</Title>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    );

    return this.props.render(node, this.actions);
  }
}

const mapState = (state, props) => {
  const feedName = getFeedKey(props);
  return {
    collection: select.feed.get(state, feedName),
    loading: select.feed.loading(state, { feed: feedName }),
  };
};

const mapDispatch = (dispatch, props) => {
  const feedName = getFeedKey(props);
  return {
    createComment: (draft, parent_id) => {
      return dispatch.feed.createAsync({ draft: draft, parent_id: parent_id, comment: true });
    },
    createObject: (draft) => {
      return draft.questions
        ? dispatch.feed.createPoll({ poll: draft })
        : dispatch.feed.createAsync({ draft: draft, parent_id: null });
    },
    updateObject: async (object) => {
      return dispatch.feed.updateAsync({ object: object });
    },
    likeObject: (object) => {
      return dispatch.feed.likeAsync({ object: object });
    },
    bookmarkObject: (object) => {
      return dispatch.feed.bookmarkAsync({ object: object, feed: feedName });
    },
    loadCollection: (params) => {
      return dispatch.feed.getAsync({
        feed: feedName,
        params: {
          ...params,
          limit: props.limit,
        },
        bookmarked: true,
      });
    },
    removeAll: () => dispatch.feed.removeAll({ feed: feedName }),
    deleteObject: async ({ object, originalItem }) => {
      let message = '';
      if (object.type === 'event') {
        message = i18n.t('Do you really want to delete this event and all its content?');
      } else {
        message = i18n.t('Delete this Post/Comment?');
      }

      if (await window.confirm(message)) {
        return dispatch.feed.deleteAsync({ object, originalItem });
      }
    },
    reportObject: async (object) => {
      if (await window.confirm(i18n.t('Report this Post/Event/Comment?'))) {
        return dispatch.feed.reportAsync({ object: object, onSuccess: onSuccessReport, onError: onErrorReport });
      }
    },
    sortChange: async (object, sort) => {
      return dispatch.feed.sortCommentsAsync({ object, sort });
    },
    reloadContentObject: (object) => {
      dispatch.feed.reloadAsync({ feed: feedName, postId: object.id });
    },
  };
};

export const FeedBookmarksCore = withTranslation()(connect(mapState, mapDispatch)(Renderer));
