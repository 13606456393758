import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosErrorHandler } from 'common/error-handlers';
import { Form, InfinityScrollSelect, Modal, ModalFormActions, Space, Text, RichTextEditor, Skeleton } from 'ui';
import { useChangelogVersion, useVersionCompare } from './hooks';
import { useReleaseNotesQuery } from './queries/useReleaseNotesQuery';

type VersionOption = {
  label: string;
  value: string;
  changelog: {
    version: string;
    htmlContent: string;
  };
};

type ChangelogFormValues = {
  version?: VersionOption;
};

const ChangelogModal = ({ isOpened, onClose }: { isOpened: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const {
    data: releaseNotes,
    fetchNextPage: fetchNextPageOfReleaseNotes,
    hasNextPage: hasMoreReleaseNotes,
    isFetching: isFetchingReleaseNotes,
    isLoading: isLoadingReleaseNotes,
    error: queryError,
  } = useReleaseNotesQuery({ limit: 10 });

  const { compareVersions } = useVersionCompare();

  const { updateLatestChangelogVersion } = useChangelogVersion();

  const allFetchedReleaseNotes = useMemo(() => {
    return releaseNotes?.pages.flatMap((page) =>
      page.data.map((option) => ({
        label: compareVersions({
          backendVersion: option.versionValue,
          packageVersion: String(process.env.REACT_APP_VERSION),
        })
          ? `${option.versionValue} - Current version`
          : option.versionValue,
        value: option.id,
        changelog: {
          version: option.versionValue,
          htmlContent: option.text,
        },
      })),
    );
  }, [compareVersions, releaseNotes?.pages]);

  const initialValues: ChangelogFormValues = {
    version: allFetchedReleaseNotes?.[0],
  };

  const handleCancel = () => {
    updateLatestChangelogVersion();
    onClose();
  };

  return (
    <Modal
      title="Release notes"
      isOpened={isOpened}
      maskClosable={false}
      width="large"
      disableBack
      onCancel={handleCancel}
      destroyOnClose
    >
      <AxiosErrorHandler error={queryError}>
        {isLoadingReleaseNotes || !allFetchedReleaseNotes ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Form<ChangelogFormValues> initialValues={initialValues}>
            <Space direction="vertical" size="middle">
              <Text>
                {t(
                  "Here's What's new! The latest release includes new features, improvements, and bug fixes to make your experience better than ever",
                )}
              </Text>
              <Form.Item name="version" label="Select version" rules={[{ required: true }]}>
                <InfinityScrollSelect<VersionOption>
                  placeholder={t('Select version')}
                  loading={isFetchingReleaseNotes}
                  options={allFetchedReleaseNotes}
                  handleRequestMoreData={fetchNextPageOfReleaseNotes}
                  hasMoreData={hasMoreReleaseNotes}
                />
              </Form.Item>
              <Form.Item noStyle dependencies={['version']}>
                {({ getFieldsValue }) => {
                  const { version } = getFieldsValue(true) as ChangelogFormValues;

                  const htmlContent = version?.changelog.htmlContent;

                  return <RichTextEditor editable={false} data={htmlContent} />;
                }}
              </Form.Item>
            </Space>
            <ModalFormActions submit={{ children: 'Got it', onClick: handleCancel }} />
          </Form>
        )}
      </AxiosErrorHandler>
    </Modal>
  );
};

export default ChangelogModal;
