import { type UploadFile } from 'antd';
import { DoneFileUpload, ErrorFileUpload, FileUploading } from './StatusComponents';

type P = {
  file: UploadFile;
  removeUpload: () => void;
  retryUpload: () => void;
};

const UploadFileList = ({ file, removeUpload, retryUpload }: P) => {
  switch (file.status) {
    case 'done':
      return <DoneFileUpload fileName={file.name} removeUpload={removeUpload} />;
    case 'error':
      return <ErrorFileUpload fileName={file.name} removeUpload={removeUpload} retryUpload={retryUpload} />;
    case 'uploading':
      return <FileUploading fileName={file.name} removeUpload={removeUpload} percent={file.percent ?? 0} />;
    default:
      return null;
  }
};

export default UploadFileList;
