import * as React from 'react';

import { Box } from '../../box';
import { Text } from '../../text';
import { Avatar } from 'ui';
import { ThemeConsumer } from '../../theme';
import { images } from 'common/utils';

export function UserItem(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box
          {...props.downshiftProps}
          flexDirection="row"
          alignItems="center"
          style={{ padding: '7px 15px', cursor: 'pointer' }}
        >
          <Box style={{ marginRight: 10 }}>
            <Avatar name={props.title} photo={props.itemProps.profile_photo ?? images.default.userProfilePhoto} />
          </Box>
          <Text component="p" size="14px" weight="500" color={theme.color.gray} lineHeight="19px">
            {`${props.itemProps.first_name} ${props.itemProps.last_name}`}
          </Text>
        </Box>
      )}
    </ThemeConsumer>
  );
}
