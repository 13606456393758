import { type AxiosProgressEvent } from 'axios';
import { Image } from 'models';
import { uploadFile as uploadFileApi } from '../services';

export const uploadImage = async (
  file: Blob | File | string,
  onProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  const response = await uploadFileApi(file, onProgress);

  return new Image(response.data);
};
