import { apiClient, routes, type ApiClientResponse } from 'common/services';
import { type VideoListItemDataObject, type PaginatedRailsDataObjects } from 'models/service';

type VideoListRequest = {
  limit?: number;
  offset?: number;
  cursor?: string;
  search?: string;
};

export const getVideos = async (params: VideoListRequest) =>
  apiClient.get<
    PaginatedRailsDataObjects<VideoListItemDataObject>,
    ApiClientResponse<PaginatedRailsDataObjects<VideoListItemDataObject>>
  >(routes.videos.find, {
    params,
  });
