import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type RequestMagicLink } from '../models';

export const requestMagicLink = async ({ email, redirectUrl }: RequestMagicLink) => {
  /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
  const data = {
    email,
    redirect_url: redirectUrl,
  };
  /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

  return apiClient.post<undefined, ApiClientResponse<undefined>>(routes.oauth.magicLink, data, { skipAuth: true });
};
