export * from './mutation';
export * from './useVideosInfiniteQuery';
export * from './useGetVideoDetailQuery';

import { generateQueryKey } from 'query';

type VideosParams = {
  filter?: { search?: string };
  pagination?: { pageSize?: number };
};

export const getVideosInfiniteQueryKey = ({ filter = {}, pagination = {} }: VideosParams) =>
  generateQueryKey(['videos', { filter, pagination }, 'infinite']);

export const getVideoDetailQueryKey = (params: { videoId: string }) => generateQueryKey(['videos', 'detail', params]);

export const getMediaQueryKey = (params: { mediaId?: string }) => generateQueryKey(['media', 'detail', params]);
