import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { PostEditorTrigger } from '../../atoms/post-editor';
import { can } from '../../utils/permissions';
// Layout
import { AppLayout } from '../../layouts/AppLayout';
import { PinnedFeed, RegularFeed } from '../../atoms/feed';
import { Sidebar } from '../../atoms/sidebar';
import { Card } from 'ui';
import { getFeedKey } from '../../models/feed';
import { EventEditor } from '../../atoms/event-editor';
import EditPoll from 'features/polls/EditPoll';
import EditActivity from 'features/programs/feed/EditActivity';
import { useFeedbackOptions } from 'pages/hooks';

class Renderer extends React.Component {
  static defaultProps = {
    community: null,
  };

  state = {
    draft: undefined,
  };

  componentDidMount() {
    this.props.resetFeed();
    this.props.loadCollection();
  }

  initiatePostEditing = async (object) => {
    switch (object.type) {
      case 'post':
      case 'event':
      case 'poll':
      case 'activity':
      case 'kudo':
        this.setState({ draft: object });
        break;
      default:
        console.warn(`Cannot initiated editing object of type '${object.type}`);
    }
  };

  navigateToEvents = () => {
    this.props.history.push(`/events`);
  };

  navigateToEvent = (eventId) => {
    this.props.history.push(`/events/${eventId}/about`);
  };

  render() {
    const canCreatePostInCommunities = this.props.viewer.joined_communities.some((communityItem) =>
      can(communityItem, 'ContentObject', 'post'),
    );

    return (
      <AppLayout
        center={
          <RegularFeed
            community={this.props.community}
            limit={7}
            viewer={this.props.viewer}
            onInitiatePostEditing={this.initiatePostEditing}
            onRequestEventDetail={this.navigateToEvent}
            reloadingPost={this.props.reloadingPost}
            skipFirstMargin={!canCreatePostInCommunities && !this.props.hasPinnedPosts}
            render={(feedNode, actions) => (
              <React.Fragment>
                {this.state.draft?.type === 'event' && (
                  <EventEditor
                    draft={this.state.draft.event}
                    editorType="update"
                    isEditorOpen={this.state.draft && this.state.draft.type === 'event'}
                    onRequestClose={() => {
                      this.setState({ draft: undefined });
                    }}
                    onSuccess={async () => {
                      await this.props.reloadContentObject(this.state.draft);
                      this.setState({ draft: undefined });
                    }}
                  />
                )}
                {canCreatePostInCommunities && (
                  <Card id="post-editor-card" shrinked>
                    <PostEditorTrigger
                      draft={this.state.draft}
                      viewer={this.props.viewer}
                      onRequestClose={() => {
                        this.setState({ draft: undefined });
                      }}
                      onSubmit={async (object) => {
                        if (object.id !== undefined) {
                          const response = await actions.updateObject(object);
                          this.setState({ draft: undefined });
                          return response;
                        } else {
                          return await actions.createObject(object);
                        }
                      }}
                    />
                  </Card>
                )}
                {this.state.draft && this.state.draft.type === 'poll' && (
                  <EditPoll
                    poll={{
                      ...this.state.draft?.poll,
                      post_in_communities: this.state.draft?.post_in_communities,
                      attachments: this.state.draft?.attachments,
                    }}
                    isOpened={this.state.draft && this.state.draft.type === 'poll'}
                    onCancel={() => this.setState({ draft: undefined })}
                    onSuccess={async () => {
                      await this.props.reloadContentObject(this.state.draft);
                      this.setState({ draft: undefined });
                    }}
                  />
                )}
                {this.state.draft && this.state.draft.type === 'activity' && (
                  <EditActivity
                    poll={this.state.draft?.activity}
                    isOpened={this.state.draft && this.state.draft.type === 'activity'}
                    onCancel={() => this.setState({ draft: undefined })}
                    onSuccess={async () => {
                      await this.props.reloadContentObject(this.state.draft);
                      this.setState({ draft: undefined });
                    }}
                  />
                )}
                {this.props.hasPinnedPosts && (
                  <div style={canCreatePostInCommunities ? { margin: '15px 0 0' } : {}}>
                    <PinnedFeed
                      community={this.props.community}
                      actions={actions}
                      limit={3}
                      pinned={true}
                      viewer={this.props.viewer}
                      onInitiatePostEditing={this.initiatePostEditing}
                    />
                  </div>
                )}
                <div>{feedNode}</div>
              </React.Fragment>
            )}
          />
        }
        right={
          <Sidebar
            viewer={this.props.viewer}
            navigateToEvents={this.navigateToEvents}
            numberOfNotifications={this.props.badges.total}
            unreadMessages={this.props.unreadMessages}
            hideTodosFeedCta
            feedbackOptions={this.props.feedbackOptions}
          />
        }
      />
    );
  }
}

const mapDispatch = (dispatch, props) => {
  const pinnedFeedName = getFeedKey({ ...props, pinned: true });
  return {
    loadCollection: () =>
      dispatch.feed.getAsync({
        feed: pinnedFeedName,
        params: {
          limit: 3,
          pinned: true,
        },
      }),
    resetFeed: () => {
      dispatch.feed.reset();
    },
    reloadContentObject: (object) => {
      dispatch.feed.reloadAsync({ feed: pinnedFeedName, postId: object.id });
    },
  };
};

const mapState = (state, props) => {
  const feedName = getFeedKey(props);
  const pinnedFeedName = getFeedKey({ ...props, pinned: true });
  const pinned = select.feed.get(state, pinnedFeedName);
  return {
    viewer: select.session.user(state),
    feed: select.feed.get(state, feedName),
    hasPinnedPosts: pinned && pinned.length > 0,
    badges: select.notifications.badges(state),
    unreadMessages: select.session.unreadMessages(state),
    reloadingPost: state.loading.effects.feed.reloadAsync,
  };
};

const PageFeedFunctional = (props) => {
  const feedbackOptions = useFeedbackOptions();
  return <Renderer {...props} feedbackOptions={feedbackOptions} />;
};

export const PageFeed = connect(mapState, mapDispatch)(PageFeedFunctional);
