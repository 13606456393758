import { type EventInviteeRole } from './event';
import { type InviteTokenDataObject as InvitationTokenDataObject } from './service';

export type InvitationTokenKind = 'event_attendee_invitation' | 'event_presenter_invitation';

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export class InvitationToken {
  static mapToEventRole: Record<InvitationTokenKind, EventInviteeRole | undefined> = {
    event_attendee_invitation: 'attendee',
    event_presenter_invitation: 'presenter',
  };

  token: string;
  subjectType: 'Event';
  subjectId: string;
  kind: InvitationTokenKind;

  constructor(data: InvitationTokenDataObject) {
    this.token = data.token;
    this.subjectType = data.subject_type;
    this.subjectId = data.subject_id;
    this.kind = data.kind;
  }

  get eventInviteeRole() {
    return InvitationToken.mapToEventRole[this.kind];
  }
}
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
