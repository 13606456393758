import moment from 'moment';
import type Program from 'models/program';
import { ContentObjectDeprecated } from './content-object-deprecated';
import { type ContentObjectDataObjectDeprecated, type PostDataObject } from './service/content-object-deprecated';
import { type UploadedFile } from './uploaded-file';
import { AutocompleteUser } from './user';

export type PostInCommunity = {
  id: string;
  name: string;
  organization_id: string;
};

// TODO U should be extend of Social
/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export default class Post<U> extends ContentObjectDeprecated<U> {
  post_in_communities: PostInCommunity[] = [];
  html_content?: string;
  text_content?: string;
  type = 'post';
  attachments: UploadedFile[] = [];
  author: AutocompleteUser = new AutocompleteUser();
  todo_due_date?: moment.Moment;

  constructor(content: ContentObjectDataObjectDeprecated, program: Program, data?: PostDataObject, shareIn: U[] = []) {
    super(content, shareIn);

    if (data) {
      Object.assign(this, data);

      this.publish_at = moment(data.publish_at);
    }

    this.publish_at = content.publish_at ? moment(content.publish_at) : moment().add(10, 'minutes');
    this.todo_due_date = content.post?.todo_due_date
      ? moment(content.post.todo_due_date)
      : moment.min(this.publish_at.clone().add(1, 'day'), moment(program?.end_time));
    this.author = new AutocompleteUser(content.author);
    this.attachments = content.attachments ?? [];
  }
}
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
