import { useCallback } from 'react';
import { type EntityDataObject } from 'models/service';

export const useRequestMentions = (onRequestMentions: (value: string) => Promise<EntityDataObject[]>) => {
  return useCallback(
    async (value: string) => {
      if (value && onRequestMentions) {
        const data = await onRequestMentions(value);
        if (data) {
          return data.map((user) => ({
            id: `@${user.name}`,
            userId: user.id,
            link: `/members/${user.id}`,
          }));
        }
      }

      return [];
    },
    [onRequestMentions],
  );
};
