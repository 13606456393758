import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { timezones } from 'common/utils';
import { Section, Title, Row, Col, ModalFormActions } from 'ui';

import { type PersonalFormData, type DeliveryFormData } from '../';
import { genders, imperialUnits, languages } from '../../utils';
import { SummaryLine } from '../SummaryLine';

type P = {
  data: {
    personal?: PersonalFormData;
    delivery?: DeliveryFormData;
  };
  onBack: () => void;
  onSubmit: () => void;
};

const PersonalInfo = ({ data, onBack, onSubmit }: P) => {
  const { t } = useTranslation();

  const generateAddress = () => {
    const address = [data.delivery?.city, data.delivery?.zip, data.delivery?.state].filter(Boolean).join(', ');

    return (
      <>
        <Row item={{ justify: 'end' }}>
          <Col>{data.delivery?.address1}</Col>
        </Row>
        {data.delivery?.address2 ? (
          <Row item={{ justify: 'end' }}>
            <Col>{data.delivery.address2}</Col>
          </Row>
        ) : null}
        <Row item={{ justify: 'end' }}>
          <Col>{address}</Col>
        </Row>
        <Row item={{ justify: 'end' }}>
          <Col>{data.delivery?.country}</Col>
        </Row>
      </>
    );
  };

  const gender = useMemo(() => {
    return genders.find((gen) => gen.value === data.personal?.gender)?.label;
  }, [data.personal?.gender]);

  const language = useMemo(() => {
    return languages.find((lang) => lang.value === data.personal?.prefferedLanguage)?.label;
  }, [data.personal?.prefferedLanguage]);

  const timezone = useMemo(() => {
    return timezones.find((timezone) => timezone.value === data.personal?.prefferedTimezone)?.label;
  }, [data.personal?.prefferedTimezone]);

  const { weightInImperial, heightInImperial } = useMemo(() => {
    if (data.personal) {
      return {
        weightInImperial: imperialUnits.units.weight.format(data.personal.weightInImperial),
        heightInImperial: `${imperialUnits.units.height.format(
          data.personal.heightInImperial.feet,
          data.personal.heightInImperial.inches,
        )}`,
      };
    }

    return {};
  }, [data.personal]);

  return (
    <>
      <Section className="summary-form">
        <Title level={4}>{t('Summary')}</Title>

        <Title className="summary-form__section" level={5}>
          {t('Personal information')}
        </Title>
        <SummaryLine label={t('Name')} value={`${data.personal?.firstName} ${data.personal?.lastName}`} />
        <SummaryLine label={t('Date of birth')} value={moment(data.personal?.birthDate.date)?.format('MM/DD/YYYY')} />
        <SummaryLine label={t('My timezone')} value={timezone} />
        <SummaryLine label={t('Gender')} value={gender ? t(gender) : gender} />
        <SummaryLine label={t('Weight')} value={weightInImperial} />
        <SummaryLine label={t('Height')} value={heightInImperial} />
        <SummaryLine underline={false} label={t('Language')} value={language} />

        <Title className="summary-form__section" level={5}>
          {t('Delivery information')}
        </Title>
        <SummaryLine label={t('Name')} value={`${data.delivery?.firstName} ${data.delivery?.lastName}`} />
        <SummaryLine label={t('E-mail')} value={data.delivery?.email} />
        <SummaryLine label={t('Phone number')} value={data.delivery?.phone} />
        <SummaryLine underline={false} label={t('Address')} value={generateAddress()} />
      </Section>
      <ModalFormActions left={{ onClick: onBack }} submit={{ onClick: onSubmit, children: 'Submit' }} />
    </>
  );
};

export default PersonalInfo;
