import { isToday } from 'date-fns';
import { useMemo, type ComponentProps } from 'react';
import { HeartbeatIcon } from 'ui';
import { isActivityAvailable, type ActivityTodo } from '../../../../types';
import { BaseTodoWidgetItem } from '../BaseTodoWidgetItem';
import { CompleteNowItem } from './CompleteNowItem';

type P = {
  type?: ComponentProps<typeof BaseTodoWidgetItem>['type'];
  todo: ActivityTodo;
  className?: string;
};

const ActivityTodoWidgetItem = ({ type, todo, className }: P) => {
  const { activity, path, endTime } = todo;
  const { name, description, currentActivityTime, periodicityType, times } = activity;

  const mode = useMemo(() => {
    if (periodicityType === 'daily' && times.length > 0) {
      return 'at';
    }

    if (periodicityType === 'one_time' && isToday(endTime)) {
      return 'by';
    }

    return 'hidden';
  }, [endTime, periodicityType, times.length]);

  const time = useMemo(() => {
    if (currentActivityTime && mode === 'at') {
      return currentActivityTime;
    }

    return endTime;
  }, [endTime, mode, currentActivityTime]);

  return (
    <BaseTodoWidgetItem
      type={type}
      className={className}
      path={path}
      icon={<HeartbeatIcon />}
      title="Complete activity"
      description={name}
      additionalDescription={description}
      item={currentActivityTime && isActivityAvailable(currentActivityTime) ? <CompleteNowItem /> : null}
      mode={mode}
      time={time}
    />
  );
};

export default ActivityTodoWidgetItem;
