import { routes } from 'routes';
import { useDebounce } from 'common/hooks';
import { EntityList } from 'features/channels';
import { Card, Empty, Link, VideoIcon } from 'ui';
import { useVideosInfiniteQuery } from '../../queries';

type P = {
  getPathToVideoDetail: (id: string) => string;
  search?: string;
};

const VideoList = ({ getPathToVideoDetail, search: propertySearch }: P) => {
  const { value: search, isDebouncing } = useDebounce(propertySearch);
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useVideosInfiniteQuery({
    filter: { search },
    pagination: { pageSize: 12 },
  });

  const videos = data?.pages.flat().flatMap((videoPaginated) => videoPaginated.data);

  const filteredView = Boolean(search);

  return (
    <Card>
      <EntityList
        getPathToEntityDetail={getPathToVideoDetail}
        empty={
          <Empty
            image={<VideoIcon />}
            description={{
              title: filteredView ? 'No videos' : 'You haven’t created any video yet',
              subtitle: filteredView
                ? 'There are no videos to display here'
                : 'Discover new content that is right for you in our Channels',
            }}
          >
            {filteredView ? null : <Link to={routes.channels.index}>Go to channels</Link>}
          </Empty>
        }
        entities={videos}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetching={isFetching}
        isLoading={isDebouncing || isLoading}
      />
    </Card>
  );
};

export default VideoList;
