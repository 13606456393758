import { type Privacy, type ReduxUser } from 'models';
import { useTranslation } from 'react-i18next';
import { type OrganizationDataObject } from 'models/service';
import { canViewChannelsSection } from 'permissions';
import { Alert, CheckboxInput, Form, HelpIcon, Section, TagsInput, Text, Title, Tooltip } from 'ui';

type RequiredFormValues = {
  show_in_channels?: boolean;
  featured_in_channels?: boolean;
  // TODO this is organization id
  organization: string;
  privacy: Privacy;
  tag_ids?: string[];
};

type P = {
  organizations: OrganizationDataObject[];
  viewer: ReduxUser;
};

const ProgramChannelsOptions = <FormValues extends RequiredFormValues>({ organizations, viewer }: P) => {
  const { t } = useTranslation();

  return (
    <Form.Item<FormValues> noStyle dependencies={['organization', 'privacy']}>
      {({ getFieldsValue, setFieldValue }) => {
        const { organization: organizationId, privacy } = getFieldsValue(true) as Partial<FormValues>;
        const organization = organizations.find((org) => org.id === organizationId);

        const channelType = organization?.custom_channels ? 'custom' : 'default';

        const isProgramSecret = privacy === 'secret';

        if (canViewChannelsSection({ viewer, organizationId }) && !isProgramSecret) {
          return (
            <Section paddingTop paddingBottom={false}>
              <Title level={4}>Channels</Title>
              <Section lineBottom={false}>
                <Alert
                  message={
                    <>
                      <Text>{t('The selected organization is using')}</Text> <Text strong>{t(channelType)}</Text>{' '}
                      <Text>{t('channels')}</Text>
                    </>
                  }
                  type="info"
                  showIcon
                />
              </Section>
              <TagsInput
                item={{
                  name: 'tag_ids',
                  label: 'Tags',
                }}
                input={{
                  options: organization?.tags?.map((tagItem) => ({
                    value: tagItem.id,
                    label: tagItem.name,
                  })),
                  emptyProps: {
                    description: t('No tags available. The selected organization has no tags defined.'),
                  },
                }}
              />
              <CheckboxInput
                item={{
                  label: (
                    <Text>
                      {t('Show program in Channels')}{' '}
                      <Tooltip
                        item={{
                          title:
                            'Program will display in your organization’s channels. Everyone in your organization will be able register for this program.',
                        }}
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Text>
                  ),
                  name: 'show_in_channels',
                  valuePropName: 'checked',
                }}
                input={{
                  onChange() {
                    // TODO find better approach - `setFieldValue` does not inherit type from `FormValues`
                    setFieldValue('featured_in_channels', false);
                  },
                }}
              />
              <Form.Item<FormValues> noStyle dependencies={['show_in_channels']}>
                {({ getFieldsValue }) => {
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- key is defined by Backend API endpoint
                  const { show_in_channels } = getFieldsValue(true) as Partial<FormValues>;

                  if (show_in_channels) {
                    return (
                      <CheckboxInput
                        item={{
                          label: (
                            <Text>
                              {t('Pin program as featured in Channels')}{' '}
                              <Tooltip item={{ title: 'Program will appear as featured in the Channels' }}>
                                <HelpIcon />
                              </Tooltip>
                            </Text>
                          ),
                          name: 'featured_in_channels',
                          valuePropName: 'checked',
                        }}
                      />
                    );
                  }

                  return null;
                }}
              </Form.Item>
            </Section>
          );
        }

        return null;
      }}
    </Form.Item>
  );
};

export default ProgramChannelsOptions;
