import { type Community, type ReduxUser } from 'models';
import { useState } from 'react';
import { canManageCommunity } from 'permissions';
import { Button, Card, Col, RichTextRenderer, Row, Title } from 'ui';
import { EditModal } from './EditModal';

type P = {
  community: Community;
  viewer: ReduxUser;
};

const Description = ({ community, viewer }: P) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);

  return (
    <>
      <Card
        className="community-description"
        title={
          <Row item={{ justify: 'space-between' }}>
            <Col>
              <Title level={5} marginBottom={false}>
                Description
              </Title>
            </Col>
            {canManageCommunity({ viewer, community }) && (
              <Col>
                <Button
                  type="link"
                  onClick={() => {
                    setOpenInviteModal(true);
                  }}
                  size="large"
                >
                  Edit
                </Button>
              </Col>
            )}
          </Row>
        }
      >
        <RichTextRenderer data={community.htmlDescription} />
      </Card>
      <EditModal
        community={community}
        isOpened={openInviteModal}
        onClose={() => {
          setOpenInviteModal(false);
        }}
      />
    </>
  );
};

export default Description;
