import { Entity } from './entity';
import { type Image } from './image';
import { type Privacy } from './privacy';
import {
  type ProfileConsentDataObject,
  type ProfileDataObject,
  type BaseUserDataObject,
  type CategoryDataObject,
  type ImageDataObject,
  type NodeUserDataObject,
  type OrganizationDataObject,
  type OrganizationListItemDataObject,
  type GenderDataObject,
  type InterestDataObject,
  type ProfessionDataObject,
  type EducationLevelDataObject,
  type ChangeRequestDataObject,
} from './service';
import { type EventType, OrganizationListItem, CommunityListItem } from './';

export type RailsId = string;
export type MongoId = string;

export type ReduxUserMetaItem = {
  name: string;
  translations: {
    en: string;
    es: string;
  };
  children?: ReduxUserMetaItem[];
};

export type ReduxUserCommunity = {
  auto_join: boolean;
  categories: CategoryDataObject[];
  cover_photo: ImageDataObject;
  description: string;
  html_description: string;
  id: string;
  joined: boolean;
  members_count: number;
  meta?: {
    patient_types?: ReduxUserMetaItem[];
    service_types?: ReduxUserMetaItem[];
    telehealth_visit_durations?: number[];
  };
  name: string;
  organization: OrganizationListItemDataObject;
  organization_id: string;
  privacy: Privacy;
  profile_photo?: Image;
  settings: {
    allowed_events_member?: EventType[];
    allowed_events_admin?: EventType[];
  };
  short_name: string;
  change_requests: ChangeRequestDataObject[];
};

type ReduxProfileConsentDataObject = {
  id: string;
  consented: boolean;
};

type NotificationSetting = {
  value: boolean;
  change_allowed?: boolean;
};

export type MembershipRole = 'admin' | 'member';
export type MembershipSubjectType = 'Community' | 'Organization' | 'Program';

export type MembershipDataObject = {
  id: string;
  subject_id: string;
  subject_type: MembershipSubjectType;
  role: MembershipRole;
};

// TODO add properties when needed
export type ReduxUser = {
  id: RailsId;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone: string;
  name: string;
  joined_organizations: OrganizationDataObject[];
  joined_communities: ReduxUserCommunity[];
  superadmin: boolean;
  admin_organizations: string[];
  admin_communities: string[];
  admin_programs: string[];
  author_programs: string[];
  memberships: MembershipDataObject[];
  profile_photo: Image;
  required_consents?: ReduxProfileConsentDataObject[];
  users_consents?: ReduxProfileConsentDataObject[];
  badge_notifications_settings?: {
    enable_notifications: NotificationSetting;
  };
  email_notifications_settings?: {
    enable_notifications: NotificationSetting;
    donations: NotificationSetting;
    events: NotificationSetting;
    newsfeed: NotificationSetting;
    program: NotificationSetting;
  };
  push_notifications_settings?: {
    enable_notifications: NotificationSetting;
    donations: NotificationSetting;
    events: NotificationSetting;
    messaging: NotificationSetting;
    newsfeed: NotificationSetting;
    program: NotificationSetting;
  };
  dexcom: {
    synced: boolean;
  };
  time_zone: string;
  time_zone_changed: boolean | null;
  street?: string;
  apt?: string;
  city?: string;
  zip?: string;
  country?: string;
  state?: string;
  birthdate?: string;
  education_level?: EducationLevelDataObject;
  gender?: GenderDataObject;
  company?: string;
  profession?: ProfessionDataObject;
  interests?: InterestDataObject[];
  biography?: string;
};
/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export class AutocompleteUser {
  id: RailsId = '';
  name = '';
  first_name = '';
  last_name = '';
  email = '';
  profile_photo?: { id: string; url: string };

  description = '';
  html_description = '';
  superadmin?: boolean;
  can_create_mod_virtual_visit?: boolean;
  has_valid_mod_access_token?: boolean;
  joined_organizations: Entity[] = [];
  admin_organizations: string[] = [];
  admin_communities: string[] = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

export class BaseUser extends Entity {
  firstName: string;
  lastName: string;
  email: string;
  profilePhoto?: ImageDataObject;

  constructor(data: BaseUserDataObject) {
    super(data);
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email;
    this.profilePhoto = data.profile_photo;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export class ProfileConsent {
  id: string;
  consented: boolean;

  constructor(data: ProfileConsentDataObject) {
    this.id = data.id;
    this.consented = data.consented;
  }
}

// TODO add missing properties when needed
export class Profile extends BaseUser {
  requiredConsents: ProfileConsent[];
  usersConsents: ProfileConsent[];
  joinedOrganizations: OrganizationListItem[];
  joinedCommunities: CommunityListItem[];
  phone?: string;
  timeZone?: string;
  birthDate?: string;

  constructor(data: ProfileDataObject) {
    super(data);
    this.requiredConsents = data.required_consents.map((consent) => new ProfileConsent(consent));
    this.usersConsents = data.users_consents.map((consent) => new ProfileConsent(consent));
    this.joinedOrganizations = data.joined_organizations.map((organization) => new OrganizationListItem(organization));
    this.joinedCommunities = data.joined_communities.map((community) => new CommunityListItem(community));
    this.phone = data.phone;
    this.timeZone = data.time_zone;
    this.birthDate = data.birthdate;
  }
}

export class NodeUser {
  id?: RailsId;
  _id: MongoId;
  firstName: string;
  lastName: string;

  constructor(data: NodeUserDataObject) {
    this.id = data.meta?.externalIds?.[1];
    this._id = data._id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get photo() {
    return `${process.env.REACT_APP_MEDIA_MODULE_URL}/media/resource/medium/user_avatar/${this._id}`;
  }
}

export const hasMembershipInSubject = ({
  viewer,
  subjectType,
  subjectId,
}: {
  viewer: ReduxUser;
  subjectType: MembershipSubjectType;
  subjectId: string;
}): boolean =>
  viewer.memberships.some(
    (membership) => membership.subject_type === subjectType && membership.subject_id === subjectId,
  );
