import { nodeApiClient, type NodeApiClientResponse, nodeApiRoutes } from 'common/services';
import { type PaginatedDataObjects, type ReleaseNotesDataObject } from 'models/service';

type ReleaseNotesRequest = {
  $limit: number;
  $skip?: number;
  platform: 'android' | 'fe' | 'ios';
};

export const getReleaseNotes = async (params: ReleaseNotesRequest) => {
  return nodeApiClient.get<
    PaginatedDataObjects<ReleaseNotesDataObject>,
    NodeApiClientResponse<PaginatedDataObjects<ReleaseNotesDataObject>>
  >(nodeApiRoutes.releaseNotes.find, { params });
};
