import { useState, useEffect } from 'react';

import { Activity } from 'models/activity';
import Program from 'models/program';
import { message, Modal } from 'ui';
import { ActivityForm } from '../form';
import api from './../services/api';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void>;
  program: Program;
  onRequestReturn?: () => void;
};

const CreateActivity = ({ onSuccess, onCancel, isOpened, program, onRequestReturn }: P) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [programData, setProgramData] = useState<Program>(new Program());
  const [activityData, setActivityData] = useState<Activity>(new Activity());
  const [step, setStep] = useState(0);

  const fetchData = () => {
    (() => {
      if (isOpened) {
        setProgramData(program);
        setActivityData(new Activity());
      }
    })();
  };

  const onSubmit = async (activity: Activity) => {
    setLoading(true);

    const activityResponse = await api.createActivity(activity);

    if (activityResponse.ok) {
      await onSuccess();
      message.success('Activity was created.');
    } else {
      message.error('Failed to create activity.');
    }

    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [isOpened]);

  return (
    <Modal
      title={'Add New Activity'}
      isOpened={isOpened}
      onCancel={onCancel}
      onBackClick={onRequestReturn}
      disableBack={!onRequestReturn || step > 0}
    >
      <ActivityForm
        program={programData}
        activity={activityData}
        onSubmit={onSubmit}
        loading={loading}
        onChangeStep={setStep}
      />
    </Modal>
  );
};

export default CreateActivity;
