import { getAppConfig } from 'old/atoms/app-config-gate';
import { Form, Title } from 'ui';
import { Separator, SocialButtons } from '../../../components';
import { type PrimaryOrganizationOption } from '../PrimaryOrganizationSelect';
import { SignUpForm, type SignUpFormValues } from './SignUpForm';

type P = {
  onSuccessLogin: () => void;
  onSubmit: (values: SignUpFormValues) => void;
  loading: boolean;
  initialOrganization?: PrimaryOrganizationOption;
  changeRequestId?: string;
  invitationToken?: string;
};

const SignUpUser = ({
  onSuccessLogin,
  onSubmit,
  loading,
  initialOrganization,
  changeRequestId,
  invitationToken,
}: P) => {
  const [form] = Form.useForm<SignUpFormValues>();

  const { constants } = getAppConfig();
  const hasAnySocialLogin = Boolean(constants.GOOGLE_ID);

  return (
    <>
      <Title level={1}>Sign up</Title>
      <SignUpForm
        form={form}
        onSubmit={onSubmit}
        loading={loading}
        initialOrganization={initialOrganization}
        changeRequestId={changeRequestId}
        invitationToken={invitationToken}
      />
      {hasAnySocialLogin ? (
        <>
          <Separator text="or" />
          <SocialButtons
            onSuccessLogin={onSuccessLogin}
            getOrganizationId={() => form.getFieldsValue()?.organization?.value}
          />
        </>
      ) : null}
    </>
  );
};

export default SignUpUser;
