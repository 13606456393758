import { Button, Form, Title } from 'ui';
import { type PrimaryOrganizationOption, PrimaryOrganizationSelect } from '../PrimaryOrganizationSelect';

type P = {
  onNext: (organization: PrimaryOrganizationOption) => void;
};

type FormValues = {
  organization: PrimaryOrganizationOption;
};

const SignUpOrganization = ({ onNext }: P) => {
  const [form] = Form.useForm<FormValues>();

  const handleSubmit = (values: FormValues) => {
    onNext(values.organization);
  };

  return (
    <Form<FormValues> form={form} onFinish={handleSubmit}>
      <Title level={1}>Sign up</Title>
      <Form.Item name="organization" label="Select your organization" rules={[{ required: true }]}>
        <PrimaryOrganizationSelect />
      </Form.Item>
      <Button block type="primary" size="large" htmlType="submit">
        Next
      </Button>
    </Form>
  );
};

export default SignUpOrganization;
