import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ChangeRequestDataObject } from 'models/service';

type GetChangeRequestParams = {
  changeRequestId: string;
  invitationToken: string;
};

export const getChangeRequest = async ({ changeRequestId, invitationToken }: GetChangeRequestParams) =>
  /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
  apiClient.get<ChangeRequestDataObject, ApiClientResponse<ChangeRequestDataObject>>(
    routes.changeRequests.get(changeRequestId),
    {
      params: { invitation_token: invitationToken },
    },
  );
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
