import { type EventSlim, getEventSlimData } from 'models';
import { useInfiniteQuery, type QueryFunctionContext, type QueryKey } from 'query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { getEvents } from '../services';
import { getEventsInfiniteQueryKey } from './';

type P = {
  pageSize: number;
  scope?: 'channels';
  organizationId?: string;
  featured?: boolean;
};

export const useEventsInfiniteQuery = ({ pageSize, scope, organizationId, featured }: P) =>
  useInfiniteQuery<EventSlim[], ApiClientResponseError<DefaultResponseErrorDataObject>>(
    getEventsInfiniteQueryKey({ pageSize, organizationId, featured, scope }),
    async ({ pageParam: cursor = undefined }: QueryFunctionContext<QueryKey, string>) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const response = await getEvents({
        cursor,
        limit: pageSize,
        organization_id: organizationId,
        featured,
        scope,
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return response.data.map((event) => getEventSlimData(event));
    },
    {
      getNextPageParam: (lastPage) => (lastPage.length < pageSize ? undefined : lastPage[lastPage.length - 1]?.id),
    },
  );
