import { Button, CommentIcon } from 'ui';

type P = {
  onShowComments?: () => void;
};

const CommentButton = ({ onShowComments }: P) => {
  return (
    <Button type="text" icon={<CommentIcon />} onClick={onShowComments} size="large">
      Comment
    </Button>
  );
};

export default CommentButton;
