import { type UploadedFileDataObject } from 'models/service';
import { AttachmentMenu } from './AttachmentMenu';

type P = {
  className?: string;
  value?: { loading: boolean; files: UploadedFileDataObject[] };
  onChange?: (value: { loading: boolean; files: UploadedFileDataObject[] }) => void;
};

const AttachmentWrapper = ({ className, value, onChange }: P) => {
  return (
    <AttachmentMenu
      className={className}
      onStartUpload={() => {
        onChange?.({
          loading: true,
          files: value ? value.files : [],
        });
      }}
      onUpload={(files) => {
        onChange?.({
          loading: false,
          files: value ? [...value.files, ...files] : files,
        });
      }}
    />
  );
};

export default AttachmentWrapper;
