import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import variables from 'common/styles/variables.json';
import { Button, Card, Col, Form, ImageInputDeprecated, NumberInput, RichTextInput, Row, TextInput } from 'ui';

type P = {
  name: string;
};

const ProgramSponsors = ({ name }: P) => {
  const { t } = useTranslation();

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <Form.Item key={field.key}>
                <Card
                  title={
                    <Row item={{ gutter: variables.spaceMd.value }}>
                      <Col item={{ span: 11 }}>
                        <TextInput
                          item={{
                            ...field,
                            name: [field.name, 'name'],
                            label: 'Sponsor name',
                            rules: [{ required: true, message: t('Sponsor name is required'), whitespace: true }],
                          }}
                          input={{
                            placeholder: t('Sponsor name'),
                            id: `program_sponsor-name[${field.key}]`,
                          }}
                        />
                      </Col>
                      <Col item={{ span: 11 }}>
                        <NumberInput
                          item={{
                            ...field,
                            name: [field.name, 'price'],
                            label: 'Sponsored price',
                            rules: [{ required: true, message: t('Sponsored price is required') }],
                          }}
                          input={{
                            min: 0,
                            placeholder: '20.00',
                            'data-test-id': 'program_sponsor-price',
                          }}
                        />
                      </Col>
                    </Row>
                  }
                  style={{ marginBottom: 16 }}
                  extra={
                    <Button
                      type="text"
                      size="large"
                      onClick={() => {
                        remove(field.name);
                      }}
                      data-test-id="btn_delete-sponsor"
                      icon={<MinusCircleOutlined />}
                    />
                  }
                >
                  <Col item={{ span: 12, className: 'sponsors-logo' }}>
                    <ImageInputDeprecated
                      item={{
                        ...field,
                        name: [field.name, 'logo'],
                        label: 'Upload sponsor logo',
                      }}
                      aspectRatio={{ width: 500, height: 500 }}
                      input={{
                        id: `program_sponsor-logo[${field.key}]`,
                      }}
                    />
                  </Col>
                  <RichTextInput
                    text={{ name: [field.name, 'description'] }}
                    html={{
                      ...field,
                      name: [field.name, 'html_description'],
                      label: 'Description',
                      maxWidth: '594px',
                    }}
                  />
                </Card>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                data-test-id="btn_add-sponsor"
                size="large"
              >
                <PlusOutlined /> {t('Add Sponsor')}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default ProgramSponsors;
