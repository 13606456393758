import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { type UploadedFileDataObject } from 'models/service';
import { editComment } from '../../services';

export const useEditCommentMutation = () => {
  return useMutation<
    // TODO: this is not returning `Comment` but direct `response.data` - DataObject
    unknown,
    {
      id: string;
      parentId: string;
      textContent?: string;
      htmlContent?: string;
      attachments?: UploadedFileDataObject[];
    }
  >(
    async ({ id, attachments, textContent, htmlContent, parentId }) => {
      const response = await editComment({
        /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
        content_object: {
          id,
          parent_id: parentId,
          html_content: htmlContent,
          text_content: textContent,
          attachments,
        },
        /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      });

      return response.data;
    },
    {
      onSuccess: async (data) => dispatch.feed.updateComment({ responseData: data }), // TODO remove this from redux
    },
  );
};
