import { type ContentObjectRedux, type ReduxUser } from 'models';
import { useTranslation } from 'react-i18next';
import { copyLinkToClipBoard, type SocialName } from 'common/utils';
import { canShareContentObjectRedux } from 'permissions';
import { Button, Dropdown, ShareIcon, LinkedInIcon, FacebookIcon, XIcon, LinkLine, message, MailIcon } from 'ui';
import { useOnShareSocial } from './hooks';

type P = {
  item: ContentObjectRedux;
  viewer: ReduxUser;
};

const ShareButton = ({ item, viewer }: P) => {
  const onShareSocial = useOnShareSocial();
  const { t } = useTranslation();

  const generateItems = () => {
    const actions = [
      {
        label: t('Copy link'),
        key: 'link',
        icon: <LinkLine width={16} height={16} />, // TODO some size prop should handle icon size
      },
    ];

    if (canShareContentObjectRedux({ viewer, contentObject: item })) {
      actions.push(
        {
          label: t('Share via email'),
          key: 'email',
          icon: <MailIcon width={16} height={16} />, // TODO some size prop should handle icon size
        },
        {
          label: t('Share on LinkedIn'),
          key: 'linkedin',
          icon: <LinkedInIcon width={16} height={16} />, // TODO some size prop should handle icon size
        },
        {
          label: t('Share on Facebook'),
          key: 'facebook',
          icon: <FacebookIcon width={16} height={16} />, // TODO some size prop should handle icon size
        },
        {
          label: t('Share on X'),
          key: 'x',
          icon: <XIcon width={16} height={16} />, // TODO some size prop should handle icon size
        },
      );
    }

    return actions;
  };

  const copyLink = async () => {
    try {
      await copyLinkToClipBoard(item);
      message.success('Link copied to clipboard');
    } catch {
      message.error('Sorry, something went wrong.');
    }
  };

  const onClick = ({ key }: { key: string }): Promise<void> | void => {
    if (key === 'link') {
      return copyLink();
    }

    onShareSocial(key as SocialName, item);
  };

  return (
    <div className="share-button">
      <Dropdown
        menu={{
          items: generateItems(),
          onClick,
        }}
        trigger={['click']}
      >
        <Button type="text" icon={<ShareIcon />} size="large">
          Share
        </Button>
      </Dropdown>
    </div>
  );
};

export default ShareButton;
