import { Title, Button, Row, Col, AppLogo } from 'ui';

export const PageConferenceEnded = () => {
  const params = new URLSearchParams(window.location.search);
  const url = params.get('url');

  return (
    <Row item={{ align: 'middle', justify: 'center', className: 'ended-conference-wrapper' }}>
      <Col item={{ xs: { span: 24 }, md: { span: 16 }, lg: { span: 12 }, className: 'ended-conference' }}>
        <Col item={{ span: 24, className: 'ended-conference__logo' }}>
          <AppLogo />
        </Col>
        <Col item={{ span: 24, className: 'ended-conference__body' }}>
          <Title level={4}>Your video conference has ended. Click on the button below to go back to the app.</Title>
          {url ? (
            <Button href={atob(url)} type="primary" size="large">
              Back to app
            </Button>
          ) : null}
        </Col>
      </Col>
    </Row>
  );
};
