import { apiClient, type ApiClientResponse, routes } from 'common/services';

type CreateVideoRequestData = {
  video: {
    title: string;
    contentable_type: 'Organization';
    contentable_id: string;
    text_content: string;
    html_content: string;
    recorded_at: string;
    // TODO: reference type
    cover_photo?: { id: string };
    price_option: string;
  };
};

export const createVideo = async (data: CreateVideoRequestData) =>
  apiClient.post<unknown, ApiClientResponse<unknown>>(routes.videos.create, data);
