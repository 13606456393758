import { useMutation } from 'query';
import { createStripeDonation } from '../../services/createStripeDonation';
import { getStripePaymentData, type StripePayment } from '../../types';

export const useStripeDonation = () =>
  useMutation<
    StripePayment,
    {
      recaptchaKey: string;
      donationAmount: string;
      campaignId: string;
      priceId: string;
      payerInformation: {
        firstName: string;
        lastName: string;
        email: string;
        street: string;
        apt?: string;
        city: string;
        zip: string;
        country: string;
        state?: string;
      };
    }
  >(async ({ recaptchaKey, donationAmount, payerInformation, campaignId, priceId }) => {
    /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
    const response = await createStripeDonation({
      donation: {
        campaign_id: campaignId,
        price_id: priceId,
        recaptcha_token: recaptchaKey,
        donation: {
          amount: donationAmount,
        },
        payer: {
          first_name: payerInformation.firstName,
          last_name: payerInformation.lastName,
          email: payerInformation.email,
          street: payerInformation.street,
          apt: payerInformation.apt,
          city: payerInformation.city,
          zip: payerInformation.zip,
          country: payerInformation.country,
          state: payerInformation.state,
        },
      },
    });
    /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

    return getStripePaymentData(response.data);
  });
