import { select } from '@rematch/select';
import { SocketConnection, type Message, useSocketIO, events } from 'chat-web';
import { useCallback } from 'react';
import { connect } from 'react-redux';

type P = {
  nodeApiUser: any;
  updateUnreadCount: (unreadCount?: number) => any;
  unreadMessages: number;
};

const Connection = ({ updateUnreadCount, unreadMessages, nodeApiUser }: P) => {
  const handleMessage = useCallback(
    (message: Message) => {
      if (message.type === 'message') {
        return message.author === nodeApiUser.result?._id ? null : updateUnreadCount(unreadMessages + 1);
      }

      return null;
    },
    [nodeApiUser?.result?._id, unreadMessages, updateUnreadCount],
  );

  useSocketIO<Message>({
    event: events.chatroom.messages.created,
    onEvent: handleMessage,
  });

  if (!nodeApiUser?.result?.token) {
    return null;
  }

  return <SocketConnection currentLoginData={{ result: nodeApiUser?.result?.token, error: null }} />;
};

// TODO: Use correct types for select
const mapState = (state: any) => ({
  // @ts-expect-error
  nodeApiUser: select.session.nodeAPIUser(state),
  // @ts-expect-error
  unreadMessages: select.session.unreadMessages(state),
});

const mapDispatch = (dispatch: any) => ({
  updateUnreadCount: (unreadCount?: number) => dispatch.session.updateChatroomStats(unreadCount),
});

export const ConnectionSocket = connect(mapState, mapDispatch)(Connection);
