import { useTranslation } from 'react-i18next';
import { Text, Tooltip } from 'ui';

type P = {
  createdAt: Date;
};

const CommentAge = ({ createdAt }: P) => {
  const { t } = useTranslation();

  return (
    <Tooltip item={{ title: t('dateFormatShortMonthDayYearTime', { date: createdAt }) }}>
      <Text type="secondary" size="small">
        {t('commentAge', { date: createdAt })}
      </Text>
    </Tooltip>
  );
};

export default CommentAge;
