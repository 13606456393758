import { useQueryClient } from '@tanstack/react-query';

import { CommunityNotificationSettings } from 'models';

import { useMutation } from 'query';
import { getCommunityNotificationsKey } from '..';
import { type CommunityNotificationSettingsFormValues as CommunityNotificationSettingsValues } from '../../components';
import { updateNotificationsSettings, type UpdateNotificationsSettingsRequest } from '../../services';

export const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation<CommunityNotificationSettings, { id: string; values: CommunityNotificationSettingsValues }>(
    async ({ id, values }) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const data: UpdateNotificationsSettingsRequest = {
        email_notifications_settings: {
          newsfeed: values.emailNotificationsSettings?.newsfeed,
          events: values.emailNotificationsSettings?.events,
          program: values.emailNotificationsSettings?.program,
          donations: values.emailNotificationsSettings?.donations,
        },
        push_notifications_settings: {
          newsfeed: values.pushNotificationsSettings?.newsfeed,
          events: values.pushNotificationsSettings?.events,
          program: values.pushNotificationsSettings?.program,
          donations: values.pushNotificationsSettings?.donations,
        },
      };
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      data.email_notifications_settings.enable_notifications = Object.values(data.email_notifications_settings).some(
        Boolean,
      );
      data.push_notifications_settings.enable_notifications = Object.values(data.email_notifications_settings).some(
        Boolean,
      );

      const communityResponse = await updateNotificationsSettings({
        id,
        data,
      });

      return new CommunityNotificationSettings(communityResponse.data);
    },
    {
      onSuccess(data, input) {
        queryClient.setQueryData(getCommunityNotificationsKey({ id: input.id }), data);
      },
    },
  );
};
