import { useTranslation } from 'react-i18next';
import { Modal, ModalFormActions, Paragraph, Space } from 'ui';

type P = {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const TelehealthRequestNoticeModal = ({ isOpened, onClose, onSubmit }: P) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={'Important notice'}
      isOpened={isOpened}
      onCancel={() => {
        onClose();
      }}
      disableBack
      destroyOnClose
    >
      <Space direction="vertical" size="large">
        <Paragraph type="danger">
          If you are experiencing a medical emergency or life-threatening medical condition, do NOT continue to request
          a virtual visit.
        </Paragraph>
        <Paragraph>
          These symptoms include difficulty breathing or shortness of breath, chest pain or discomfort, dizziness,
          confusion, severe or persistent vomiting, and/or flu-like symptoms that improve then worsen again. Instead,
          immediately call 911 or go to your nearest emergency department.
        </Paragraph>
        <Paragraph>
          {`By clicking “I understand" you are acknowledging that you are not experiencing a medical emergency.`}
        </Paragraph>
      </Space>
      <ModalFormActions
        left={{ onClick: onClose, children: t('Back') }}
        submit={{
          children: t('I understand'),
          onClick() {
            onSubmit();
          },
        }}
      />
    </Modal>
  );
};

export default TelehealthRequestNoticeModal;
