import { CommunityListItem, type PaginationData } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { type CommunityListItemDataObject } from 'models/service';
import { getCommunities } from '../services';
import { type CommunitiesScope } from '../types';
import { getCommunitiesInfiniteQueryKey } from '.';

type P = {
  scope?: CommunitiesScope;
  pageSize: number;
  organizationId?: string;
  featured?: boolean;
  search?: string;
};

export const useCommunitiesInfiniteQuery = ({ scope, pageSize, organizationId, featured, search }: P) => {
  return useInfiniteQuery<PaginationData<CommunityListItem>>(
    getCommunitiesInfiniteQueryKey({ scope, pageSize, organizationId, featured, search }),
    async ({ pageParam: cursor = undefined }: QueryFunctionContext<QueryKey, string>) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const response = await getCommunities({
        scope,
        limit: pageSize,
        cursor,
        organization_id: organizationId,
        featured,
        view: 'table',
        search,
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return {
        data: response.data.data.map((community: CommunityListItemDataObject) => new CommunityListItem(community)),
        total: response.data.total,
      };
    },
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * pageSize >= lastPage.total) {
          return undefined;
        }

        return lastPage.data[lastPage.data.length - 1]?.id;
      },
    },
  );
};
