import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Tabs } from 'ui';

type P = {
  isPublicView: boolean;
  communityId: string;
  active: CommunityTabItem;
  showMembers?: boolean;
};

export type CommunityTabItem = '/about' | '/assets' | '/events' | '/feed' | '/members';

export const mapCommunityTabToPathName = (communityId: string, key: CommunityTabItem) => {
  /* eslint-disable @typescript-eslint/naming-convention -- Start: These are url paths */
  return {
    '/feed': `/communities/${communityId}/feed`,
    '/events': `/communities/${communityId}/events`,
    '/members': `/communities/${communityId}/members`,
    '/assets': `/communities/${communityId}/assets`,
    '/about': `/communities/${communityId}/about`,
  }[key];
};
/* eslint-enable @typescript-eslint/naming-convention -- End: These are url paths */

const CommunityTabs = ({ isPublicView, communityId, active, showMembers = true }: P) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const onTabClick = useCallback(
    (key: string) => {
      push(mapCommunityTabToPathName(communityId, key as CommunityTabItem));
    },
    [communityId, push],
  );

  return (
    <Tabs onTabClick={onTabClick} activeKey={active} className="community-tabs" animated={{ inkBar: false }}>
      {!isPublicView && (
        <>
          <Tabs.TabPane key="/feed" tab={t('News')} />
          <Tabs.TabPane key="/events" tab={t('Events')} />
          {showMembers ? <Tabs.TabPane key="/members" tab={t('Members')} /> : null}
          <Tabs.TabPane key="/assets" tab={t('Files')} />
        </>
      )}
      <Tabs.TabPane key="/about" tab={t('About')} />
    </Tabs>
  );
};

export default CommunityTabs;
