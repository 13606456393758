// TODO fix imports(ui component should not be dependent on anything from outside ui)
// TODO there is already FileIcon.tsx in `feed/components`
import { type FileAsset, type FolderAsset, FileType, FolderType } from 'models';
import { DefaultFileIcon, DocumentFileIcon, FolderIcon, ImgFileIcon, PdfFileIcon, SheetFileIcon } from 'ui/Icons';

type P = {
  asset: FileAsset | FolderAsset;
};

const FileIcon = ({ asset }: P) => {
  if (asset.type === FolderType) {
    return <FolderIcon size="middle" />;
  }

  if (asset.type === FileType) {
    if (asset.contentType.includes('image')) {
      return <ImgFileIcon size="middle" />;
    }

    if (asset.contentType.includes('pdf')) {
      return <PdfFileIcon size="middle" />;
    }

    if (asset.contentType.includes('spreadsheetml')) {
      return <SheetFileIcon size="middle" />;
    }

    if (['wordprocessingml', 'msword'].some((word) => asset.contentType.includes(word))) {
      return <DocumentFileIcon size="middle" />;
    }
  }

  return <DefaultFileIcon size="middle" />;
};

export default FileIcon;
