import { type ReduxUser, type EventType, type Tag } from 'models';
import {
  type UploadedFileDataObject,
  type BaseUserDataObject,
  type PostInCommunityDataObject,
  type ReplyDataObject,
} from './service';

export type ProgramRedux = {
  id: string;
  name: string;
  organization?: { id: string };
  settings: {
    allow_comments: boolean;
    allow_invitees: boolean;
    allow_posts: boolean;
    allow_unsubscribe: boolean;
    allowed_events_admin: EventType[];
    allowed_events_member: EventType[];
  };
};

type BaseContentObjectRedux = {
  // TODO these should be spreded into different ContentObject - `PostContentObject` / `KudoContentObject` / ...
  type: 'activity' | 'campaign' | 'comment' | 'event' | 'kudo' | 'poll' | 'post' | 'text' | 'withings_order_post';
  comments_count: number;
  id: string;
  // TODO: this is hack in Event Detail
  contentObjectId?: string;
  post_in_communities: PostInCommunityDataObject[];
  top_parent_type?: 'activity' | 'campaign' | 'event' | 'kudo' | 'poll' | 'post' | 'text' | 'withings_order_post';
  top_parent_shareable_id?: string;
  html_content?: string;
  text_content?: string;
  bookmarked?: boolean;
  replies?: ReplyDataObject[];
  parent_id?: string | null;
  attachments?: UploadedFileDataObject[];
  author: BaseUserDataObject;
  created_at: string;
  campaign?: {
    id: string;
    organization: {
      id: string;
    };
  };
  post?: {
    id: string;
  };
  kudo?: {
    id: string;
  };
  poll?: {
    id: string;
  };
  event?: {
    id: string;
  };
  withings_order_post?: {
    id: string;
  };
  activity?: {
    id: string;
  };
  tags?: Tag[];
  contentable_id?: string;
  contentable_type?: string;
};

export type ProgramContentObjectRedux = BaseContentObjectRedux & {
  program?: ProgramRedux;
  evaluation: number | null;
  evaluation_rating_average: string;
  contentable_id: string;
  contentable_type: 'Program';
};

export type BasicContentObjectRedux = BaseContentObjectRedux & {
  liked: boolean;
  likes_count: number;
};

export type ContentObjectRedux = BasicContentObjectRedux | ProgramContentObjectRedux;

export const isProgramContentObject = (
  contentObject: ContentObjectRedux,
): contentObject is ProgramContentObjectRedux => {
  return contentObject !== undefined && 'program' in contentObject && typeof contentObject.program === 'object';
};

export const isCommunityAdminInProgramContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject: ContentObjectRedux;
}) => {
  return Boolean(
    contentObject.contentable_type === 'Program' &&
      contentObject.contentable_id &&
      viewer.admin_programs.includes(contentObject.contentable_id),
  );
};

export const isOrganizationAdminInProgramContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject: ContentObjectRedux;
}) => {
  return Boolean(
    contentObject.contentable_type === 'Program' &&
      'program' in contentObject &&
      contentObject.program &&
      contentObject.program.organization &&
      viewer.admin_organizations.includes(contentObject.program.organization.id),
  );
};

export const isCommunityAdminInCommunityContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject: ContentObjectRedux;
}) => {
  return Boolean(
    contentObject.contentable_type === 'Community' &&
      contentObject.post_in_communities[0] &&
      viewer.admin_communities.includes(contentObject.post_in_communities[0].id),
  );
};

export const isOrganizationAdminInCommunityContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject: ContentObjectRedux;
}) => {
  return Boolean(
    contentObject.contentable_type === 'Community' &&
      contentObject.post_in_communities[0] &&
      viewer.admin_organizations.includes(contentObject.post_in_communities[0].id),
  );
};
