import { FileIcon } from 'features/feed/components/FileIcon';
import { type UploadedFileDataObject } from 'models/service';
import { Button, CloseLineIcon, Col, Row, Text } from 'ui';

type P = { attachment: UploadedFileDataObject; onRemove: (attachment: UploadedFileDataObject) => void }; // TODO data object?

const FileAttachment = ({ attachment, onRemove }: P) => {
  const { content_type: contentType, filename } = attachment;

  return (
    <Row
      item={{
        className: 'file-attachment__row',
        align: 'middle',
      }}
    >
      <Col>
        <FileIcon contentType={contentType} size="small" />
      </Col>

      <Col item={{ flex: '1 1 0', className: 'file-attachment__col--filename' }}>
        <Text ellipsis>{filename}</Text>
      </Col>

      <Col>
        <Button
          type="link-secondary"
          onClick={() => {
            onRemove(attachment);
          }}
          icon={<CloseLineIcon />}
        />
      </Col>
    </Row>
  );
};

export default FileAttachment;
