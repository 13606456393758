import { type ChangeRequest } from 'models';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import variables from 'common/styles/variables.json';
import { isPasswordValid } from 'common/validators';
import {
  CheckboxInput,
  Col,
  Form,
  PasswordInput,
  Row,
  Skeleton,
  TextInput,
  Text,
  Button,
  Section,
  Link,
  type FormInstance,
} from 'ui';
import { useChangeRequestsQuery } from '../../../../queries';
import { type PrimaryOrganizationOption, PrimaryOrganizationSelect } from '../../PrimaryOrganizationSelect';

type P = {
  form: FormInstance<SignUpFormValues>;
  onSubmit: (data: SignUpFormValues) => void;
  loading: boolean;
  initialOrganization?: PrimaryOrganizationOption;
  changeRequestId?: string;
  invitationToken?: string;
};

const getInitialValues = ({
  changeRequestData,
  initialOrganization,
}: {
  changeRequestData?: ChangeRequest;
  initialOrganization?: PrimaryOrganizationOption;
}): Partial<SignUpFormValues> => ({
  organization: initialOrganization,
  firstName: changeRequestData?.meta.firstName,
  lastName: changeRequestData?.meta.lastName,
  email: changeRequestData?.meta.invitationEmail,
});

export type SignUpFormValues = {
  organization?: PrimaryOrganizationOption;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const SignUpForm = ({ form, onSubmit, loading, initialOrganization, changeRequestId, invitationToken }: P) => {
  const { t } = useTranslation();

  const {
    data: changeRequestData,
    isLoading,
    isFetching,
  } = useChangeRequestsQuery({
    changeRequestId,
    invitationToken,
  });

  const initialValues = getInitialValues({ changeRequestData, initialOrganization });

  const onFinish = (data: SignUpFormValues) => {
    onSubmit(data);
  };

  return (
    <div className="sign-up-form">
      {isLoading && isFetching ? (
        <Skeleton active paragraph={{ rows: 8 }} className="sign-up-form__loading" />
      ) : (
        <Form<SignUpFormValues> onFinish={onFinish} form={form} initialValues={initialValues}>
          <Row item={{ gutter: variables.spaceMd.value }}>
            {initialOrganization ? (
              <Col item={{ span: 24 }}>
                <Form.Item name="organization" label="Select your organization" rules={[{ required: true }]}>
                  <PrimaryOrganizationSelect />
                </Form.Item>
              </Col>
            ) : null}
            <Col item={{ span: 12 }}>
              <TextInput
                item={{
                  name: 'firstName',
                  label: 'First name',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                    },
                  ],
                }}
                input={{
                  autoComplete: 'given-name',
                }}
              />
            </Col>
            <Col item={{ span: 12 }}>
              <TextInput
                item={{
                  name: 'lastName',
                  label: 'Last name',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                    },
                  ],
                }}
                input={{
                  autoComplete: 'family-name',
                }}
              />
            </Col>
          </Row>
          <TextInput
            item={{
              name: 'email',
              label: 'Email',
              rules: [
                {
                  type: 'email',
                },
                {
                  required: true,
                  whitespace: true,
                },
              ],
            }}
            input={{
              autoComplete: 'email',
            }}
          />
          <PasswordInput
            item={{
              name: 'password',
              label: 'Password',
              rules: [
                {
                  required: true,
                  whitespace: true,
                },
                () => ({
                  message: '',
                  validator: async (_, value) => (isPasswordValid(value) ? Promise.resolve() : Promise.reject()),
                }),
              ],
            }}
            showValidation
          />
          <CheckboxInput
            item={{
              label: (
                <Text>
                  {t('I agree with')}{' '}
                  <a href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">
                    {t('Terms')}
                  </a>{' '}
                  {t('and')}{' '}
                  <Link to={routes.privacyPolicy} target="_blank" rel="noopener noreferrer">
                    {t('Privacy Policy')}
                  </Link>
                </Text>
              ),
              name: 'termsAgreement',
              valuePropName: 'checked',
              rules: [
                {
                  message: t('Error: Accepting Terms & Conditions is required'),
                  validator: async (_, value) => (value ? Promise.resolve() : Promise.reject()),
                },
              ],
            }}
          />
          <Section paddingTop={false} lineTop={false} lineBottom={false}>
            <Button id="create-account" block loading={loading} htmlType="submit" type="primary" size="large">
              Sign up
            </Button>
          </Section>
        </Form>
      )}
    </div>
  );
};

export default SignUpForm;
