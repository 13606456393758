import { type EntityOptions, type CommunitySlim, type VideoSlim } from 'models';
import { type ReactNode } from 'react';
import variables from 'common/styles/variables.json';
import { type ProgramSlim } from 'features/programs/types';
import { Col, InfinityLoading, Row, Skeleton } from 'ui';
import { EntityCard } from '../EntityCard';

type Entity = CommunitySlim | ProgramSlim | VideoSlim;

type P = {
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
  empty: ReactNode;
  entities?: Entity[];
  isLoading: boolean; // TODO replace these query props with one query prop
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  showTag?: boolean;
  showPrice?: boolean;
};

const EntityList = ({
  getPathToEntityDetail,
  empty,
  entities,
  isLoading,
  isFetching,
  fetchNextPage,
  hasNextPage,
  showTag,
  showPrice,
}: P) => {
  return (
    <>
      {isLoading || !entities ? (
        <Skeleton active paragraph={{ rows: 12 }} />
      ) : entities.length === 0 ? (
        empty
      ) : (
        <>
          <Row item={{ gutter: [variables.spaceMd.value, variables.spaceLg.value] }}>
            {entities.map((entity) => (
              <Col item={{ span: 6 }} key={entity.id}>
                <EntityCard
                  getPathToEntityDetail={getPathToEntityDetail}
                  entity={entity}
                  showTag={showTag}
                  showPrice={showPrice}
                />
              </Col>
            ))}
          </Row>
          <InfinityLoading isLoading={isFetching} hasMoreData={hasNextPage} onEnter={fetchNextPage} />
        </>
      )}
    </>
  );
};

export default EntityList;
