import { createOrganizationBase, type OrganizationBase } from 'models';
import { useInfiniteQuery } from 'query';
import { getPublicOrganizations } from '../services';
import { getPublicOrganizationsInfiniteQueryKey } from '.';

// eslint-disable-next-line @typescript-eslint/naming-convention -- Keys are constant values
const PAGE_LIMIT = 10;

export const usePublicOrganizationsInfiniteQuery = ({
  filter,
  options,
}: {
  filter?: { search?: string; communityId?: string };
  options?: { enabled?: boolean };
}) =>
  useInfiniteQuery<OrganizationBase[]>(
    getPublicOrganizationsInfiniteQueryKey({ filter, pagination: { limit: PAGE_LIMIT } }),
    async ({ pageParam: offset }) => {
      const { data: organizations } = await getPublicOrganizations({
        /* eslint-disable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
        limit: PAGE_LIMIT,
        offset,
        search: filter?.search,
        community_id: filter?.communityId,
        /* eslint-enable @typescript-eslint/naming-convention -- key is defined by Backend API endpoint */
      });

      return organizations.map((organization) => createOrganizationBase(organization));
    },
    {
      getNextPageParam(lastPage, pages) {
        const totalFetched = pages.reduce((accumulator, page) => accumulator + page.length, 0);
        return lastPage.length < PAGE_LIMIT ? undefined : totalFetched;
      },
      useErrorBoundary: true,
      ...options,
    },
  );
