import { createVideoListItem, type VideoListItem, type PaginationData } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { type VideoListItemDataObject } from 'models/service';
import { getVideos } from '../services';
import { getVideosInfiniteQueryKey } from '.';

type P = {
  filter?: { search?: string };
  pagination: { pageSize: number };
};

export const useVideosInfiniteQuery = ({ filter, pagination }: P) =>
  useInfiniteQuery<PaginationData<VideoListItem>>(
    getVideosInfiniteQueryKey({ filter, pagination }),
    async ({ pageParam: cursor = undefined }: QueryFunctionContext<QueryKey, string>) => {
      const response = await getVideos({
        limit: pagination.pageSize,
        cursor,
        search: filter?.search,
      });

      return {
        data: response.data.data.map((videoData: VideoListItemDataObject) => createVideoListItem(videoData)),
        total: response.data.total,
      };
    },
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * pagination.pageSize >= lastPage.total) {
          return undefined;
        }

        return lastPage.data[lastPage.data.length - 1]?.id;
      },
    },
  );
