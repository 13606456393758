import { AutocompleteUser } from 'models';
import { useMutation } from 'query';

import { getCommunitiesUsers } from '../../services';

// TODO add `queryKey` maybe? this is more like `useQuery`
export const useCommunitiesUsersMutation = () => {
  return useMutation<
    AutocompleteUser[],
    {
      communitiesIds: string[];
      role?: 'admin' | 'member';
      limit?: number;
      search?: string;
    }
  >(async ({ communitiesIds, role, limit, search }) => {
    const usersResponse = await getCommunitiesUsers({
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      community_ids: communitiesIds,
      role,
      limit,
      search,
      /* eslint-enable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
    });

    return usersResponse.data.map((user: any) => new AutocompleteUser(user));
  }, {});
};
