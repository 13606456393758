import { parseISO } from 'date-fns';
import { getTagData, type ChannelTag } from './channel';
import { type Image } from './image';
import { type VideoDetailDataObject, type VideoListItemDataObject } from './service/video';

// TODO: this is only for now, will be changed when we have more data for video
export type VideoSlim = VideoListItem;

// TODO: Implement VideoBase with additional properties if needed
export type VideoBase = VideoSlim;

export type VideoListItem = {
  id: string;
  title: string;
  coverPhoto?: Image;
  recordedAt: Date;
  trailer?: {
    externalId: string;
  };
};

export const createVideoListItem = (data: VideoListItemDataObject): VideoListItem => {
  return {
    id: data.id,
    title: data.title,
    coverPhoto: data.cover_photo,
    recordedAt: parseISO(data.recorded_at),
    trailer: data.trailer ? { externalId: data.trailer.external_id } : undefined,
  };
};

export type VideoDetail = VideoListItem & {
  createdAt: Date;
  updatedAt: Date;
  textContent: string;
  htmlContent: string;
  priceOption: 'free' | 'paid';
  price: unknown;
  status: 'draft' | 'published';
  featuredInChannels: boolean;
  showInChannels: boolean;
  full?: { externalId: string };
  tags: ChannelTag[];
};

export const createVideoDetail = (data: VideoDetailDataObject): VideoDetail => ({
  ...createVideoListItem(data),
  createdAt: parseISO(data.created_at),
  updatedAt: parseISO(data.updated_at),
  textContent: data.text_content,
  htmlContent: data.html_content,
  priceOption: data.price_option,
  price: data.price,
  status: data.status,
  featuredInChannels: data.featured_in_channels,
  showInChannels: data.show_in_channels,
  full: data.full ? { externalId: data.full.external_id } : undefined,
  tags: data.tags.map((tag) => getTagData(tag)),
});
