import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type OauthTokenDataObject } from '../models/service';

type TokenLoginRequest = {
  login_token: string;
  login_token_type?: 'magic_token';
  grant_type: 'password';
};

export const loginWithToken = async (data: TokenLoginRequest) =>
  apiClient.post<OauthTokenDataObject, ApiClientResponse<OauthTokenDataObject>>(routes.oauth.token, data, {
    skipAuth: true,
  });
