import { apiClient, type ApiClientResponse } from 'common/services';
import deprecatedApiClient, {
  type ApiClientResponse as ApiClientResponseDeprecated,
} from 'common/services/deprecatedApi';
import { type Activity, type Log } from 'models/activity';
import { CategoryDeprecated } from 'models/category';
import Program from 'models/program';
import { AutocompleteUser } from 'models/user';

// TODO fix type
type ProgramRequest = any;
export type EditProgramRequest = ProgramRequest;
export type CreateProgramRequest = ProgramRequest;

const api = {
  async getProgram(program: string) {
    const response = await deprecatedApiClient.get<Program, ApiClientResponseDeprecated<Program>>(
      `/programs/${program}`,
    );

    if (response.ok && response.data) {
      response.data = new Program(response.data);
    }

    return response;
  },

  async getCategories(params: any) {
    const response = await deprecatedApiClient.get<any, ApiClientResponseDeprecated<any>>(`/categories`, { params });

    if (response.ok && response.data) {
      response.data = response.data.map((category: any) => new CategoryDeprecated(category));
    }

    return response;
  },

  async getMembers(params: any) {
    const response = await deprecatedApiClient.get<any, ApiClientResponseDeprecated<any>>(`/auto_completes/users`, {
      params,
    });

    if (response.ok && response.data) {
      response.data = response.data.map((user: any) => new AutocompleteUser(user));
    }

    return response;
  },

  async createProgram(program: CreateProgramRequest) {
    return deprecatedApiClient.post<any, ApiClientResponseDeprecated<any>>(`/programs`, { program });
  },

  async editProgram(id: string, program: EditProgramRequest) {
    return deprecatedApiClient.put<any, ApiClientResponseDeprecated<any>>(`/programs/${id}`, { program });
  },

  async createActivity(activity: Activity) {
    return deprecatedApiClient.post<any, ApiClientResponseDeprecated<any>>(`/activities`, { activity });
  },

  async updateActivity(activity: Activity) {
    return deprecatedApiClient.put<any, ApiClientResponseDeprecated<any>>(`/activities/${activity.id}`, { activity });
  },

  async markActivityAsCompleted(id: string) {
    return apiClient.post<unknown, ApiClientResponse<any>>(`/activities/${id}/complete`);
  },

  async downloadReport(id: string) {
    return deprecatedApiClient.get<any, ApiClientResponseDeprecated<any>>(`/activities/${id}/report.csv`, {
      responseType: 'blob',
    });
  },

  async createTaskLog(id: string, taskLogs: Log[]) {
    return deprecatedApiClient.post<any, ApiClientResponseDeprecated<any>>(`/activities/${id}/submissions`, taskLogs);
  },

  async postRating(id: string, value: number) {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- key is defined by Backend API endpoint
    const params = { content_object_id: id, rating: value };
    return deprecatedApiClient.post<any, ApiClientResponseDeprecated<any>>(`/evaluations`, params);
  },
};

export default api;
