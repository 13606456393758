import * as React from 'react';

import {
  EventRenderer,
  PostRenderer,
  CampaignRenderer,
  PollRenderer,
  ActivityRenderer,
  WithingsOrderPostRenderer,
} from '.';

import { withTranslation } from 'react-i18next';
import { Comment } from '../comment';
import { can } from 'old/utils/permissions';
import { routes } from 'routes';

function isObject(object) {
  return object != null && typeof object === 'object';
}

function deepEqual(object1, object2, maxDepth) {
  if (maxDepth < 0) {
    return true;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && val1 !== val2 && !deepEqual(val1, val2, maxDepth - 1)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export class Renderer extends React.Component {
  shouldComponentUpdate = (nextProps, nextState) => {
    return !deepEqual(nextProps, this.props, 7) || !deepEqual(nextState, this.state, 1);
  };

  state = {
    showComments: this.props.commentsOpened ?? false,
  };

  onBookmarkClick = () => {
    this.props.onBookmarkObject(this.props.item);
  };

  createPill = () => {
    const { item } = this.props;

    if (item.contentable_type === 'Program' && item.program) {
      return {
        name: item.program.name,
        link: can(item.program, 'Course', 'isMemberOfProgram')
          ? routes.program.timeline(item.program.id)
          : routes.program.about(item.program.id),
      };
    }

    if (item.post_in_communities.length > 0) {
      const firstCommunity = item.post_in_communities[0];
      return {
        name: firstCommunity.name,
        link: routes.community.feed(firstCommunity.id),
      };
    }
  };

  render() {
    const { item, isBookmark, isJournal, isOnFeed } = this.props;

    const authorName =
      item.author?.first_name || item.author?.last_name
        ? `${item.author.first_name ?? ''} ${item.author.last_name ?? ''}`.trim()
        : 'Unknown user';

    const showBookmarkBadge = !isBookmark && !isJournal;

    let bookmark = null;

    if (showBookmarkBadge) {
      bookmark = {
        isBookmarked: item.bookmarked,
        onBookmarkClick: () => this.props.onBookmarkObject(item),
      };
    }

    if (['post', 'kudo'].includes(item.type)) {
      return (
        <PostRenderer
          item={item}
          scrollToCommentId={this.props.scrollToCommentId}
          scrollToReplyId={this.props.scrollToReplyId}
          onCreateComment={this.props.onCreateComment}
          onCreateObject={this.props.onCreateObject}
          onDeleteObject={this.props.onDeleteObject}
          onEditObject={this.props.onEditObject}
          onInitiatePostEditing={this.props.onInitiatePostEditing}
          onLikeObject={this.props.onLikeObject}
          onShowComments={() => this.setState({ showComments: !this.state.showComments })}
          showComments={this.state.showComments}
          onReportObject={this.props.onReportObject}
          onSortChange={this.props.onSortChange}
          onBookmarkObject={this.props.onBookmarkObject}
          viewer={this.props.viewer}
          onReloadObject={this.props.onReloadObject}
          isJournal={isJournal}
          isBookmark={isBookmark}
          isOnFeed={isOnFeed}
          bookmark={bookmark}
          authorName={authorName}
          showBookmarkBadge={showBookmarkBadge}
          pill={this.createPill()}
        />
      );
    } else if (item.type === 'poll') {
      return (
        <PollRenderer
          item={item}
          scrollToCommentId={this.props.scrollToCommentId}
          scrollToReplyId={this.props.scrollToReplyId}
          onCreateComment={this.props.onCreateComment}
          onCreateObject={this.props.onCreateObject}
          onDeleteObject={this.props.onDeleteObject}
          onEditObject={this.props.onEditObject}
          onInitiatePostEditing={this.props.onInitiatePostEditing}
          onLikeObject={this.props.onLikeObject}
          onShowComments={() => this.setState({ showComments: !this.state.showComments })}
          showComments={this.state.showComments}
          onReportObject={this.props.onReportObject}
          onSortChange={this.props.onSortChange}
          onBookmarkObject={this.props.onBookmarkObject}
          viewer={this.props.viewer}
          onReloadObject={this.props.onReloadObject}
          isBookmark={isBookmark}
          isOnFeed={isOnFeed}
          bookmark={bookmark}
          authorName={authorName}
          showBookmarkBadge={showBookmarkBadge}
          pill={this.createPill()}
        />
      );
    } else if (item.type === 'event') {
      return (
        <EventRenderer
          item={item}
          scrollToCommentId={this.props.scrollToCommentId}
          scrollToReplyId={this.props.scrollToReplyId}
          onCreateComment={this.props.onCreateComment}
          onCreateObject={this.props.onCreateObject}
          onDeleteObject={this.props.onDeleteObject}
          onEditObject={this.props.onEditObject}
          onInitiatePostEditing={this.props.onInitiatePostEditing}
          onLikeObject={this.props.onLikeObject}
          onShowComments={() => this.setState({ showComments: !this.state.showComments })}
          showComments={this.state.showComments}
          onReportObject={this.props.onReportObject}
          onRequestEventDetail={this.props.onRequestEventDetail}
          onSortChange={this.props.onSortChange}
          onBookmarkObject={this.props.onBookmarkObject}
          viewer={this.props.viewer}
          onReloadObject={this.props.onReloadObject}
          isBookmark={isBookmark}
          bookmark={bookmark}
          authorName={authorName}
          pill={this.createPill()}
        />
      );
    } else if (item.type === 'campaign') {
      return (
        <CampaignRenderer
          item={item}
          onCreateComment={this.props.onCreateComment}
          onCreateObject={this.props.onCreateObject}
          onDeleteObject={this.props.onDeleteObject}
          onEditObject={this.props.onEditObject}
          onInitiatePostEditing={this.props.onInitiatePostEditing}
          onLikeObject={this.props.onLikeObject}
          onReportObject={this.props.onReportObject}
          onSortChange={this.props.onSortChange}
          onBookmarkObject={this.props.onBookmarkObject}
          viewer={this.props.viewer}
          isBookmark={isBookmark}
          bookmark={bookmark}
          authorName={authorName}
          pill={this.createPill()}
        />
      );
    } else if (item.type === 'activity') {
      return (
        <ActivityRenderer
          item={item}
          scrollToCommentId={this.props.scrollToCommentId}
          scrollToReplyId={this.props.scrollToReplyId}
          onCreateComment={this.props.onCreateComment}
          onCreateObject={this.props.onCreateObject}
          onDeleteObject={this.props.onDeleteObject}
          onEditObject={this.props.onEditObject}
          onInitiatePostEditing={this.props.onInitiatePostEditing}
          onLikeObject={this.props.onLikeObject}
          onShowComments={() => this.setState({ showComments: !this.state.showComments })}
          showComments={this.state.showComments}
          onReportObject={this.props.onReportObject}
          onSortChange={this.props.onSortChange}
          onReloadObject={this.props.onReloadObject}
          onBookmarkObject={this.props.onBookmarkObject}
          viewer={this.props.viewer}
          reloadingPost={this.props.reloadingPost}
          isBookmark={isBookmark}
          isOnFeed={isOnFeed}
          bookmark={bookmark}
          authorName={authorName}
          pill={this.createPill()}
        />
      );
    } else if (item.type === 'comment') {
      // TODO this is old comment in `Bookmark` need replace with new one
      return (
        <Comment
          index={0}
          comment={item}
          scrollToCommentId={this.props.scrollToCommentId}
          scrollToReplyId={this.props.scrollToReplyId}
          onCreateComment={this.props.onCreateComment}
          onDeleteObject={this.props.onDeleteObject}
          onEditObject={this.props.onEditObject}
          onLikeObject={this.props.onLikeObject}
          onReportObject={this.props.onReportObject}
          onBookmarkObject={this.props.onBookmarkObject}
          parent={this.props.parent}
          viewer={this.props.viewer}
          originalItem={this.props.originalItem}
          course={this.props.course}
          focused={true}
          isBookmark={isBookmark}
          bookmark={bookmark}
          authorName={authorName}
          pill={this.createPill()}
        />
      );
    } else if (item.type === 'withings_order_post') {
      return (
        <WithingsOrderPostRenderer
          item={item}
          scrollToCommentId={this.props.scrollToCommentId}
          scrollToReplyId={this.props.scrollToReplyId}
          onCreateComment={this.props.onCreateComment}
          onCreateObject={this.props.onCreateObject}
          onDeleteObject={this.props.onDeleteObject}
          onEditObject={this.props.onEditObject}
          onLikeObject={this.props.onLikeObject}
          onShowComments={() => this.setState({ showComments: !this.state.showComments })}
          showComments={this.state.showComments}
          onReportObject={this.props.onReportObject}
          onSortChange={this.props.onSortChange}
          onBookmarkObject={this.props.onBookmarkObject}
          viewer={this.props.viewer}
          onReloadObject={this.props.onReloadObject}
          isBookmark={isBookmark}
          isOnFeed={isOnFeed}
          bookmark={bookmark}
          authorName={authorName}
          pill={this.createPill()}
        />
      );
    } else {
      console.warn(`Cannot render content object of type '${item.type}'`);
      return null;
    }
  }
}

export const ContentObjectRenderer = withTranslation()(Renderer);
