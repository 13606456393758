import { type UserRSVP, type EventInviteeRole, type ReduxUser, type EventEmailInvitee } from 'models';
import { parseBigmarkerErrorMessage } from 'old/utils/big-marker';
import { useCallback } from 'react';
import { handleAxiosError } from 'common/error-handlers';
import { message } from 'ui';
import { useEnterBigMarker } from '../queries';
import { isEmailInvitee, isOpenToJoin } from '../utils';

// TODO this type should be replaced by some event type
type Event = {
  id: string;
  startTime: Date;
  endTime: Date;
  bmgId: string | null;
  currentUserRsvp: UserRSVP;
  currentUserRole: EventInviteeRole;
  emailInvitees?: EventEmailInvitee[];
};

export const useOnRequestEnterConference = () => {
  const { mutate: enterBigMarkerConference, isLoading: isJoining } = useEnterBigMarker();

  const onRequestEnterConference = useCallback(
    (event: Event, viewer: ReduxUser) => {
      if (isOpenToJoin(event)) {
        enterBigMarkerConference(
          {
            userIds: [viewer.id],
            eventId: event.id,
            exitUrl: isEmailInvitee({ viewer, event })
              ? `${window.location.origin}/conference-ended?url=${btoa(window.location.origin)}`
              : `${window.location.href}?feedback=true&eventId=${event.id}`,
          },
          {
            onSuccess(data) {
              window.open(data.enterUri, '_self');
            },
            onError(error) {
              handleAxiosError(error, parseBigmarkerErrorMessage(error.response?.data?.message ?? ''));
            },
          },
        );
      } else {
        message.warn('This video conference is already over');
      }
    },
    [enterBigMarkerConference],
  );

  return { onRequestEnterConference, isJoining };
};
