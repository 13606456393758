import { type AxiosError } from 'axios';
import { type AutocompleteUser, type InviteType } from 'models';
import { AxiosErrorHandler } from 'common/error-handlers';
import { type FormInstance, Skeleton } from 'ui';

import EventInvitees, { type InviteesType } from '../../../EventInvitees';

import { mapTypeToEmailFieldName, type CommonFormValues } from './hooks';

type P = {
  allowedInvites?: InviteesType[];
  form: FormInstance<CommonFormValues>;
  inviteType: InviteType;
  onInvite: (inviteType: InviteType, invitees: AutocompleteUser[], emailInvitees?: string[] | undefined) => void;
  isLoading: boolean;
  isFetching: boolean;
  isRefetching: boolean;
  data?: AutocompleteUser[];
  error?: AxiosError | null;
};

const InviteesPicker = ({
  inviteType,
  allowedInvites,
  onInvite,
  form,
  isLoading,
  isFetching,
  isRefetching,
  data,
  error,
}: P) => {
  const chosenRegistered: Array<{ id: string }> | undefined = form.getFieldValue(inviteType);
  const chosenEmails: Array<{ email: string }> | undefined = form.getFieldValue(mapTypeToEmailFieldName(inviteType));

  return (
    <AxiosErrorHandler error={error} actions={false}>
      {(isLoading && isFetching) || !data ? (
        <Skeleton active paragraph={{ rows: 15 }} />
      ) : (
        <EventInvitees
          isRefetching={isRefetching}
          inviteType={inviteType}
          allowedInvites={allowedInvites}
          onInvite={onInvite}
          allUsers={data}
          chosenRegistered={chosenRegistered?.map((invite) => invite.id)}
          chosenEmails={chosenEmails?.map((invite) => invite.email)}
        />
      )}
    </AxiosErrorHandler>
  );
};

export default InviteesPicker;
