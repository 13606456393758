import { type CampaignSlim, CommunitySlim, type EventSlim, getCampaignSlimData, getEventSlimData } from 'models';
import { getProgramSlimData, type ProgramSlim } from 'features/programs/types';
import {
  type ChannelProgramEntityDataObject,
  type ChannelBaseDataObject,
  type ChannelTagDataObject,
  type ChannelDetailDataObject,
  type ChannelCommunityEntityDataObject,
  type ChannelEventEntityDataObject,
  type ChannelCampaignEntityDataObject,
  type ChannelEntityDataObject,
} from './service';

// TODO: check how it's used in admin and if this is correct, if its different fix it
export type ChannelTag = {
  id: string;
  organizationId: string;
  name: string;
};

export type ChannelBase = {
  id: string;
  name: string;
  description: string;
  priority: number;
  active: boolean;
  channelTags: ChannelTag[];
};

export type ChannelDetail = ChannelBase & {
  allCount: number;
  programCount: number;
  communityCount: number;
  campaignCount: number;
  eventCount: number;
};

export const getChannelDetailData = (data: ChannelDetailDataObject): ChannelDetail => ({
  ...getChannelBaseData(data),
  allCount: data.all_count,
  programCount: data.program_count,
  communityCount: data.community_count,
  campaignCount: data.campaign_count,
  eventCount: data.event_count,
});

export type ChannelEntity = ChannelCampaignEntity | ChannelCommunityEntity | ChannelEventEntity | ChannelProgramEntity;

export type ChannelProgramEntity = {
  type: 'Program';
  program: ProgramSlim;
};

export type ChannelCommunityEntity = {
  type: 'Community';
  community: CommunitySlim;
};

export type ChannelEventEntity = {
  type: 'Event';
  event: EventSlim;
};

export type ChannelCampaignEntity = {
  type: 'Campaign';
  campaign: CampaignSlim;
};

// TODO: check how it's used in admin and if this is correct, if its different fix it
export const getTagData = (data: ChannelTagDataObject): ChannelTag => ({
  id: data.id,
  name: data.name,
  organizationId: data.organization_id,
});

export const getChannelBaseData = (data: ChannelBaseDataObject): ChannelBase => ({
  id: data.id,
  name: data.name,
  description: data.description,
  priority: data.priority,
  active: data.active,
  channelTags: data.channel_tags.map((tag) => getTagData(tag)),
});

const getChannelProgramEntity = (data: ChannelProgramEntityDataObject): ChannelProgramEntity => ({
  type: data.type,
  program: getProgramSlimData(data.program),
});

const getChannelCommunityEntity = (data: ChannelCommunityEntityDataObject): ChannelCommunityEntity => ({
  type: data.type,
  community: new CommunitySlim(data.community),
});

const getChannelEventEntity = (data: ChannelEventEntityDataObject): ChannelEventEntity => ({
  type: data.type,
  event: getEventSlimData(data.event),
});

const getChannelCampaignEntity = (data: ChannelCampaignEntityDataObject): ChannelCampaignEntity => ({
  type: data.type,
  campaign: getCampaignSlimData(data.campaign),
});

export const getChannelEntityData = (data: ChannelEntityDataObject): ChannelEntity => {
  switch (data.type) {
    case 'Program':
      return getChannelProgramEntity(data);
    case 'Community':
      return getChannelCommunityEntity(data);
    case 'Event':
      return getChannelEventEntity(data);
    case 'Campaign':
      return getChannelCampaignEntity(data);
  }
};

// TODO add event and campaigin to return type
export const getNestedEntity = (entity: ChannelEntity): CommunitySlim | ProgramSlim => {
  switch (entity.type) {
    case 'Program':
      return entity.program;
    case 'Community':
      return entity.community;
    case 'Event':
      return entity.event;
    case 'Campaign':
      return entity.campaign;
  }
};

export type EntityOptions =
  | { entityType: 'campaign'; organizationId: string }
  | { entityType: 'community' | 'event' | 'program' | 'video' };
