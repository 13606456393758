import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { type ReduxUser } from 'models/user';
import { Avatar, Col, HiddenLabel, Row, TextInput } from 'ui';

type P = {
  viewer: ReduxUser;
  onRequestInput: () => void;
};

const CreateCommentFormPlaceholder = ({ viewer, onRequestInput }: P) => {
  const { t } = useTranslation();

  return (
    <Row
      item={{
        className: 'create-comment-form-placeholder',
        gutter: [variables.spaceXs.value, variables.spaceXs.value],
      }}
    >
      <Col item={{ flex: '0 1 0' }}>
        <Avatar size="default" photo={viewer.profile_photo?.url ?? images.default.userProfilePhoto} />
      </Col>

      <Col
        item={{
          flex: '1 0 0',
        }}
      >
        <HiddenLabel htmlFor="comment">{t('Comment')}</HiddenLabel>
        <TextInput
          item={{
            className: 'create-comment-form-placeholder__input',
            name: 'comment',
          }}
          input={{
            placeholder: t('Write comment...'),
            onFocus() {
              onRequestInput();
            },
          }}
        />
      </Col>
    </Row>
  );
};

export default CreateCommentFormPlaceholder;
