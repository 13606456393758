import { type Pagination, CommunityListItem, type PaginationData } from 'models';

import { useQuery } from 'query';
import { type CommunityListItemDataObject } from 'models/service';
import { getCommunities } from '../services';
import { type CommunitiesScope } from '../types';
import { getCommunitiesQueryKey } from '.';

type P = {
  scope?: CommunitiesScope;
  organizationId?: string;
  featured?: boolean;
  search?: string;
  pagination?: Pagination;
};

export const useCommunitiesQuery = ({ scope, pagination, organizationId, featured, search }: P) => {
  return useQuery<PaginationData<CommunityListItem>>(
    getCommunitiesQueryKey({ scope, pagination, organizationId, featured, search }),
    async () => {
      const { pageSize, position } = pagination ?? {};

      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const response = await getCommunities({
        scope,
        limit: pageSize,
        offset: position !== undefined && pageSize !== undefined ? position * pageSize : undefined,
        organization_id: organizationId,
        featured,
        view: 'table',
        search,
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return {
        data: response.data.data.map((community: CommunityListItemDataObject) => new CommunityListItem(community)),
        total: response.data.total,
      };
    },
  );
};
