import { type UploadSuccessAction, type VideoType } from 'lib/media-upload-file';
import { useCallback } from 'react';
import { type FormInstance } from 'ui';
import { useUpdateMedia } from '../../../../queries';

type P = {
  initiateFileUpload: ({ file, onSuccessAction }: { file: File; onSuccessAction: UploadSuccessAction }) => void;
  form: FormInstance;
  formName: string;
  type: VideoType;
};

export const useStartUpload = ({ initiateFileUpload, form, formName, type }: P) => {
  const { mutate: updateMedia } = useUpdateMedia();

  return useCallback(
    ({ file }: { file: File }) => {
      initiateFileUpload({
        file,
        onSuccessAction({ id, ref }) {
          updateMedia({ id: ref, data: { video: { [type]: { externalId: id } } } });
        },
      });

      form.setFieldsValue({
        [formName]: [
          {
            uid: type,
            name: file.name,
            status: 'uploading',
            percent: 0,
          },
        ],
      });
    },
    [initiateFileUpload, updateMedia, form, formName, type],
  );
};
