import { apiClient, type ApiClientResponse, routes } from 'common/services';

type ReportRequestData = {
  abuse_report: {
    content_object_id: string;
  };
};

export const reportObject = async (data: ReportRequestData) =>
  apiClient.post<unknown, ApiClientResponse<unknown>>(routes.report, data);
