import { DropboxChooser } from 'old/atoms/dropbox-chooser';
// TODO: use new mutation
import { StorageService } from 'old/services/StorageService';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type UploadedFileDataObject } from 'models/service';
import { Dropdown, Button, AttachmentLineIcon, DropboxIcon, ComputerLineIcon } from 'ui';

type P = {
  className?: string;
  // TODO: maybe optimize
  onStartUpload: () => void;
  // TODO: this should contain info about progress
  onUpload: (files: UploadedFileDataObject[]) => void;
};

const AttachmentMenu = ({ className, onStartUpload, onUpload }: P) => {
  const { t } = useTranslation();
  // TODO: type constants
  const [openedKey, setOpenedKey] = useState<'dropbox'>();
  // TODO: Do not use local loading state
  const [loading, setLoading] = useState(false);

  const reference = useRef<HTMLInputElement>(null);

  const onLoadingUpdate = (uploading: boolean) => {
    if (uploading) {
      onStartUpload();
    }

    setLoading(uploading);
  };

  // TODO: replace any with suitable type
  const onSelectDropbox = async (data: any) => {
    onLoadingUpdate(true);

    const files = data.map((file: any) => ({
      name: file.name,
      url: file.link,
    }));

    const promises = files.map(async (file: any) => StorageService.send(file));

    const responses = await Promise.all(promises);
    onUpload(responses.map((r: any) => r.data));

    onLoadingUpdate(false);
  };

  const menu = () => [
    {
      label: t('Your Computer'),
      key: 'image-upload',
      onClick() {
        reference.current?.click();
      },
      icon: <ComputerLineIcon />,
    },
    {
      label: t('Dropbox'),
      key: 'dropbox',
      icon: <DropboxIcon />,
    },
  ];

  const onSelectKey = ({ key }: { key: string }) => {
    // eslint-disable-next-line sonarjs/no-small-switch -- This is enum, we want switch for it
    switch (key) {
      case 'dropbox':
        setOpenedKey('dropbox');
        break;
    }
  };

  return (
    <>
      <Dropdown
        menu={{
          items: menu(),
          onClick: onSelectKey,
        }}
        trigger={['click']}
      >
        <Button className={className} icon={<AttachmentLineIcon />} loading={loading} type="link-secondary" />
      </Dropdown>

      {/* TODO: create component maybe? */}
      <input
        ref={reference}
        type="file"
        // TODO: fix styling
        style={{ display: 'none' }}
        onChange={async (daco) => {
          onLoadingUpdate(true);

          const promises = [];
          if (daco.target.files) {
            for (let index = 0; index < daco.target.files.length; index++) {
              promises.push(StorageService.send(daco.target.files.item(index)));
            }
          }

          // TODO: show errors?
          const responses = await Promise.all(promises);
          onUpload(responses.filter((response) => response.ok).map((response) => response.data));

          onLoadingUpdate(false);
        }}
      />

      <DropboxChooser
        onSelect={onSelectDropbox}
        opened={openedKey === 'dropbox'}
        onCancel={() => {
          setOpenedKey(undefined);
        }}
      >
        {/* TODO: fix styling */}
        <div style={{ display: 'none' }} />
      </DropboxChooser>
    </>
  );
};

export default AttachmentMenu;
