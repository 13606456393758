import { type Moment } from 'moment';
import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type WithingsOrderPostDataObject } from 'models/service/content-object-deprecated';

export const createWithingsOrderPost = async ({
  programId,
  programTopicId,
  publishAt,
  textContent,
  htmlContent,
  title,
  todoDueDate,
}: {
  programId: string;
  programTopicId: string;
  publishAt: Moment;
  textContent: string;
  htmlContent: string;
  title?: string;
  todoDueDate?: Moment;
}) => {
  /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
  const data = {
    withings_order_post: {
      program_id: programId,
      program_topic_id: programTopicId,
      publish_at: publishAt,
      text_content: textContent,
      html_content: htmlContent,
      title,
      todo_due_date: todoDueDate,
    },
  };
  /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

  return apiClient.post<WithingsOrderPostDataObject, ApiClientResponse<WithingsOrderPostDataObject>>(
    routes.withings.createOrderPost,
    data,
  );
};
