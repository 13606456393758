import { message } from 'ui';

export { RichTextEditorProvider } from '@pba/react-rich-text-editor';

export const richTextEditorSettings = {
  onError: message.error,
  translations: {
    colors: {
      black: 'Black',
      blue: 'Blue',
      gray: 'Gray',
      green: 'Green',
      orange: 'Orange',
      red: 'Red',
    },
    errors: {
      fileAccessFailed: 'Error during accessing file',
      fileUploadFailed: 'Error during file upload',
      fileUploadAborted: 'Abort file upload',
      sourceValidationFailed:
        'We cannot allow images from unknown sources for security reasons. Please use the regular upload method to submit your images.',
    },
  },
};
