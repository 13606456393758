import classNames from 'classnames';

type SvgWrapperProperties = React.SVGProps<SVGSVGElement>;

export type IconSize = 'large' | 'middle' | 'small';

type IconType = 'error' | 'success';

type P = SvgWrapperProperties & { type?: IconType; spanClassName?: string; size?: IconSize };

const withAntIconWrapper = (SvgComponent: React.FunctionComponent<SvgWrapperProperties>) => {
  return ({ spanClassName, rotate, type, size, ...props }: P) => (
    <span
      className={classNames(spanClassName, 'appicon', 'anticon', {
        'appicon--success': type === 'success',
        'appicon--error': type === 'error',
        'appicon--small': size === 'small',
        'appicon--middle': size === 'middle',
        'appicon--large': size === 'large',
      })}
    >
      <SvgComponent style={{ transform: rotate ? `rotate(${rotate}deg)` : undefined }} {...props} />
    </span>
  );
};

export default withAntIconWrapper;
