import { Event, type CommunityListItem } from 'models';
import { type Moment } from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RichTextInput, Form, Section, ModalFormActions, message, Title } from 'ui';
import { useRequestVisit } from '../../queries';
import { TelehealthOptions } from '../EventFormModal/DataStepEventForm/TelehealthEventForm';

export type TelehealthRequestFormValues = {
  communityId: string;
  patientType: string;
  visitType: string;
  visitTypeSubOption: string;
  visitTypeSubSubOption?: string;
  startTime: Moment;
  duration: number;
  endTime: Moment;
  htmlContent: string;
  textContent: string;
};

type P = {
  communities: CommunityListItem[];
  communityId?: string;
  onSuccess: () => void;
};

const TelehealthRequestForm = ({ communityId, communities, onSuccess }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<TelehealthRequestFormValues>();
  const { mutate: requestVisit, isLoading: isRequestVisitLoading } = useRequestVisit();

  const initialValues = useMemo((): Partial<TelehealthRequestFormValues> => {
    const duration = 30;
    const startTime = Event.generateStartDate({});

    return {
      duration,
      startTime,
      endTime: Event.getEndDate({ startTime, duration }),
      htmlContent: '',
      textContent: '',
      communityId,
    };
  }, [communityId]);

  const onFinish = (data: TelehealthRequestFormValues) => {
    if (!data.communityId) {
      return null;
    }

    const selectedCommunity = communities.find((community) => community.id === data.communityId);
    if (selectedCommunity) {
      requestVisit(
        { data, selectedCommunity },
        {
          onSuccess() {
            onSuccess();
            message.success(t('Telehealth visit was requested'));
          },
        },
      );
    }

    return null;
  };

  return (
    <Form<TelehealthRequestFormValues>
      initialValues={initialValues}
      onFinish={onFinish}
      form={form}
      name="telehealth-request-form"
    >
      <Section paddingTop={false} paddingBottom={false}>
        <Title level={3}>Visit details</Title>
        <TelehealthOptions form={form} communities={communities} />
      </Section>

      <Section paddingBottom={false}>
        <RichTextInput
          text={{ name: 'textContent' }}
          html={{
            name: 'htmlContent',
            label: 'Why do you need consultation',
            maxWidth: '630px',
            'data-test-id': 'event_description',
            rules: [{ required: true, message: t('This field is required') }],
          }}
        />
      </Section>
      <ModalFormActions
        submit={{
          loading: isRequestVisitLoading,
          children: 'Request Visit',
          id: 'btn_request-telehealth',
        }}
      />
    </Form>
  );
};

export default TelehealthRequestForm;
