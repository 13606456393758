import { CaretRightOutlined } from '@ant-design/icons';
import { select } from '@rematch/select';
import { Collapse } from 'antd';
import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { ContactUs } from 'features/programs/contact-us';
import { CreateRequestToJoin } from 'features/programs/request-to-join';
import { Program } from 'models';
import { canSeeTags } from 'permissions';
import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Avatar, Button, Card, Col, ProfileCard, Row, ScrollToTop, TagList, Title, RichTextRenderer } from 'ui';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { Translation } from '../../atoms/translation';
import { AppLayout } from '../../layouts/AppLayout';
import { CourseLayout } from '../../layouts/CourseLayout';
import { can } from '../../utils/permissions';
import { getPriceProperties, getSponsorValue } from '../../utils/programs';
import { ChatToButton } from '../../widgets/chat-to-button';

const { Panel } = Collapse;

class Renderer extends React.Component {
  state = {
    learnMore: false,
    isCreateRequestToJoinOpened: false,
    isContactUsModalOpened: false,
  };
  descriptionRef = React.createRef();

  renderCourseDescription(course) {
    return (
      <Row item={{ ref: this.descriptionRef, gutter: [0, variables.spaceMd.value], className: 'course-description' }}>
        <Col item={{ span: 24 }}>
          <Title level={4}>Overview</Title>
        </Col>
        {course.html_content ? (
          <Col item={{ span: 24 }}>
            <RichTextRenderer data={course.html_content} />
            <Translation id={course.id} type={`program`} />
          </Col>
        ) : course.text_content ? (
          <Col item={{ span: 24 }}>
            <Text>{course.text_content}</Text>
          </Col>
        ) : (
          <Col item={{ span: 24 }}>
            <Text>No Description</Text>
          </Col>
        )}
        {!this.state.learnMore ? (
          <Col item={{ span: 24 }}>
            <Button onClick={() => this.setState({ learnMore: true })}>Learn More</Button>
          </Col>
        ) : null}
      </Row>
    );
  }

  renderCourseAuthor(course) {
    return (
      <Box className="card-wrapper card-wrapper--course-author">
        <Text size="20px" color="#263044" className="card-content__title" component="div">
          <Trans>Authored by</Trans>
        </Text>
        <Box flexDirection="row" className="course-author__body">
          <Box className="avatar-photo avatar-photo--authors">
            <Avatar photo={course.author_logo ?? images.default.userProfilePhoto} size={72} />
          </Box>
          <Box className="card-content">
            <Text size="20px" color="#263044" className="card-content__title">
              {course.author_name}
            </Text>
            {course.html_author_overview ? (
              <RichTextRenderer data={course.html_author_overview} />
            ) : (
              <Text>{course.author_overview_text}</Text>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  renderCourseSponsors(course, color) {
    return (
      <Box flexDirection="row" className="card-wrapper card-wrapper--participants">
        <Box className="card-content card-content--participants">
          <Text size="20px" color="#263044" className="card-content__title">
            <Trans>Sponsored by</Trans>
          </Text>
          {course.sponsors.map((sponsor) => (
            <Box key={sponsor.name} flexDirection="row" className="participant-box">
              <Box className="avatar-photo avatar-photo--instructors">
                <Avatar photo={sponsor.logo ?? images.default.userProfilePhoto} size={72} />
              </Box>
              <Box className="card-content">
                <Text size="16px" color={color.brand}>
                  {sponsor.name}
                </Text>
                <Text size="16px" color="#717E94" className="participant-box__quote">
                  <RichTextRenderer data={sponsor.html_description} />
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  renderEnroll(course) {
    return (
      <Box flexDirection="row">
        <Box className="card-content">
          <Text size="20px" color="#263044" className="card-content__title">
            <Trans>Who should enroll</Trans>
          </Text>
          <Text size="14px" color="#717E94">
            {course.who_should_enroll}
          </Text>
        </Box>
      </Box>
    );
  }

  renderExpectedOutcomes(course) {
    return (
      <Box flexDirection="row" className="card-wrapper card-wrapper--expected-outcomes">
        <Box className="card-content">
          <Text size="20px" color="#263044" className="card-content__title">
            <Trans>Expected outcomes</Trans>
          </Text>
          <ul className="outcomes-list">
            {course.expected_outcomes.map((outcome, index) => {
              return <li key={index}>{outcome}</li>;
            })}
          </ul>
        </Box>
      </Box>
    );
  }

  renderInstructors(course, brandColor) {
    return (
      <Box flexDirection="row">
        <Box className="card-content card-content--instructors">
          <Text size="20px" color="#263044" className="card-content__title">
            <Trans>Instructors</Trans>
          </Text>
          {course.instructors.map((instructor) => (
            <Box key={instructor.id} flexDirection="row" className="participant-box">
              <Box className="avatar-photo avatar-photo--instructors">
                <Avatar photo={instructor.profile_photo ?? images.default.userProfilePhoto} size={48} />
              </Box>
              <Box className="card-content participant-box--rich-text">
                <Text size="18px" color={brandColor}>
                  {instructor.first_name} {instructor.last_name}
                </Text>
                <RichTextRenderer data={instructor.html_description} />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  renderTopics(course) {
    return (
      <Box flexDirection="row" className="card-wrapper card-wrapper--topics">
        <Box className="card-content--topics">
          <Text size="20px" color="#263044" className="card-content__title">
            <Trans>Modules</Trans>
          </Text>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 0} />}
            expandIconPosition="right"
            className="topics-list"
          >
            {course.topics.map((topic, index) => {
              return (
                <Panel
                  header={
                    <Box flexDirection="row" className="topics-list__header">
                      <span>{index + 1}</span>
                      {topic.name}
                    </Box>
                  }
                  key={index}
                  className="topics-list__item"
                >
                  <p>{topic.description}</p>
                </Panel>
              );
            })}
          </Collapse>
        </Box>
      </Box>
    );
  }

  renderFeaturedParticipants(course, color) {
    return (
      <Box flexDirection="row" className="card-wrapper card-wrapper--participants">
        <Box className="card-content card-content--participants">
          <Text size="20px" color="#263044" className="card-content__title">
            <Trans>Featured Participants</Trans>
          </Text>
          {course.featured_participants.map((participant) => (
            <Box key={participant.name} flexDirection="row" className="participant-box">
              <Box className="avatar-photo avatar-photo--participants">
                <Avatar photo={participant.logo ?? images.default.userProfilePhoto} size={72} />
              </Box>
              <Box className="card-content">
                <Text size="16px" color={color.brand}>
                  {participant.name}
                </Text>
                <Text size="16px" color="#717E94" className="participant-box__quote">
                  <RichTextRenderer data={participant.html_description} />
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  renderCourseTags(course) {
    return (
      <div className="description-card--tags__body">
        <Box className="card-content">
          <Text size="20px" color="#263044" className="card-content__title">
            <Trans>Tags</Trans>
          </Text>
          <TagList tags={course.tags} />
        </Box>
      </div>
    );
  }

  renderCourseTopbar(course, color) {
    const onSubscribe = async () => {
      if (course.privacy === 'private') {
        return this.setState({ isCreateRequestToJoinOpened: true });
      }
      const response = await this.props.acceptInvitation();
      if (response.ok) {
        this.props.history.push(`/courses/${course.id}/timeline`);
      }
    };

    const { totalPrice, paidBySponsors, missingToPay, isFree } = getPriceProperties(course);
    const program = new Program(course);
    const isContactUsVisible =
      program.isContactUs && !program.isUserAuthor(this.props.viewer.id) && !can(course, 'Course', 'joinedAsUser');

    return (
      <Row item={{ className: 'program-topbar', align: 'middle', justify: 'space-between' }}>
        {course.topics?.length ? (
          <>
            <Box className="program-topbar__duration">
              <Text size="16px" color={color.brand} className="program-topbar__date">
                {this.props.t('programDurationDates', {
                  startDate: new Date(course.start_time),
                  endDate: new Date(course.end_time),
                })}
              </Text>
            </Box>

            <div className="program-topbar__price">
              {isFree ? (
                <Text size="20px" color={color.brand} className="program-topbar__price__free">
                  <Trans>Free program</Trans>
                </Text>
              ) : isContactUsVisible ? (
                <ContactUs programData={course} viewer={this.props.viewer} onSuccess={this.props.reloadProgramData} />
              ) : paidBySponsors > 0 ? (
                <div className="program-topbar__price__price-wrapper">
                  <Text size="16px" color="#717E94" className="program-topbar__price__discount">
                    ${parseFloat(totalPrice).toFixed(2)}
                  </Text>
                  <Text size="20px" color={color.brand}>
                    ${parseFloat(missingToPay).toFixed(2)}
                  </Text>
                </div>
              ) : totalPrice ? (
                <Text size="20px" color={color.brand} className="program-topbar__price__price-wrapper">
                  ${totalPrice.toFixed(2)}
                </Text>
              ) : null}
              {course.sponsors
                ? course.sponsors.map((sponsor) => (
                    <Text
                      key={sponsor.name}
                      size="16px"
                      color="#717E94"
                      component="div"
                      className="program-topbar__sponsor"
                    >
                      <div style={{ marginRight: 15 }}>
                        {getSponsorValue(course.price, sponsor.price)} <Trans>Sponsored by</Trans> {sponsor.name}
                      </div>
                      <Avatar
                        photo={sponsor.logo ?? images.default.userProfilePhoto}
                        size={35}
                        className="program-topbar__sponsor__logo"
                      />
                    </Text>
                  ))
                : null}
            </div>
          </>
        ) : null}
        {!can(course, 'Course', 'isMemberOfProgram') && course.status === 'published' && !program.isContactUs && (
          <>
            <Button
              type="primary"
              className="program-topbar__register-button"
              onClick={onSubscribe}
              disabled={can(course, 'Course', 'hasUserSentJoinRequest')}
              loading={this.props.isLoadingSubscribe}
            >
              {course.privacy === 'private'
                ? can(course, 'Course', 'hasUserSentJoinRequest')
                  ? 'Request Sent'
                  : 'Request to Subscribe'
                : 'Subscribe'}
            </Button>

            <CreateRequestToJoin
              isOpened={this.state.isCreateRequestToJoinOpened}
              program={course}
              onSuccess={() => this.setState({ isCreateRequestToJoinOpened: false })}
              onCancel={() => this.setState({ isCreateRequestToJoinOpened: false })}
            />
          </>
        )}
      </Row>
    );
  }

  renderChatToButton = (legacyId) => {
    return <ChatToButton legacyUserId={legacyId} />;
  };

  renderProgramAdmins = (program) => {
    return (
      <Box className="card-wrapper card-wrapper--course-author">
        <Text size="20px" color="#263044" className="card-content__title" component="div">
          <Trans>Admins</Trans>
        </Text>
        <Row item={{ gutter: 12 }}>
          {program.administrators.map((admin) => (
            <Col item={{ span: 8, style: { marginBottom: 12 } }}>
              <ProfileCard user={admin} cardAction={this.renderChatToButton(admin.id)} />
            </Col>
          ))}
        </Row>
      </Box>
    );
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <AppLayout
            center={
              <CourseLayout courseId={this.props.courseId} viewer={this.props.viewer} history={this.props.history}>
                {(course) => (
                  <div className="row">
                    <ScrollToTop />
                    <div className="col-xs-12">
                      <Card>{this.renderCourseTopbar(course, theme.color)}</Card>
                    </div>
                    <div className="col-xs-12">
                      <Card>
                        {this.renderCourseDescription(course)}
                        {this.state.learnMore && (
                          <React.Fragment>
                            {course.author_name && (
                              <div className="description-card">{this.renderCourseAuthor(course)}</div>
                            )}
                            {course.sponsors?.length ? (
                              <div className="description-card description-card--sponsors">
                                {this.renderCourseSponsors(course, theme.color.brand)}
                              </div>
                            ) : null}
                            {course.administrators && (
                              <div className="description-card description-card--administrators">
                                {this.renderProgramAdmins(course)}
                              </div>
                            )}
                            {course.who_should_enroll && (
                              <div className="description-card description-card--enroll">
                                {this.renderEnroll(course)}
                              </div>
                            )}
                            {course.expected_outcomes?.length ? (
                              <div className="description-card description-card--outcomes">
                                {this.renderExpectedOutcomes(course)}
                              </div>
                            ) : null}
                            {course.topics?.length ? (
                              <div className="description-card description-card--topics">
                                {this.renderTopics(course)}
                              </div>
                            ) : null}
                            {course.instructors?.length ? (
                              <div className="description-card description-card--instructors">
                                {this.renderInstructors(course, theme.color.brand)}
                              </div>
                            ) : null}
                            {course.featured_participants?.length ? (
                              <div className="description-card">
                                {this.renderFeaturedParticipants(course, theme.color)}
                              </div>
                            ) : null}
                          </React.Fragment>
                        )}
                        {course.tags &&
                        course.tags.length > 0 &&
                        canSeeTags({ viewer: this.props.viewer, organizationId: course.organization.id })
                          ? this.renderCourseTags(course)
                          : null}
                      </Card>
                    </div>
                  </div>
                )}
              </CourseLayout>
            }
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  courseId: props.match.params.courseId,
  viewer: select.session.user(state),
  isLoadingSubscribe: state.loading.effects.courses.accept,
});

const mapDispatch = (dispatch, props) => ({
  acceptInvitation: () => dispatch.courses.accept(props.match.params.courseId),
  reloadProgramData: (programId) => dispatch.courses.find(programId),
});

export const PageCourseAbout = withTranslation()(connect(mapState, mapDispatch)(Renderer));
