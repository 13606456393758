import { type VideoDetail, createVideoDetail } from 'models';
import { useQuery } from 'query';
import { getVideoDetail } from '../services';
import { getVideoDetailQueryKey } from '.';

// TODO: return whole CO object not just video detail
export const useGetVideoDetailQuery = (videoId: string) =>
  useQuery<VideoDetail>(getVideoDetailQueryKey({ videoId }), async () => {
    const { data } = await getVideoDetail(videoId);

    return createVideoDetail(data.video);
  });

export default useGetVideoDetailQuery;
