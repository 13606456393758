export const images = {
  default: {
    userProfilePhoto: '/assets/default_images/user_profile_photo.png',
    organizationCoverPhoto: '/assets/default_images/organization_cover_photo.png',
    organizationProfilePhoto: '/assets/default_images/organization_profile_photo.png',
    organizationWhiteProfilePhoto: '/assets/default_images/organization_white_profile_photo.png',
    communityCoverPhoto: '/assets/default_images/community_cover_photo.png',
    programCoverPhoto: '/assets/default_images/program_cover_photo.png',
    eventCoverPhoto: '/assets/default_images/event_cover_photo.png',
    campaignCoverPhoto: '/assets/default_images/campaign_cover_photo.png',
    communityProfilePhoto: '/assets/default_images/community_profile_photo.png',
    videoCoverPhoto: '/assets/default_images/video_cover_photo.png',
  },
};
