import { AutocompleteUser } from 'models';
import { useQuery } from 'query';

import { getCommunitiesUsers } from '../services';
import { getCommunityUsersKey } from '.';

export const useCommunitiesUsers = (
  // TODO role should be type
  {
    communitiesIds,
    role,
    limit,
    search,
  }: { communitiesIds: string[]; role?: 'admin' | 'member'; limit?: number; search?: string },
  options?: { useErrorBoundary?: boolean; enabled?: boolean },
) => {
  return useQuery<AutocompleteUser[]>(
    getCommunityUsersKey({ communitiesIds, role, limit, search }),
    async () => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- We missing correct application model in camelCase, we need to fix it
      const usersResponse = await getCommunitiesUsers({ community_ids: communitiesIds, role, limit, search });

      return usersResponse.data.map((user) => new AutocompleteUser(user));
    },
    {
      // TODO this is set to `always` communities users can be changed and React Query is not notified about changes
      refetchOnMount: 'always',
      useErrorBoundary: true,
      ...options,
    },
  );
};
