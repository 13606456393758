import { LoadingOutlined } from '@ant-design/icons';
import { DeleteIcon, Progress, Button } from 'ui';

type P = {
  removeUpload: () => void;
  percent: number;
  fileName: string;
};

const FileUploading = ({ fileName, removeUpload, percent }: P) => {
  return (
    <div className="ant-upload-list-item ant-upload-list-item-uploading ant-upload-list-item-list-type-text">
      <div className="ant-upload-list-item-info">
        <span className="ant-upload-span">
          <div className="ant-upload-text-icon">
            <LoadingOutlined />
          </div>

          <span className="ant-upload-list-item-name">{fileName}</span>

          <span className="ant-upload-list-item-card-actions">
            <Button
              type="link"
              onClick={() => {
                removeUpload();
              }}
              icon={<DeleteIcon />}
              className="ant-upload-list-item-card-actions-btn"
            />
          </span>
        </span>
      </div>
      <div className="ant-upload-list-item-progress">
        <Progress percent={percent} size="small" showInfo={false} strokeWidth={2} />
      </div>
    </div>
  );
};

export default FileUploading;
