import { generateQueryKey } from 'query';

export * from './usePublicOrganizationsInfiniteQuery';
export * from './useOrganizationsInfiniteQuery';

export const getPublicOrganizationsInfiniteQueryKey = ({
  filter = {},
  pagination = {},
}: {
  filter?: { search?: string; communityId?: string };
  pagination?: { limit?: number };
}) => generateQueryKey(['organizations', { filter, pagination }, 'infinite']);

type OrganizationsParams = {
  filter?: { search?: string };
  pagination?: { pageSize?: number };
};

export const getOrganizationsInfiniteQueryKey = ({ filter = {}, pagination = {} }: OrganizationsParams) =>
  generateQueryKey(['organizations', { filter, pagination }, 'infinite']);
