import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ListUserDataObject } from 'models/service';

type GetUsersParams = {
  search?: string;
  limit?: number;
  scope?: 'all';
  exclude_community_id?: string;
};

export const getUsers = async ({ limit = 10, scope = 'all', ...restParams }: GetUsersParams) =>
  apiClient.get<ListUserDataObject[], ApiClientResponse<ListUserDataObject[]>>(routes.user.autocomplete, {
    params: { scope, limit, ...restParams },
  });
