import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { Col, Image, Row, Text } from 'ui';

type P = {
  title: string;
  description?: string;
  image?: {
    alt: string;
    src?: string;
  };
};

const OrganizationOption = ({ title, description, image }: P) => {
  return (
    <Row item={{ gutter: [variables.spaceMd.value, 0], align: 'middle' }}>
      {image ? (
        <Col>
          <Image
            size="middle"
            preview={false}
            src={image.src ?? images.default.organizationProfilePhoto}
            alt={image.alt}
          />
        </Col>
      ) : null}
      <Col item={{ flex: '1 1 0' }}>
        <Row>
          <Col item={{ span: 24 }}>
            <Text>{title}</Text>
          </Col>
          {description ? (
            <Col item={{ span: 24 }}>
              <Text type="secondary">{description}</Text>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default OrganizationOption;
