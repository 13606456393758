import { useQueryClient } from '@tanstack/react-query';

import { Community, type Image } from 'models';

import { useMutation } from 'query';
import { getCommunityQueryKey } from '..';
import { updateCommunity } from '../../services';

export const useUpdateCommunity = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Community,
    { id: string; data: { image?: Image; description?: string; htmlDescription?: string } }
  >(
    async ({ id, data }) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const communityResponse = await updateCommunity(id, {
        cover_photo: data.image,
        html_description: data.htmlDescription,
        description: data.description,
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return new Community(communityResponse.data);
    },
    {
      onSuccess(data, parameters) {
        queryClient.setQueryData(getCommunityQueryKey({ id: parameters.id }), data);
        // TODO this will be removed with useCommunityDataObjectQuery
        queryClient.invalidateQueries(['data-object', ...getCommunityQueryKey({ id: parameters.id })]);
      },
      onError(_, parameters) {
        queryClient.invalidateQueries(getCommunityQueryKey({ id: parameters.id }));
        // TODO this will be removed with useCommunityDataObjectQuery
        queryClient.invalidateQueries(['data-object', ...getCommunityQueryKey({ id: parameters.id })]);
      },
    },
  );
};
