import { useEffect } from 'react';
import { type UploadContextType } from '../UploadContext';

type P = {
  uploads: UploadContextType;
};

const hasActiveUploads = (uploads: UploadContextType): boolean => {
  return Object.values(uploads).some((contentTypeUploads) =>
    Object.values(contentTypeUploads).some(
      (referenceUploads) =>
        referenceUploads && Object.values(referenceUploads).some((upload) => upload.state.status === 'uploading'),
    ),
  );
};

export const useUploadBeforeUnload = ({ uploads }: P) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasActiveUploads(uploads)) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploads]);
};
