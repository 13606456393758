import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { dispatch } from 'old/store';
import qs from 'qs';
import { defaultHeadersInterceptor, defaultRailsInterceptor } from './interceptors';

export { appVersion, googleReCaptchaSiteKey } from './api-keys';

export interface ApiClientResponse<T> extends AxiosResponse<T> {}

const apiClient = axios.create({
  responseType: 'json',
  baseURL: `${process.env.REACT_APP_API_URL}`,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

export type ApiClientResponseError<T> = AxiosError<T>;

export type DefaultResponseErrorDataObject = {
  error: string;
  message: string;
};

export type ValidationResponseErrorDataObject = {
  errors: Record<string, string[]>;
};

export type ResponseErrorDataObject = DefaultResponseErrorDataObject | ValidationResponseErrorDataObject;

export const isValidationResponseErrorDataObject = (data: unknown): data is ValidationResponseErrorDataObject =>
  Boolean(data) &&
  typeof data === 'object' &&
  data !== null &&
  'errors' in data &&
  typeof data.errors === 'object' &&
  data.errors !== null &&
  !Array.isArray(data.errors);

export const isDefaultResponseErrorDataObject = (data: unknown): data is DefaultResponseErrorDataObject =>
  Boolean(data) &&
  typeof data === 'object' &&
  data !== null &&
  'error' in data &&
  typeof data.error === 'string' &&
  'message' in data &&
  typeof data.message === 'string';

apiClient.interceptors.request.use((config) => defaultHeadersInterceptor(config));
apiClient.interceptors.request.use(async (config) => defaultRailsInterceptor(config));

apiClient.interceptors.response.use(
  (response) => normalizeSuccessResponse(response),
  (error) => {
    normalizeErrorResponse(error);
  },
);

function normalizeSuccessResponse<T>(response: AxiosResponse<T>): ApiClientResponse<T> {
  return response;
}

function normalizeErrorResponse(error: any) {
  if (
    ['invalid_grant', 'invalid_token', 'user_banned'].includes(error.response?.data?.error) ||
    error.response?.status === 401
  ) {
    // TODO change one day to new logout
    dispatch.session.logOut();
  }

  throw error;
}

export default apiClient;
