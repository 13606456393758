import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ListUserDataObject } from 'models/service';

type ProgramsUsersRequest = {
  program_ids?: string[];
  limit?: number;
  search?: string;
  scope: 'programs';
};

export const getProgramsUsers = async (params: ProgramsUsersRequest) =>
  apiClient.get<ListUserDataObject[], ApiClientResponse<ListUserDataObject[]>>(routes.program.autoComplete, {
    params,
  });
