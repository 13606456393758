import { apiClient, routes } from 'common/services';
import { type ResetPassword } from '../models';

export const resetPassword = async ({
  email,
  currentPassword,
  password,
  passwordConfirmation,
  resetPasswordToken,
}: ResetPassword) => {
  /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
  const data = {
    user: {
      email,
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken,
    },
  };
  /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

  return apiClient.put(routes.user.password, data, { skipAuth: true });
};
