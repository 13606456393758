import { type EventEmailInvitee, type RegisteredEventInvitee, InviteType, type EventContentObject } from 'models';
import { Form, Section, ModalFormActions } from 'ui';

import { mapInviteTypeToRole } from '../../EventFormModal';
import {
  type CommonFormValues,
  EventInviteesPicker,
  useEventInvitees,
} from '../../EventFormModal/DataStepEventForm/EventInviteesPicker';
import EventInviteesSelect from '../../EventInviteesSelect';

type DefaultEventFormValues = CommonFormValues;

type DefaultEventFormData = {
  formValues: DefaultEventFormValues;
};

type P = {
  eventContentObject: EventContentObject;
  isUpdating: boolean;
  onSubmit: (data: DefaultEventFormData) => void;
  inviteType: InviteType;
  onInviteesTypeChanged: (type: InviteType) => void;
};

const getInitialValues = (eventContentObject: EventContentObject): Partial<DefaultEventFormValues> => {
  const { event } = eventContentObject;

  return {
    invitees: event.invitees.filter(
      (invitee: RegisteredEventInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.invitees),
    ),
    moderators: event.invitees.filter(
      (invitee: RegisteredEventInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.moderators),
    ),
    presenters: event.invitees.filter(
      (invitee: RegisteredEventInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.presenters),
    ),
    emailInvitees: event.emailInvitees.filter(
      (invitee: EventEmailInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.invitees),
    ),
    emailModerators: event.emailInvitees.filter(
      (invitee: EventEmailInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.moderators),
    ),
    emailPresenters: event.emailInvitees.filter(
      (invitee: EventEmailInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.presenters),
    ),
  };
};

const DefaultEventForm = ({ eventContentObject, isUpdating, onSubmit, onInviteesTypeChanged, inviteType }: P) => {
  const { viewPermission, id: eventId } = eventContentObject.event;
  const [form] = Form.useForm<DefaultEventFormValues>();

  const initialValues = getInitialValues(eventContentObject);

  const { onInvite, onShowInvitees } = useEventInvitees({
    skipPostInCommunityValidation: true,
    form,
    onInviteesTypeChanged,
  });

  const onFinish = (formValues: DefaultEventFormValues) => {
    onSubmit({ formValues });
  };

  return (
    <>
      {inviteType ? (
        <EventInviteesPicker
          form={form}
          communityId={eventContentObject.postInCommunities.map((community) => community.id)[0]}
          inviteType={inviteType}
          onInvite={onInvite}
        />
      ) : (
        <Form<DefaultEventFormValues> initialValues={initialValues} onFinish={onFinish} form={form}>
          <Section paddingTop={false}>
            <EventInviteesSelect
              name="moderators"
              nameEmail="emailModerators"
              label="Admins / Hosts"
              buttonText="Invite"
              onPress={onShowInvitees(InviteType.moderators)}
              data-test-id="btn_invite-admins"
            />
            <EventInviteesSelect
              name="presenters"
              nameEmail="emailPresenters"
              label="Presenters"
              buttonText="Invite"
              onPress={onShowInvitees(InviteType.presenters)}
              data-test-id="btn_invite-presenters"
              inviteOptions={{
                eventId,
                kind: 'event_presenter_invitation',
                successMessage: 'Link for presenter copied to clipboard',
              }}
            />
            <EventInviteesSelect
              name="invitees"
              nameEmail="emailInvitees"
              label="Attendees"
              buttonText="Invite"
              onPress={onShowInvitees(InviteType.invitees)}
              data-test-id="btn_invite-attendees"
              disabled={viewPermission === 'public'}
              inviteOptions={{
                eventId,
                kind: 'event_attendee_invitation',
                successMessage: 'Link for Attendee copied to clipboard',
              }}
            />
          </Section>
          <ModalFormActions
            submit={{
              id: 'btn_invite_users',
              loading: isUpdating,
            }}
          />
        </Form>
      )}
    </>
  );
};

export default DefaultEventForm;
