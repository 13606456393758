import { useEffect } from 'react';
import { CampaignCard } from 'features/campaigns';
import { CARDS_IN_ROW } from 'features/channels';
import { Slider } from 'ui';
import { useIsChannelEmpty, usePageCursor } from '../../hooks';
import { useFeaturedCampaigns } from './hooks';

type P = {
  getPathToCampaignDetail: (id: string) => string;
  organizationId?: string;
  title?: string;
  onSeeAll?: () => void;
  view: 'detail' | 'preview';
  onEmptyState?: (isEmpty: boolean) => void;
};

const CampaignsSlider = ({ getPathToCampaignDetail, organizationId, title, onSeeAll, view, onEmptyState }: P) => {
  const [pageCursor, setPageCursor] = usePageCursor(organizationId);
  const {
    featured,
    query: { data: featuredCampaigns, isFetching: isFetchingFeatured },
  } = useFeaturedCampaigns({
    onSeeAll,
    organizationId,
    pagination: { pageSize: CARDS_IN_ROW, position: pageCursor },
  });

  const showSeeAllButton = onSeeAll && (featuredCampaigns?.length ?? 0) > 0;

  const isEmpty = useIsChannelEmpty(isFetchingFeatured, pageCursor, featuredCampaigns);

  // TODO fix not working without organizationId, this hack is needed together with useEffect in default channels to set empty state correctly
  useEffect(() => {
    onEmptyState?.(isEmpty);
  }, [isEmpty, onEmptyState, organizationId]);

  if (isEmpty) {
    return null;
  }

  return (
    <Slider
      isLoading={isFetchingFeatured}
      items={featuredCampaigns?.map((campaign) => (
        <CampaignCard
          campaign={campaign}
          getPathToCampaignDetail={getPathToCampaignDetail}
          key={`campaing-${campaign.id}`}
        />
      ))}
      title={title}
      queryData={{
        limit: CARDS_IN_ROW,
        hasNextPage: CARDS_IN_ROW === featuredCampaigns?.length,
        hasPreviousPage: pageCursor > 0,
      }}
      clickActions={{
        onPrevious() {
          setPageCursor((pageCursor) => Math.max(pageCursor - 1, 0));
        },
        onNext() {
          setPageCursor((pageCursor) => pageCursor + 1);
        },
        onSeeAll: showSeeAllButton ? onSeeAll : undefined,
      }}
      hideArrows={!featured}
      view={view}
    />
  );
};

export default CampaignsSlider;
