import { useTranslation } from 'react-i18next';

import { Form, Button, Section, TextAreaInput } from 'ui';

type P = {
  onSubmit: (data: { reason: string }) => void;
  loading: boolean;
};

const RequestToJoinForm = ({ onSubmit, loading }: P) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = (data: { reason: string }) => {
    onSubmit(data);
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Section paddingTop={false} paddingBottom={false}>
        <TextAreaInput
          item={{
            name: 'reason',
            label: 'requestToJoinFormTextAreaLabel',
          }}
          input={{
            placeholder: t('Write your answer...'),
            required: true,
          }}
        />
      </Section>
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <Button loading={loading} htmlType="submit" type="primary">
          Send Request
        </Button>
      </Section>
    </Form>
  );
};

export default RequestToJoinForm;
