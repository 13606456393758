import { AttachmentLineIcon, Button, DeleteIcon, RepeatLine } from 'ui';

type P = {
  fileName: string;
  removeUpload: () => void;
  retryUpload: () => void;
};

const ErrorFileUpload = ({ fileName, removeUpload, retryUpload }: P) => {
  return (
    <div className="ant-upload-list-item ant-upload-list-item-error ant-upload-list-item-list-type-text">
      <div className="ant-upload-list-item-info">
        <span className="ant-upload-span">
          <div className="ant-upload-text-icon">
            <AttachmentLineIcon className=" anticon-paper-clip" />
          </div>
          <span className="ant-upload-list-item-name" title={fileName}>
            {fileName}
          </span>

          <span className="ant-upload-list-item-card-actions">
            <Button
              type="link"
              onClick={() => {
                retryUpload();
              }}
              icon={<RepeatLine type="error" />}
              danger
            />

            <Button
              type="link"
              onClick={() => {
                removeUpload();
              }}
              icon={<DeleteIcon type="error" />}
              danger
            />
          </span>
        </span>
      </div>
    </div>
  );
};

export default ErrorFileUpload;
