import { AttachmentLineIcon, Button, DeleteIcon } from 'ui';

type P = {
  fileName: string;
  removeUpload: () => void;
};

const DoneFileUpload = ({ fileName, removeUpload }: P) => {
  return (
    <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
      <div className="ant-upload-list-item-info">
        <span className="ant-upload-span">
          <div className="ant-upload-text-icon">
            <AttachmentLineIcon className="anticon-paper-clip" />
          </div>
          <span className="ant-upload-list-item-name" title={fileName}>
            {fileName}
          </span>
          <span className="ant-upload-list-item-card-actions">
            <Button
              type="link"
              onClick={() => {
                removeUpload();
              }}
              icon={<DeleteIcon />}
              className="ant-upload-list-item-card-actions-btn"
            />
          </span>
        </span>
      </div>
    </div>
  );
};

export default DoneFileUpload;
