import { useState } from 'react';
import { type AutocompleteUser, type ReduxUser } from 'models/user';
import { useCreateCommentMutation } from '../../queries';
import CommentForm, { type CommentFormValues } from '../CommentForm';
import { CreateCommentFormPlaceholder } from './CreateCommentFormPlaceholder';

type CommentMode = 'input' | 'placeholder';

type P = {
  viewer: ReduxUser;
  parentId: string;
  onRequestMentions: (value: string) => Promise<AutocompleteUser[]>;
  initialMode?: CommentMode;
  onSuccess?: () => void;
};

const CreateCommentForm = ({ viewer, parentId, onRequestMentions, initialMode = 'placeholder', onSuccess }: P) => {
  const { isLoading: isCreatingComment, mutate: createComment } = useCreateCommentMutation();
  const [mode, setMode] = useState<CommentMode>(initialMode);

  const onFinish = (values: CommentFormValues) => {
    createComment(
      {
        parentId,
        textContent: values.textContent,
        htmlContent: values.htmlContent,
        attachments: values.attachments.files,
      },
      {
        onSuccess() {
          onSuccess?.();
          setMode('placeholder');
        },
      },
    );
  };

  return (
    <>
      {mode === 'placeholder' ? (
        <CreateCommentFormPlaceholder
          viewer={viewer}
          onRequestInput={() => {
            setMode('input');
          }}
        />
      ) : null}
      {mode === 'input' ? (
        <CommentForm
          onSubmit={onFinish}
          viewer={viewer}
          onRequestMentions={onRequestMentions}
          isLoading={isCreatingComment}
        />
      ) : null}
    </>
  );
};

export default CreateCommentForm;
