import variables from 'common/styles/variables.json';
import { isVisualFile } from 'common/utils';
import { type UploadedFileDataObject } from 'models/service';
import { Image, Row, Col } from 'ui';

type P = {
  attachments: UploadedFileDataObject[];
};

const ImageAttachments = ({ attachments }: P) => {
  const imageAttachments = attachments.filter((attachment) => isVisualFile(attachment));

  if (imageAttachments.length === 0) {
    return null;
  }

  return (
    <Image.PreviewGroup>
      <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value], className: 'image-attachments' }}>
        {imageAttachments.map((image) => (
          <Col key={image.id}>
            <Image
              src={image.url}
              alt={image.filename}
              borderRadius="large"
              objectFit="cover"
              className="image-attachments__image"
            />
          </Col>
        ))}
      </Row>
    </Image.PreviewGroup>
  );
};

export default ImageAttachments;
