import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type OauthTokenDataObject } from '../models/service';

type LoginWithPasswordRequest = {
  username: string;
  password: string;
  provider: 'local';
  grant_type: 'password';
  join_community_id?: string;
};

export const loginWithPassword = async (data: LoginWithPasswordRequest) =>
  apiClient.post<OauthTokenDataObject, ApiClientResponse<OauthTokenDataObject>>(routes.oauth.token, data, {
    skipAuth: true,
  });
