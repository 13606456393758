import withAntIconWrapper from '../icon-wrapper/withAntIconWrapper';

import { ReactComponent as NavigationActiveBookmarksIconImport } from './bookmark_fill.svg';
import { ReactComponent as NavigationBookmarksIconImport } from './bookmark_line.svg';
import { ReactComponent as NavigationActiveEventsIconImport } from './calendar_fill.svg';
import { ReactComponent as NavigationEventsIconImport } from './calendar_line.svg';
import { ReactComponent as NavigationActiveCommunitiesIconImport } from './classify_2_fill.svg';
import { ReactComponent as NavigationCommunitiesIconImport } from './classify_2_line.svg';
import { ReactComponent as NavigationActiveMessagesIconImport } from './comment_fill.svg';
import { ReactComponent as NavigationMessagesIconImport } from './comment_line.svg';
import { ReactComponent as NavigationActiveExploreIconImport } from './compass_fill.svg';
import { ReactComponent as NavigationExploreIconImport } from './compass_line.svg';
import { ReactComponent as NavigationActiveCampaignsIconImport } from './hand_heart_fill.svg';
import { ReactComponent as NavigationCampaignsIconImport } from './hand_heart_line.svg';
import { ReactComponent as NavigationActiveHomeIconImport } from './home_4_fill.svg';
import { ReactComponent as NavigationHomeIconImport } from './home_4_line.svg';
import { ReactComponent as NavigationActiveProgramsIconImport } from './mortarboard_fill.svg';
import { ReactComponent as NavigationProgramsIconImport } from './mortarboard_line.svg';
import { ReactComponent as NavigationActiveJournalIconImport } from './notebook_2_fill.svg';
import { ReactComponent as NavigationJournalIconImport } from './notebook_2_line.svg';
import { ReactComponent as NavigationActiveVideoIconImport } from './video_fill.svg';
import { ReactComponent as NavigationVideoIconImport } from './video_line.svg';

export const NavigationHomeIcon = withAntIconWrapper(NavigationHomeIconImport);
export const NavigationEventsIcon = withAntIconWrapper(NavigationEventsIconImport);
export const NavigationProgramsIcon = withAntIconWrapper(NavigationProgramsIconImport);
export const NavigationCommunitiesIcon = withAntIconWrapper(NavigationCommunitiesIconImport);
export const NavigationMessagesIcon = withAntIconWrapper(NavigationMessagesIconImport);
export const NavigationBookmarksIcon = withAntIconWrapper(NavigationBookmarksIconImport);
export const NavigationJournalIcon = withAntIconWrapper(NavigationJournalIconImport);
export const NavigationCampaignsIcon = withAntIconWrapper(NavigationCampaignsIconImport);
export const NavigationExploreIcon = withAntIconWrapper(NavigationExploreIconImport);

export const NavigationActiveHomeIcon = withAntIconWrapper(NavigationActiveHomeIconImport);
export const NavigationActiveEventsIcon = withAntIconWrapper(NavigationActiveEventsIconImport);
export const NavigationActiveProgramsIcon = withAntIconWrapper(NavigationActiveProgramsIconImport);
export const NavigationActiveCommunitiesIcon = withAntIconWrapper(NavigationActiveCommunitiesIconImport);
export const NavigationActiveMessagesIcon = withAntIconWrapper(NavigationActiveMessagesIconImport);
export const NavigationActiveBookmarksIcon = withAntIconWrapper(NavigationActiveBookmarksIconImport);
export const NavigationActiveJournalIcon = withAntIconWrapper(NavigationActiveJournalIconImport);
export const NavigationActiveCampaignsIcon = withAntIconWrapper(NavigationActiveCampaignsIconImport);
export const NavigationActiveExploreIcon = withAntIconWrapper(NavigationActiveExploreIconImport);
export const NavigationActiveVideoIcon = withAntIconWrapper(NavigationActiveVideoIconImport);
export const NavigationVideoIcon = withAntIconWrapper(NavigationVideoIconImport);
