import { apiClient, type ApiClientResponse, routes } from 'common/services';

export const confirmTimezone = async (userId: string, timeZone: string) => {
  /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
  const updateUser = {
    user: {
      time_zone: timeZone,
    },
  };
  /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

  return apiClient.patch<undefined, ApiClientResponse<undefined>>(routes.user.patch(userId), updateUser);
};
