import { WithingsOrderPost, Program } from 'models';

import { useEffect, useMemo } from 'react';

import { type Social } from 'models/content-object';
import { type ContentObjectDataObjectDeprecated } from 'models/service/content-object-deprecated';
import { Modal, message } from 'ui';
import { WithingsOrderPostForm } from './components';
import { useEditWithingsOrderPostQuery } from './queries';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
  objectData: ContentObjectDataObjectDeprecated;
  program: Program;
  onRequestReturn?: () => void;
};

const generateOrderPost = (objectData: ContentObjectDataObjectDeprecated) =>
  new WithingsOrderPost<Social>(objectData, new Program(objectData.program), objectData.withings_order_post);

const EditWithingsOrderPost = ({ onSuccess, onCancel, isOpened, objectData, program, onRequestReturn }: P) => {
  const postData = useMemo(() => generateOrderPost(objectData), [objectData]);

  const {
    mutate: editWithingsOrderPost,
    isLoading: isEditingWithingsOrderPost,
    error: editOrderPostError,
    isSuccess: isEditWithingsOrderPostSuccess,
  } = useEditWithingsOrderPostQuery();

  const onSubmit = (post: WithingsOrderPost<Social>) => {
    if (post.program_topic_id && post.publish_at && post.text_content && post.html_content) {
      editWithingsOrderPost({
        withingsPostOrderId: postData.id,
        programId: program.id,
        programTopicId: post.program_topic_id,
        publishAt: post.publish_at,
        textContent: post.text_content,
        htmlContent: post.html_content,
        title: post.title,
        todoDueDate: post.todo_due_date,
      });
    }
  };

  useEffect(() => {
    if (editOrderPostError) {
      message.error('Something went wrong, please try again later.');
    }
  }, [editOrderPostError]);

  useEffect(() => {
    if (isEditWithingsOrderPostSuccess) {
      onSuccess();
    }
  }, [onSuccess, isEditWithingsOrderPostSuccess]);

  return (
    <Modal
      title="Edit Order"
      isOpened={isOpened}
      onCancel={onCancel}
      onBackClick={onRequestReturn}
      disableBack={!onRequestReturn}
    >
      <WithingsOrderPostForm
        submitText="Edit Post"
        onSubmit={onSubmit}
        loading={isEditingWithingsOrderPost}
        post={postData}
        program={program}
      />
    </Modal>
  );
};

export default EditWithingsOrderPost;
