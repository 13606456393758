import { InvitationToken, type InvitationTokenKind } from 'models';
import { useMutation } from 'query';
import { getInviteToken } from '../../services';

type Variables = {
  kind: InvitationTokenKind;
  subjectId: string;
};

export const useGenerateInviteToken = () =>
  useMutation<InvitationToken, Variables>(async ({ kind, subjectId }: Variables) => {
    /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
    const resposne = await getInviteToken({
      kind,
      subject_type: 'Event',
      subject_id: subjectId,
    });
    /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

    return new InvitationToken(resposne.data);
  });
