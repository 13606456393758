import { CheckCircleOutlined } from '@ant-design/icons';
import { isPast } from 'date-fns';
import { type EventInviteeRole, type ReduxUser, type UserRSVP } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnRequestEnterConference } from 'features/events/hooks';
import { useUpdateEventUserStatus } from 'features/events/queries';
import { isOpenToJoin } from 'features/events/utils';
import { type ButtonStatus, message, type MenuProps, CloseCircleFillIcon } from 'ui';

type EventData = {
  id: string;
  startTime: Date;
  endTime: Date;
  bmgId: string | null;
  currentUserRole: EventInviteeRole;
  currentUserRsvp: UserRSVP;
};

export const useEventActions = ({ data, viewer }: { data: EventData; viewer: ReduxUser }) => {
  const { t } = useTranslation();

  const { mutate: acceptEvent, isLoading: isAccepting } = useUpdateEventUserStatus();
  const { mutate: rejectEvent, isLoading: isRejecting } = useUpdateEventUserStatus();
  const { onRequestEnterConference, isJoining } = useOnRequestEnterConference();

  const isPastEvent = isPast(data.endTime);
  const isOpenToResponse = !isPastEvent;

  const onEventActionClick = useCallback(
    (action: UserRSVP | 'join') => {
      switch (action) {
        case 'join':
          onRequestEnterConference(data, viewer);
          break;
        case 'going':
          if (isOpenToResponse) {
            acceptEvent({ id: data.id, statusToApply: action });
          } else {
            message.warn('This event has started already');
          }

          break;
        case 'not_going':
          if (isOpenToResponse) {
            rejectEvent({ id: data.id, statusToApply: action });
          } else {
            message.warn('This event has started already');
          }

          break;
        default:
          console.log('Unhandled action:', action);
      }
    },
    [data, isOpenToResponse, onRequestEnterConference, acceptEvent, rejectEvent, viewer],
  );

  const handleJoinStatusButtonDropdown = useCallback((): MenuProps | undefined => {
    if (isOpenToJoin(data) || data.currentUserRsvp === 'pending' || isPastEvent) {
      return undefined;
    }

    switch (data.currentUserRsvp) {
      case 'going':
        return {
          items: [
            {
              label: t('Not going'),
              icon: <CloseCircleFillIcon type="error" />,
              key: 'reject',
            },
          ],
          onClick(event) {
            event.domEvent.preventDefault();
            onEventActionClick('not_going');
          },
        };
      case 'not_going':
        return {
          items: [
            {
              label: t("Yes, I'm going"),
              icon: <CheckCircleOutlined />,
              key: 'accept',
            },
          ],
          onClick(event) {
            event.domEvent.preventDefault();
            onEventActionClick('going');
          },
        };
    }
  }, [data, isPastEvent, onEventActionClick, t]);

  const onJoinButtonClick = useCallback(() => {
    if (isOpenToJoin(data)) {
      onEventActionClick('join');
      return;
    }

    if (data.currentUserRsvp === 'pending') {
      onEventActionClick('going');
      return;
    }

    console.error(
      'Join button clicked, but no action performed: Event is neither open to join nor is user RSVP pending.',
    );

    return null;
  }, [data, onEventActionClick]);

  const onRejectButtonClick = useCallback(() => {
    onEventActionClick('not_going');
  }, [onEventActionClick]);

  const handleJoinStatusButtonStatus = useCallback((): ButtonStatus => {
    if (isOpenToJoin(data)) {
      return 'join';
    }

    switch (data.currentUserRsvp) {
      case 'pending':
        return 'accept-reject';
      case 'not_going':
        return 'rejected';
      case 'going':
        return 'accepted';
    }
  }, [data]);

  return {
    handleJoinStatusButtonDropdown,
    onRejectButtonClick,
    handleJoinStatusButtonStatus,
    onJoinButtonClick,
    isAccepting,
    isRejecting,
    isJoining,
    onRequestEnterConference,
    onEventActionClick,
  };
};
