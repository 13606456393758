export const APP_STORE_CATEGORIES = [
  'Books',
  'Music',
  'Business',
  'Navigation',
  'News',
  'Education',
  'Entertainment',
  'Photo & Video',
  'Finance',
  'Productivity',
  'Food & Drink',
  'Reference',
  'Games',
  'Shopping',
  'Social Networking',
  'Health & Fitness',
  'Sports',
  'Lifestyle',
  'Travel',
  'Kids',
  'Utilities',
  'Magazines & Newspapers',
  'Medical',
  'Weather',
];

export const GOOGLE_PLAY_CATEGORIES = [
  'Art & Design',
  'Auto & Vehicles',
  'Beauty',
  'Books & Reference',
  'Business',
  'Comics',
  'Communications',
  'Dating',
  'Education',
  'Entertainment',
  'Events',
  'Finance',
  'Food & Drink',
  'Health & Fitness',
  'House & Home',
  'Libraries & Demo',
  'Lifestyle',
  'Maps & Navigation',
  'Medical',
  'Music & Audio',
  'News & Magazines',
  'Parenting',
  'Personalization',
  'Photography',
  'Productivity',
  'Shopping',
  'Social',
  'Sports',
  'Tools',
  'Travel & Local',
  'Video Players & Editors',
  'Weather',
];

export const FEATURE_FLAGS = [
  {
    name: 'explore_branded_app',
    label: 'Explore Branded App',
  },
  {
    name: 'campaigns',
    label: 'Campaigns',
  },
  {
    name: 'add_users_to_general_pba_community',
    label: 'Add Users to General BigHeart Community',
  },
];

export const getInitialValues = (appConfig) => {
  return {
    name: appConfig.name || '',
    app_name: appConfig.app_name || '',
    app_domain: appConfig.app_domain || '',
    app_email: appConfig.app_email || '',
    app_color: appConfig.app_color || '',
    landing_summary: appConfig.landing_summary || '',
    landing_description: appConfig.landing_description || '',
    facebook_url: appConfig.facebook_url || '',
    twitter_url: appConfig.twitter_url || '',
    linkedin_url: appConfig.linkedin_url || '',
    vimeo_url: appConfig.vimeo_url || '',
    instagram_url: appConfig.instagram_url || '',
    google_client_web_id: appConfig.google_client_web_id || '',
    google_client_web_secret: appConfig.google_client_web_secret || '',
    google_client_ios_id: appConfig.google_client_ios_id || '',
    google_client_android_id: appConfig.google_client_android_id || '',
    google_analytics_id: appConfig.google_analytics_id || '',
    firebase_plist_ios: appConfig.firebase_plist_ios,
    google_services: appConfig.google_services,
    outlook_client_id: appConfig.outlook_client_id || '',
    outlook_client_secret: appConfig.outlook_client_secret || '',
    dropbox_api_key: appConfig.dropbox_api_key || '',
    ios_app_url: appConfig.ios_app_url || '',
    android_app_url: appConfig.android_app_url || '',
    invite_text: appConfig.invite_text || '',
    terms_url: appConfig.terms_url || 'https://bigheartphilanthropy.org/terms-conditions',
    privacy_policy: {
      html_content: appConfig.privacy_policy?.html_content ?? '',
      use_default: appConfig.privacy_policy?.use_default ?? true,
    },
    organization_name: appConfig.organization?.id || '',
    organization_website: appConfig.organization_website || '',
    organization_address: appConfig.organization_address || '',
    app_store_id: appConfig.app_store_id || '',
    app_store_name: appConfig.app_store_name || '',
    app_store_subtitle: appConfig.app_store_subtitle || '',
    app_store_description: appConfig.app_store_description || '',
    app_store_category: appConfig.app_store_category || '',
    google_play_name: appConfig.google_play_name || '',
    google_play_short_description: appConfig.google_play_short_description || '',
    google_play_full_description: appConfig.google_play_full_description || '',
    google_play_category: appConfig.google_play_category || '',
    bundle_identifier: appConfig.bundle_identifier || '',
    apple_pay_merchant: appConfig.apple_pay_merchant || '',
    braintree_merchant_id: appConfig.braintree_merchant_id || '',
    braintree_tokenization_key: appConfig.braintree_tokenization_key || '',
    app_url_scheme: appConfig.app_url_scheme || '',
    cloudfront_distribution_id: appConfig.cloudfront_distribution_id || '',
    fcm_server_key: appConfig.fcm_server_key || '',
    web_icon: appConfig.web_icon,
    web_logo_dark: appConfig.web_logo_dark,
    web_logo_light: appConfig.web_logo_light,
    web_landing_image: appConfig.web_landing_image,
    mobile_vector_logo_launch_screen: appConfig.mobile_vector_logo_launch_screen,
    mobile_vector_logo: appConfig.mobile_vector_logo,
    mobile_launch_image: appConfig.mobile_launch_image,
    mobile_ic_launcher_web: appConfig.mobile_ic_launcher_web,
    mobile_launch_screen: appConfig.mobile_launch_screen,
    mobile_logo: appConfig.mobile_logo,
    mobile_logo_white: appConfig.mobile_logo_white,
    mobile_splash_icon: appConfig.mobile_splash_icon,
    landscape_icon: appConfig.landscape_icon,
    app_store_screenshots_55: appConfig.app_store_screenshots_55 || [],
    app_store_screenshots_65: appConfig.app_store_screenshots_65 || [],
    app_store_ipad_screenshots: appConfig.app_store_ipad_screenshots || [],
    google_play_graphic: appConfig.google_play_graphic,
    google_play_screenshots: appConfig.google_play_screenshots || [],
    feature_flags: appConfig.feature_flags || {
      explore_branded_app: true,
      campaigns: true,
      add_users_to_general_pba_community: false,
    },
    node_api_url: appConfig.node_api_url,
  };
};

export const getValidationRules = (validateAll) => {
  const rules = {
    name: ['required', 'regex:/^[a-z0-9_]*$/'],
    app_domain: 'regex:/^https?://[^/]+$/',
  };

  return validateAll
    ? {
        ...rules,
        app_name: 'required',
        app_email: 'required|email',
        app_color: ['required', 'regex:/^#[A-F0-9]{6}$/'],
        app_domain: ['required', 'regex:/^https?://[^/]+$/'],
        landing_summary: 'required',
        google_client_web_id: 'required',
        google_client_web_secret: 'required',
        google_client_ios_id: 'required',
        google_client_android_id: 'required',
        google_analytics_id: 'required',
        google_services: 'required',
        firebase_plist_ios: 'required',
        outlook_client_id: 'required',
        outlook_client_secret: 'required',
        dropbox_api_key: 'required',
        invite_text: 'required',
        terms_url: 'required',
        organization_name: 'required',
        organization_website: 'required',
        organization_address: 'required',
        app_store_id: 'required',
        app_store_name: 'required|max:30',
        app_store_subtitle: 'required|max:30',
        app_store_description: 'required',
        app_store_category: ['required', { in: APP_STORE_CATEGORIES }],
        google_play_name: 'required|max:30',
        google_play_short_description: 'required|max:80',
        google_play_full_description: 'required|max:4000',
        google_play_category: ['required', { in: GOOGLE_PLAY_CATEGORIES }],
        bundle_identifier: 'required',
        app_url_scheme: 'required',
        cloudfront_distribution_id: 'required',
        web_icon: 'required',
        web_logo_light: 'required',
        web_logo_dark: 'required',
        web_landing_image: 'required',
        mobile_vector_logo_launch_screen: 'required',
        mobile_vector_logo: 'required',
        mobile_launch_image: 'required',
        mobile_ic_launcher_web: 'required',
        mobile_launch_screen: 'required',
        mobile_logo: 'required',
        mobile_splash_icon: 'required',
        landscape_icon: 'required',
        app_store_screenshots_55: 'required|array|min:3',
        app_store_screenshots_65: 'required|array|min:3',
        app_store_ipad_screenshots: 'required|array|min:3',
        google_play_graphic: 'required',
        google_play_screenshots: 'required|array|min:2',
        fcm_server_key: 'required',
        node_api_url: 'required',
      }
    : rules;
};
