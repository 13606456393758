import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isDefaultResponseErrorDataObject,
  isValidationResponseErrorDataObject,
  type ApiClientResponseError,
  type ResponseErrorDataObject,
} from 'common/services';
import { message, Title } from 'ui';
import { NewPasswordForm } from '../components';
import { type ResetPassword } from '../models';
import { useResetPasswordQuery } from '../queries';

type P = {
  onSuccessChangePassword: () => void;
  onNavigateToPrimaryLogin: () => void;
  token?: string;
  email?: string;
};

const NewPassword = ({ onSuccessChangePassword, token, email, onNavigateToPrimaryLogin }: P) => {
  const { t } = useTranslation();
  const {
    mutate: changePassword,
    isLoading: isLoadingChangePassword,
    error: changePasswordError,
    data: changePasswordData,
  } = useResetPasswordQuery();

  const onSuccess = useMemo(
    () => () => {
      onSuccessChangePassword();
      message.success(t('Your password was successfully changed'));
    },
    [onSuccessChangePassword, t],
  );

  useEffect(() => {
    if (changePasswordData) {
      onSuccess();
    }
  }, [onSuccess, changePasswordData]);

  useEffect(() => {
    const getErrorMessage = (error: ApiClientResponseError<ResponseErrorDataObject>) => {
      const errorData = error.response?.data;

      if (isDefaultResponseErrorDataObject(errorData) && errorData.error === 'invalid_grant') {
        return { errorMessage: 'Invalid user name or password or user is blocked' };
      }

      if (isValidationResponseErrorDataObject(errorData) && errorData.errors) {
        return {
          errorMessage: Object.entries(errorData.errors)
            .map(([key, value]) => value.map((error: string) => `${key} ${error}`).join(', '))
            .join(', '),
        };
      }

      if (error.response?.data['invalid-params']) {
        const invalidParams = error.response.data['invalid-params'];

        if (
          invalidParams.some((invalid: { name: string; reason: string }) => invalid.name === 'reset_password_token')
        ) {
          return { errorMessage: t('reset_password_token is invalid'), duration: 5 };
        }

        if (
          invalidParams.some((invalid: { name: string; reason: string }) => invalid.reason === 'was used previously.')
        ) {
          return { errorMessage: t('passwordErrorUsed'), duration: 5 };
        }

        return { errorMessage: t('passwordError'), duration: 5 };
      }

      return { errorMessage: 'Something went wrong, please try again later.' };
    };

    if (changePasswordError) {
      const { errorMessage, duration } = getErrorMessage(changePasswordError);
      message.error(errorMessage, duration);
    }
  }, [changePasswordError, t]);

  const onSubmit = (submitData: ResetPassword) => {
    changePassword({ ...submitData, resetPasswordToken: token });
  };

  return (
    <>
      <Title level={1}>{t('New Password')}</Title>
      <div className="onboarding-new-password__form-wraper">
        <NewPasswordForm
          onSubmit={onSubmit}
          loading={isLoadingChangePassword}
          token={token}
          email={email}
          onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
        />
      </div>
    </>
  );
};

export default NewPassword;
