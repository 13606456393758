import * as React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '../box';
import { CommentThread } from 'features/feed/comment';
import { Icon } from '../icon';
import { SegmentedControl } from '../segmented-control';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';

const SORT_KEYS = {
  Chronological: 'chronological',
  'Top Comments': 'popularity',
};

const LIMIT = 10;

export class CommentsList extends React.Component {
  scrollRef = React.createRef();
  indexOfComment = this.getIndexForScrollToComment();
  visibleNumberOfComments = 3;

  state = {
    sort: 'Chronological',
    visibleCommentsNo: (this.indexOfComment > -1 ? this.indexOfComment + 1 : undefined) ?? this.visibleNumberOfComments,
  };

  componentDidMount() {
    window.requestAnimationFrame(() => this.scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }));
  }

  getIndexForScrollToComment() {
    const { scrollToCommentId, scrollToReplyId } = this.props;
    const { replies: commentReplies, id: commentId } = this.props.item;

    if (commentId === scrollToCommentId) {
      return commentReplies.findIndex((reply) => reply.id === scrollToReplyId);
    }

    return commentReplies.findIndex((reply) => reply.id === scrollToCommentId);
  }

  onLoadNext = () => {
    this.setState(({ visibleCommentsNo }) => {
      return { visibleCommentsNo: visibleCommentsNo + LIMIT };
    });
  };

  onChange = (sort) => {
    this.setState({ sort: sort }, () => {
      if (this.props.onSortChange) {
        this.props.onSortChange(this.props.item, SORT_KEYS[sort]);
      }
    });
  };

  shouldScrollToComment = (commentData) => {
    const { scrollToCommentId, scrollToReplyId } = this.props;

    return commentData.id === (scrollToReplyId ?? scrollToCommentId);
  };

  render() {
    const { item } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <React.Fragment>
            {item.replies.length > 1 && this.props.onSortChange ? (
              <Box flexDirection="row" alignItems="center" justifyContent="center">
                <div style={{ paddingBottom: 16, width: 240 }}>
                  <SegmentedControl
                    color={theme.color.lightGray}
                    labels={Object.keys(SORT_KEYS)}
                    selectedLabel={this.state.sort}
                    onChange={this.onChange}
                  />
                </div>
              </Box>
            ) : null}

            <div>
              {item.replies.slice(0, this.state.visibleCommentsNo).map((reply) => (
                <CommentThread
                  viewer={this.props.viewer}
                  commentDataObject={reply}
                  contentObjectRedux={this.props.originalItem}
                  scrollToCommentId={this.props.scrollToCommentId}
                  scrollToReplyId={this.props.scrollToReplyId}
                  scrollRef={this.shouldScrollToComment(reply) ? this.scrollRef : null}
                />
              ))}
            </div>

            {this.state.visibleCommentsNo < item.replies.length ? (
              <Box flexDirection="row" justifyContent="center">
                <div className="comment-list__see-more" onClick={this.onLoadNext} id={'see-more-comments'}>
                  <Text size={13} color={theme.color.lightGray} style={{ marginRight: 5 }}>
                    <Trans>See more comments</Trans>
                  </Text>
                  <Icon name="arrow-open-bottom-thin" size={12} color={theme.color.lightGray} />
                </div>
              </Box>
            ) : null}
          </React.Fragment>
        )}
      </ThemeConsumer>
    );
  }
}
