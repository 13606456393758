import cs from 'classnames';
import { type BasicContentObjectRedux } from 'models';
import { Button, HeartFilledIcon, HeartIcon } from 'ui';
import { useLikeObjectMutation, useUnlikeObjectMutation } from '../../../queries';

type P = {
  item: BasicContentObjectRedux;
};

const LikeButton = ({ item }: P) => {
  const { id, liked } = item;
  const { isLoading: isLoadingLikeObject, mutate: likeObject } = useLikeObjectMutation();
  const { isLoading: isLoadingUnlikeObject, mutate: unlikeObject } = useUnlikeObjectMutation();

  return (
    <div className="like-button">
      <Button
        type="text"
        className={cs('like-button__button', { 'like-button__heart-filled': liked })}
        icon={liked ? <HeartFilledIcon className="like-button__icon" /> : <HeartIcon className="like-button__icon" />}
        onClick={() => {
          liked ? unlikeObject({ id, reduxItem: item }) : likeObject({ id, reduxItem: item });
        }}
        loading={isLoadingLikeObject || isLoadingUnlikeObject}
        size="large"
      >
        Like
      </Button>
    </div>
  );
};

export default LikeButton;
