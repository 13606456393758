import moment from 'moment';
import { removeCircularReference } from 'common/utils';
import type Program from 'models/program';
import { ContentObjectDeprecated } from './content-object-deprecated';
import {
  type ContentObjectDataObjectDeprecated,
  type WithingsOrderPostDataObject,
} from './service/content-object-deprecated';
import { AutocompleteUser } from './user';

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export default class WithingsOrderPost<U> extends ContentObjectDeprecated<U> {
  html_content?: string;
  text_content?: string;
  type = 'withings_order_post';
  author: AutocompleteUser = new AutocompleteUser();
  todo_due_date?: moment.Moment;
  title?: string;

  constructor(
    content: ContentObjectDataObjectDeprecated,
    program: Program,
    data?: WithingsOrderPostDataObject,
    shareIn: U[] = [],
  ) {
    content = removeCircularReference(content);
    shareIn = removeCircularReference(shareIn);

    super(content, shareIn);

    if (data) {
      Object.assign(this, data);
    }

    this.publish_at = content.publish_at ? moment(content.publish_at) : moment().add(10, 'minutes');
    this.author = new AutocompleteUser(content.author);
    this.todo_due_date = content?.withings_order_post?.todo_due_date
      ? moment(content.withings_order_post.todo_due_date)
      : moment.min(this.publish_at.clone().add(1, 'day'), moment(program?.end_time));
  }
}
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
