import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ProfileDataObject } from 'models/service';

type SignUpRequest = {
  body: {
    user: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      password_confirmation: string;
      join_community_id?: string;
      time_zone: string;
    };
  };
  params?: {
    invitation_token?: string;
    organization_id?: string;
  };
};

export const signUp = async ({ body, params }: SignUpRequest) => {
  return apiClient.post<ProfileDataObject, ApiClientResponse<ProfileDataObject>>(routes.signup, body, {
    skipAuth: true,
    params,
  });
};
