import { type ReleaseNotesDataObject } from './service';

export type ReleaseNotes = {
  id: string;
  versionValue: string;
  text: string;
};

export const createReleaseNotes = (releaseNotes: ReleaseNotesDataObject): ReleaseNotes => ({
  id: releaseNotes._id,
  versionValue: releaseNotes.versionValue,
  text: releaseNotes.text,
});
