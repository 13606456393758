// TODO move this request to different folder once is reqused also for community invite
import { type InvitationTokenKind } from 'models';
import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type InviteTokenDataObject } from 'models/service';

type InviteTokenRequest = {
  kind: InvitationTokenKind;
  subject_id: string;
  subject_type: 'Event';
};

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
export const getInviteToken = async (data: InviteTokenRequest) =>
  apiClient.post<InviteTokenDataObject, ApiClientResponse<InviteTokenDataObject>>(routes.tokens.generateInvitation, {
    invitation_token: data,
  });
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
