import {
  type AutocompleteUser,
  InviteType,
  RegisteredEventInvitee,
  type EventEmailInvitee,
  type EventInviteeRole,
} from 'models';
import { useMemo } from 'react';
import { type FormInstance, message } from 'ui';

export type CommonFormValues = {
  invitees?: RegisteredEventInvitee[];
  moderators?: RegisteredEventInvitee[];
  presenters?: RegisteredEventInvitee[];
  emailInvitees?: EventEmailInvitee[];
  emailModerators?: EventEmailInvitee[];
  emailPresenters?: EventEmailInvitee[];
};

type RequiredFormValues = CommonFormValues & {
  communityId?: string;
};

type HookProperties = {
  skipPostInCommunityValidation?: boolean;
  form: FormInstance<RequiredFormValues>;
  onInviteesTypeChanged: (type: InviteType) => void;
};

export const mapInviteTypeToRole = (type: InviteType): EventInviteeRole => {
  const mapType: Record<string, EventInviteeRole> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '': 'attendee',
    invitees: 'attendee',
    moderators: 'moderator',
    presenters: 'presenter',
  };

  return mapType[type] ?? mapType.invitees;
};

export const mapTypeToEmailFieldName = (inviteType: InviteType) =>
  `email${inviteType[0].toUpperCase() + inviteType.slice(1)}`;

export const useEventInvitees = ({
  skipPostInCommunityValidation = false,
  form,
  onInviteesTypeChanged,
}: HookProperties) => {
  return useMemo(() => {
    const setInvitees = (
      forType: InviteType,
      type: InviteType,
      invitees: AutocompleteUser[],
      emailInvitees?: string[],
    ) => {
      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const mapInvitees = (invitees: AutocompleteUser[]): RegisteredEventInvitee[] => {
        return invitees.map(
          (invitee: AutocompleteUser) =>
            new RegisteredEventInvitee({
              id: invitee.id,
              first_name: invitee.first_name,
              last_name: invitee.last_name,
              profile_photo: invitee.profile_photo,
              event_role: mapInviteTypeToRole(forType),
            }),
        );
      };
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      const mapEmails = (emails?: string[]): EventEmailInvitee[] | undefined =>
        emails?.map((email: string) => ({
          email,
          eventRole: mapInviteTypeToRole(forType),
        }));

      if (type === forType) {
        form.setFieldsValue({
          [forType]: mapInvitees(invitees),
          [mapTypeToEmailFieldName(forType)]: mapEmails(emailInvitees),
        });
      } else {
        form.setFieldsValue({
          [forType]: (form.getFieldValue(forType) || []).filter(
            (invitee: RegisteredEventInvitee) =>
              !invitees.some((newInvitee: AutocompleteUser) => invitee.id === newInvitee.id),
          ),
          [mapTypeToEmailFieldName(forType)]: (form.getFieldValue(mapTypeToEmailFieldName(forType)) || []).filter(
            (invitee: EventEmailInvitee) => !emailInvitees?.find((email: string) => email === invitee.email),
          ),
        });
      }
    };

    const resetSelectedUsers = () => {
      setInvitees(InviteType.invitees, InviteType.invitees, [], []);
      setInvitees(InviteType.moderators, InviteType.moderators, [], []);
      setInvitees(InviteType.presenters, InviteType.presenters, [], []);
    };

    const onInvite = (type: InviteType, invitees: AutocompleteUser[], emailInvitees?: string[]) => {
      setInvitees(InviteType.invitees, type, invitees, emailInvitees);
      setInvitees(InviteType.moderators, type, invitees, emailInvitees);
      setInvitees(InviteType.presenters, type, invitees, emailInvitees);

      onInviteesTypeChanged(InviteType.empty);
    };

    const onShowInvitees = (type: InviteType) => () => {
      if (skipPostInCommunityValidation) {
        onInviteesTypeChanged(type);
      } else {
        const { communityId } = form.getFieldsValue(['communityId']);

        if (communityId) {
          onInviteesTypeChanged(type);
        } else {
          message.info('Please select community');
        }
      }
    };

    return {
      resetSelectedUsers,
      onInvite,
      onShowInvitees,
    };
  }, [skipPostInCommunityValidation, form, onInviteesTypeChanged]);
};
