import { useMutation } from 'query';
import { getFile } from 'features/feed/services';
import { type UploadedFileDataObject } from 'models/service';
import { message } from 'ui';

export const useGetFiles = () => {
  return useMutation<void, UploadedFileDataObject[]>( // TODO fix after Uploaded file type is correct
    async (attachments) => {
      const promises = attachments.map(async (attachment) => {
        const response = await getFile(attachment.download_url!); // TODO fix after Uploaded file type is correct
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([response.data]));
        link.download = attachment.filename;
        document.body.append(link);
        link.click();
        link.remove();
      });

      await Promise.all(promises);
    },
    {
      onError(error) {
        message.error('Files cannot be downloaded, please try again later.');
        console.error('Error while downloading files', error);
      },
    },
  );
};
