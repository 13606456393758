import { type UploadedFileDataObject } from 'models/service';

export const calculateFileSize = (size: number): string => {
  const divide = Math.round(size / 1024);
  return divide > 1024
    ? `${(divide / 1024).toFixed(1)} MB`
    : divide < 1
    ? `${size.toFixed(1)} B`
    : `${divide.toFixed(1)} kB`;
};

// TODO type?
export const isVisualFile = (file: UploadedFileDataObject): boolean => {
  return file.content_type.split('/')[0] === 'image';
};
