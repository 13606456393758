import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { type Task } from 'models/activity';
import { Button, Form, Row, Col, SelectInput, TextAreaInput, NumberInput, Text } from 'ui';

type P = {
  name: string;
  tasks: Task[];
  isBookmark: boolean;
  isMultipleSubmissionsAllowed: boolean;
};

const ActivityTasksFormInputs = ({ name, tasks, isBookmark, isMultipleSubmissionsAllowed }: P) => {
  const { t } = useTranslation();

  // @ts-expect-error
  const renderFormItem = (task: Task, field: { name: number; key: number }) => {
    switch (task.input) {
      case 'text':
        return (
          <TextAreaInput
            key={task.id}
            item={{
              ...field,
              name: [field.name, `${task.id}`],
              label: task.name,
              rules: [
                {
                  required: true,
                  message: t('This field is required'),
                  whitespace: true,
                },
              ],
            }}
            input={{
              placeholder: t('Description'),
              disabled: isBookmark,
            }}
          />
        );
      case 'number':
        return (
          <NumberInput
            key={task.id}
            item={{
              ...field,
              name: [field.name, `${task.id}`],
              label: task.name,
              rules: [
                {
                  required: true,
                  message: t('This field is required, provide a number value'),
                },
              ],
            }}
            input={{
              placeholder: '123.4',
              step: 0.01,
              min: 0,
              disabled: isBookmark,
            }}
          />
        );
      case 'select':
        return (
          <SelectInput
            key={task.id}
            item={{
              ...field,
              name: [field.name, `${task.id}`],
              label: task.name,
              rules: [
                {
                  required: true,
                  message: t('This field is required'),
                },
              ],
            }}
            input={{
              placeholder: t('Please make your selection'),
              options: task.options ?? [],
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
              showSearch: true,
              disabled: isBookmark,
            }}
          />
        );
      default:
    }
  };

  const renderFields = (field: any, activityNumber: number, removable: boolean, remove: (name: number) => void) => {
    return (
      <Form.Item required={false} key={field.key}>
        <Row item={{ className: 'tasks-form-inputs__header' }}>
          <Col item={{ span: 23 }}>
            <Text weight="bold">
              {t('Activity')} {isMultipleSubmissionsAllowed ? activityNumber : null}
            </Text>
          </Col>
          {removable ? (
            <Col item={{ span: 1, className: '' }}>
              <CloseOutlined
                onClick={() => {
                  remove(field.name);
                }}
                data-test-id="btn_delete-entry"
              />
            </Col>
          ) : null}
        </Row>
        <Row item={{ className: 'tasks-form-inputs__wrapper' }}>
          <Col item={{ span: 24 }}>{tasks.map((task) => renderFormItem(task, field))}</Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        const first = fields[0];
        const rest = fields.slice(1);

        return (
          <div>
            {renderFields(first, 1, fields.length > 1, remove)}
            {rest.map((field, index) => renderFields(field, index + 2, true, remove))}
            {isMultipleSubmissionsAllowed ? (
              <Col item={{ className: 'tasks-form-inputs__add-activity-wrapper' }}>
                <Form.Item>
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      add();
                    }}
                    icon={<PlusCircleOutlined className="tasks-form-inputs__add-activity-icon" />}
                    data-test-id="btn_add-activity"
                  >
                    {t('Add another activity')}
                  </Button>
                </Form.Item>
              </Col>
            ) : null}
          </div>
        );
      }}
    </Form.List>
  );
};

export default ActivityTasksFormInputs;
