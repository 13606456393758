import cs from 'classnames';
import { forwardRef, type VideoHTMLAttributes } from 'react';
import { Text } from 'ui';

const VideoPlayer = forwardRef<HTMLVideoElement, VideoHTMLAttributes<HTMLVideoElement>>(
  ({ children, className, ...rest }, reference) => {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption -- We don't have captions for those videos
      <video controls className={cs('video-player-ui', className)} ref={reference} {...rest}>
        {children}
        <Text>Your browser does not support the video tag.</Text>
      </video>
    );
  },
);

export default VideoPlayer;
