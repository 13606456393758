export const nodeApiRoutes = {
  members: {
    getById: (legacyId: string) => `/users?meta.externalIds=${legacyId}`,
  },
  accounts: {
    find: '/accounts',
  },
  releaseNotes: {
    find: '/release-notes',
  },
};
