import { type ReduxUser, type BasicContentObjectRedux } from 'models';
import { useState } from 'react';
import { Button, RepeatLine } from 'ui';
import { RepostModal } from './RepostModal';

type P = {
  item: BasicContentObjectRedux;
  viewer: ReduxUser;
};

const RepostButton = ({ item, viewer }: P) => {
  const [isRepostOpen, setIsRepostOpen] = useState<boolean>(false);

  return (
    <div>
      <Button
        type="text"
        icon={<RepeatLine />}
        onClick={() => {
          setIsRepostOpen(true);
        }}
        size="large"
      >
        Repost
      </Button>
      <RepostModal
        isOpened={isRepostOpen}
        onCancel={() => {
          setIsRepostOpen(false);
        }}
        item={item}
        viewer={viewer}
      />
    </div>
  );
};

export default RepostButton;
