import { type ReactNode } from 'react';

type P = {
  htmlFor: string;
  children: ReactNode;
};

const HiddenLabel = ({ htmlFor, children }: P) => {
  return (
    <label htmlFor={htmlFor} className="visually-hidden">
      {children}
    </label>
  );
};

export default HiddenLabel;
