import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type UploadedFileDataObject } from 'models/service';

type EditCommentRequest = {
  content_object: {
    id: string;
    parent_id: string;
    text_content?: string;
    html_content?: string;
    attachments?: UploadedFileDataObject[];
  };
};

export const editComment = async (data: EditCommentRequest) =>
  // TODO replace with type comment
  apiClient.put<any, ApiClientResponse<any>>(routes.contentObjects.comment.edit(data.content_object.id), data);
