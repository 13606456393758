import { useMutation } from '@tanstack/react-query';
import { currentUser } from '../../services';

export const useUserQuery = () =>
  useMutation({
    async mutationFn() {
      const userResponse = await currentUser();

      // TODO: Do not return DataObject
      return userResponse.data;
    },
  });
