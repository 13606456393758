import { type ContentObjectRedux, type Reply } from 'models';
import { Translation } from 'old/atoms/translation';
import { type RefObject, useState } from 'react';
import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { useCommunitiesUsersMutation } from 'features/communities/queries';
// TODO bad imports, this is this feature and should be reusable components
import { Attachments } from 'features/feed/components';
import { AvatarLink } from 'features/feed/ContentObjectHeader/AvatarLink';
import { UserNameLink } from 'features/feed/ContentObjectHeader/UserNameLink';
import { useProgramsUsersMutation } from 'features/programs/queries';
import { type ReplyDataObject } from 'models/service';
import { type AutocompleteUser, type ReduxUser } from 'models/user';
import { canCommentContentObject } from 'permissions';
import { Row, Col, RichTextRenderer } from 'ui';
import { CreateCommentForm, EditCommentForm } from '../../CommentForm';
import { CommentActions } from './CommentActions';
import { CommentFooter } from './CommentFooter';

type P = {
  viewer: ReduxUser;
  comment: Reply;
  commentDataObject: ReplyDataObject;
  contentObjectRedux: ContentObjectRedux;
  scrollRef?: RefObject<HTMLDivElement>;
};

const Comment = ({ viewer, comment, commentDataObject, contentObjectRedux, scrollRef }: P) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isReplying, setIsReplying] = useState(false);

  const { mutateAsync: getCommunitiesUsers } = useCommunitiesUsersMutation();

  const { mutateAsync: getProgramUsers } = useProgramsUsersMutation();

  const onRequestMentions = async (search: string): Promise<AutocompleteUser[]> => {
    if (contentObjectRedux.contentable_type === 'Community' && contentObjectRedux.contentable_id) {
      return getCommunitiesUsers({
        communitiesIds: [contentObjectRedux.contentable_id],
        limit: 5,
        search,
      });
    }

    if (contentObjectRedux.contentable_type === 'Program' && contentObjectRedux.contentable_id) {
      return getProgramUsers({ programsIds: [contentObjectRedux.contentable_id], limit: 5, search });
    }

    return [];
  };

  return (
    <Row item={{ className: 'comment', ref: scrollRef, gutter: [variables.spaceXs.value, 0] }}>
      <Col item={{ className: 'comment__author-photo' }}>
        <AvatarLink
          authorId={comment.author.id}
          avatar={{ url: comment.author.profilePhoto?.url ?? images.default.userProfilePhoto }}
        />
      </Col>
      <Col item={{ className: 'comment__body' }}>
        <div className="comment__message">
          <Row item={{ justify: 'space-between', className: 'comment__header' }}>
            <Col>
              <UserNameLink
                authorId={comment.author.id}
                title={`${comment.author.firstName} ${comment.author.lastName}`}
              />
            </Col>
            <Col>
              <CommentActions
                viewer={viewer}
                comment={comment}
                commentDataObject={commentDataObject}
                contentObjectRedux={contentObjectRedux}
                onEditObject={() => {
                  setIsEditing(true);
                }}
              />
            </Col>
          </Row>
          <div>
            {isEditing ? (
              <EditCommentForm
                viewer={viewer}
                comment={comment}
                onRequestMentions={onRequestMentions}
                onSuccess={() => {
                  setIsEditing(false);
                }}
              />
            ) : (
              <>
                {comment.htmlContent ? <RichTextRenderer data={comment.htmlContent} /> : null}
                {comment.textContent ? <Translation id={comment.id} type={comment.type} /> : null}
                {comment.attachments.length > 0 ? <Attachments attachments={comment.attachments} /> : null}
              </>
            )}
          </div>
        </div>

        <CommentFooter
          comment={comment}
          replyDataObject={commentDataObject}
          onReplyClick={
            canCommentContentObject({ viewer, contentObject: contentObjectRedux })
              ? () => {
                  setIsReplying((previousState) => !previousState);
                }
              : undefined
          }
        />

        {isReplying ? (
          <CreateCommentForm
            viewer={viewer}
            parentId={
              comment.parentId === contentObjectRedux.id || comment.parentId === contentObjectRedux.contentObjectId
                ? comment.id
                : comment.parentId
            }
            onRequestMentions={onRequestMentions}
            initialMode="input"
            onSuccess={() => {
              setIsReplying(false);
            }}
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default Comment;
