import * as React from 'react';
import { Trans } from 'react-i18next';

import { ClockCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import numbro from 'numbro';
import { canSeeTags } from 'permissions';
import { Progress, RichTextRenderer, TagList } from 'ui';
import { Box } from '../box';
import { H3, Paragraph, Text } from '../text';
import { ThemeConsumer } from '../theme';
import { Translation } from '../translation';

export class CampaignDetail extends React.Component {
  renderTimeInfo() {
    const { campaign } = this.props;

    if (campaign.finished) {
      return (
        <Box flexDirection="row" className="campaign-detail-time-info campaign-finished">
          <ClockCircleOutlined className="icon" /> <Trans>Campaign is finished.</Trans>
        </Box>
      );
    } else {
      return (
        <React.Fragment>
          <Box flexDirection="row" className="campaign-detail-time-info">
            <ClockCircleOutlined className="icon" /> {t('campaignEndsOn', { date: new Date(campaign.end_time) })}
          </Box>
        </React.Fragment>
      );
    }
  }

  percent() {
    return (this.props.campaign.amount_total / this.props.campaign.amount_goal) * 100;
  }

  render() {
    const { campaign, viewer } = this.props;

    return (
      <ThemeConsumer>
        {() => (
          <>
            <Box flexDirection="row" justifyContent="space-between">
              <H3>{campaign.name}</H3>
              {/* $FlowMissingDefinition */}
            </Box>
            <Text size="13px" color="#717E94">
              {this.renderTimeInfo()}
            </Text>

            <Paragraph style={{ whiteSpace: 'pre-line', textAlign: 'left', marginTop: 20 }}>
              <table className="campaign-detail-description" id="campaign-about">
                <tbody>
                  <tr>
                    <th>
                      <Trans>Goal</Trans>:
                    </th>
                    <td>
                      {numbro(campaign.amount_goal).formatCurrency({
                        mantissa: 2,
                        thousandSeparated: true,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Raised</Trans>:
                    </th>
                    <td>
                      {numbro(campaign.amount_total).formatCurrency({
                        mantissa: 2,
                        thousandSeparated: true,
                      })}
                    </td>
                  </tr>
                  {!campaign.finished && (
                    <tr>
                      <th>
                        <Trans>To Go</Trans>:
                      </th>
                      <td>
                        {numbro(Math.max(campaign.amount_goal - campaign.amount_total, 0)).formatCurrency({
                          mantissa: 2,
                          thousandSeparated: true,
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Progress
                percent={this.percent()}
                showInfo={false}
                strokeLinecap={'square'}
                className="campaign-progress-bar"
              />
              <RichTextRenderer data={campaign.html_content} />
              {campaign.text_content ? <Translation id={campaign.id} type="campaign" /> : null}
            </Paragraph>
            {viewer && campaign.tags.length > 0 && canSeeTags({ viewer, organizationId: campaign.organization.id }) ? (
              <>
                <H3>{t('Tags')}</H3>
                <TagList tags={campaign.tags} />
              </>
            ) : null}
          </>
        )}
      </ThemeConsumer>
    );
  }
}
