import { getDate, getDaysInMonth, getMonth, getYear, setMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Col, Row, Select, InputNumber } from 'ui';

export type BirthDateType = {
  day: number;
  month: number;
  year: number;
  date: Date;
  isNumberOfDaysValid?: boolean;
};

type P = {
  value?: Partial<BirthDateType>;
  onChange?: (value: Partial<BirthDateType>) => void;
  id?: string;
};

// TODO move this somewhere esle
export const formatBirthdayToString = (birthdate: BirthDateType) => {
  return `${String(birthdate.year).padStart(4, '0')}-${String(Number(birthdate.month) + 1).padStart(2, '0')}-${String(
    birthdate.day,
  ).padStart(2, '0')}`;
};

const generateBirthDateTypeValue = (birthDate: Date) => ({
  date: birthDate,
  day: getDate(birthDate),
  month: getMonth(birthDate),
  year: getYear(birthDate),
});

export const getBirthDateTypeValue = (birthdate?: Date | string) => {
  if (typeof birthdate === 'string') {
    // TODO this is hack `+ 'T00:00:00'` because Date is parsing wrong format `2023-01-01` this become different day in timezones places where going back time compared to UTC
    return generateBirthDateTypeValue(new Date(birthdate + 'T00:00:00'));
  }

  if (birthdate instanceof Date) {
    return generateBirthDateTypeValue(birthdate);
  }

  return undefined;
};

const BirthDatePicker = ({ value, onChange, id }: P) => {
  const { t } = useTranslation();
  const makeMonthOptions = () => {
    const options = [];
    const date = new Date();

    for (let index = 0; index < 12; index += 1) {
      const then = setMonth(date, index);

      options.push({
        value: index,
        label: t('dateFormatMonth', { date: then }),
      });
    }

    return options;
  };

  const onChangeDate = ({ day, month, year }: Partial<BirthDateType>) => {
    const selectedDay = day ?? value?.day;
    const selectedMonth = month ?? value?.month;
    const selectedYear = year ?? value?.year;

    const daysInMonth =
      selectedYear !== undefined && selectedMonth !== undefined
        ? getDaysInMonth(new Date(selectedYear, selectedMonth))
        : undefined;

    const isNumberOfDaysValid = daysInMonth !== undefined && selectedDay !== undefined && daysInMonth >= selectedDay;

    return onChange?.({
      day: selectedDay,
      month: selectedMonth,
      year: selectedYear,
      date:
        selectedDay !== undefined && selectedMonth !== undefined && selectedYear !== undefined && isNumberOfDaysValid
          ? new Date(selectedYear, selectedMonth, selectedDay, 12, 0)
          : undefined,
      isNumberOfDaysValid,
    });
  };

  const filterOption = (input: string, option?: { value: number; label: string }): boolean => {
    if (!option) {
      return false;
    }

    return option?.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Row
      item={{
        gutter: [variables.spaceXs.value, variables.spaceXs.value],
        className: 'birth-date-picker',
        // To be focused on error, we need an id for the input, the ant design adds the id automatically to the input, but this component has a different structure and the id is not added correctly
        id,
      }}
    >
      <Col item={{ span: 8 }}>
        <Select
          showSearch
          filterOption={filterOption}
          options={makeMonthOptions()}
          value={value?.month}
          placeholder={t('Month')}
          onChange={(selectedValue) => onChangeDate({ month: Number(selectedValue) })}
        />
      </Col>
      <Col item={{ span: 8 }}>
        <InputNumber
          className="birth-date-picker__input"
          value={value?.day}
          min={1}
          max={31}
          onChange={(selectedValue) => onChangeDate({ day: selectedValue ?? undefined })}
          // TODO remove placeholder after every input has its own label in #59310
          placeholder="DD"
        />
      </Col>
      <Col item={{ span: 8 }}>
        <InputNumber
          className="birth-date-picker__input"
          value={value?.year}
          min={1}
          max={getYear(new Date())}
          onChange={(selectedValue) => onChangeDate({ year: Number(selectedValue) })}
          // TODO remove placeholder after every input has its own label in #59310
          placeholder="YYYY"
        />
      </Col>
    </Row>
  );
};

export default BirthDatePicker;
