import { type IconSize } from 'ui/Icons/icon-wrapper';
import { AudioFileIcon, DefaultFileIcon, ImgFileIcon, VideoFileIcon, Image } from 'ui';

type P = {
  type: string;
  url?: string;
  size: IconSize;
};

const ContentFileIcon = ({ type, url, size }: P) => {
  switch (type) {
    case 'video':
      return <VideoFileIcon size={size} />;
    case 'audio':
      return <AudioFileIcon size={size} />;
    case 'image':
      return url ? (
        <Image className="content-file-icon__image" src={url} preview={false} borderRadius="small" objectFit="cover" />
      ) : (
        <ImgFileIcon size={size} />
      );
    default:
      return <DefaultFileIcon />;
  }
};

export default ContentFileIcon;
