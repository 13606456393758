import { type Reply, type Comment } from 'models';
import variables from 'common/styles/variables.json';
import { type ReplyDataObject } from 'models/service';
import { Button, Row, Col } from 'ui';
import { LikeCount } from '../../../components';
import { useLikeObjectMutation, useUnlikeObjectMutation } from '../../../queries';
import { CommentAge } from './CommentAge';

type P = {
  comment: Comment | Reply;
  onReplyClick?: () => void;
  replyDataObject: ReplyDataObject;
};

const CommentFooter = ({ onReplyClick, comment, replyDataObject: reduxComment }: P) => {
  const { mutate: likeObject, isLoading: isLiking } = useLikeObjectMutation();
  const { mutate: unlikeObject, isLoading: isUnliking } = useUnlikeObjectMutation();

  const handleLike = (comment: Comment | Reply) => {
    comment.liked
      ? unlikeObject({ id: comment.id, reduxItem: reduxComment })
      : likeObject({ id: comment.id, reduxItem: reduxComment });
  };

  return (
    <Row item={{ justify: 'space-between', className: 'comment-footer' }}>
      <Col>
        <Row item={{ gutter: [variables.spaceMd.value, 0] }}>
          <Col>
            <CommentAge createdAt={comment.createdAt} />
          </Col>
          <Col>
            <Button
              type="link-secondary"
              onClick={() => {
                handleLike(comment);
              }}
              loading={isLiking || isUnliking}
            >
              Like
            </Button>
          </Col>
          {onReplyClick ? (
            <Col>
              <Button type="link-secondary" onClick={onReplyClick}>
                Reply
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col>{comment.likesCount > 0 ? <LikeCount count={comment.likesCount} liked={comment.liked} /> : null}</Col>
    </Row>
  );
};

export default CommentFooter;
