import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { User, Profile } from '../../../common/models';
import { routes } from '../../../common/services';
import UserCard from '../../../components/UserCard';
import { usersSearch } from '../../../recoil/atoms';
import { usersState } from '../../../recoil/selectors';
import {
  CustomTagProps as CustomTagProperties,
  Avatar,
  Button,
  Col,
  Empty,
  Row,
  Select,
  SelectSearch,
  Spin,
  Tag,
} from '../../../ui';

import { XIcon } from '../../../ui/Icons';
import { getInitials } from '../../../utils';

type P = {
  members: User[];
  onMembersChange: (members: User[]) => void;
  onCancelClick: () => void;
  currentUserProfile: Profile;
  defaultNewChatroomUser?: User;
};

interface CustomTagPropertiesValueType extends CustomTagProperties {
  value: string;
}

const Header = ({ members, onCancelClick, onMembersChange, currentUserProfile, defaultNewChatroomUser }: P) => {
  const { t } = useTranslation(['translationChat']);
  const searchUsersData = useRecoilValueLoadable(usersState);
  const [usersSearchState, setUsersSearchState] = useRecoilState(usersSearch);

  const handleOnSearch = debounce((value: string) => {
    setUsersSearchState(value);
  }, 500);

  // TODO: investigate 'value' type and change it from unknown to correct one
  const handleOnChange = (values: unknown) => {
    if (Array.isArray(values)) {
      const selectedIds = values as string[];

      const newMembers: User[] = [];
      
      for (const selectedUserId of selectedIds) {
        const previousUser = members.find(member => member._id === selectedUserId);
        const newUser = searchUsersData.contents.result.find((user: User) => user._id === selectedUserId);
  
        if (previousUser || newUser) {
          newMembers.push(previousUser || newUser);
        }
      }
  
      onMembersChange(newMembers);
    }
  }

  const handleOnBlur = () => setUsersSearchState(null);

  const handleCancelClick = onCancelClick;

  const renderSelectError = () => (
    <Select.Option disabled key={0} value={''} label={''}>
      <Empty />
    </Select.Option>
  );

  const renderSelectOptions = () => {
    switch (searchUsersData.state) {
      case 'hasValue':
        if (searchUsersData.contents.error) {
          return renderSelectError();
        }

        return [
          ...searchUsersData.contents.result,
          ...(defaultNewChatroomUser && !usersSearchState ? [defaultNewChatroomUser] : []),
        ]
          .filter((u: User) => u._id !== currentUserProfile?._id)
          .map((u: User) => (
            <Select.Option key={u._id} value={u._id} label={u.getFullName()}>
              <UserCard user={u} />
            </Select.Option>
          ));

      case 'loading':
        return (
          <Select.Option key={0} value={''} label={''}>
            <Spin />
          </Select.Option>
        );
      case 'hasError':
        return renderSelectError();
    }
  };

  const renderCustomTags = (props: CustomTagPropertiesValueType) => (
    <Tag {...props} closable className="user-tag">
      <Avatar
        size="small"
        alt={`${props.value.toString()} profile photo`}
        src={
          new URL(
            routes.chatrooms.media.getAvatarFile(props.value.toString(), 'user_avatar', 'medium'),
            process.env.REACT_APP_MEDIA_MODULE_URL,
          ).href
        }
        className="user-tag__avatar"
      >
        {typeof props.label === 'string'
          ? getInitials(props.label.split(' ')[0], props.label?.split(' ')[props.label.split(' ').length - 1])
          : null}
      </Avatar>
      <span className="label--medium user-tag__name">{props.label}</span>
    </Tag>
  );

  return (
    <Row className="chatroom-detail__header__adding" align={'middle'}>
      <Col flex={'0 0 auto'}>
        <span className="label--large">{t('chat.chatroomDetail.header.newMessageTo')}</span>
      </Col>

      <Col flex={'1'} className="col--input">
        <SelectSearch
          id="chat-detail-header-search-user"
          autoClearSearchValue
          className="select-search--add-members"
          placeholder={t('chat.chatroomDetail.header.addMembersPlaceholder')}
          optionLabelProp={'label'}
          mode={'multiple'}
          loading={searchUsersData.state === 'loading'}
          popupClassName={'select-add-user-dropdown'}
          tagRender={renderCustomTags}
          onSearch={(value: string) => handleOnSearch(value)}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          createCustomOptions={renderSelectOptions}
          defaultValue={defaultNewChatroomUser?._id ? [defaultNewChatroomUser._id] : []}
        />
      </Col>

      <Col flex={'0 0 auto'}>
        <Button
          type="link"
          className="icon icon--cancel"
          onClick={handleCancelClick}
          id="chat-detail-header-close"
          size="large"
        >
          <XIcon width={16} height={16} className="x-icon" />
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
