import { useQueryClient } from '@tanstack/react-query';
import { type Image } from 'models';
import { useMutation } from 'query';
import { createVideo } from 'features/videos/services';
import { type ImageUploadedData } from 'ui';
import { getVideosInfiniteQueryKey } from '..';

export type VideoVariables = {
  title: string;
  organizationId: string;
  textContent: string;
  htmlContent: string;
  recordedAt: string;
  coverPhoto?: ImageUploadedData<Image>;
};

export const useCreateVideo = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, VideoVariables>(
    async (video) => {
      const response = await createVideo({
        video: {
          /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
          title: video.title,
          contentable_type: 'Organization',
          contentable_id: video.organizationId,
          text_content: video.textContent,
          html_content: video.htmlContent,
          recorded_at: video.recordedAt,
          cover_photo: video.coverPhoto?.image,
          // TODO: hardcode for now because we do not have price in application model
          price_option: 'free',
          /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
        },
      });

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(getVideosInfiniteQueryKey({}));
      },
    },
  );
};
