import { type DefaultImage, type Image } from 'models';
import { useTranslation } from 'react-i18next';
import { uploadImage } from 'common/queries';
import { type ImageUploadedData, Form, ImageInput, Modal, ModalFormActions, Section, transformImageForInit } from 'ui';

type FormValues = {
  image: ImageUploadedData<Image>;
};

type P = {
  title: string;
  image: DefaultImage | Image;
  isOpened: boolean;
  isLoading?: boolean;
  onSubmit: (image: Image) => void;
  onCancel: () => void;
};

const EditImageModal = ({ title, isOpened, isLoading, onSubmit, onCancel, image }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();

  const handleFinish = (values: FormValues) => {
    onSubmit(values.image.image);
  };

  const initialValues = {
    image: transformImageForInit(image),
  };

  return (
    <Modal disableBack maskClosable={false} onCancel={onCancel} isOpened={isOpened} title={title}>
      <Form<FormValues> initialValues={initialValues} form={form} onFinish={handleFinish}>
        <Section>
          <ImageInput
            validations={[
              {
                key: 'image_size',
                isValidImage: (image) => image.width === 960 && image.height === 540,
                message: t('Cover Image has to be {{width}}px width and {{height}}px height', {
                  width: 960,
                  height: 540,
                }),
              },
            ]}
            uploadFile={uploadImage}
            item={{ name: 'image' }}
          />
        </Section>
        <ModalFormActions submit={{ children: t('Save'), loading: isLoading }} />
      </Form>
    </Modal>
  );
};

export default EditImageModal;
