import { type ReduxUser } from 'models';

export const canReportContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject?: { author?: { id?: string } };
}) => !isAuthor({ viewer, contentObject });

const isAuthor = ({ viewer, contentObject }: { viewer: ReduxUser; contentObject?: { author?: { id?: string } } }) =>
  viewer.id === contentObject?.author?.id;
