import { AppLayout } from 'old/layouts/AppLayout';
import { useParams } from 'react-router-dom';
import { VideoDetail } from 'features/videos';
import { Title } from 'ui';

const PageVideoDetail = () => {
  const { videoId } = useParams<{ videoId: string }>();

  return (
    <AppLayout
      center={
        <>
          {/* TODO: fix layout */}
          <Title level={1}>Video Upload</Title>
          <VideoDetail videoId={videoId} />
        </>
      }
    />
  );
};

export default PageVideoDetail;
