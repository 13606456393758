import { Collapse as CollapseA } from 'antd';
import cn from 'classnames';
import { type ComponentProps } from 'react';
import { DownLine } from 'ui/Icons';

type P = ComponentProps<typeof CollapseA>;

const { Panel } = CollapseA;

const Collapse = ({ className, ...rest }: P) => {
  return (
    <CollapseA
      className={cn(className, 'ui-collapse')}
      expandIcon={({ isActive }) => (
        <DownLine rotate={isActive ? 0 : -90} spanClassName="ui-collapse__arrow ant-collapse-arrow" />
      )}
      {...rest}
    />
  );
};

Collapse.Panel = Panel;

export default Collapse;
