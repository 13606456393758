import { Avatar as AvatarAD } from 'antd';

// TODO: Extend type to use `title: string`
export type AvatarProperties = React.ComponentProps<typeof AvatarAD>;

const Avatar = ({ children, ...rest }: AvatarProperties) => {
  return (
    // TODO: Unify Avatar components in parent repository and maybe use `title={title}` for better accessibility.
    <span className="avatar">
      <AvatarAD {...rest}>{children}</AvatarAD>
    </span>
  );
};

Avatar.Group = AvatarAD.Group;

export default Avatar;
