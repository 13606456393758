import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { formatBirthdayToString } from 'ui';
import { type DeliveryData, type InfoData, type PersonalData } from '../types';
import { imperialUnits } from '../utils';
import { type SubscribeDataObject } from './data-objects';

export const subscribe = async ({
  programId,
  infoData,
  personalData,
  deliveryData,
}: {
  programId: string;
  infoData: InfoData;
  personalData: PersonalData;
  deliveryData: DeliveryData;
}) => {
  const shortname = (personalData.firstName + personalData.lastName + '111')
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toUpperCase()
    .slice(0, 3);

  /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
  const data = {
    order: {
      program_id: programId,
      mailingpref: Boolean(infoData.marketing),
      birthdate: formatBirthdayToString(personalData.birthDate),
      unit_pref: {
        weight: 1,
        height: 6,
        distance: 6,
        temperature: 11,
      },
      measures: [
        {
          value: imperialUnits.units.height.convertToDefault(personalData.heightInImperial),
          unit: -2,
          type: 4,
        },
        {
          value: imperialUnits.units.weight.convertToDefault(personalData.weightInImperial),
          unit: 0,
          type: 1,
        },
      ],
      gender: personalData.gender === 'male' ? 0 : 1,
      preflang: personalData.prefferedLanguage,
      email: deliveryData.email,
      timezone: personalData.prefferedTimezone,
      shortname: shortname.trim(),
      firstname: personalData.firstName.trim(),
      lastname: personalData.lastName.trim(),
      order: {
        address: {
          name: `${deliveryData.firstName} ${deliveryData.lastName}`.trim(),
          email: deliveryData.email,
          telephone: deliveryData.phone,
          address1: deliveryData.address1.trim(),
          address2: deliveryData.address2.trim(),
          city: deliveryData.city.trim(),
          zip: deliveryData.zip.trim(),
          state: deliveryData.state,
          country: deliveryData.country,
        },
      },
    },
  };
  /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

  return apiClient.post<SubscribeDataObject, ApiClientResponse<SubscribeDataObject>>(routes.withings.subscribe, data);
};
