import cn from 'classnames';
import { type ReduxUser } from 'models';
import { getAppConfig } from 'old/atoms/app-config-gate';
import { type ReactText, useMemo, type ReactNode, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, Divider, Text } from '../../../../';

import { navigationMenuConfig } from './config';
import { generateNavigationMenuIcon } from './NavigationMenuIcon';
import { NavigationMenuLabel, type BadgeOptions } from './NavigationMenuLabel';

type MenuSection = Array<{
  id: string;
  key: string;
  icon: ReactNode;
  label: ReactNode;
  alias?: string;
}>;

const isActive = (pathname: string, key: string, alias?: string) =>
  Boolean(pathname.startsWith(key) || (alias && pathname.includes(alias)));

const findActiveMenuSectionKey = (pathname: string, menuItems: MenuSection) =>
  menuItems.find((item) => isActive(pathname, item.key, item.alias))?.key;

type P = {
  viewer: ReduxUser;
  isCollapsed: boolean;
  badges?: BadgeOptions;
};

const NavigationMenu = ({ viewer, isCollapsed, badges }: P) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const menuSections = useMemo(() => {
    const menuSections: MenuSection[] = [];

    for (const menuSection of navigationMenuConfig) {
      const generatedMenuSection: MenuSection = [];

      const section = menuSection.section ?? [];

      for (const menuItem of section) {
        if (!menuItem.isHidden?.({ appConfig: getAppConfig(), viewer })) {
          generatedMenuSection.push({
            id: menuItem.id,
            key: menuItem.key,
            icon: generateNavigationMenuIcon({
              config: menuItem,
              pathname,
              isCollapsed,
              badges,
              isActive,
            }),
            label: <NavigationMenuLabel config={menuItem} isCollapsed={isCollapsed} badges={badges} />,
            alias: menuItem.alias,
          });
        }
      }

      if (generatedMenuSection.length > 0) {
        menuSections.push(generatedMenuSection);
      }
    }

    return menuSections;
  }, [badges, isCollapsed, pathname, viewer]);

  const onSelect = ({ key }: { key: ReactText }) => {
    const path = String(key);

    if (path === pathname) {
      window.scrollTo(0, 0);
    } else {
      push(path);
    }
  };

  return (
    <>
      {menuSections.map((menuSection, index) => {
        const active = findActiveMenuSectionKey(pathname, menuSection);

        return (
          <Fragment key={index}>
            {navigationMenuConfig[index].name && navigationMenuConfig[index].collapsedName ? (
              <div className="navigation-menu-text">
                <Text type="secondary">
                  {isCollapsed ? navigationMenuConfig[index].collapsedName : navigationMenuConfig[index].name}
                </Text>
              </div>
            ) : null}
            <Menu
              multiple
              onDeselect={onSelect}
              onSelect={onSelect}
              theme="light"
              mode="inline"
              selectedKeys={active ? [active] : []}
              className="navigation-menu"
              inlineCollapsed={isCollapsed}
              items={menuSection}
            />

            {index < menuSections.length - 1 ? (
              <Divider
                className={cn('navigation-menu-divider', { 'navigation-menu-divider--collapsed': isCollapsed })}
              />
            ) : null}
          </Fragment>
        );
      })}
    </>
  );
};

export default NavigationMenu;
