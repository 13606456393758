import { type IconSize } from 'ui/Icons/icon-wrapper';
import {
  CodeFileIcon,
  DefaultFileIcon,
  DocumentFileIcon,
  PdfFileIcon,
  PptFileIcon,
  SheetFileIcon,
  VideoFileIcon,
  ZipFileIcon,
} from 'ui';

type P = {
  subtype: string;
  size: IconSize;
};

// eslint-disable-next-line complexity
const ApplicationFileIcon = ({ subtype, size }: P) => {
  switch (subtype) {
    case 'pdf':
    case 'postscript':
    case 'vnd.ms-xpsdocument':
    case 'epub+zip':
      return <PdfFileIcon size={size} />;
    case 'msword':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'rtf':
    case 'vnd.oasis.opendocument.text':
    case 'vnd.ms-works':
      return <DocumentFileIcon size={size} />;
    case 'vnd.ms-powerpoint':
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'vnd.apple.keynote':
    case 'vnd.oasis.opendocument.presentation':
      return <PptFileIcon size={size} />;
    case 'zip':
    case 'vnd.rar':
    case 'x-7z-compressed':
    case 'x-tar':
    case 'gzip':
      return <ZipFileIcon size={size} />;
    case 'vnd.ms-excel':
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'vnd.oasis.opendocument.spreadsheet':
      return <SheetFileIcon size={size} />;
    case 'x-virtualbox-vbox':
    case 'x-vmware-vmx':
      return <VideoFileIcon size={size} />;
    case 'javascript':
      return <CodeFileIcon size={size} />;
    default:
      return <DefaultFileIcon size={size} />;
  }
};

export default ApplicationFileIcon;
