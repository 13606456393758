import { type CalendarSync } from 'models';
import { type ReactNode, useMemo } from 'react';
import { routes } from 'routes';
import { useCalendarAzureLoginMutation, useRemoveCalendarSyncMutation } from 'features/user-profile/queries';
import { AppleIcon, OutlookIcon } from 'ui';

export type CalendarOptionType = 'apple' | 'google' | 'outlook';
export type CalendarOptionStatus = 'synced' | 'unsynced' | 'upcoming';

export type CalendarOption = {
  loading?: boolean;
  type: CalendarOptionType;
  status: CalendarOptionStatus;
  icon: ReactNode;
  title: ReactNode;
  onSync?: () => void;
  onDesync?: () => void;
};

export const useCalendarOptions = ({ calendars }: { calendars?: CalendarSync[] }): { calendars: CalendarOption[] } => {
  const { isLoading: isUnsyncingOutlook, mutate: unsyncOutlook } = useRemoveCalendarSyncMutation({ provider: 'azure' });

  const { isLoading: isSyncingOutlook, mutate: syncOutlook } = useCalendarAzureLoginMutation();

  return useMemo(
    () => ({
      calendars: [
        {
          loading: isSyncingOutlook || isUnsyncingOutlook,
          type: 'outlook',
          status: calendars?.some((calendar) => calendar.provider === 'azure') ? 'synced' : 'unsynced',
          // TODO add size prop
          icon: <OutlookIcon width={24} height={24} />,
          title: 'Outlook',
          onSync() {
            // TODO this is hack
            // TODO also should come from page
            syncOutlook({ redirectUrl: window.location.origin + routes.onboardingTour.calendarSyncUrl });
          },
          onDesync() {
            unsyncOutlook({});
          },
        },
        {
          type: 'apple',
          status: 'upcoming',
          // TODO add size prop
          icon: <AppleIcon width={24} height={24} />,
          title: 'Apple',
        },
      ],
    }),
    [calendars, isSyncingOutlook, isUnsyncingOutlook, syncOutlook, unsyncOutlook],
  );
};
