import { useEffect } from 'react';
import { isSignUpOrganizationEnabled } from 'common/validators';
import { usePublicOrganizationsInfiniteQuery } from 'features/organizations/queries';
import { type PrimaryOrganizationOption, useCreatePrimaryOrganizationOption } from '../PrimaryOrganizationSelect';

type P = {
  communityId?: string;
  onPrimaryOrganization: (organization: PrimaryOrganizationOption) => void;
};

export const usePreselectPrimaryOrganization = ({ communityId, onPrimaryOrganization }: P) => {
  const createPrimaryOrganizationOption = useCreatePrimaryOrganizationOption();
  const isEnabled = Boolean(communityId) && isSignUpOrganizationEnabled();

  const { isLoading, isFetching, data } = usePublicOrganizationsInfiniteQuery({
    filter: { communityId },
    options: { enabled: isEnabled },
  });

  useEffect(() => {
    const organization = data?.pages[0][0];

    if (isEnabled && organization) {
      onPrimaryOrganization(createPrimaryOrganizationOption(organization));
    }
  }, [onPrimaryOrganization, createPrimaryOrganizationOption, isEnabled, data]);

  return { isLoading: isEnabled && isLoading && isFetching };
};
