import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { default as PageVideos } from './PageVideos';

// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint does not consider this component as a component but as a function because of .js file type
const PageVideosReduxConnect = ({ viewer }) => <PageVideos viewer={viewer} />;

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export default connect(mapState)(PageVideosReduxConnect);
