import { OrganizationListItem } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { type DefaultResponseErrorDataObject, type ApiClientResponseError } from 'common/services';
import { getOrganizations } from '../services';
import { getOrganizationsInfiniteQueryKey } from './';

type P = {
  filter?: { search?: string };
  pagination: { pageSize: number };
};

export const useOrganizationsInfiniteQuery = ({ filter, pagination }: P) =>
  useInfiniteQuery<OrganizationListItem[], ApiClientResponseError<DefaultResponseErrorDataObject>>(
    getOrganizationsInfiniteQueryKey({ filter, pagination }),
    async ({ pageParam: cursor }: QueryFunctionContext<QueryKey, string>) => {
      const { data: organizations } = await getOrganizations({
        limit: pagination.pageSize,
        cursor,
        search: filter?.search,
      });

      return organizations.map((organization) => new OrganizationListItem(organization));
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.length < pagination.pageSize ? undefined : lastPage[lastPage.length - 1]?.id,
    },
  );
