import { Component } from 'react';
import { Content } from './shared/Content';
import { Attachments } from './shared/Attachments';
import { VisualAttachments } from './shared/VisualAttachments';
import { Kudo } from './shared/Kudo';
import { ReadMore } from 'ui';
import { ContentObjectPreview } from 'features/feed';
import { MarkAsRead } from 'features/programs';
import { can } from 'old/utils/permissions';

const PADDING = 20;

export class Renderer extends Component {
  renderMarkAsRead() {
    const { item, isBookmark } = this.props;

    if (item.contentable_type === 'Program' && !can(item.program, 'Course', 'contentCreator')) {
      return (
        <div className="renderer-core__mark-as-read">
          <MarkAsRead postId={item.post.id} status={item.post.status} view={isBookmark ? 'bookmark' : 'other'} />
        </div>
      );
    }

    return null;
  }

  render() {
    const { item, isOnFeed } = this.props;

    const postBody = (
      <>
        <Kudo item={item} />
        <Content item={item} />

        <div style={{ paddingLeft: PADDING, paddingRight: PADDING, display: 'inline-block', width: '100%' }}>
          <Attachments item={item} /> {/** TODO replace by new attachments? */}
          {item.shared_content_object && (
            <ContentObjectPreview item={item.shared_content_object} viewer={this.props.viewer} view="feed" />
          )}
        </div>

        {this.renderMarkAsRead()}
      </>
    );

    return (
      <>
        <VisualAttachments item={item} /> {/** TODO should this component exist by itself?  */}
        <div className="renderer-core">
          {isOnFeed && !item.shared_content_object ? <ReadMore>{postBody}</ReadMore> : postBody}
        </div>
      </>
    );
  }
}

export const PostRendererCore = Renderer;
