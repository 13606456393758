import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { UserProfile } from 'features/user-profile';

// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint does not consider this component as a component but as a function because of .js file type
const PageUserProfile = ({ viewer }) => <UserProfile user={viewer} />;

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export default connect(mapState)(PageUserProfile);
