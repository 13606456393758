import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans, withTranslation } from 'react-i18next';
import { AppLayout } from '../../layouts/AppLayout';
import { Box } from '../../atoms/box';
import { CommentsList } from '../../atoms/comment';
import { enterBigMarkerConference } from '../../utils/big-marker';
import { EventLayout } from '../../layouts/EventLayout';
import { RsvpButton } from '../../widgets/events-list';
import { Icon } from '../../atoms/icon';
import { isOpenToJoin, isOpenToResponse } from '../../utils/events';
import { LoaderOverlay } from '../../atoms/loader';
import { ShowMore } from '../../atoms/show-more';
import { showAlert, showSuccessErrorAlert } from '../../atoms/alert';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { Translation } from '../../atoms/translation';
import { EventOrganizers } from '../../atoms/events';
import { can } from '../../utils/permissions';
import { AutocompleteService } from '../../services/AutocompleteService';
import { onErrorReport, onSuccessReport } from '../../utils/report';
import { Tag, Card, RichTextRenderer, Tooltip, VideoPlayer } from 'ui';
import { JoinWebinarButton } from '../../atoms/join-webinar-button';
import { getLastChangeRequestStatusRedux } from 'features/events/utils';
import { EventAbout } from 'features/events/components';
import { getFeedKey } from '../../models/feed';

import { useFeedbackOptions } from 'pages/hooks';
import { EventFeedback } from 'features/events';
import { CommentsEmpty, CreateCommentForm } from 'features/feed';

const RECORD_STATUS = {
  recorded: <Trans>PLAY RECORDING</Trans>,
  not_available: <Trans>No recording available</Trans>,
  processing: <Trans>Video is processing...</Trans>,
};

class Renderer extends React.Component {
  state = {
    eventId: null,
  };

  onRequestMentions = async (value) => {
    const response = await AutocompleteService.getCommunityUsers({
      scope: 'communities',
      search: value,
      community_ids: this.props.contentObject.post_in_communities.map((c) => c.id),
      limit: 5,
    });

    if (response.ok) {
      return response.data;
    }

    return [];
  };

  descriptionRef = React.createRef();

  componentDidMount = async () => {
    await this.props.loadContentObject();
  };

  onEventActionClick = (action, event) => {
    switch (action) {
      case 'going':
      case 'not_going':
        this.props.eventChangeStatus({ id: event.id, current_user_rsvp: action });
        break;
      default:
        console.log('Unhandled action:', action);
    }
  };

  deleteEvent = async (event) => {
    if (window.confirm(this.props.t('Are you sure you want to delete this event?'))) {
      const response = await this.props.deleteEvent(event);

      showSuccessErrorAlert({
        isOk: response.ok,
        successMessage: <Trans>Event successfully deleted.</Trans>,
        errorMessage: <Trans>Event was not deleted, please try again later.</Trans>,
      });

      if (response.ok) {
        this.props.history.push(`/feed`);
      }
    }
  };

  navigateToEvent = (eventContentObject) => {
    this.props.history.push(`/events/${eventContentObject.event.id}`);
  };

  onRequestEnterConference = async (event) => {
    let errorMessage;
    if (isOpenToJoin(event)) {
      errorMessage = await enterBigMarkerConference({
        userIds: [this.props.viewer.id],
        eventId: event.id,
        exitUrl: can(event, 'Event', 'isEmailInvitee')
          ? `${window.location.origin}/conference-ended?url=${btoa(window.location.origin)}`
          : undefined,
      });
    } else {
      errorMessage = <Trans>Not able to join this video conference</Trans>;
    }

    if (errorMessage) {
      showAlert({ type: 'info', message: errorMessage });
    }
  };

  renderDialUp(dial_info) {
    if (!dial_info) {
      return null;
    }

    return (
      <Card
        id="dial-info-card"
        title={
          <div style={{ textTransform: 'uppercase' }}>
            <Trans>Dial-In Information</Trans>
          </div>
        }
      >
        <div>
          <Text lineHeight="24px">
            <Trans>Phone Number</Trans>:{' '}
            <a href={`tel:${dial_info.number};;${dial_info.id};;${dial_info.passcode}`}>{dial_info.number}</a>
          </Text>
        </div>
        <div>
          <Text lineHeight="24px">
            <Trans>Dial-in ID</Trans>: {dial_info.id}
          </Text>
        </div>
        <div>
          <Text lineHeight="24px">
            <Trans>Dial-in Passcode</Trans>: {dial_info.passcode}
          </Text>
        </div>
      </Card>
    );
  }

  renderEventStatus(status) {
    if (status === 'pending') {
      return (
        <Tag className="page-event-detail__status-tag--pending">
          <Trans>Pending admin approval</Trans>
        </Tag>
      );
    }

    if (status === 'denied') {
      return (
        <Tag className="page-event-detail__status-tag--denied">
          <div>
            <Trans>Event was declined by admin</Trans>
          </div>
          <Text color={'#FC3B2E'}>
            <Trans>We cannot allow this event because the number of invited members is exceeded.</Trans>
          </Text>
        </Tag>
      );
    }

    if (status === 'expired') {
      return (
        <Tag className="page-event-detail__status-tag--expired">
          <div>
            <Trans>Event is past due</Trans>
          </div>
          <Text color={'#717E94'}>
            <Trans>Admin did’t approve the event in time for the start of the event.</Trans>
          </Text>
        </Tag>
      );
    }

    if (status === 'approved') {
      return (
        <Tag className="page-event-detail__status-tag--approved">
          <Trans>Approved by admin</Trans>
        </Tag>
      );
    }
  }

  renderRsvp(event) {
    const status = getLastChangeRequestStatusRedux(event);

    return (
      <Card id="going-card">
        {status !== 'approved' && status !== undefined ? (
          <div className="page-event-detail__status-tag-wrapper">{this.renderEventStatus(status)}</div>
        ) : (
          <div className="row">
            <div className="col-xs-12">
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <div className="page-event-detail__rsvp-button-section">
                  <Text size="16px">
                    <Trans>Are you going?</Trans>
                  </Text>
                  <div className="page-event-detail__rsvp-button-section__rsvp-button">
                    <React.Fragment>
                      <RsvpButton
                        variant={event.current_user_rsvp === 'not_going' ? 'not_going' : 'pending'}
                        style={{ marginRight: 7 }}
                        disabled={!isOpenToResponse(event)}
                        onClick={() => this.onEventActionClick('not_going', event)}
                        id={'btn-not-going'}
                      >
                        <Tooltip item={{ title: "No, I'm not going", trigger: 'hover' }}>
                          <Icon name="close" color="inherit" size={14} />
                        </Tooltip>
                      </RsvpButton>
                      <RsvpButton
                        variant={event.current_user_rsvp === 'going' ? 'going' : 'pending'}
                        disabled={!isOpenToResponse(event)}
                        onClick={() => this.onEventActionClick('going', event)}
                        id={'btn-going'}
                      >
                        <Tooltip item={{ title: "Yes, I'm going", trigger: 'hover' }}>
                          <Icon name="check" color="inherit" size={14} />
                        </Tooltip>
                      </RsvpButton>
                    </React.Fragment>
                  </div>
                  {this.renderEventStatus(status)}
                </div>
                <div className="col-xs-2">
                  <JoinWebinarButton
                    event={event}
                    user={this.props.viewer}
                    onClick={(event) => this.onRequestEnterConference(event)}
                    id="join_event"
                  />
                </div>
              </Box>
            </div>
          </div>
        )}
      </Card>
    );
  }

  renderDescription(event) {
    const { html_content, text_content } = event;
    return (
      <Card
        title={
          <div style={{ textTransform: 'uppercase' }}>
            <Trans>Description</Trans>
          </div>
        }
      >
        <div style={{ wordBreak: 'break-word' }}>
          {html_content ? (
            <ShowMore
              collapsedText={<Trans>Continue reading</Trans>}
              expandedText={<Trans>Hide</Trans>}
              innerComponentRef={this.descriptionRef}
              cutoffHeight={100}
            >
              <div ref={this.descriptionRef}>
                <RichTextRenderer data={html_content} />
              </div>
            </ShowMore>
          ) : (
            <Text>{text_content}</Text>
          )}
        </div>
        {text_content ? <Translation id={event.id} type="event" /> : null}
      </Card>
    );
  }

  renderComments(event) {
    return (
      <Card
        id="event-comments-card"
        title={
          <div style={{ textTransform: 'uppercase' }}>
            <Trans>Comments</Trans>
          </div>
        }
      >
        {/* TODO: delete not working */}
        {event.replies?.length > 0 ? (
          <CommentsList
            item={event}
            scrollToCommentId={this.props.match?.params.commentId}
            scrollToReplyId={this.props.match?.params.replyId}
            onDeleteObject={this.props.deleteObject}
            onEditObject={this.props.updateObject}
            onLikeObject={this.props.likeObject}
            onReportObject={this.props.reportObject}
            onSortChange={this.props.sortComments}
            onBookmarkObject={this.props.bookmarkObject}
            parent="post"
            viewer={this.props.viewer}
            originalItem={event}
          />
        ) : (
          <CommentsEmpty />
        )}

        {/* TODO: create will not create comment need refresh */}
        {can(event, 'ContentObject', 'comment') ? (
          <CreateCommentForm
            viewer={this.props.viewer}
            // TODO: `event` is really bad type, not at all like network response
            parentId={event.contentObjectId}
            onRequestMentions={this.onRequestMentions}
          />
        ) : null}
      </Card>
    );
  }

  renderRecording(event) {
    const { recording_status, recording_url } = event;

    return recording_status ? (
      <ThemeConsumer>
        {() => (
          <Card
            title={
              <div style={{ textTransform: 'uppercase' }}>
                <Trans>Video recording</Trans>
              </div>
            }
          >
            <div style={{ textAlign: 'center' }}>
              {recording_status && recording_status === 'recorded' && recording_url ? (
                <VideoPlayer src={recording_url} type="video/mp4" />
              ) : (
                <Text>{RECORD_STATUS[recording_status]}</Text>
              )}
            </div>
          </Card>
        )}
      </ThemeConsumer>
    ) : null;
  }

  render() {
    return (
      <AppLayout
        center={
          <>
            <EventFeedback {...this.props.feedbackOptions} />

            <LoaderOverlay
              text={<Trans>It can take several seconds to delete an event. Please wait.</Trans>}
              loading={this.props.deletingEvent}
            >
              <EventLayout
                eventId={this.props.eventId}
                onDeleteEvent={this.deleteEvent}
                onReportEvent={this.props.reportObject}
                onBookmarkEvent={this.props.bookmarkObject}
                onDuplicateEvent={this.navigateToEvent}
                location={this.props.location}
              >
                {(object) => {
                  return (
                    <div className="row">
                      <div className="col-xs-7">
                        {this.renderRsvp(object)}
                        {object.html_content || object.text_content ? this.renderDescription(object) : null}
                        {object.dial_in_information && object.dial_in_information.id && isOpenToResponse(object)
                          ? this.renderDialUp(object.dial_in_information)
                          : null}
                        {this.renderRecording(object)}

                        {this.renderComments(object)}
                      </div>

                      <div className="col-xs-5">
                        <EventOrganizers event={object} viewer={this.props.viewer} />
                      </div>
                      <div className="col-xs-5">
                        <EventAbout event={object} viewer={this.props.viewer} />
                      </div>
                    </div>
                  );
                }}
              </EventLayout>
            </LoaderOverlay>
          </>
        }
      />
    );
  }
}

const mapState = (state, props) => ({
  eventId: props.match.params.eventId,
  viewer: select.session.user(state),
  contentObject: select.events.find(state, props.match.params.eventId),
  deletingEvent: state.loading.effects.events.deleteAsync,
});

const mapDispatch = (dispatch, props) => ({
  eventChangeStatus: (event) => dispatch.events.statusAsync(event),
  deleteEvent: (event) => dispatch.events.deleteAsync(event),
  likeObject: (object) => {
    return dispatch.feed.likeAsync({ object: object });
  },
  bookmarkObject: (object) => {
    return dispatch.feed.bookmarkAsync({ object: object });
  },
  deleteObject: async ({ object, originalItem }) => {
    if (await window.confirm('Delete this Post/Event/Comment?')) {
      return dispatch.feed.deleteAsync({ object, eventDetail: true, originalItem });
    }
  },
  reportObject: async (object) => {
    if (await window.confirm('Report this Post/Event/Comment?')) {
      return dispatch.feed.reportAsync({ object: object, onSuccess: onSuccessReport, onError: onErrorReport });
    }
  },
  updateObject: async (object) => {
    return dispatch.feed.updateAsync({ object: object });
  },
  loadContentObject: () => {
    const feed = getFeedKey(props);
    return dispatch.feed.findEventContentObjectAsync({
      feed: feed,
      eventId: props.match.params.eventId,
    });
  },
  sortComments: (object, sort) => {
    return dispatch.feed.sortCommentsAsync({ object, sort });
  },
});

const PageEventDetailFunctional = (props) => {
  const feedbackOptions = useFeedbackOptions();
  return <Renderer {...props} feedbackOptions={feedbackOptions} />;
};

export const PageEventDetail = withTranslation()(connect(mapState, mapDispatch)(PageEventDetailFunctional));
