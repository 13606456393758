import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ListUserDataObject } from 'models/service';

type CommunitiesUsersRequest = {
  community_ids: string[];
  limit?: number;
  search?: string;
  // TODO this should be type
  role?: 'admin' | 'member';
};

export const getCommunitiesUsers = async (params: CommunitiesUsersRequest) =>
  apiClient.get<ListUserDataObject[], ApiClientResponse<ListUserDataObject[]>>(routes.community.autoComplete, {
    params: {
      scope: 'communities',
      ...params,
    },
  });
