import * as React from 'react';
import { Trans } from 'react-i18next';

import { Text } from '../text';
import { Loader } from '../loader';
import { ThemeConsumer } from '../theme';
import { showAlert } from '../alert';
import { FileService } from '../../services/FileService';

export const downloadFile = async (service, fileName) => {
  const response = await service();
  fileName = fileName.replace(/[^a-z0-9_\-.]/gi, '_').toLowerCase();

  if (response.status === 200) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    showAlert({
      type: 'error',
      message: <Trans>File cannot be downloaded, please try again later.</Trans>,
    });
  }
};

export class FileDownloader extends React.Component {
  state = {
    downloading: false,
  };

  setDownloading = (downloading) => {
    this.setState({ downloading });
  };

  /* For more info see https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743 */
  downloadItem = () => {
    if (!this.state.downloading) {
      this.setDownloading(true);
      downloadFile(() => FileService.get(this.props.url), this.props.fileName);
      this.setDownloading(false);
    }
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <span
            className={`file-downloader ${this.state.downloading ? 'disabled' : ''}`}
            onClick={this.props.onClick || (this.state.downloading ? undefined : this.downloadItem)}
          >
            {this.state.downloading && <Loader size={12} color={theme.color.darkBlue} />}
            <Text color={this.props.color || theme.color.brand}>
              {this.state.downloading ? <Trans>Saving</Trans> : <Trans>Save</Trans>}
            </Text>
          </span>
        )}
      </ThemeConsumer>
    );
  }
}
