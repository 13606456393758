import { apiClient, routes } from 'common/services';
import { type OrganizationListItemDataObject } from 'models/service';

type OrganizationsListRequest = {
  limit: number;
  offset?: number;
  cursor?: string;
  search?: string;
};

export const getOrganizations = async (params: OrganizationsListRequest) =>
  apiClient.get<OrganizationListItemDataObject[]>(routes.organizations.find, { params });
