import { type OauthTokenDataObject } from './service';

export type OauthToken = {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiresIn: number;
  createdAt: number;
};

export const createOauthToken = (data: OauthTokenDataObject) => ({
  accessToken: data.access_token,
  tokenType: data.token_type,
  refreshToken: data.refresh_token,
  expiresIn: data.expires_in,
  createdAt: data.created_at,
});
