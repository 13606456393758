import { type EntityOptions } from 'models';
import { useCallback } from 'react';
import { routes } from 'routes';

export const useGetPathToEntityDetail = () => {
  return useCallback((id: string, options: EntityOptions) => {
    const { entityType } = options;

    switch (entityType) {
      case 'community':
        return routes.community.feed(id);
      case 'program':
        return routes.program.about(id);
      case 'event':
        return routes.events.detail(id);
      case 'campaign':
        // eslint-disable-next-line unicorn/consistent-destructuring
        return routes.contentObjects.campaigns(id, options.organizationId);
      case 'video':
        return routes.video.detail(id);
    }
  }, []);
};
