import { type VideoSlim } from 'models';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { DataCard } from 'ui';

type P = {
  getPathToVideoDetail: (id: string) => string;
  video: VideoSlim;
};

const VideoCard = ({ getPathToVideoDetail, video }: P) => {
  const { t } = useTranslation();

  // TODO: add another field to video entity such as recorded at date
  return (
    <DataCard
      title={video.title}
      image={{
        src: video.coverPhoto?.url ?? images.default.videoCoverPhoto,
        alt: t('Video cover image'),
      }}
      path={getPathToVideoDetail(video.id)}
    />
  );
};

export default VideoCard;
