import { Entity, type CommunityListItem } from 'models';
import { useMutation } from 'query';
import { changeRequest } from 'common/services';
import { type TelehealthRequestFormValues } from '../../components/Telehealth/TelehealthRequestForm';

export const useRequestVisit = () =>
  useMutation<Entity, { data: TelehealthRequestFormValues; selectedCommunity: CommunityListItem }>(
    async ({ data, selectedCommunity }) => {
      const patientType = selectedCommunity?.meta?.patientTypes?.find(
        (patientType) => patientType.name === data.patientType,
      );

      const visitType = selectedCommunity?.meta?.serviceTypes?.find(
        (serviceType) => serviceType.name === data.visitType,
      );

      const visitTypeOption = visitType?.children?.find((child) => child.name === data.visitTypeSubOption);

      const visitTypeSubOption = visitTypeOption?.children?.find(
        (childItem) => childItem.name === data.visitTypeSubSubOption,
      );

      if (!patientType || !visitType || !visitTypeOption || (data.visitTypeSubSubOption && !visitTypeSubOption)) {
        throw new Error('Wrong patientType / visitType / visitTypeOption / visitTypeSubOption');
      }

      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const response = await changeRequest({
        subject_type: 'Community',
        subject_id: selectedCommunity.id,
        kind: 'event_request_to_create',
        meta: {
          patient_type: {
            name: patientType.name,
            translations: patientType.translations,
          },
          visit_type: {
            name: visitType.name,
            translations: visitType.translations,
          },
          visit_type_option: {
            name: visitTypeOption.name,
            translations: visitTypeOption.translations,
          },
          visit_type_sub_option: visitTypeSubOption
            ? {
                name: visitTypeSubOption.name,
                translations: visitTypeSubOption.translations,
              }
            : undefined,
          visit_duration: data.duration,
          visit_date: data.startTime.toDate(),
          text_content: data.textContent,
          html_content: data.htmlContent,
        },
      });
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return new Entity(response.data);
    },
  );
