import { createReleaseNotes, type ReleaseNotes } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { type PaginationData, type NodeApiClientResponseError, type NodeApiError } from 'common/services';
import { type ReleaseNotesDataObject } from 'models/service';
import { getReleaseNotes } from '../services';
import { getReleaseNotesQueryKey } from '.';

export const useReleaseNotesQuery = ({ limit }: { limit: number }) =>
  useInfiniteQuery<PaginationData<ReleaseNotes>, NodeApiClientResponseError<NodeApiError>>(
    getReleaseNotesQueryKey({ limit }),
    async ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
      const { data: releaseNotes } = await getReleaseNotes({
        $limit: limit,
        $skip: pageParam,
        platform: 'fe',
      });

      return {
        total: releaseNotes.total,
        limit: releaseNotes.limit,
        skip: releaseNotes.skip,
        data: releaseNotes.data.map((releaseNote: ReleaseNotesDataObject) => createReleaseNotes(releaseNote)),
      };
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.skip + lastPage.data.length < lastPage.total ? lastPage.skip + lastPage.limit : undefined,
    },
  );
