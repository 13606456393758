import cn from 'classnames';
import { type ContentObjectRedux, getReplyData } from 'models';
import { CommentsList } from 'old/atoms/comment';
import { type RefObject } from 'react';
import { type ReplyDataObject } from 'models/service';
import { type ReduxUser } from 'models/user';
import { Comment } from '../Comment';

type P = {
  viewer: ReduxUser;
  commentDataObject: ReplyDataObject;
  contentObjectRedux: ContentObjectRedux;
  scrollToCommentId?: () => void;
  scrollToReplyId?: () => void;
  onSortChange?: () => void;
  scrollRef?: RefObject<HTMLDivElement>;
};

const CommentThread = ({
  viewer,
  commentDataObject,
  contentObjectRedux,
  scrollToCommentId,
  scrollToReplyId,
  onSortChange,
  scrollRef,
}: P) => {
  const comment = getReplyData(commentDataObject);

  return (
    <div className={cn('comment-thread', { 'comment-thread--parent': comment.parentId === contentObjectRedux.id })}>
      {/* TODO ul, li tags should be styled instead */}
      <Comment
        viewer={viewer}
        comment={comment}
        commentDataObject={commentDataObject}
        contentObjectRedux={contentObjectRedux}
        scrollRef={scrollRef}
      />

      {commentDataObject.replies && commentDataObject.replies.length > 0 ? (
        <div className="comment-thread__replies">
          <CommentsList
            viewer={viewer}
            item={commentDataObject}
            originalItem={contentObjectRedux}
            scrollToCommentId={scrollToCommentId}
            scrollToReplyId={scrollToReplyId}
            onSortChange={onSortChange}
            showSortFilter={false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CommentThread;
