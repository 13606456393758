import {
  type ReduxUser,
  type ContentObjectRedux,
  isProgramContentObject,
  isCommunityAdminInProgramContentObject,
  isOrganizationAdminInProgramContentObject,
  isCommunityAdminInCommunityContentObject,
  isOrganizationAdminInCommunityContentObject,
} from 'models';

export const canCommentContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject?: ContentObjectRedux;
}) => {
  if (!contentObject) {
    return false;
  }

  if (contentObject.type === 'campaign') {
    return false;
  }

  if (viewer.superadmin) {
    return true;
  }

  if (contentObject.contentable_type === 'Program' && isProgramContentObject(contentObject)) {
    return (
      Boolean(contentObject.program?.settings?.allow_comments) ||
      isCommunityAdminInProgramContentObject({ viewer, contentObject }) ||
      isOrganizationAdminInProgramContentObject({ viewer, contentObject })
    );
  }

  if (contentObject.contentable_type === 'Community') {
    return (
      Boolean(contentObject.post_in_communities[0]?.settings.allow_comments) ||
      isCommunityAdminInCommunityContentObject({ viewer, contentObject }) ||
      isOrganizationAdminInCommunityContentObject({ viewer, contentObject })
    );
  }

  console.error('canCommentContentObject', viewer, contentObject);

  return false;
};
